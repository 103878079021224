<div class="d-flex">
  <div class="race-progress-wrap d-flex">
    <div class="flex-fill race-progress-item race-created completed"  >Course créée</div>
    <div class="flex-fill race-progress-item gpx-downloaded" [ngClass]="{'completed': race?.gpx}">GPX téléchargé</div>
    <div class="flex-fill race-progress-item has-poi" [ngClass]="{'completed': race?.hasPoiOrga}">POI renseignés</div>
    <div class="flex-fill race-progress-item has-race-description" [ngClass]="{'completed': race?.description}">Information course</div>
  </div>
  <div class="race-progress-label">{{ percentComplete }}%</div>




</div>
