import { Observable, of, Subject } from 'rxjs';
import { Injectable, ViewContainerRef } from '@angular/core';
import { ConfirmDialogComponent } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { ModalComponent } from '../../components/dialogs/modal/modal.component';
import { EventModel } from '../../models/event.model';
import { ConfirmDeleteEventComponent } from '../../components/dialogs/confirm-delete-event/confirm-delete-event.component';
import { NotificationModel } from '../../models/notification.model';
import { NOTIFICATION_TYPE } from '../../enums/notification-type.enum';
import { SendNotificationComponent } from '../../components/dialogs/send-notification/send-notification.component';
import { OrganizerModel } from '../../models/organizer.model';
import { RaceModel } from '../../models/race.model';
import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { OnboardingRoadbookComponent } from '../../components/dialogs/onboarding-roadbook/onboarding-roadbook.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Injectable({
              providedIn: 'root'
            })
export class DialogService {

  modalOpened = false;

  openedDialogRef: MatDialogRef<any>;

  triggerCloseDialog: Subject<boolean> = new Subject<boolean>();

  constructor(private dialog: MatDialog) {
    this.triggerCloseDialog.subscribe((close: boolean) => {
      this.closeDialog();
    });
  }

  /**
   *
   * @param title
   * @param message
   * @param viewContainerRef
   * @param extraAction
   * @param extraMessage
   * @param yesLabel
   * @param noLabel
   */
  public modal(title: string,
               message: string,
               viewContainerRef: ViewContainerRef,
               extraAction?: string,
               extraMessage?: string,
               yesLabel?: string,
               noLabel?: string): Observable<string> {

    let dialogRef: MatDialogRef<ModalComponent>;
    const config = new MatDialogConfig();

    if (!this.modalOpened) {
      config.viewContainerRef = viewContainerRef;
      config.disableClose = true;

      dialogRef = this.dialog.open(ModalComponent, config);
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;
      dialogRef.componentInstance.extraAction = extraAction;
      dialogRef.componentInstance.extraMessage = extraMessage;
      dialogRef.componentInstance.yesLabel = yesLabel;
      dialogRef.componentInstance.noLabel = noLabel;

      this.openedDialogRef = dialogRef;

      this.modalOpened = true;
      return dialogRef.afterClosed();
    } else {
      return of('');
    }

  }

  /**
   *
   * @param title
   * @param message
   * @param viewContainerRef
   * @param extraAction
   * @param extraMessage
   * @param yesLabel
   * @param noLabel
   */
  public confirm(title: string,
                 message: string,
                 viewContainerRef: ViewContainerRef,
                 extraAction?: string,
                 extraMessage?: string,
                 yesLabel?: string,
                 noLabel?: string): Observable<string> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.extraAction = extraAction;
    dialogRef.componentInstance.extraMessage = extraMessage;
    dialogRef.componentInstance.yesLabel = yesLabel;
    dialogRef.componentInstance.noLabel = noLabel;

    this.openedDialogRef = dialogRef;

    return dialogRef.afterClosed();
  }

  /**
   *
   * @param title
   * @param event
   * @param viewContainerRef
   */
  public confirmDeleteEvent(title: string, event: EventModel,
                            viewContainerRef: ViewContainerRef): Observable<string> {

    let dialogRef: MatDialogRef<ConfirmDeleteEventComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;
    config.disableClose = true;
    config.maxWidth = '450px';

    dialogRef = this.dialog.open(ConfirmDeleteEventComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.event = event;

    this.openedDialogRef = dialogRef;

    return dialogRef.afterClosed();
  }

  /**
   *
   * @param defaultSubject
   * @param defaultTitle
   * @param defaultContent
   */
  notifyTrailers(organizer: OrganizerModel,
                 race: RaceModel,
                 viewContainerRef: ViewContainerRef,
                 defaultSubject?: string,
                 defaultTitle?: string,
                 defaultContent?: string) {

    let dialogRef: MatDialogRef<SendNotificationComponent>;

    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = '680px';

    const notificationToSend = new NotificationModel();
    notificationToSend.sender = organizer;
    notificationToSend.emailNotification = true;
    notificationToSend.internNotification = true;
    notificationToSend.type = NOTIFICATION_TYPE.INFO;
    notificationToSend.retrieveRecipientsFromRelatedObject = true;
    notificationToSend.relatedObject = 'Race';
    notificationToSend.relatedObjectId = race.id;

    if (defaultTitle) { notificationToSend.title = defaultTitle; }
    if (defaultSubject) { notificationToSend.subject = defaultSubject; }
    if (defaultContent) { notificationToSend.content = defaultContent; }

    dialogRef = this.dialog.open(SendNotificationComponent, dialogConfig);

    // on renseigne les champs du form
    dialogRef.componentInstance.notificationToSend = notificationToSend;

    // La popup renvoi le poi créé après fermeture de la popup
    dialogRef.afterClosed().subscribe((ret: any) => {
      if ('undefined' !== typeof ret) {
      }
      dialogRef = null;
    });
  }

  /**
   *
   * @param typeUserToLogin
   * @param selectedIndex
   */
  openSignInPopup(typeUserToLogin, selectedIndex, callbackUrl? :string ) {
    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.width = '450px';

    dialogConfig.data = {selectedIndex, typeUser:typeUserToLogin, callbackUrl};

    const dialogSignInRef: MatDialogRef<SignInComponent, any> = this.dialog.open(SignInComponent, dialogConfig);
    // save dialog ref to access it to close it at navigatiion
    this.openedDialogRef = dialogSignInRef;
  }

  /**
   *
   */
  public closeDialog() {
    if (this.openedDialogRef) {
      this.openedDialogRef.close();
    }
  }


  openDefaultAverageSpeedDialog(averageSpeed: number): Observable<string> {
    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    dialogConfig.data = {
      averageSpeed
    };

    const dialogAverageSpeedRef: MatDialogRef<OnboardingRoadbookComponent, any> = this.dialog.open(OnboardingRoadbookComponent,
                                                                                                   dialogConfig);
    // save dialog ref to access it to close it at navigatiion
    this.openedDialogRef = dialogAverageSpeedRef;

    return dialogAverageSpeedRef.afterClosed();
  }


}
