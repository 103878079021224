import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RaceModel } from '../../../../models/race.model';
import { UserService } from '../../../../services/user/user.service';

@Component({
  selector: 'race-list-item',
  templateUrl: './race-list-item.component.html',
  styleUrls: ['./race-list-item.component.scss'],
})

export class RaceListItemComponent implements OnInit {

  hasError = false;

  @Input() race: RaceModel;
  @Input() id: number;
  @Output() raceSelected: EventEmitter<RaceModel> = new EventEmitter<RaceModel>();

  constructor(protected userService: UserService,
              public router: Router,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
  }

  selectRace() {

    this.raceSelected.emit(this.race);
    this.router.navigate(['./races/show', this.race.id], {relativeTo: this.route});
  }



}
