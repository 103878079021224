import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { SpinnerService } from '../../../services/common/spinner.service';


@Component({
  selector: 'main-spinner-cmp',
  templateUrl: './main-spinner.component.html',
  styleUrls: ['./main-spinner.component.scss']
})
export class MainSpinnerComponent implements OnInit {

  // Sets initial value to true to show loading spinner on first load
  @Input() active = false;
  message = 'Chargement...';

  @Input() id: string; // id du spinner
  @Input() size: string; // small , medium, big

  cancelTransaction: boolean;

  public constructor(spinner: SpinnerService,
                     private renderer: Renderer2 ) {
    spinner.status.subscribe((status: boolean) => {
      this.active = status;
      if (this.active) {this.launchCancelTimer(); }

    });
    spinner.content.subscribe((message: string) => {
      this.message = message;
    });
  }

  ngOnInit() {
  }

  launchCancelTimer() {
    this.cancelTransaction = false;
    setTimeout(() => {
      this.message = 'Le temps de traitement semble un peu long...';
      this.cancelTransaction = true;
    }, 10000);
  }

  close() {
    this.active = false;
  }
}
