import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { EventModel } from "../../../models/event.model";
import { UserService } from "../../../services/user/user.service";
import { OrganizerModel } from "../../../models/organizer.model";
import { CommonService } from "../../../services/common/common.service";
import { SpinnerService } from "../../../services/common/spinner.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { ErrorService } from "../../../services/common/error.service";
import { EventService } from "../../../services/event/event.service";
import { ORGANIZER, TRAILER, UserModel } from "../../../models/user.model";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { DialogService } from "../../../services/common/dialog.service";
import { HttpStatusCode } from "@angular/common/http";
import { UsersValidator } from "../../../shared/validators/users.validator";
import { AvatarModel } from "../../../models/avatar.model";
import { SearchParam } from "../../../classes/search-param";
import { DataService } from "../../../services/common/data.service";
import { TerrainModel } from "../../../models/terrain.model";

@Component({
  selector: "app-register-orga-stepper",
  templateUrl: "./register-orga-stepper.component.html",
  styleUrls: ["./register-orga-stepper.component.scss"],
})
export class RegisterOrgaStepperComponent implements OnInit, AfterViewInit {
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  defaultSelectedIndex = 0;

  organizerIdCtrl: UntypedFormControl;
  organizmCtrl: UntypedFormControl;
  organizmWebsiteCtrl: UntypedFormControl;
  lastnameCtrl: UntypedFormControl;
  firstnameCtrl: UntypedFormControl;
  avatarCtrl: UntypedFormControl;
  isNotificationsAllowedCtrl: UntypedFormControl;

  recaptchaCtrl: UntypedFormControl;

  eventNameCtrl: UntypedFormControl;
  terrainCtrl: UntypedFormControl;
  eventWebsiteCtrl: UntypedFormControl;
  eventEditionCtrl: UntypedFormControl;
  dateBeginCtrl: UntypedFormControl;
  dateEndCtrl: UntypedFormControl;
  statusCtrl: UntypedFormControl;

  registerOrganizerForm: UntypedFormGroup;
  eventForm: UntypedFormGroup;
  eventDateForm: UntypedFormGroup;
  private event: EventModel;

  avatars: Array<AvatarModel>;

  terrains: Array<TerrainModel>;

  @ViewChild("autocompleteLocation") autocompleteLocation: ElementRef;
  private registeringOrganizer: OrganizerModel;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private ref: ApplicationRef,
    private userService: UserService,
    private commonService: CommonService,
    private eventService: EventService,
    private spinner: SpinnerService,
    private snackbarService: SnackbarService,
    private dataService: DataService,
    private errorService: ErrorService,
    private dialogService: DialogService
  ) {
    this.organizmCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.organizmWebsiteCtrl = new UntypedFormControl(
      "",
      Validators.compose([UsersValidator.validateWebsite])
    );
    this.lastnameCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.firstnameCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.avatarCtrl = new UntypedFormControl(null);

    this.eventNameCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.eventWebsiteCtrl = new UntypedFormControl(
      "",
      Validators.compose([UsersValidator.validateWebsite])
    );
    this.statusCtrl = new UntypedFormControl("draft");
    this.terrainCtrl = new UntypedFormControl(
      null,
      Validators.compose([Validators.required])
    );

    this.eventEditionCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.dateBeginCtrl = new UntypedFormControl(
      null,
      Validators.compose([Validators.required])
    );
    this.dateEndCtrl = new UntypedFormControl(
      null,
      Validators.compose([Validators.required])
    );

    this.userService.registeringUser.subscribe((organizer: OrganizerModel) => {
      if (organizer) {
        this.registeringOrganizer = organizer;
      }
    });
  }

  /**
   *
   */
  ngOnInit() {
    this.defaultSelectedIndex = 0;
    this.buildForm();
    this.loadAvatars(ORGANIZER);
    this.populateDropDownLists();
    this.event = new EventModel();
  }

  private buildForm() {
    this.registerOrganizerForm = this.formBuilder.group({
      organizm: this.organizmCtrl,
      firstname: this.firstnameCtrl,
      lastname: this.lastnameCtrl,
      avatar: this.avatarCtrl,
      organizmWebsite: this.organizmWebsiteCtrl,
    });

    this.eventForm = this.formBuilder.group({
      status: this.statusCtrl,
      name: this.eventNameCtrl,
      terrain: this.terrainCtrl,
      website: this.eventWebsiteCtrl,
    });

    this.eventDateForm = this.formBuilder.group({
      edition: this.eventEditionCtrl,
      dateBegin: this.dateBeginCtrl,
      dateEnd: this.dateEndCtrl,
    });
  }

  ngAfterViewInit() {
    this.autocomplete();
  }

  next() {}

  /**
   *
   */
  populateDropDownLists() {
    this.dataService.getDropDownList("terrains").subscribe(
      (terrains: any) => {
        this.terrains = terrains;
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  completeEvent() {
    if (this.eventForm.valid && this.eventDateForm.valid) {
      this.event = {
        ...this.event,
        ...this.eventForm.value,
        ...this.eventDateForm.value,
      };
      const dataOrga: OrganizerModel = {
        ...this.registeringOrganizer,
        ...this.registerOrganizerForm.value,
      };

      this.spinner.start("Initialisation de votre espace organisateur...");

      this.userService
        .registerOrganizer(dataOrga)
        .pipe(
          map((registeredUser: OrganizerModel) => {
            // dataEvent.organizerId = registeredUser.id;
            this.event.organizer = registeredUser;
            return registeredUser;
          }),
          catchError((e) => {
            const formErrorMessage: string = this.errorService.handleFormError(
              e.error
            );
            e.message = formErrorMessage
              ? formErrorMessage
              : "Erreur lors de la création de votre espace - Veuillez contacter" +
                " l'equipe Splits";
            return throwError(e);
          }),
          switchMap(() => {
            this.spinner.updateMessage(
              "Chargement de votre espace organisateur..."
            );
            return this.userService.login({
              email: this.registeringOrganizer.email,
              password: this.registeringOrganizer.matchingPasswords.password,
              type_user: ORGANIZER,
            });
          }),
          catchError((e) => {
            const errorCode = this.errorService.extractErrorCode(e);
            e.message =
              errorCode === HttpStatusCode.Unauthorized
                ? "Erreur lors de la connexion à l'espace organisateur"
                : e.message;
            this.errorService.handleError(e);
            return throwError(() => new Error(e));
          }),
          switchMap(() => {
            this.spinner.updateMessage(
              "Création de votre premier événement..."
            );
            return this.eventService.postEvents(this.event);
          }),
          catchError((e) => {
            const formErrorMessage: string = this.errorService.handleFormError(
              e.error
            );
            e.message = formErrorMessage
              ? formErrorMessage
              : "Erreur lors de la création de votre événement - Veuillez contacter" +
                " l'equipe Splits";
            return throwError(() => new Error(e));
          })
        )
        .subscribe({
          next: () => {
            this.spinner.stop();
            this.stepper.next();
          },
          error: (error) => {
            this.errorService.handleError(error);
          },
        });
    }
  }

  /**
   *
   */
  enter() {
    this.spinner.start("Connexion à votre espace organisateur...");
    this.dialogService.triggerCloseDialog.next(true);
    this.spinner.stop();
    this.router.navigate(["/", ORGANIZER, "home"]);

    // this.userService
    //   .login({
    //     email: this.registeringOrganizer.email,
    //     password: this.registeringOrganizer.matchingPasswords.password,
    //     type_user: ORGANIZER,
    //   })
    //   .subscribe(
    //     (user: UserModel) => {
    //       // close dialog
    //       this.dialogService.triggerCloseDialog.next(true);
    //       this.spinner.stop();
    //       this.router.navigate(["/", ORGANIZER, "home"]);
    //     },
    //     (error) => {
    //       const errorCode: any = this.errorService.extractErrorCode(error);
    //       error.message =
    //         errorCode === HttpStatusCode.Unauthorized
    //           ? "Erreur lors de la connexion à l'espace organisateur"
    //           : error.message;
    //       this.errorService.handleError(error);
    //     }
    //   );
  }

  autocomplete() {
    const componentForm = {
      locality: "long_name",
      country: "long_name",
      postal_code: "short_name",
      administrative_area_level_2: "short_name",
      administrative_area_level_1: "short_name",
    };

    const options = {
      // componentRestrictions: {country: 'fr'}
    };

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteLocation.nativeElement,
      options
    );

    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      if (place) {
        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        this.event.formattedAddress = place.formatted_address;

        for (let i = 0; i < place.address_components.length; i++) {
          const addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            const val = place.address_components[i][componentForm[addressType]];
            switch (addressType) {
              case "locality":
                this.event.locality = val;
                break;
              case "country":
                this.event.country = val;
                break;
              case "postal_code":
                this.event.postalCode = val;
                break;
              case "administrative_area_level_2":
                this.event.administrativeAreaLevel2 = val;
                break;
              case "administrative_area_level_1":
                this.event.administrativeAreaLevel1 = val;
                break;
            }
          }
        }
        if (place.geometry) {
          this.event.latitude = place.geometry.location.lat();
          this.event.longitude = place.geometry.location.lng();
        }
      }
    });
  }

  protected loadAvatars(typeAvatar: string = TRAILER) {
    // get terrains dropdownlist
    this.dataService
      .getDropDownList("avatars", [new SearchParam("type", typeAvatar)])
      .pipe(
        tap((avatars: AvatarModel[]) => this.avatarCtrl.setValue(avatars[0]))
      )
      .subscribe((avatars: AvatarModel[]) => {
        this.avatars = avatars;
      });
  }
}
