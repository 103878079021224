import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { EventModel } from '../../../models/event.model';
import { EventService } from '../../../services/event/event.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { RaceModel } from '../../../models/race.model';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ToolbarService } from '../../../services/common/toolbar.service';

@Component({
             selector: 'app-event-show',
             templateUrl: './event-show.component.html',
             styleUrls: ['./event-show.component.scss']
           })
export class EventShowComponent implements OnInit {
  event: EventModel;
  private hasError: boolean;
  requestRaceForDetails: RaceModel;
  heroBannerUrl: string;
  eventLoaded = false;

  @ViewChild('eventShowTab') eventShowTab: MatTabGroup;

  constructor(private eventService: EventService,
              private snackbarService: SnackbarService,
              private route: ActivatedRoute,
              private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap(() => this.eventLoaded = false),
      switchMap((paramMap: ParamMap) => {
                  const eventId = +paramMap.get('eventId');
                  const raceId = +paramMap.get('raceId');
                  return this.getEvent(eventId, raceId);
                }
      )).subscribe((event: EventModel) => {
                     this.event = event;
                     this.heroBannerUrl = this.eventService.retrieveEventHeroBannerUrlFromTerrain(event.terrain);
                   },
                   error => {
                     this.hasError = true;
                     this.snackbarService.openDanger('Evénement introuvable');
                   }
    );
  }

  /**
   *
   * @param eventId
   */
  getEvent(eventId: number, raceId?: number) {
    return this.eventService.getOneEvent(eventId).pipe(map((event) => {
                                                             event.races = event.races.filter((race: RaceModel) => race.status === 'published');
                                                             if (event.races && event.races.length >= 1) {
                                                               let raceToDisplay: RaceModel = null;
                                                               if (raceId) {
                                                                 raceToDisplay = event.races.filter(race => race.id === raceId)[0];
                                                                 this.eventShowTab.selectedIndex = 1;
                                                               } else {
                                                                 raceToDisplay = event.races[0];
                                                               }
                                                               this.displayRaceDetail(raceToDisplay);
                                                             }
                                                             return event;
                                                           }
    ), finalize(() => this.eventLoaded = true));
  }

  triggerTabSelectAndRequestForDetails(race: RaceModel) {
    this.eventShowTab.selectedIndex = 1;
    this.requestRaceForDetails = race;
  }

  displayRaceDetail(race: RaceModel) {
    this.requestRaceForDetails = race;
  }
}
