import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  averageSpeed: number;
}

@Component({
  selector: 'app-onboarding-roadbook',
  templateUrl: './onboarding-roadbook.component.html',
  styleUrls: ['./onboarding-roadbook.component.scss']
})
export class OnboardingRoadbookComponent implements OnInit {

  averageSpeed: number;

  constructor(
    public dialogRef: MatDialogRef<OnboardingRoadbookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }

  ngOnInit() {
  }

}
