/**
 * Created by macbookaxel on 23/06/2016.
 */
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { SnackbarService } from '../../services/notifications/snackbar.service';
import { BaseComponent } from '../../components/base.component';
import { DialogService } from '../../services/common/dialog.service';

import { map } from 'rxjs/operators';

@Injectable({
              providedIn: 'root'
            })
export class SaveFormsGuard implements CanDeactivate<any> {

  constructor(private router: Router,
              private snackBarService: SnackbarService,
              private dialogService: DialogService) {
  }

  canDeactivate(component: BaseComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    let ret: boolean;

    if (component.unsavedChanges) {

      return this.dialogService
        .confirm('Modifications non sauvegardées',
                 'Des modifications sur le formulaire n\'ont pas été sauvegardées.<br> Souhaitez vous réellement quitter cette page sans sauvegarder ?',
                 component.viewContainerRef,
                 null,
                 null,
                 'Quitter',
                 'Rester')
        .pipe(map(res => {
          if (typeof res != 'undefined' && res == 'confirm') {
            ret = true;
          } else {
            ret = false;
          }
          return ret;
        }));
    }
    return true;
  }
}
