import { Injectable } from '@angular/core';
import { Trkpt } from '../../classes/trkpt';
import { PoiModel } from '../../models/Pois/poi.model';
import { ChartPoint } from 'chart.js';
import { CustomChartPoint } from '../../classes/chart.js/custom.chart.js';
import { Subject } from 'rxjs';
import { PoiTypes } from '../../models/Pois/poi.type.model';

@Injectable({
              providedIn: 'root'
            })
export class ChartService {

  public refreshGraphSubject = new Subject<boolean>();

  constructor() {
  }

  /** Retourne un ChartPoint à partir d'un Trkpt
   *
   * @param trkpt
   * @returns {ChartPoint}
   */
  public getCustomChartPointFromTrkpt(trkpt: Trkpt): CustomChartPoint {
    const chartPoint: CustomChartPoint = {
      x: trkpt.total_distance,
      y: trkpt.ele,
      lat: trkpt.lat,
      lon: trkpt.lon
    };
    return chartPoint;
  }


  /** Retourne un ChartPoint à partir d'un PoiModel
   *
   * @param poi
   * @returns {ChartPoint}
   */
  public getCustomChartPointFromPoi(poi: PoiModel): CustomChartPoint {
    return {
      id: poi.internId,
      x: poi.distance,
      y: poi.elevation,
      lat: poi.latitude,
      lon: poi.longitude
    };
  }

  /** Retourne un ChartPoint à partir d'un PoiModel
   *
   * @param poi
   * @returns {ChartPoint}
   */
  public getPoiFromChartPoint(pois: Array<PoiModel>, distance: number, poiType?: PoiTypes): PoiModel {

    let poisFiltered: PoiModel[];

    if (pois != null) {
      poisFiltered = pois.filter(currPoi =>(currPoi.distance === distance));
    }

    if (poisFiltered && poisFiltered.length === 1) {
      return poisFiltered[0];
    } else {
      return null;
    }
  }

  /** Renvoi le Trkpt le plus proche du Poi
   *
   * @param poi
   */
  public getClosestTrkptOfPoi(distance: number, trkpts: Array<Trkpt>): Trkpt {

    let closestTrkpt: Trkpt = new Trkpt();

    if (typeof trkpts !== 'undefined') {
      closestTrkpt = trkpts.reduce((prev, curr) => {
        return (Math.abs(curr.dst - distance) < Math.abs(prev.dst - distance) ? curr : prev);
      });
    }

    return closestTrkpt;

  }

  /**
   *
   * @param idx
   * @param trkpts
   */
  public getTrkptByX(_x: number, trkpts: Array<Trkpt>): Trkpt {

    let arrTrkpt: Trkpt[];

    if (typeof trkpts != 'undefined') {
      arrTrkpt = trkpts.filter((_trkpt: Trkpt) => (_trkpt.dst == _x));
    }

    return (typeof arrTrkpt[0] !== 'undefined') ? arrTrkpt[0] : null;
  }


}
