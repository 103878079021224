import { animate, style, transition, trigger } from "@angular/animations";
import {
  AfterViewInit,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from "@angular/router";
import moment from "moment";
import { filter, map, mergeMap } from "rxjs/operators";

import { environment } from "../environments/environment";
import { CommonService } from "./services/common/common.service";
import { DialogService } from "./services/common/dialog.service";
import { LoaderService } from "./services/common/loader.service";
import { SpinnerService } from "./services/common/spinner.service";

@Component({
  selector: "app-splits-app",
  viewProviders: [Title],
  templateUrl: "./app-splits.component.html",
  styleUrls: ["./app-splits.component.scss"],
  animations: [
    trigger("routeAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class AppSplitsComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private loader: LoaderService,
    private viewContainerRef: ViewContainerRef,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    moment.locale("fr");
  }

  ngOnInit() {
    // gestion de l'affichage du titre
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        let title: string = environment.siteTitle;
        title += typeof event.title !== "undefined" ? " : " + event.title : "";
        this.titleService.setTitle(title);
      });

    // on intercepte les autres événements de navigation
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngAfterViewInit() {
    const ie: any = this.detectIE();
    if (ie && ie < 12) {
      this.dialogService
        .modal(
          "Vous naviguez avec internet explorer ?",
          "Splits est optimisé pour les navigateurs de dernière génération. " +
            "Pour une meilleure expérience, nous vous invitons à naviguer avec Chrome, Firefox, Safari ou Edge ",
          this.viewContainerRef,
          null,
          null,
          "J'ai compris"
        )
        .subscribe((res) => {
          if (typeof res !== "undefined" && res === "confirm") {
            this.dialogService.modalOpened = false;
          }
        });
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0);
      this.commonService.scroll(true);
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.spinner.stop();
      this.loader.hide();
    }
  }

  prepRouteState(outlet: any) {
    return outlet.activatedRouteData.animation || "firstPage";
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  }

  ngOnDestroy() {
    this.spinner.status.unsubscribe();
    this.loader.loaderSubject.unsubscribe();
  }
}
