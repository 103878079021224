import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, Validators } from "@angular/forms";

import { ORGANIZER } from "../../../models/user.model";
import { PoiBaseComponent } from "../poi-base-component";

@Component({
  selector: "add-poi-cmp",
  templateUrl: "./add-poi.component.html",
  styleUrls: ["./add-poi.component.scss"],
})
export class AddPoiComponent extends PoiBaseComponent implements OnInit {
  addSuccessfully: boolean;

  @ViewChild("roadbookPassportForm") roadbookPassportForm: NgForm;

  ngOnInit() {
    super.ngOnInit();
    super.buildForm();
    this.populatePoiInfoWithPreviousPoi(this.workingPoi.distance);
    if (this.workingPoi.origin === ORGANIZER) {
      this.poiTypesCtrl.setValidators([Validators.required]);
    }
    this.poiForm.patchValue(this.workingPoi);
  }

  /** Sauvegarde de la course
   *
   * Recupère la nouvelle entité Race
   *
   * @returns {TeardownLogic|AnonymousSubscription|Subscription}
   */
  savePoi() {
    if (this.poiForm.valid) {
      super.savePoi();
      this.submitted = true;

      // on reporte les modifications du Poi de travail sur le POI
      // évite que si on annule ou on clique en dehors de la fenêtre, on édite quand même le POI
      this.poi = { ...this.workingPoi, ...this.poiForm.value };

      const ret = {
        poi: this.poi,
        action: "add",
      };
      this.dialogRef.close(ret);
      this.snackbarService.openSuccess("Point d'intéret ajouté avec succès");
    }
  }
}
