import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConvertorService } from '../../../services/common/convertor.service';

@Component({
             selector: 'app-duration',
             templateUrl: './duration.component.html',
             styleUrls: ['./duration.component.scss']
           })
export class DurationComponent implements OnInit, OnChanges {

  @Input() durationInSeconds: number;
  @Input() disabled: boolean;

  @Output() durationChange: EventEmitter<number> = new EventEmitter<number>();

  hours = 0;
  minutes = 0;
  seconds = 0;

  constructor(private convertorService: ConvertorService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.durationInSeconds) {
      this.setDurationFields();
    }
  }

  private setDurationFields() {
    const durationFull = this.convertorService.convertDurationFromSecondToFullJson(this.durationInSeconds);

    if (typeof durationFull !== 'undefined') {
      this.hours = durationFull.hours;
      this.minutes = durationFull.minutes;
      this.seconds = durationFull.seconds;
    }
  }

  check(type) {
    if (type === 'hours') {
      this.hours = this.getValidValue(this.hours, 23);
    } else if (type === 'minutes') {
      this.minutes = this.getValidValue(this.minutes, 59);
    } else if (type === 'seconds') {
      this.seconds = this.getValidValue(this.seconds, 59);
    }

    this.durationInSeconds = this.convertorService.getTimeInSecond(this.hours, this.minutes, this.seconds);
    if (this.durationInSeconds) {
      this.durationChange.emit(this.durationInSeconds);
    }
  }

  getValidValue(value, max) {
    let n;
    if (/^\d+$/.test(value)) {
      n = +value;
      n = Math.max(0, n);
      n = Math.min(max, n);
      return n;
    } else {
      return 0;
    }
  }

}
