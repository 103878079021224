import { AddressModel } from "./address.model";
import { Trkpt } from "../classes/trkpt";
import { RoadbookModel } from "./roadbook.model";
import { PoiModel } from "./Pois/poi.model";
import { RACE_TYPE_ENUM } from "../enums/roadbook.enum copy";

export class RaceBaseModel {
  id: number;
  name: string;
  raceType: RACE_TYPE_ENUM;
  description: string;

  date: Date;
  departureTime: Date;

  distance: number;
  ascendingElevation: string;
  negativeElevation: string;
  address: AddressModel;

  status: "draft" | "published" | "archived" | "canceled";

  formattedAddress: string;
  postalCode: string;
  locality: string;
  country: string;
  administrativeAreaLevel1: string; // département
  administrativeAreaLevel2: string; // région
  latitude: number;
  longitude: number;

  // nom de fichier gpx
  gpx: string;
  // nom de fichier kml
  kml: string;
  isGpxModified: boolean;
  //
  trkpts: Array<Trkpt>;

  roadbooks: Array<RoadbookModel>;

  ephemeris: {
    date: Date;
    sunrise: Date;
    sunset: Date;
  }[];

  gpxFileUpload: any;

  parentRaceRefId: number;

  pois: Array<PoiModel> = [];
  _poisOrga: Array<PoiModel> = [];
  _poisTrailer: Array<PoiModel> = [];

  hasPoiOrga: boolean;

  countRoadbooks: number;

  public constructor(init?: Partial<RaceBaseModel>) {
    Object.assign(this, init);
  }

  get poisOrga(): Array<PoiModel> {
    this._poisOrga = this.pois
      ? this.pois.filter((poi: PoiModel) => poi.origin === "organizer")
      : [];
    return this._poisOrga;
  }

  get poisTrailer(): Array<PoiModel> {
    this._poisTrailer = this.pois
      ? this.pois.filter((poi: PoiModel) => poi.origin === "trailer")
      : [];
    return this._poisTrailer;
  }
}
