import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { filter, switchMap } from "rxjs/operators";

import { CommonService } from "../../services/common/common.service";
import { ErrorService } from "../../services/common/error.service";
import { SpinnerService } from "../../services/common/spinner.service";
import { SnackbarService } from "../../services/notifications/snackbar.service";
import { UserService } from "../../services/user/user.service";
import { UsersValidator } from "../../shared/validators/users.validator";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  emailCtrl: UntypedFormControl;
  passwordCtrl: UntypedFormControl;
  confirmPasswordCtrl: UntypedFormControl;
  typeUserCtrl: UntypedFormControl;
  tosCtrl: UntypedFormControl;
  recaptchaCtrl: UntypedFormControl;

  registerForm: UntypedFormGroup;
  matchingPasswords: UntypedFormGroup;
  submitted = false;
  hasError = false;
  errorMessage: string;

  @Input() typeUserToRegister: string;
  emailAvailable = true;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private errorService: ErrorService
  ) {
    this.emailCtrl = new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        UsersValidator.validateEmail,
      ])
    );

    this.passwordCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required, Validators.minLength(8)])
    );
    this.confirmPasswordCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );

    this.matchingPasswords = new UntypedFormGroup(
      {
        password: this.passwordCtrl,
        confirm_password: this.confirmPasswordCtrl,
      },
      [UsersValidator.MatchValidator("password", "confirm_password")]
    );

    this.typeUserCtrl = new UntypedFormControl(this.typeUserToRegister, [
      Validators.required,
    ]);
    this.recaptchaCtrl = new UntypedFormControl(null);

    this.registerForm = this.formBuilder.group({
      email: this.emailCtrl,
      type_user: this.typeUserCtrl,
      matchingPasswords: this.matchingPasswords,
      recaptcha: this.recaptchaCtrl,
      tos: this.tosCtrl,
    });
  }

  ngOnInit() {
    this.emailCtrl.valueChanges
      .pipe(
        filter(() => this.emailCtrl.valid),
        switchMap((email) => this.userService.checkUserEmailAvailable(email))
      )
      .subscribe(
        (isAvailable) => {
          this.emailCtrl.setErrors(
            !isAvailable ? { emailAvailability: !isAvailable } : null
          );
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
  }

  register(): any {
    if (this.registerForm.valid) {
      this.submitted = true;
      this.userService.registeringUser.next(this.registerForm.value);
    }
  }
}
