import {Injectable} from '@angular/core';

import {forkJoin, Observable, of} from 'rxjs';

import {HttpService} from '../http/http.service';
import {SearchParam} from '../../classes/search-param';
import {environment} from '../../../environments/environment';
import moment from 'moment';
import {RaceModel} from '../../models/race.model';
import {ConvertorService} from './convertor.service';

// TODO : A mettre à jour dès qu'on a la bonne version de ngbootstrap ou material
// import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  externalApiUrl: string = environment.sunsetSunriseApiUrl;

  ephemeris: any;

  private searchEphemeristParams: Array<SearchParam>;

  constructor(private http: HttpService, private convertorService: ConvertorService) {
  }

  public newUTCDate(date: Date) {
    return moment(date).toDate();
  }

  /** Retourne le timestamp d'une date
   * en milliseconds
   * @param date
   */
  public getTimestamp(date: Date) {
    if (typeof date !== 'undefined' && date) {
      return date.getTime();
    }
  }

  public getTimestampInSecond(date: Date) {
    if (typeof date !== 'undefined' && date) {
      return date.getTime() / 1000;
    }
  }


  /** call sunrise Sunset API that provides sunset and sunrise times for a given latitude and longitude.
   *
   * @param lat : Latitude in decimal degrees. Required.
   * @param lng : Longitude in decimal degrees. Required.
   * @param date : Date in YYYY-MM-DD format. Also accepts other date formats and even relative date formats. If not present, date defaults to current date. Optional.
   * @param additionalDays : Extra days to get ephemeris for
   * @returns {ErrorObservable<T>}
   */

  public getEphemeris(lat: number, lng: number, date: Date, username: string = 'splits'): Observable<any> {

    this.searchEphemeristParams = new Array<SearchParam>();

    const dateCheck = moment(date).format('YYYY-MM-DD');

    // ajout d'un critère de recherche
    this.searchEphemeristParams.push(new SearchParam('lat', lat));
    this.searchEphemeristParams.push(new SearchParam('lng', lng));
    this.searchEphemeristParams.push(new SearchParam('date', dateCheck));
    this.searchEphemeristParams.push(new SearchParam('username', 'splits'));
    this.searchEphemeristParams.push(new SearchParam('formatted', '0'));

    this.ephemeris = this.http.getExternal(this.externalApiUrl, this.searchEphemeristParams);

    return this.ephemeris;
  }


  /**
   *
   * @param race
   * @param additionalDays
   * @returns {any}
   */
  public getEphemerisMultipleDays(dateLocation: { date: Date, lat: number, lon: number }, additionalDays): Observable<any> {
    if (typeof dateLocation.date !== 'undefined') {
      const observableBatch = [];

      for (let i = 0; i <= additionalDays; i++) {
        observableBatch.push(
          this.getEphemeris(dateLocation.lat, dateLocation.lon, dateLocation.date, environment.sunsetSunriseApiUsername)
        );
        dateLocation.date.setDate(dateLocation.date.getDate() + 1);
      }
      return forkJoin(observableBatch);

    } else {
      return of([]);
    }

  }

  /** Retourne l'intervale de temps entre une date et aujourdui
   *
   * @param date1
   */
  public getIntervalToToday(date: Date, format: any = 'days') {

    let intervalBetweenDate = 0;

    if (typeof date !== 'undefined') {
      const today = new Date();
      const raceDate = new Date(date);

      intervalBetweenDate = this.getIntervalBetweenDate(raceDate, today, format);
    }

    return intervalBetweenDate;
  }

  /** Retourne l'intervale de temps entre 2 dates
   *
   * @param date1
   * @param date2
   * @param format - format retourner. (days,months,seconds,...) / Défault "days"
   */
  public getIntervalBetweenDate(dateAfter: Date, dateBefore: Date, format: any = 'days') {

    let interval = 0;

    if (typeof dateAfter !== 'undefined' && typeof dateAfter !== 'undefined') {
      interval = moment(dateAfter).diff(moment(dateBefore), format);
    }
    return interval;
  }

  /**
   *
   * @param date1
   * @param format 'YYYY-MM-DD' par défaut
   * @returns {Date}
   */
  public formatDateXBrowsers(date: string, time: string = null, format: any = 'YYYY/MM-DD') {

    let dateToConvert: string = date, dateXBrowsersFormatted: string;
    dateToConvert += (time) ? ' ' + time : null;

    if (dateToConvert) {
      dateXBrowsersFormatted = dateToConvert.replace(/-/g, '/');
    }
    return dateXBrowsersFormatted;
  }


  /**
   *
   * @param intervalInSeconds
   * @returns {string}
   */
  getIntervalLabel(intervalInSeconds: any): any {

    let intervalPrefix = '';
    let intervalLabel = '';

    if (intervalInSeconds > 0) {
      const intervalFull = this.convertorService.convertDurationFromSecondToFullJson(intervalInSeconds, false);

      if (intervalFull.days > 1) {
        intervalPrefix = 'J- ';
        intervalLabel = intervalFull.days.toString();
      } else {
        intervalPrefix = 'H- ';
        const duration = moment.duration(intervalInSeconds, 'seconds');
        intervalLabel = ` titi ${duration.hours().toString()}h${duration.minutes().toString()}:${duration.seconds().toString()}`;
      }
    } else {
      intervalLabel = 'Course terminée';
    }

    return {
      intervalPrefix,
      intervalLabel
    };

  }

  /**
   *
   * @param previousEta
   * @param duration
   * @returns {Date}
   */
  getTimeOfArrival(timeRef: Date, duration) {
    if (typeof timeRef !== 'undefined' && duration) {
      const durationInMs = duration * 1000;
      const toa: Date = new Date(timeRef.getTime() + durationInMs);
      return toa;
    }
  }

}
