<footer>
    <div class="container-fluid" id="upper-footer">
        <div class="row _contain_footer d-flex flex-wrap">
            <div class="links-wrap p-3 p-md-5">
                <div class="d-flex flex-column justify-content-start">
                    <h3 class="title pb-3">A propos</h3>
                    <p>
                        Splits est un annuaire dédié aux courses de trail. Trouvez et planifiez votre prochaine course,
                        construisez votre roadbook, puis comparez vos performances.
                        <br>
                        Organisateurs, publiez vos évènements
                        et entrez dans la communauté <a href="https://www.desbossesetdesbulles.com/" target="_blank">Des
                        Bosses et des Bulles</a>.
                    </p>
                </div>
            </div>
            <div class="p-3 p-md-5 links-wrap">
                <div class="d-flex flex-column justify-content-start">
                    <div>
                        <h3 class="title pb-3">Coureur</h3>
                        <a (click)="registerTrailer()">S'inscrire</a>
                        <br>
                        <a [routerLink]="['/pages/about-us']">Créer un roadbook</a>
                    </div>
                </div>
            </div>
            <div class="p-3 p-md-5 links-wrap">
                <div class="d-flex flex-column justify-content-start">
                    <div>
                        <h3 class="title pb-3">Organisateur</h3>
                        <a (click)="registerOrga()">Devenir organisateur</a>
                        <br>
                        <a (click)="registerOrga()">Créer un événement</a>
                        <br>
                        <a [routerLink]="['/pages/about-us']">Ajouter une course</a>
                    </div>
                </div>
            </div>
            <div class="p-3 p-md-5 links-wrap">
                <div class="d-flex flex-column justify-content-start">
                    <div>
                        <h3 class="title pb-3">En savoir plus</h3>
                        <a [routerLink]="['/pages/faq']">Besoin d'aide ?</a>
                        <br>
                        <a [routerLink]="['/pages/contact-us']">Nous contacter</a>
                        <br>
                        <a [routerLink]="['/pages/about-us']">Qui sommes-nous ?</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="copyright">
        <div class="_contain_bottom d-flex flex-wrap">
            <div class="flex-grow-1">
                <div>
                    <span class="pb-2">© Splits 2022</span>
                    <a [routerLink]="['/pages/privacy']">Confidentialité</a>
                    <a [routerLink]="['/pages/tos']">Conditions générales</a>
                </div>
            </div>
            <div class="_bottom_socials">
                <!--
                <a href="">
                    Français
                </a>
                <a href="">
                    English
                </a>-->
                <!-- <a href="https://www.facebook.com/splitsbydbdb" target="_blank" class="pl-3 social-link"><i
                        class="fa fa-twitter fa-2x" aria-hidden="true"></i></a>-->
                <a href="https://www.instagram.com/splits_trail/" target="_blank" class="social-link"><i
                        class="fa fa-instagram fa-2x" aria-hidden="true"></i></a>
                <a href="https://www.facebook.com/splitsbydbdb" target="_blank" class="social-link"><i
                        class="fa fa-facebook fa-2x" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

</footer>
