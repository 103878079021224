import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { IndexService } from '../../services/common/index.service';
import { ORGANIZER, TRAILER } from '../../models/user.model';
import { MediaService } from '../../services/media/media.service';


@Component({
  selector: 'app-index-base',
  templateUrl: './index-base.component.html',
  styleUrls: ['./index-base.component.scss'],
  // declare all the components you use in your template
  encapsulation: ViewEncapsulation.None
})
export class IndexBaseComponent implements OnInit {

  isIndexTrailer: boolean;
  isIndexOrganizer: boolean;
  backgroundImagePath = 'assets/img/app/background/default_background.jpg';

  screenHeight: string;
  screenWidth: string;

  constructor(private indexService: IndexService,
              private mediaService: MediaService,
              private cdRef: ChangeDetectorRef,
              private router: Router,
              private meta: Meta) {
  }

  ngOnInit() {
    this.indexService.indexChange.subscribe((index) => {
      if (typeof index !== 'undefined') {
        if (index === TRAILER) {
          this.isIndexTrailer = true;
          this.isIndexOrganizer = false;
        }
        if ( index === ORGANIZER) {
          this.isIndexOrganizer = true;
          this.isIndexTrailer = false;
        }
      }
    });


    this.mediaService.backgroundIndexMedia.subscribe((backgroundImagePath: string) => {
      this.backgroundImagePath = backgroundImagePath;
    });

    this.screenHeight = (window.screen.height) + 'px';

    this.screenWidth = (window.screen.width) + 'px';

  }

  /**
   * Avoid ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked Error
   */
  public ngDoCheck(): void {
    this.cdRef.detectChanges();
  }

  goToIndexTrailer() {
    // this.router.navigate(['/index-trailer']);
    window.scrollTo(0, 0);

  }
  goToIndexOrga() {
    this.router.navigate(['/index-organizer']);
    window.scrollTo(0, 0);
  }
}
