<div class="race-list-item p-3" role="button">
  <div class="row">
    <div class="col-10 p-0">
      <h2>
        <span>{{ race.name }}</span>
      </h2>
    </div>
    <div
      class="col-1 d-flex align-items-center justify-content-end"
      *ngIf="race.utmbIndex"
    >
      <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_tag" color="black" class="splits-icon-l"></mat-icon>-->

      <div
        [ngClass]="[
          '__icon _sm _bg-size-100 _no-border',
          '_icon-utmb-index-' + race.utmbIndex
        ]"
        style="height: 40px"
      ></div>
    </div>
    <div
      style="height: 40px"
      class="col-1 d-flex align-items-center justify-content-start"
      *ngIf="race.runningStones"
    >
      <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_tag" color="black" class="splits-icon-l"></mat-icon>-->
      <i
        class="icon-extra-splits icon-picto_utmb"
        matTooltipShowDelay="1000"
        matTooltip="Running Stones®"
        matTooltipPosition="above"
        style="font-size: 1.7rem; margin-right: 8px"
      ></i>
    </div>
  </div>
  <div class="d-flex justify-content-start mb-2">
    <span class="_distance">{{ race.distance }} km</span>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Date & Heure de départ</span>
        <div *ngIf="race?.status == 'canceled'; else ddate">
          <div class="chip-list">
            <div class="chip disabled">{{ race?.status | translate }}</div>
          </div>
        </div>
        <ng-template #ddate>
          <span
            >{{ race.date | date : "EEE dd MMM y" }} à
            {{ race.departureTime | date : "HH:mm" }}</span
          >
        </ng-template>
      </div>
    </div>
    <div class="col-md-5 col-lg-4">
      <div class="d-flex flex-column flex-grow-1 align-items-start">
        <span class="_int" i18n>Lieu de départ</span>
        <span>{{ race.locality }} </span>
      </div>
    </div>
    <div class="col-md-3 col-lg-2">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Dénivelé +</span>
        <span>{{ race.ascendingElevation || "--" }}mD+</span>
      </div>
    </div>
    <div class="col-md-3 col-lg-2">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Dénivelé -</span>
        <span>{{ race.negativeElevation || "--" }}mD-</span>
      </div>
    </div>
  </div>
</div>
