import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewContainerRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { ORGANIZER, UserModel } from "../../../models/user.model";
import { DialogService } from "../../../services/common/dialog.service";
import { NavService } from "../../../services/common/nav.service";
import { UserService } from "../../../services/user/user.service";
import { BaseComponent } from "../../base.component";

@Component({
  selector: "toolbar-orga",
  templateUrl: "./toolbar-orga.component.html",
  styleUrls: ["./toolbar-orga.component.scss"],
})
export class ToolbarOrgaComponent extends BaseComponent implements OnInit {
  user: UserModel;
  userEventsSubscription: any;

  searchScope: string[] = ["event", "race"];

  @Output() openSideBar = new EventEmitter();

  toolbarExpanded: boolean;

  isConnected: boolean;
  constructor(
    protected userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private navbarService: NavService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef
  ) {
    super(userService, viewContainerRef);
    this.toolbarExpanded = true;
  }

  afterInit() {
    // nothing to do
    this.user = this.loggedInUser;
  }

  home() {
    this.router.navigate(["/organizer/home"]);
  }

  signIn() {
    this.dialogService.openSignInPopup(ORGANIZER, 0);
  }

  register() {
    this.dialogService.openSignInPopup(ORGANIZER, 1);
  }

  faq() {
    this.router.navigate(["pages/faq"]);
  }

  toogleExpandNavbar() {
    if (!this.toolbarExpanded) {
      this.navbarService.expand();
      this.toolbarExpanded = true;
    } else {
      this.navbarService.collapse();
      this.toolbarExpanded = false;
    }
  }
}
