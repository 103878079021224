<div class="race-list-item p-3" [ngClass]="{'_border-bottom': !isLastLine}" role="button" (click)="editRace()">
  <div class="row">
    <div class="col-md-10">
      <div class="d-flex justify-content-start mb-2 align-items-center">
        <div class="d-flex flex-wrap _contain_title">
          <span class="_name">{{ race.name }}</span>
          <div class="_chip_list d-flex align-items-center">
            <div class="_chip" *ngIf="race?.gpx">GPX</div>
            <div class="_chip" *ngIf="race.pois?.length>0">Ravitos</div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4">
          <div class="d-flex flex-column align-items-start">
            <span class="_int" i18n>Date & Heure de départ</span>
            <div *ngIf="race?.status=='canceled' else ddate">
              <div class="chip-list">
                <div class="chip disabled">{{ race?.status | translate }}
                </div>
              </div>
            </div>
            <ng-template #ddate>
              <span>{{ race.date | date : 'EEE dd MMM y'}} à {{ race.departureTime | date: 'HH:mm'}}</span>
            </ng-template>
          </div>
        </div>
        <div class="col-md-4 col-lg-5">
          <div class="d-flex flex-column flex-grow-1 align-items-start">
            <span class="_int" i18n>Lieu de départ</span>
            <span>{{ race.locality}} </span>
          </div>
        </div>
        <div class="col-md-2 col-lg-1">
          <div class="d-flex flex-column align-items-start">
            <span class="_int" i18n>Dénivelé</span>
            <span>{{ race.ascendingElevation}}mD+</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="d-flex flex-column align-items-center" style="gap: 10px">
          <div class="status-wrap">
            <div *ngIf="isDraft" class="badge bg-default">Brouillon</div>
            <div *ngIf="isPublished" class="badge bg-success">Publiée</div>
            <div *ngIf="isArchived" class="badge bg-info">Terminée</div>
            <div *ngIf="isCanceled" class="badge bg-danger">Annulée</div>
          </div>

          <div class="percent-wrap">
            <app-complete-race-percent [matTooltip]="messageMissingRaceInfos.toString()" class="ml-auto" [percent]="percentComplete"
                                       *ngIf="isDraft"></app-complete-race-percent>
          </div>

      </div>
    </div>
  </div>

</div>
