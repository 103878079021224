import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EventModel } from '../../../models/event.model';

@Component({
             selector: 'app-confirm-delete-event',
             templateUrl: './confirm-delete-event.component.html',
             styleUrls: ['./confirm-delete-event.component.scss']
           })
export class ConfirmDeleteEventComponent implements OnInit {

  public title: string;
  public event: EventModel;
  confirmDeleteText: string;
  btnDisabled = true;

  constructor(public dialogRef: MatDialogRef<ConfirmDeleteEventComponent>) {
  }

  ngOnInit(): void {
  }

  checkInputDelete(value: any) {
    this.btnDisabled = value.toUpperCase() !== 'SUPPRIMER';
  }
}
