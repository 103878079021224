import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OverlayService } from '../../services/common/overlay.service';
import { OverlayState } from '../../classes/OverlayState';




@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  show = false;
  private subscription: Subscription;
  constructor(
      private overlayService: OverlayService
  ) { }

  ngOnInit() {

    this.subscription = this.overlayService.overlayState
        .subscribe((state: OverlayState) => {
          this.show = state.show;
        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
