/**
 * Created by macbookaxel on 23/06/2016.
 */
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from './../services/user/user.service';
import { SnackbarService } from './../services/notifications/snackbar.service';
import { ORGANIZER, TRAILER, UserModel } from '../models/user.model';

@Injectable({
              providedIn: 'root'
            })
export class AccessGuard implements CanActivate {

  permissions: any;

  user: UserModel;

  constructor(private userService: UserService,
              private router: Router,
              private snackBarService: SnackbarService) {

    // user connecté
    this.userService.userEvents.subscribe
    (user => {
      this.user = user;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    if (!this.checkHavePermission(state.url)) {
      this.snackBarService.open('Vous n\'avez pas accès à cet espace', 'Fermer');
      this.redirectToHomeByProfil(this.user.type_user);
    } else { return true; }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.checkHavePermission(state.url);
  }

  private checkHavePermission(url) {

    if (url.match(/^\/trailer.*/)) {
      return this.isMatchingProfil('trailer');
    } else if (url.match(/^\/organizer.*/)) {
      return this.isMatchingProfil('organizer');
    }
  }

  isMatchingProfil(profil: string) {
    const profilMatch = (this.user.type_user === profil);
    return profilMatch;
  }

  redirectToHomeByProfil(profil: string) {
    if (profil === TRAILER) {
      this.router.navigate(['/', profil, 'dashboard']);
    }
    if (profil === ORGANIZER) {
      this.router.navigate(['/', profil, 'home']);
    }
  }
}


