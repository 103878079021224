import { SearchElementCriterias } from './searchElementCriterias';

export class SearchRoadbookCriterias extends SearchElementCriterias {

  raceName: string;
  dateSearchStart: Date;
  dateSearchEnd: Date;
  dateSearchStartTimestamp: number;
  dateSearchEndTimestamp: number;

  status: string[]; // plusieurs statuts de recherche parmis 'draft'|'published'|'archived'|'canceled';
  trailerId: number;
}
