import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
  LegacySimpleSnackBar as SimpleSnackBar
} from '@angular/material/legacy-snack-bar';
import { SpinnerService } from '../common/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  snackBarConfig: MatSnackBarConfig;

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(public snackBar: MatSnackBar, private spinnerService: SpinnerService) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.verticalPosition = 'bottom'; // 'top' | 'bottom'
    this.snackBarConfig.horizontalPosition = 'center'; // 'start' | 'center' | 'end' | 'left' | 'right';
  }

  /** Snackbar par défaut
   *
   * @param message - required
   * @param action - default "OK"
   * @param duration - default 3000ms (3s) - 0 for infinite
   * @returns {MatSnackBarRef<SimpleSnackBar>}
     */
  open(message: string, action: string = 'OK', duration?: number): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbarOpen(message, action);
  }

  /**
   *
   * @param message - required
   * @param action - default "OK"
   * @param duration - default 3000ms (3s) - 0 for infinite
   * @returns {MatSnackBarRef<SimpleSnackBar>}
   */
  openInfo(message: string, action: string = 'FERMER', duration?: number, position?: MatSnackBarVerticalPosition, fullWidth?: boolean): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbarOpen(message, action, 'snackBar-info', duration, position, fullWidth);
  }
  /**
   *
   * @param message - required
   * @param action - default "OK"
   * @param duration - default 3000ms (3s) - 0 for infinite
   * @returns {MatSnackBarRef<SimpleSnackBar>}
     */
  openSuccess(message: string, action: string = 'FERMER', duration?: number, position?: MatSnackBarVerticalPosition, fullWidth?: boolean): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbarOpen(message, action, 'snackBar-success', duration, position, fullWidth);
  }

  /**
   *
   * @param message - required
   * @param action - default "OK"
   * @param duration - default 3000ms (3s) - 0 for infinite
   * @returns {MatSnackBarRef<SimpleSnackBar>}
     */
  openWarning(message: string, action: string = 'FERMER', duration?: number, position?: MatSnackBarVerticalPosition, fullWidth?: boolean): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbarOpen(message, action, 'snackBar-warning', duration, position, fullWidth);
  }

  /**
   *
   * @param message - required
   * @param action - default "OK"
   * @param duration - default 3000ms (3s) - 0 for infinite
   * @returns {MatSnackBarRef<SimpleSnackBar>}
     */
  openDanger(message: string, action: string = 'FERMER', duration?: number, position?: MatSnackBarVerticalPosition, fullWidth?: boolean): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbarOpen(message, action, 'snackBar-danger', duration, position, fullWidth);
  }

  /** Ouvre un snackbar et retourne la ref de la snackbar en Observable
   *
   * @param message
   * @param action
   * @param extraClass
   * @param duration
   * @returns {MatSnackBarRef<SimpleSnackBar>}
     */
  private snackbarOpen(message: string = '', action: string, extraClass: string = 'snackBar-default', duration: number = 3000, position: MatSnackBarVerticalPosition = 'bottom', fullWidth: boolean = false): MatSnackBarRef<SimpleSnackBar> {

    this.spinnerService.stop();

    this.snackBarConfig.verticalPosition = position;
    this.snackBarConfig.duration = duration;
    this.snackBarConfig.panelClass = [extraClass];

    if (fullWidth) { this.snackBarConfig.panelClass.push('full-width'); }


    this.snackBarRef = this.snackBar.open(message, action, this.snackBarConfig);

    return this.snackBarRef;
  }
}
