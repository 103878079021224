import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SelectItemModel } from '../../models/select.item.model';
import { HttpService } from '../http/http.service';
import { SearchParam } from '../../classes/search-param';
import { RaceTagModel } from '../../models/race-tag.model';
import { EventModel } from '../../models/event.model';

@Injectable({
              providedIn: 'root'
            })
export class DataService {

  private listDatas: Observable<Array<SelectItemModel>>;

  private urlRoot = '/api/';
  private dataUrl: string;

  constructor(private http: HttpService) {
  }


  /**
   *
   * @param listName
   * @returns {any}
   */
  getDropDownList(listName: string, _searchParams: Array<SearchParam> = []): Observable<Array<SelectItemModel>> {

    let searchParams: Array<SearchParam> = [];

    if (listName !== null) {
      if (listName === 'raceTags' || listName === 'eventTags') {
        searchParams = [new SearchParam('type', listName)];
        listName = 'tags';
      }

      searchParams = [...searchParams, ..._searchParams];

      // conversion du nom de la liste en snake_case pour la génération de la route
      this.listDatas = this.http.get(this.urlRoot + listName, searchParams);
      return this.listDatas;
    }
    return null;
  }


  getBloodGroupList() {
    const bloodGroups: Array<any> = [{code: 'A+'},
      {code: 'A-'},
      {code: 'B+'},
      {code: 'B-'},
      {code: 'AB+'},
      {code: 'AB-'},
      {code: 'O+'},
      {code: 'O-'}];

    return bloodGroups;
  }

  getAscendingElevations() {
    const ascendingElevations: Array<any> = [];

    for (let i = 0; i <= 20000; i = i + 100) {
      ascendingElevations.push({value: i});
    }
    return ascendingElevations;
  }

  /**
   *
   */
  reduceRaceTags(event: EventModel) {
    let uniqueRaceTags: Array<RaceTagModel> = [];
    let extraTagLabel = '';
    if (event.races) {
      let arrRaceTags: Array<RaceTagModel> = [];
      event.races.forEach((race) => {
        arrRaceTags = [...arrRaceTags, ...race.tags];
      });

      // suppression des doublons
      uniqueRaceTags = arrRaceTags.filter((elem, index, self) => self.findIndex(
        (t) => {
          return (t.id === elem.id);
        }) === index);
      extraTagLabel = (uniqueRaceTags.length > 3) ? this.getExtraTagsLabels(uniqueRaceTags.slice(3)) : null;
    }
    return {uniqueRaceTags, extraTagLabel};
  }

  getExtraTagsLabels(extraRaceTags: Array<RaceTagModel>) {
    let extraTagLabels = '';
    extraRaceTags.forEach((raceTag: RaceTagModel) => extraTagLabels += raceTag.name + ' | ');
    return extraTagLabels;
  }

}
