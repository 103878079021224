import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
        name: 'translate'
      })

export class TranslatePipe implements PipeTransform {

  statusesFem = {
    draft: 'Brouillon',
    published: 'Publiée',
    archived: 'Archivée',
    canceled: 'Annulée'

  };

  transform(value: string): string {
    return this.statusesFem[value];
  }
}
