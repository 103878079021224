import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '../../models/user.model';
import { filter } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
             selector: 'app-sign-in',
             templateUrl: './sign-in.component.html',
             styleUrls: ['./sign-in.component.scss']
           })
export class SignInComponent implements OnInit {
  signInStep: string;

  typeUser: string;
  selectedIndex: number;
  callbackUrl?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              protected userService: UserService) {

    this.typeUser = data.typeUser ?? 'login';
    this.selectedIndex = data.selectedIndex ?? '';
    this.callbackUrl = data.callbackUrl;

    this.userService.registeringUser.subscribe((user: UserModel) => {
      if (user) {
        this.signInStep = user.type_user;
      }
    });
  }

  ngOnInit(): void {
    this.signInStep = 'login';
  }

}
