import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { PoiModel } from "../../models/Pois/poi.model";
import { SearchParam } from "../../classes/search-param";

import { BehaviorSubject, Observable } from "rxjs";
import { RaceModel } from "../../models/race.model";
import { ChartService } from "../chart/chart.service";
import { Trkpt } from "../../classes/trkpt";
import { PoiOrganizerModel } from "../../models/Pois/poi.organizer.model";
import { PoiTrailerModel } from "../../models/Pois/poi.trailer.model";
import { RaceOffModel } from "../../models/race-off.model";

@Injectable({
  providedIn: "root",
})
export class PoiService {
  listPois: Observable<Array<PoiModel>>;

  poi: Observable<PoiModel>;

  poisUrl = "/api/pois";
  addPoiUrl = "/api/pois";
  editPoiUrl = "/api/pois/";
  deletePoiUrl = "/api/pois/";
  deletePoisFromOneRaceUrl = "/api/pois/all_from_one_race/";
  onePoiUrl = "/api/pois/";

  poisOrga: BehaviorSubject<Array<PoiModel>> = new BehaviorSubject<
    Array<PoiOrganizerModel>
  >([]);
  poisTrailer: BehaviorSubject<Array<PoiModel>> = new BehaviorSubject<
    Array<PoiTrailerModel>
  >([]);

  constructor(private http: HttpService, private chartService: ChartService) {}

  /** Récupération de la liste complète des événements
   *
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  getPois(searchParams: Array<SearchParam>) {
    this.listPois = this.http.get(this.poisUrl, searchParams);
    return this.listPois;
  }

  /** Retourne les détails d'une course
   *
   * @returns {Observable<RaceModel>}
   */
  getOnePoi(poiId: number) {
    this.poi = this.http.get(this.onePoiUrl + poiId, null);
    return this.poi;
  }

  /** POST event
   *
   * @param event
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  postPoi(poi: PoiModel): Observable<PoiModel> {
    return this.http.post(this.addPoiUrl, poi);
  }

  putPoi(poi: PoiModel): Observable<PoiModel> {
    return this.http.put(this.editPoiUrl + poi.id, poi);
  }

  deletePoisFromOneRace(raceId: number): Observable<PoiModel> {
    return this.http.delete(this.deletePoisFromOneRaceUrl + raceId, { raceId });
  }

  deletePoi(poi: PoiModel): Observable<PoiModel> {
    return this.http.delete(this.deletePoiUrl + poi.id, poi);
  }

  populatePoiInfoWithPreviousPoi(
    distance,
    workingPoi: PoiModel,
    pois: PoiModel[],
    trkpts: Array<Trkpt>
  ) {
    const previousPoi = this.getPreviousPoi(distance, workingPoi, pois);

    const race: RaceModel | RaceOffModel =
      workingPoi.raceOff ?? workingPoi.race;

    const infoWithinClosestTrkpt = this.getPoiInfoWithinClosestTrkpt(
      distance,
      race,
      previousPoi,
      trkpts
    );
    return { ...workingPoi, ...infoWithinClosestTrkpt };
  }

  /** Retourne les infos du POI en fonction du TRKPT le plus proche du POI saisi
   *
   * @param currentPoi
   * @param previousPoi
   */
  getPoiInfoWithinClosestTrkpt(
    distance: number,
    race: RaceModel | RaceOffModel,
    previousPoi: PoiModel,
    trkpts: Array<Trkpt>
  ) {
    const closestTrkpt = this.chartService.getClosestTrkptOfPoi(
      distance,
      trkpts
    );

    let infosClosestPoint = {};
    let infosPreviousPoi = {};

    if (typeof closestTrkpt !== "undefined" && closestTrkpt.dst > 0) {
      infosClosestPoint = {
        distance: closestTrkpt.dst,
        latitude: +closestTrkpt.lat,
        longitude: +closestTrkpt.lon,
        elevation: +closestTrkpt.ele,
        cumulativePositiveElevation: +closestTrkpt.cumul_positive_elevation,
        cumulativeNegativeElevation: +closestTrkpt.cumul_negative_elevation,
        idx: closestTrkpt.idx,
      };
    }

    if (typeof previousPoi !== "undefined") {
      infosPreviousPoi = {
        previousPoiName: previousPoi.name,
        distanceInBetween: +distance - previousPoi.distance,
        cumulativePositiveElevationInBetween:
          +closestTrkpt.cumul_positive_elevation -
          previousPoi.cumulativePositiveElevation,
        cumulativeNegativeElevationInBetween:
          +closestTrkpt.cumul_negative_elevation -
          previousPoi.cumulativeNegativeElevation,
        previousPoiEta: previousPoi.trailerEstimation
          ? previousPoi.trailerEstimation.estimatedTimeOfArrival
          : race.date,
      };
    }
    return { ...infosClosestPoint, ...infosPreviousPoi };
  }

  /** Renvoi Poi précdent le POI passé en paramêtre
   *
   * @param poi
   */
  public getPreviousPoi(
    distance: number,
    poiRef: PoiModel,
    pois: Array<PoiModel>
  ): PoiModel {
    let previousPoi: PoiModel = new PoiModel();

    if (typeof pois !== "undefined") {
      const previousPois: Array<PoiModel> = pois.filter((currPoi) => {
        return currPoi.distance < distance && currPoi.idx !== poiRef.idx;
      });
      if (previousPois.length > 0) {
        // le point précédent le plus proche est le dernier du tableau
        previousPoi = previousPois[previousPois.length - 1];
      }
    }
    return previousPoi;
  }

  /** Renvoi le Trkpt le plus proche du Poi
   *
   * @param poi
   */
  public getFollowingPoi(poiRef: PoiModel, pois: Array<PoiModel>): PoiModel {
    let followingPoi: PoiModel = new PoiModel();

    if (typeof pois != "undefined") {
      const followingPois: Array<PoiModel> = pois.filter(
        (currPoi) =>
          currPoi.distance > poiRef.distance && currPoi.idx !== poiRef.idx
      );

      if (followingPois.length > 0) {
        // le point précédent le plus proche est le dernier du tableau
        followingPoi = followingPois[followingPois.length - 1];
      }
    }

    return followingPoi;
  }
}
