import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayState } from '../../classes/OverlayState';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  public overlaySubject = new Subject<OverlayState>();
  overlayState = this.overlaySubject.asObservable();

  constructor() { }
  show() {
    this.overlaySubject.next({show: true} as OverlayState);
  }
  hide() {
    this.overlaySubject.next({show: false} as OverlayState);
  }
}
