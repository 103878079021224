import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public status: Subject<boolean> = new Subject();
  public content: Subject<string> = new Subject();
  private _active = false;
  private _message = 'Chargement...';

  constructor() { }

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public get message(): string {
    return this._message;
  }

  public set message(m: string) {
    this._message = m;
    this.content.next(m);
  }

  public start(message: string = null): void {

    if (typeof message == 'undefined' || !message) {
      message = 'Chargement...';
    }

    this.active = true;
    this.message = message;
  }

  public updateMessage(message: string = null): void{
    this.message = message;
  }

  public stop(): void {
    this.active = false;
  }
}
