
<button (click)="refreshRaces();">Actualiser la liste</button>

<p>{{races.length}} races</p>

<div *ngIf="races.length > 0"><h2>Races</h2></div>

<div *ngIf="raceSelected">{{ raceSelected.name }}</div>

<div class="list-group">

 <race-item-cmp *ngFor="let currentRace of races | slice:pageSize*(page-1):pageSize*page; let i=index"
                [eventParent]="currentRace.event"
                [race]="currentRace"
                [id]="i"
                (raceSelected)="clickOnRace($event)"></race-item-cmp>

</div>

<!--
TODO : A mettre à jour dès qu'on a la bonne version de ngbootstrap ou material
<ngb-pagination [collectionSize]="races.length" [(page)]="page" [maxSize]="pageSize" [pageSize]="pageSize" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>-->


