import { Component, Input, OnInit } from '@angular/core';
import { EventModel } from '../../../models/event.model';
import moment from 'moment';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { Router } from '@angular/router';
import { RaceTagModel } from '../../../models/race-tag.model';
import { DataService } from '../../../services/common/data.service';

@Component({
             selector: 'app-event-directory-item',
             templateUrl: './event-directory-item.component.html',
             styleUrls: ['./event-directory-item.component.scss'],
             animations: [
               // animation triggers go here
             ]
           })
export class EventDirectoryItemComponent implements OnInit {
  @Input() event: EventModel;

  dateEventDisplay: string;
  likeState: string;
  likeAnimation: string;
  raceTags: Array<RaceTagModel> = [];

  raceTagsReduced: { extraTagLabel: string; uniqueRaceTags: Array<RaceTagModel> };

  constructor(private snackbarService: SnackbarService, private router: Router, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.formatDateEvent();
    this.likeState = 'n-like';

    this.raceTagsReduced = this.dataService.reduceRaceTags(this.event);

  }

  formatDateEvent() {
    const dateBegin: Date = new Date(this.event.dateBegin);
    const dateEnd: Date = new Date(this.event.dateEnd);
    if (dateBegin === dateEnd) {
      moment(dateBegin).format('DD MMMM YYYY');
    } else if (dateBegin.getDate() !== dateEnd.getDate() && dateBegin.getMonth() === dateEnd.getMonth()) {
      this.dateEventDisplay = dateBegin.getDate() + '-' + moment(dateEnd).format('DD MMMM YY');
    } else if (dateBegin.getMonth() !== dateEnd.getMonth()) {
      this.dateEventDisplay = moment(dateBegin).format('DD MMMM YY') + ' - ' + moment(dateEnd).format('DD MMMM YY');
    }
  }

  showEvent() {
    this.router.navigate(['/events', this.event.id, 'show']);
  }

  showRace(raceId: number) {
    this.router.navigate(['/events', this.event.id, 'show', raceId, 'race']);
  }

}
