/**
 * Created by macbookaxel on 13/07/2016.
 */

import {
  AbstractControl,
  UntypedFormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export class UsersValidator {
  /** Verifie que le mot de passe saisi correspond bien à la confirmation
   *
   * @param c
   */
  static matchingPassword(control: {
    controls: {
      password: UntypedFormControl;
      confirm_password: UntypedFormControl;
    };
  }) {
    const password = control.controls.password.value;
    const confirmPassword = control.controls.confirm_password.value;
    const notMatching = password !== confirmPassword;
    console.log(password, confirmPassword);
    return notMatching ? { matchingError: true } : null;
  }

  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);
      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  /** Verifie le format de l'email saisie
   *
   * @param c
   * @returns {{validateEmail: {valid: boolean}}}
   */
  static validateEmail(c: UntypedFormControl) {
    const EMAIL_REGEXP =
      /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

    return !c.value || EMAIL_REGEXP.test(c.value)
      ? null
      : {
          validateEmail: {
            valid: false,
          },
        };
  }

  /** Verifie le format de l'email saisie
   *
   * @param c
   * @returns {{validateEmail: {valid: boolean}}}
   */
  static validateWebsite(c: UntypedFormControl) {
    const URL_REGEXP =
      /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
    return !c.value || URL_REGEXP.test(c.value)
      ? null
      : {
          validateUrl: {
            valid: false,
          },
        };
  }

  /** Verifie que l'email saisi n'a pas déjà été pris
   *
   * @param c
   * @returns {{validateEmail: {valid: boolean}}}
   */
  static checkUser(c: UntypedFormControl) {
    return {
      checkUser: {
        valid: false,
      },
    };
  }
}
