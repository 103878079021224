<!--

       <mat-form-field style="width: 100%">
        <mat-label>Mot de passe</mat-label>
        <input matInput placeholder="Mot de passe" formControlName="password" required type="password">
        <mat-error>Champ obligatoire</mat-error>
      </mat-form-field>
       <button mat-raised-button (click)="signInWithFB()" alt="Se connecter avec Facebook"
              class=" mx-auto btn-block facebook-signin-button"><i class="fa fa-facebook splits-icon-sm"></i>&nbsp;Continuer
        avec Facebook
      </button>
-->
<div class="login-form-wrap text-left">

  <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
    <input type="hidden" formControlName="type_user" required>

    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label>Email *</label>
        <input matInput formControlName="email" required>
        <mat-error>Champ obligatoire</mat-error>
      </mat-form-field>
    </div>


    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label>Mot de passe *</label>
        <input matInput formControlName="password" required type="password">
        <mat-error>Champ obligatoire</mat-error>
      </mat-form-field>
    </div>


    <button id="login-btn" type="submit" class="_sp_btn _blue btn-block" i18n
            matTooltip="Commencer" matTooltipShowDelay="1000" aria-label="Bouton commencer"><span>Se connecter</span>
    </button>

    <div class="reset-link-wrap">
      <a href="{{forgotPasswordUrl}}" matTooltip="Mot de passe oublié?" matTooltipShowDelay="1000" class="reset-link">Vous
        avez oublié votre mot de passe ?</a>
    </div>
  </form>

  <div class="_alternate"><strong>ou</strong></div>

  <div *ngIf="hasSocialSignIn" class="social-signin-wrap d-flex flex-column">
    <div class="facebook-connect-wrap">

      <button class="_btn_fb" (click)="signInWithFB()">
        <i class="fa fa-facebook splits-icon-sm"></i>
        <span>Continuer avec Facebook</span>
      </button>
    </div>
  </div>
</div>

<!--</div>-->

