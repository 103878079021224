<section class="_section_full" id="hero">
  <!-- image-->

  <div class="_background"></div>
  <div class="_background_white"></div>
  <div class="_hero_illustration">
    <img
      class="img-fluid"
      src="/assets/img/app/home/illu_header_home.png"
      alt=""
    />
  </div>
  <div class="container-splits">
    <div class="__header_infos col-7">
      <h1 i18n>
        Trouvez votre prochaine course de trail parmi les
        {{ countTotalEvents || "..." }} évènements
      </h1>
      <div class="exergue" i18n>
        Recherchez les courses à venir, mesurez vos performances grâce au
        roadbook et rivalisez avec la communauté de trailers.
      </div>
    </div>
  </div>
  <div class="col-12 p-0">
    <div
      class="quick-search-form-wrap d-flex justify-content-center mt-3"
      #quicksearchwrap
    >
      <div class="container-splits">
        <app-search-box-lite class="_wrap_search"></app-search-box-lite>
      </div>
    </div>
    <div
      class="container-splits d-flex justify-content-center align-items-center"
    >
      ou
      <div class="btn-action-wrap p-3" style="background-color: transparent">
        <a
          [routerLink]="['/trailer/about-race-off']"
          class="_sp_btn _blue _inline _small d-flex align-items-center"
          i18n
        >
          <mat-icon>cloud_upload</mat-icon>
          <span>Importer un gpx</span>
        </a>
      </div>
    </div>
  </div>
  <div class="col-12 p-0">
    <div class="search-mobile" (click)="openSearch()">
      <span>Rechercher une course ...</span>
    </div>
  </div>
</section>

<section id="s-next-races" class="pt-0">
  <div class="container-splits">
    <h2 i18n>Prochaines courses</h2>

    <div class="next-races-wrap mt-3 mb-3">
      <swiper
        *ngIf="incomingEventsLoaded; else skeletonLoaderRaces"
        class="_swiper_races"
        [config]="swipperRacesConfig"
      >
        <ng-template
          *ngFor="let incomingEvent of incomingEvents; let i = index"
          swiperSlide
          class="__swipe_height"
        >
          <app-event-card
            class="__card_test mx-2 p-2 event-card-item d-flex"
            [event]="incomingEvent"
          ></app-event-card>
        </ng-template>
      </swiper>

      <ng-template #skeletonLoaderRaces>
        <ngx-skeleton-loader
          count="3"
          [theme]="{
            width: '300px',
            'border-radius': '10px',
            height: '230px',
            margin: '1rem'
          }"
        ></ngx-skeleton-loader>
      </ng-template>
    </div>
    <div>
      <a [routerLink]="['/events']" class="_sp_btn_arrow _right">
        <div class="d-flex">
          <span>Voir toutes les courses</span>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </a>
    </div>
  </div>
</section>

<section id="s-suggestion">
  <div class="container-splits">
    <h2 i18n>Trouvez l'inspiration</h2>
    <div class="suggestions-wrap">
      <swiper
        *ngIf="tagsLoaded; else skeletonLoaderTags"
        class="_swiper_races"
        [config]="swipperCategoryConfig"
      >
        <ng-template
          *ngFor="let raceTag of raceTags; let i = index"
          swiperSlide
        >
          <div class="suggestion-card-item d-flex">
            <div class="_contain_card d-flex align-items-center">
              <div
                class="tag-icon-wrap _lg"
                (click)="searchByTag(raceTag.code)"
                role="button"
              >
                <div [ngClass]="['__icon', '_icon-' + raceTag.code]"></div>
                <div class="suggestion-infos pt-2">
                  <h4 class="_little">{{ raceTag.name }}</h4>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
      <ng-template #skeletonLoaderTags>
        <ngx-skeleton-loader
          count="7"
          appearance="circle"
          [theme]="{ width: '100px', height: '100px', margin: '24px' }"
        ></ngx-skeleton-loader>
      </ng-template>
    </div>
  </div>
</section>

<section id="s-features-1" class="__section_features">
  <div class="_background_feature">
    <img
      class="img-fluid"
      src="/assets/img/app/home/background_suggestions.png"
      alt=""
    />
  </div>

  <div class="container-splits d-flex">
    <div class="col-5 order-lg-1 feature-thumb-wrap">
      <img
        class="img-fluid"
        src="/assets/img/app/home/suggestions/splits_trouver_course.png"
        alt="Ttrouver votre course avec Splits"
      />
    </div>
    <div class="col-7 order-lg-2 d-flex align-items-center feature-infos">
      <div>
        <h3 i18n>Trouvez et planifiez votre prochaine course sur Splits</h3>
        <ul class="_bullet_check _70">
          <li i18n>
            Que vous soyez un ultra-coureur aguerri, un amateur de courses à
            saucisson ou un trailer en herbe, Splits c'est l'annuaire trail de
            référence, le point de départ de vos prochains défis !
          </li>
          <li i18n>
            Recherchez vos courses selon différents critères : géographiques,
            distances, univers, etc…
          </li>
          <li i18n>
            Ajoutez vos courses préférées à vos favoris pour être alertés des
            nouveautés et actualités, soyez sûr d'être prévenu à temps !
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="s-insert-orga">
  <div class="container-splits">
    <div class="__bloc_organisateur">
      <div class="_background_organisateur">
        <img
          src="/assets/img/app/home/background_organisateurs.jpg"
          alt="Organisateurs Splits"
        />
      </div>
      <div class="col-5 __orga_infos">
        <h3 i18n>Vous êtes organisateur d'évènements ?</h3>
        <span i18n>
          Inscrivez votre course sur Splits et développez votre communauté de
          coureurs !
        </span>
        <a [routerLink]="['organizer']" class="_sp_btn _blue _inline" i18n
          >En savoir plus</a
        >
      </div>
    </div>
  </div>
</section>

<section id="s-features-2" class="__section_features">
  <div class="_background_feature _right">
    <img
      class="img-fluid"
      src="/assets/img/app/home/background_suggestions.png"
      alt=" background suggestions Splits"
    />
  </div>
  <div class="container-splits d-flex">
    <div class="feature-thumb-wrap order-lg-2 col-5">
      <img
        class="img-fluid"
        src="/assets/img/app/home/suggestions/splits_preparation_course.png"
        alt="Préparer votre course avec Splits"
      />
    </div>
    <div class="d-flex order-lg-1 align-items-center feature-infos col-7">
      <div>
        <h3 i18n>Préparez votre course avec Splits !</h3>
        <ul class="_bullet_check _70">
          <li i18n>
            Construisez votre roadbook à partir de Splits et sur la base des
            tracés fournis par les organisateurs : estimez-vous !
          </li>
          <li i18n>
            Étudiez le topo de la course grâce à notre carte et au profil de
            course mise à votre disposition.
          </li>
          <li i18n>
            Planifiez votre course à la minute près : temps de passage, allure,
            heure d'arrivée ou de passage aux ravitos...
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="s-features-3" class="__section_features">
  <div class="_background_feature">
    <img
      class="img-fluid"
      src="/assets/img/app/home/background_suggestions.png"
      alt=""
    />
  </div>

  <div class="container-splits d-flex">
    <div class="feature-thumb-wrap col-5">
      <img
        class="img-fluid"
        src="/assets/img/app/home/suggestions/splits_statistiques_courses.png"
        alt="Statistiques courses Splits"
      />
    </div>
    <div class="d-flex align-items-center feature-infos col-7">
      <div>
        <h3 i18n>Comparez, vos performances, les courses, les ambiances...</h3>
        <ul class="_bullet_check _70">
          <li i18n>
            Splits c’est un outil de comparaison fiable, fun mais précis,
            marrant mais sérieux ! Soyez actif pour optimiser votre classement
            Splits - sérieux mais pas trop dans l'esprit Des Bosses et des
            Bulles !
          </li>
          <li i18n>
            Apprenez de vos courses en comparant vos temps estimés avant-course
            avec la réalité du terrain !
          </li>
          <li i18n>
            Comparez les courses avec des avis de coureurs objectifs sur des
            courses et des tracés.
          </li>
          <li i18n>
            Choisissez les courses les mieux adaptées à votre personnalité :
            montagne, ville, campagne, sérieux, fun, intimiste... Tous les
            trails sont sur Splits
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
