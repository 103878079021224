import { Injectable } from '@angular/core';

import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  extractFileExtensionFromFilename(filename: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)[1];
  }

  public downloadFile(data: any, filename: string, type: string) {
    const blob = new Blob([data], { type });
    FileSaver.saveAs(blob, filename);
  }

  public previewFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}
