import { Component, Input, OnInit } from '@angular/core';

import { RaceModel } from '../../models/race.model';

import { Title } from '@angular/platform-browser';
import { UserService } from '../../services/user/user.service';
import { RaceService } from '../../services/race/race.service';

@Component({

  selector: 'races-cmp',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss'],
  viewProviders: [Title],
})

export class RacesComponent implements OnInit {

  @Input() name: string;

  title: Title;

  races: Array<RaceModel> = [];
  isHidden = false;
  foreground = '#D5D5D5';
  messageCount: number;
  colorEven = '#000';
  colorOdd = ' #C25F15';

  // #PAGINATION #
  page = 1;
  pageSize = 10;


  raceSelected: RaceModel;

  constructor(title: Title, private raceService: RaceService, private userService: UserService) {
    this.title = title;
    this.messageCount = 10;
  }

  ngOnInit() {
  this.list();
  }

  refreshRaces() {
    // this.races = [{name: 'UTMB'}, {name: 'CCC'}];
  }

  changeColor(color) {
    this.foreground = color;
    // this.toto = 'flex';
  }


  onSpacePress() {
    console.log('clavier pressé');
  }

  incrementMessage() {
    this.messageCount --;
  }


  list() {

    this.raceService.getRaces(null)
        .subscribe(
            races => { this.races = races; },
            error => console.log(error)
        );
  }

  clickOnRace(race: RaceModel) {
    this.title.setTitle(race.name);
    this.raceSelected = race;
  }



}
