<ng-container>
  <div class="d-flex align-items-center justify-content-around duration-wrap">
    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label i18n>hh</label>
        <input matInput type="number"
               [(ngModel)]="hours"
               (ngModelChange)="check('hours')"
               name="estimated_duration_hour"
               id="estimated_duration_hour"
               min="0"
               step="1"
               pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
               required>

      </mat-form-field>
    </div>
    <span>:</span>
    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label i18n>mm</label>
        <input matInput type="number"
               [(ngModel)]="minutes"
               (ngModelChange)="check('minutes')"
               name="estimated_duration_min"
               id="estimated_duration_min"
               min="0"
               max="59"
               step="1"
               pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
               required>
      </mat-form-field>
    </div>
    <span>:</span>
    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label i18n>ss</label>
        <input matInput type="number"
               [(ngModel)]="seconds"
               (ngModelChange)="check('seconds')"
               name="estimated_duration_sec"
               id="estimated_duration_sec"
               min="0"
               max="59"
               step="1"
               pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
               required>

      </mat-form-field>
    </div>
  </div>
</ng-container>
