<nav class="navbar navbar-toggleable d-flex" mat-tab-nav-bar>
  <div class="navbar-brand"></div>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav d-flex align-items-center">
      <a #rlat="routerLinkActive" [active]="rlat.isActive"
         [hidden]="rlat?.isActive || (!rlat?.isActive && !rlao?.isActive)"
         [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/index-trailer']"
         mat-tab-link routerLinkActive="active">Vous êtes un trailer ?</a>
      <a #rlao="routerLinkActive" [active]="rlao.isActive" [hidden]="rlao?.isActive "
         [routerLinkActiveOptions]="{exact: false}"
         [routerLink]="['/organizer']"
         mat-tab-link routerLinkActive="active">Vous êtes un organisateur ?</a>

      <a [hidden]="rlat?.isActive || (!rlat?.isActive && !rlao?.isActive)" [routerLink]="['/pages/contact-us']"
         mat-icon-button style="color: white">
        <i class="material-icons">mail_soutline</i>
      </a>
    </div>
  </div>
</nav>

<div class="_nav_mobile">

  <div class="__logo d-flex align-items-center">
    <a href="" class="d-flex flex-fill">
      <img src="assets/img/app/logo_splits_mobile.png" alt="logo Splits">
    </a>
    <div class="_right">
      <a (click)="openMenu()" class="_user _ic_menu d-flex align-items-center"><i class="fa-solid fa-user"></i></a>
      <a (click)="openSearchMobile()" class="_search _ic_menu d-flex align-items-center"><i
        class="fa-solid fa-magnifying-glass"></i></a>
    </div>
  </div>
</div>

<div class="__ss_menu" [ngClass]="{'_open':searchMobileOpened}" #search>

  <div class="_logo d-flex align-items-center">
    <a (click)="toggleMenuSearch()"><i class="fa-solid fa-arrow-left"></i></a>
    <img src="assets/img/app/logo_splits_mobile.png" alt="logo Splits">
  </div>

  <app-search-box-mobile (closeSearch)="searchMobileOpened=false"></app-search-box-mobile>
</div>

<div class="__ss_menu" [ngClass]="{'_open':menuOpened}" #userlogged>

  <div class="_logo d-flex align-items-center">
    <a (click)="toggleMenu()"><i class="fa-solid fa-arrow-left"></i></a>
    <img src="assets/img/app/logo_splits_mobile.png" alt="logo Splits">
  </div>

  <div class="_contain  w-100" *ngIf="loggedInUser">
    <ul>
      <li>
        <a (click)="dashboard()">
          <i class="fa-solid fa-house"></i>
          <span> Mon tableau de bord </span>
        </a>
      </li>
      <li>
        <a (click)="favourites()" *ngIf="loggedInUser?.type_user==='trailer'">
          <i class="fa-regular fa-heart"></i>
          <span> Mes favoris </span>
        </a>
      </li>
      <li>
        <a (click)="profile()">
          <i class="fa-solid fa-user"></i>
          <span>
          Mon profil
        </span>
        </a>
      </li>
      <li>
        <a (click)="logout()" class="_sp_btn _white_black _full my-5"><span class="_center">Me déconnecter</span></a>
      </li>
    </ul>
  </div>
  <div class="_contain  w-100" *ngIf="!loggedInUser">
    <ul>
      <li>
        <a (click)="signIn()">
          <i class="fa-solid fa-arrow-right-to-bracket"></i>
          <span>
          Me connecter
        </span>
        </a>
      </li>
      <li>
        <a (click)="register()">
          <i class="fa-solid fa-pen-to-square"></i>
          <span>
         M'inscrire
        </span>
        </a>
      </li>
      <li>
        <a [routerLink]="['pages/faq']">
          <i class="fa-regular fa-circle-question text-success"></i>
          <span>Aide </span>
        </a>
      </li>
    </ul>
  </div>
</div>
