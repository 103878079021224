export class Trkpt {
    idx: number;
    lat: number;
    lon: number;
    ele: number;
    cumul_positive_elevation: number;
    cumul_negative_elevation: number;
    dst: number;
    total_distance: number;
    distance_in_between: number;
}
