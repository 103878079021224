<div class="raceOff-list-item p-3" role="button">
  <div class="col-12 p-0">
    <h2><span>{{ raceOff.name }}</span></h2>
  </div>
  <div class="d-flex justify-content-start mb-2">
    <span class="_distance">{{ raceOff.distance }} km</span>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Date & Heure de départ</span>
        <div *ngIf="raceOff?.status=='canceled' else ddate">
          <div class="chip-list">
            <div class="chip disabled">{{ raceOff?.status | translate }}
            </div>
          </div>
        </div>
        <ng-template #ddate>
          <span>{{ raceOff.date | date : 'EEE dd MMM y'}} à {{ raceOff.departureTime | date: 'HH:mm'}}</span>
        </ng-template>
      </div>
    </div>
    <div class="col-md-5 col-lg-4">
      <div class="d-flex flex-column flex-grow-1 align-items-start">
        <span class="_int" i18n>Lieu de départ</span>
        <span>{{ raceOff.locality}} </span>
      </div>
    </div>
    <div class="col-md-3 col-lg-2">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Dénivelé +</span>
        <span>{{ raceOff.ascendingElevation || '--'}}mD+</span>
      </div>
    </div>
    <div class="col-md-3 col-lg-2">
      <div class="d-flex flex-column align-items-start">
        <span class="_int" i18n>Dénivelé -</span>
        <span>{{ raceOff.negativeElevation || '--'}}mD-</span>
      </div>
    </div>
  </div>
</div>

