import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule,
} from "@angular/material/legacy-snack-bar";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { FileUploadModule } from "ng2-file-upload";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxUploaderModule } from "ngx-uploader";

import { AlertComponent } from "../components/alerts/alert.component";
import { BabassComponent } from "../components/babass/babass.component";
import { ConfirmDialogComponent } from "../components/dialogs/confirm-dialog/confirm-dialog.component";
import { ModalComponent } from "../components/dialogs/modal/modal.component";
import { EventItemComponent } from "../components/events/event-item/event-item.component";
import { EventListComponent } from "../components/events/event-list/event-list.component";
import { LoaderComponent } from "../components/loader/loader.component";
import { LoginFormComponent } from "../components/login-form/login-form.component";
import { MapComponent } from "../components/map/map.component";
import { NavDefaultComponent } from "../components/nav/nav-default.component";
import { NavOrgaComponent } from "../components/nav/nav-orga/nav-orga.component";
import { NavTrailerComponent } from "../components/nav/nav-trailer/nav-trailer.component";
import { NotificationWidgetComponent } from "../components/notifications/notification-widget/notification-widget.component";
import { AddPoiComponent } from "../components/pois/add-poi/add-poi.component";
import { EditPoiComponent } from "../components/pois/edit-poi/edit-poi.component";
import { PoiBaseComponent } from "../components/pois/poi-base-component";
import { RaceChartComponent } from "../components/race-chart/race-chart.component";
import { RaceOffListItemComponent } from "../components/races-off/race-off-list-item/race-off-list-item.component";
import { RaceOffListComponent } from "../components/races-off/race-off-list/race-off-list.component";
import { RaceCompleteBarComponent } from "../components/races/race-complete-bar/race-complete-bar.component";
import { RaceItemComponent } from "../components/races/race-item/race-item.component";
import { RaceListItemComponent } from "../components/races/race-list/race-list-item/race-list-item.component";
import { RaceListComponent } from "../components/races/race-list/race-list.component";
import { RaceOrgaListItemComponent } from "../components/races/race-orga-list/race-orga-list-item/race-orga-list-item.component";
import { RaceOrgaListComponent } from "../components/races/race-orga-list/race-orga-list.component";
import { RacesComponent } from "../components/races/races.component";
import { UploaderComponent } from "../components/races/uploader/uploader.component";
import { RegisterOrgaStepperComponent } from "../components/register/register-orga-stepper/register-orga-stepper.component";
import { RegisterTrailerStepperComponent } from "../components/register/register-trailer-stepper/register-trailer-stepper.component";
import { RegisterTrailerComponent } from "../components/register/register-trailer/register-trailer.component";
import { RegisterComponent } from "../components/register/register.component";
import { RoadbookListItemComponent } from "../components/roadbooks/roadbook-list/roadbook-list-item/roadbook-list-item.component";
import { RoadbookListComponent } from "../components/roadbooks/roadbook-list/roadbook-list.component";
import { SearchBoxAdvancedComponent } from "../components/search-boxes/search-box-advanced/search-box-advanced.component";
import { SearchBoxLiteComponent } from "../components/search-boxes/search-box-lite/search-box-lite.component";
import { SearchBoxMobileComponent } from "../components/search-boxes/search-box-mobile/search-box-mobile.component";
import { SignInComponent } from "../components/sign-in/sign-in.component";
import { CompleteRacePercentComponent } from "../components/spinners/complete-race-percent/complete-race-percent.component";
import { EstimationSpinnerComponent } from "../components/spinners/estimation-spinner/estimation-spinner.component";
import { LocalSpinnerComponent } from "../components/spinners/local-spinner/local-spinner.component";
import { VmaSpinnerComponent } from "../components/spinners/vma-spinner/vma-spinner.component";
import { FaqComponent } from "../components/static-pages/faq/faq.component";
import { HowToComponent } from "../components/static-pages/how-to/how-to.component";
import { AccountNavMenuComponent } from "../components/toolbars/account-nav-menu/account-nav-menu.component";
import { ToolbarDefaultComponent } from "../components/toolbars/toolbar-default/toolbar-default.component";
import { ToolbarOrgaComponent } from "../components/toolbars/toolbar-orga/toolbar-orga.component";
import { ToolbarTrailerComponent } from "../components/toolbars/toolbar-trailer/toolbar-trailer.component";
import { UploaderImgComponent } from "../components/uploader-img/uploader-img.component";
import { UserProfileComponent } from "../components/user-profiles/user-profile.component";
import { DurationComponent } from "../components/utils/duration/duration.component";
import { PaginatorComponent } from "../components/utils/paginator/paginator.component";
import { NavigateBackDirective } from "../directives/navigate-back.directive";
import { PlaceAutocompleteDirective } from "../directives/place-autocomplete.directive";
import { ScrollerDirective } from "../directives/scroller.directive";
import { AccessGuard } from "../guards/access-guard";
import { RoadbookSaveGuard } from "../guards/forms/roadbook-save.guard";
import { SaveFormsGuard } from "../guards/forms/save-form.guard";
import { OnlyLoggedInUsersGuard } from "../guards/only-logged-in-users.guard";
import { DurationPipe } from "../pipes/duration.pipe";
import { TranslatePipe } from "../pipes/translate.pipe";
import { TruncatePipe } from "../pipes/truncate.pipe";

const materialModules = [
  MatCheckboxModule,
  MatInputModule,
  MatFormFieldModule,
  MatDialogModule,
  MatSliderModule,
  MatRadioModule,
  MatToolbarModule,
  MatCardModule,
  MatRippleModule,
  MatSortModule,
  MatChipsModule,
  MatIconModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatTableModule,
  MatTabsModule,
  CdkTableModule,
  DragDropModule,
  MatPaginatorModule,
  MatGridListModule,
  MatListModule,
  MatExpansionModule,
  MatStepperModule,
];

const sharedComponents = [
  ToolbarDefaultComponent,
  ToolbarOrgaComponent,
  ToolbarTrailerComponent,
  AccountNavMenuComponent,
  LoginFormComponent,
  NotificationWidgetComponent,
  UserProfileComponent,
  RaceChartComponent,
  FaqComponent,
  HowToComponent,
  LocalSpinnerComponent,
  PoiBaseComponent,
  AddPoiComponent,
  EditPoiComponent,
  AlertComponent,
  ConfirmDialogComponent,
  ModalComponent,
  SearchBoxAdvancedComponent,
  UploaderImgComponent,
  LoaderComponent,
  PaginatorComponent,
  EstimationSpinnerComponent,
  BabassComponent,
  VmaSpinnerComponent,
  NavDefaultComponent,
  NavTrailerComponent,
  NavOrgaComponent,
  RacesComponent,
  RaceListComponent,
  RaceListItemComponent,
  RaceItemComponent,
  RaceCompleteBarComponent,
  MapComponent,
  SearchBoxLiteComponent,
  SearchBoxMobileComponent,
  SignInComponent,
  RegisterComponent,
  RegisterTrailerComponent,
  RegisterOrgaStepperComponent,
  RegisterTrailerStepperComponent,
  EventListComponent,
  EventItemComponent,
  RaceOrgaListComponent,
  RaceOrgaListItemComponent,
  NavigateBackDirective,
  CompleteRacePercentComponent,
  DurationComponent,
  RoadbookListComponent,
  RoadbookListItemComponent,
  EventItemComponent,
  UploaderComponent,
  RaceOffListComponent,
  RaceOffListItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ...materialModules,
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),
    NgxUploaderModule,
    FileUploadModule,
    OverlayModule,
    GoogleMapsModule,
    AlertModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({
      animation: "progress",
      loadingText: "Chargement...",
    }),
    NgxSliderModule,
  ],
  declarations: [
    ScrollerDirective,
    PlaceAutocompleteDirective,
    DurationPipe,
    TruncatePipe,
    TranslatePipe,
    ...sharedComponents,
  ],
  exports: [
    CommonModule,
    EstimationSpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ScrollerDirective,
    PlaceAutocompleteDirective,
    DurationPipe,
    TruncatePipe,
    TranslatePipe,
    RecaptchaFormsModule,
    RecaptchaModule,
    NgxUploaderModule,
    AccordionModule,
    GoogleMapsModule,
    TimepickerModule,
    ...materialModules,
    ...sharedComponents,
    AlertModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
  ],
  providers: [
    OnlyLoggedInUsersGuard,
    AccessGuard,
    SaveFormsGuard,
    RoadbookSaveGuard,
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey:
          "6LctDj0UAAAAAP1Sj2rwwnAb4Pi8G3QnE-qZPlZq" as RecaptchaSettings,
      },
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
})
export class SharedModule {}
