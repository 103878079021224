  <mat-list-item>
      <div class="d-flex align-items-center w-100">
          <div class="details p-2 d-flex justify-content-start align-items-center mr-auto ">
              <div>
                  <a (click)="showRace()">{{ race.name }}</a>
                  <!--<div class="d-flex align-items-start flex-column justify-content-start info-item">-->
                  <!--<span class="title">{{ race?.date | date: 'dd/MM/y' }}</span>-->
                  <!--</div>-->
              </div>
              <div class="chip-list" style="margin-left: 1rem;">
                  <div class="chip default" *ngIf="race.isFallbackCourse">Parcours de repli
                  </div>
                  <div class="chip" [ngClass]="{
              'info': race.status=='draft',
              'success': race.status=='published',
              'default': race.status=='archived',
              'disabled': race.status=='canceled' }">{{ race.status | translate}}
                  </div>
              </div>
          </div>

          <div class="p-2">
              <race-complete-bar [raceId]="race.id"></race-complete-bar>
          </div>

          <div class="action p-2">
              <div class="notify-button-wrap">
                  <button *ngIf="race?.status=='published' && canModify" (click)="notifyTrailers()" mat-icon-button
                          color="accent" type="button" [disabled]="race.roadbooks?.length==0"
                          matTooltipShowDelay="1000" matTooltip="Envoyer une notification aux trailers">
                      <i class="icon-splits_chat splits-icon-l"></i>
                      <div class="roadbooks-count" *ngIf="race.roadbooks?.length>0">
                          <span>{{ race.roadbooks?.length }}</span>
                      </div>
                  </button>
              </div>
              <button (click)="showRace()" mat-icon-button color="accent" type="button" matTooltipShowDelay="1000" matTooltip="Consulter">
                  <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_view" color="white" class="splits-icon-md"></mat-icon>-->
                  <i class="icon-splits_eye splits-icon-l"></i>
              </button>
              <button *ngIf="(race?.status=='draft' || race?.status=='published' ) && canModify" (click)="editRace()"
                      mat-icon-button color="accent" type="button"
                      matTooltipShowDelay="1000" matTooltip="Modifier">
                  <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_edit" color="white" class="splits-icon-md"></mat-icon>-->
                  <i class="icon-splits_edit splits-icon-l"></i>
              </button>
              <button *ngIf="eventParent.status =='published' && race?.status=='draft' && canModify"
                      (click)="publishRace()"
                      mat-icon-button color="primary" type="button" matTooltipShowDelay="1000" matTooltip="Publier la course">
                  <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_edit" color="white" class="splits-icon-md"></mat-icon>-->
                  <i class="icon-splits_publish splits-icon-l"></i>
              </button>
              <button *ngIf="canModify && (race?.status=='draft' || race?.status=='canceled') " (click)="deleteRace()"
                      mat-icon-button color="primary"
                      type="button"
                      matTooltipShowDelay="1000" matTooltip="Supprimer la course">
                  <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_trash" color="white" class="splits-icon-md"></mat-icon>-->
                  <i class="icon-splits_trash splits-icon-l"></i>
              </button>

              <button *ngIf="canModify && race?.status=='published'" (click)="createFallbackRoute()"
                      mat-icon-button color="primary"
                      type="button"
                      matTooltipShowDelay="1000" matTooltip="Créer un parcours de repli">
                  <i class="material-icons" style="transform: rotate(+90deg);">call_split</i>
              </button>

              <button *ngIf="canModify && race?.status=='published'" (click)="cancelRace()" mat-icon-button
                      class="primary"
                      type="button"
                      matTooltipShowDelay="1000" matTooltip="Annuler la course">
                  <i class="material-icons">stop</i>
              </button>
          </div>
      </div>

  </mat-list-item>

  <mat-divider *ngIf="!isLastLine"></mat-divider>