<div class="roadbook-list-items-wrap py-3">
  <app-roadbook-list-item *ngFor="let roadbook of roadbooks | slice:pageSize*(page):pageSize*(page+1); let i=index"
                          [roadbook]="roadbook"
                          [id]="i"  (mouseenter)="mouseEnter(roadbook.id)"
                          (mouseleave)="mouseLeave(roadbook.id)"
  ></app-roadbook-list-item>

  <div class="pagination-wrap ">
    <paginator [length]="roadbooks?.length" [pageSize]=pageSize (pageChanged)="handlePageChange($event)"></paginator>
  </div>
</div>

