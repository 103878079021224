import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';
import { environment } from '../../../environments/environment';

@Injectable({
              providedIn: 'root'
            })
export class IndexService {

  indexChange: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(private http: HttpService) {
  }

  inMaintenance() {
    return environment.maintenance;
  }

  async pingApi() {
    await this.http.get('/api/index', null).toPromise();
  }
}
