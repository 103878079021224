import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { EventModel } from '../../../models/event.model';
import { RaceModel } from '../../../models/race.model';
import { EventService } from '../../../services/event/event.service';
import { SearchParam } from '../../../classes/search-param';
import { RaceService } from '../../../services/race/race.service';
import { BaseComponent } from '../../base.component';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../services/common/dialog.service';
import { UserService } from '../../../services/user/user.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import moment from 'moment';

@Component({

             selector: 'event-item-cmp',
             templateUrl: './event-item.component.html',
             styleUrls: ['./event-item.component.scss']
           })

export class EventItemComponent extends BaseComponent implements OnInit {

  @Input() event: EventModel;
  @Input() id: number;
  @Input() isFirstLine: boolean;
  @Input() showItemActions;

  dateEventDisplay: string;

  hasError = false;
  isDraft: boolean;
  isPublished: boolean;
  isArchived: boolean;


  @Output() eventSelected: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  @Output() eventPublished: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventDuplicated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();

  private searchRaceParams: Array<SearchParam>;


  constructor(protected userService: UserService,
              private eventService: EventService,
              private raceService: RaceService,
              public router: Router,
              private dialogsService: DialogService,
              private snackbarService: SnackbarService,
              private dialogService: DialogService,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
    this.searchRaceParams = new Array<SearchParam>();
  }

  ngOnInit() {
    super.ngOnInit();
    this.canModify = this.eventService.canModify(this.event, this.loggedInUser);

    this.formatDateEvent();
  }

  afterInit() {
    // nothing to do
  }

  formatDateEvent() {
    const dateBegin: Date = new Date(this.event.dateBegin);
    const dateEnd: Date = new Date(this.event.dateEnd);
    if (dateBegin === dateEnd) {
      moment(dateBegin).format('DD MMMM YYYY');
    } else if (dateBegin.getDate() !== dateEnd.getDate() && dateBegin.getMonth() === dateEnd.getMonth()) {
      this.dateEventDisplay = dateBegin.getDate() + '-' + moment(dateEnd).format('DD MMMM YY');
    } else if (dateBegin.getMonth() !== dateEnd.getMonth()) {
      this.dateEventDisplay = moment(dateBegin).format('DD MMMM YY') + ' - ' + moment(dateEnd).format('DD MMMM YY');
    }
  }

  selectEvent() {
    this.eventSelected.emit(this.event);
    this.router.navigate(['/events',this.event.id, 'show']);
  }

  /**
   * Ajouter une course
   * @param event
   */
  addRace(event: EventModel) {
    this.router.navigate(['/organizer/races/new', this.event.id]);
  }

}
