<div class="avatar-menu-wrap">
  <!-- menu opens when trigger button is clicked -->
  <button mat-icon-button [mat-menu-trigger-for]="menu" matTooltip="Mon compte" matTooltipShowDelay="1000">
    <div class="_img_account">
      <div
        [ngClass]="['avatar-icon __icon _bg-size-100 _no-border', user?.avatar ? '_icon-avatar-'+ user?.avatar.code : '_icon-avatar-'+ user?.type_user+'-male-1']"></div>
    </div>

  </button>
  <!-- this menu starts as hidden by default -->
  <mat-menu #menu="matMenu" class="_menu_user">
    <button mat-menu-item (click)="home()">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-house"></i>
        <span> Mon tableau de bord </span>
      </div>
    </button>
    <button mat-menu-item *ngIf="user?.type_user==='trailer'" (click)="goToMyFavourites()">
      <div class="d-flex align-items-center">
        <i class="fa-regular fa-heart"></i>
        <span> Mes favoris </span>
      </div>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="myProfile()">
      <div class="d-flex align-items-center">
        <i class="fa-regular fa-user"></i>
        <span> Mon profil </span>
      </div>
    </button>
    <button mat-menu-item (click)="logout()">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
        <span>Me déconnecter</span>
      </div>
    </button>
  </mat-menu>
</div>
