import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable({
              providedIn: 'root'
            })
export class ConvertorService {

  constructor() {
  }

  getPaceFromSpeed(_speed: number) {
    if (_speed) {
      const minutes: number = Math.floor(60 / _speed);
      // On sépare les entiers des décimaux de l’allure en min/km.
      // On multiplie par 60 les décimaux de l’allure afin de les convertir en secondes.
      const seconds: number = ((60 / _speed) % 1) * 60;
      const pace: Date = new Date();
      pace.setMinutes(minutes);
      pace.setSeconds(seconds);

      return moment(pace).format('mm:ss');
    }
  }

  getSpeedFromPace(_pace: string) {

    let speed = 0;

    if (typeof _pace != 'undefined') {
      const paceSplited = _pace.split(':');
      const minutes: number = +paceSplited[0];
      const seconds: number = +paceSplited[1];

      const paceInBase100 = minutes + seconds / 60;

      speed = parseFloat((Math.round(60 / paceInBase100 * 100) / 100).toFixed(2));
    }

    return speed;
  }

  /**
   *
   * @param hours
   * @param minutes
   * @param seconds
   * @returns {string}
   */
  formatIntoPace(minutes, seconds) {
    const pace: Date = new Date();
    pace.setMinutes(minutes);
    pace.setSeconds(seconds);

    return moment(pace).format('mm:ss');
  }


  getSpeedFromTime(_distance: number, _timeInSecond: number) {

    if (_distance && _timeInSecond) {
      const timeOfArrivalInHour: number = _timeInSecond / 3600;

      const speed: number = parseFloat((Math.round(_distance / timeOfArrivalInHour * 100) / 100).toFixed(2));

      return speed;
    }

  }


  getDurationFromSpeed(_distance: number, _speed: number) {

    if (_distance && _speed) {
      const timeOfArrivalInSecond = (_distance / _speed) * 3600;

      const duration: moment.Duration = moment.duration(timeOfArrivalInSecond, 'seconds');

      const durationInSecond = Math.floor(duration.asSeconds());

      return durationInSecond;
    }
  }

  getDurationFromPace(_distance: number, _pace: string) {

    if (_distance && _pace) {
      const speed = this.getSpeedFromPace(_pace);

      return this.getDurationFromSpeed(_distance, speed);
    }
  }

  /**
   *
   * @param _pace
   * @returns {any}
   */
  convertPaceFromStringToFullJson(_pace: string) {

    let paceFullJson;

    if (typeof _pace != 'undefined') {
      // Splitting it with : as the separator
      const arrPace = _pace.split(':');

      paceFullJson = {
        minutes: arrPace[0],
        seconds: arrPace[1]
      };
    }

    return paceFullJson;
  }


  convertDurationFromSecondToFullJson(_durationInSecond: number, cumulateHours: boolean = true) {

    const timeOfArrivalInSecond = _durationInSecond;

    const duration: moment.Duration = moment.duration(timeOfArrivalInSecond, 'seconds');

    const durationFullJson = {
      days: (!cumulateHours) ? Math.floor(duration.asDays()) : 0,
      hours: (cumulateHours) ? Math.floor(duration.asHours()) : duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };

    return durationFullJson;
  }

  getTimeInSecond(_hour: number, _min: number, _sec: number) {
    return _hour * 3600 + _min * 60 + _sec;
  }

  convertNumberToString(number, minDigits): string {
    let s = number + '';
    while (s.length < minDigits) { s = '0' + s; }
    return s;
  }

}
