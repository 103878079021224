import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user/user.service';
import { CommonService } from '../../../services/common/common.service';
import { SpinnerService } from '../../../services/common/spinner.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { ErrorService } from '../../../services/common/error.service';
import { UsersValidator } from '../../../shared/validators/users.validator';
import { TRAILER } from '../../../models/user.model';
@Component({

    selector: 'app-register-trailer',
    templateUrl: './register-trailer.component.html',
    styleUrls: ['./register-trailer.component.css'],
})

export class RegisterTrailerComponent implements OnInit {

    lastnameCtrl: UntypedFormControl;
    firstnameCtrl: UntypedFormControl;
    emailCtrl: UntypedFormControl;
    isNotificationsAllowedCtrl: UntypedFormControl;
    tosCtrl: UntypedFormControl;

    passwordCtrl: UntypedFormControl;
    confirm_passwordCtrl: UntypedFormControl;

    recaptcha_Ctrl: UntypedFormControl;

    registerTrailerForm: UntypedFormGroup;
    matchingPasswords: UntypedFormGroup;


    submitted = false;
    addSuccessfully: boolean;
    hasError = false;
    errorMessage: string;

    constructor(public router: Router,
                private formBuilder: UntypedFormBuilder,
                private userService: UserService,
                private commonService: CommonService,
                private spinner: SpinnerService,
                private snackbarService: SnackbarService,
                private errorService: ErrorService) {
    }

    ngOnInit() {

        this.lastnameCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));
        this.firstnameCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));

        this.emailCtrl = new UntypedFormControl('', Validators.compose([Validators.required,
            Validators.minLength(3),
            UsersValidator.validateEmail]));

        this.passwordCtrl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(8)]));
        this.confirm_passwordCtrl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(8)]));

        this.matchingPasswords = this.formBuilder.group({
            password: this.passwordCtrl,
            confirm_password: this.confirm_passwordCtrl
        }, {validator: UsersValidator.matchingPassword});

        this.isNotificationsAllowedCtrl = new UntypedFormControl(true);

        this.tosCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));

        this.recaptcha_Ctrl = new UntypedFormControl(null);

        this.registerTrailerForm = this.formBuilder.group({
            firstname: this.firstnameCtrl,
            lastname: this.lastnameCtrl,
            email: this.emailCtrl,
            tos: this.tosCtrl,
            isNotificationsAllowed: this.isNotificationsAllowedCtrl,
            matchingPasswords: this.matchingPasswords,
            recaptcha: this.recaptcha_Ctrl
        });
    }

    register(): Subscription {
        this.spinner.start('Création du compte...');

        this.submitted = true;
        return this.userService.registerTrailer(this.registerTrailerForm.value)
            .subscribe(response => {
                    this.spinner.start('Connexion...');
                    this.userService.login({
                            email: this.emailCtrl.value,
                            password: this.passwordCtrl.value,
                            type_user: TRAILER
                        })
                        .subscribe(response => {
                            this.router.navigate(['/', TRAILER, 'dashboard']);
                        }, (error) => {
                            this.spinner.stop();
                            this.snackbarService.openDanger('Votre email et/ou votre mot de passe sont incorrects');
                        });
                },
                error => {
                    this.hasError = true;

                    const formErrorMessage: string = this.errorService.handleFormError(error);
                    const errorMessage: string = (formErrorMessage) ? formErrorMessage : 'Erreur lors de votre inscription - Veuillez contacter l\'equipe Splits';
                    this.snackbarService.openDanger(errorMessage);
                });
    }


}
