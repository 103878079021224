/**
 * Created by macbookaxel on 26/06/2017.
 */
export enum NOTIFICATION_TYPE {
    DEFAULT = 1,
    INFO = 2,
    SUCCESS = 3,
    WARNING = 4,
    DANGER = 5
}
