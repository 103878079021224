<div class="register-form-wrap text-left">
  <form (ngSubmit)="register()" [formGroup]="registerForm" class="text-left">

    <re-captcha id="recap" render="explicit" formControlName="recaptcha" size="invisible" (resolved)="register()"
                #captchaRef></re-captcha>


    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label>Email *</label>
        <input matInput type="email" [class.valid]="emailCtrl.valid"
               [class.invalid]="emailCtrl.touched && emailCtrl.dirty && !emailCtrl.valid" #email
               formControlName="email" required
               autocomplete="new-email">
        <mat-error *ngIf="emailCtrl.dirty &&emailCtrl.hasError('required')">Champ obligatoire</mat-error>
        <mat-error *ngIf="emailCtrl.hasError('validateEmail') && !emailCtrl.hasError('required')">Email
          invalide
        </mat-error>
        <mat-error *ngIf="emailCtrl.dirty && emailCtrl.hasError('emailAvailability')">Il existe déjà un compte avec cet
          email.
        </mat-error>
      </mat-form-field>
    </div>


    <div [formGroup]="matchingPasswords">

      <div class="form-group __input">
        <mat-form-field class="_wrap_input">
          <label>Mot de passe *</label>
          <input matInput type="password" #password required
                 formControlName="password"
                 autocomplete="new-password">
          <mat-error *ngIf="passwordCtrl.hasError('required')">Champ obligatoire</mat-error>
          <mat-error *ngIf="passwordCtrl.hasError('minlength') && !passwordCtrl.hasError('required')">Le mot de passe doit avoir au minimum 8 caractères</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group __input">
        <mat-form-field class="_wrap_input">
          <label>Confirmation Mot de passe *</label>
          <input matInput type="password" formControlName="confirm_password"
                 #confirmpassword required
                 autocomplete="new-password">
          <mat-error *ngIf="confirmPasswordCtrl.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-error style="font-size: 75%; margin-top: -1rem;" *ngIf="matchingPasswords.hasError('mismatch') && confirmPasswordCtrl.touched && !confirmPasswordCtrl.hasError('required')">
          Les mots de passe ne correspondent pas
        </mat-error>
      </div>
    </div>

    <div class="form-group __input">
      <label>Vous êtes : </label>
      <mat-radio-group i18n aria-label="Vous êtes"
                       class="d-flex align-items-center justify-content-start"
                       formControlName="type_user">
        <mat-radio-button class="px-3" value="trailer">Coureur</mat-radio-button>
        <mat-radio-button class="px-3" value="organizer">Organisateur</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="form-group mt-3">
      <mat-checkbox formControlName="tos" name="tos"
                    id="tos" required>
        J'accepte les <a href="/pages/tos" target="_blank" alt="Conditions générales d'utilisation">conditions
        générales
        d'utilisation</a>
      </mat-checkbox>
    </div>

    <button type="button" class="_sp_btn _blue btn-block no_marge" i18n
            matTooltip="Continuer" matTooltipShowDelay="1000" [disabled]="registerForm.invalid"
            aria-label="Bouton Continuer" (click)="captchaRef.execute()"><span>Continuer</span>
    </button>

  </form>
</div>




