import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {SearchParam} from '../../classes/search-param';
import {HttpService} from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class GpxService {

  private searchParams: Array<SearchParam> = [];

  constructor(private http: HttpService) {
  }

  getTrackByFilename(gpxFilename: string): Observable<any> {
    this.searchParams = [];
    this.searchParams.push(new SearchParam('gpxFilename', gpxFilename));
    return this.http.get('/api/track/by_filename', this.searchParams);
  }
}
