import {
  Chart,
  ChartColor,
  ChartConfiguration,
  ChartData,
  ChartDataSets,
  ChartOptions,
  ChartPoint,
  ChartTooltipOptions,
  LinearTickOptions
} from 'chart.js';

/**
 * Created by macbookaxel on 09/06/2017.
 */


export class CustomChart extends Chart {
  toBase64Image: () => string;
  config: CustomChartConfiguration;
}


export interface CustomChartData extends ChartData {
  datasets?: CustomChartDataSets[];
}


export interface CustomChartDataSets extends ChartDataSets {
  showLine?: boolean;
  spanGaps?: boolean;
  _meta?: any[];
  data?: CustomChartPoint[];
}

export interface CustomChartConfiguration extends ChartConfiguration {
  type: string;
  options: CustomChartOptions;
  plugins: any[];
}

export interface CustomChartOptions extends ChartOptions {
  onClick?: (any1?: any, any2?: any) => any;
  annotation?: ChartAnnotationOptions;
  layout: {
    padding: {
      top: number;
      bottom: number;
      left: number;
      right: number
    }
  };
  tooltips?: ChartTooltipOptions;
}


export interface CustomChartPoint extends ChartPoint {
  id?: string;
  lat?: number;
  lon?: number;
}

export interface CustomLinearTickOptions extends LinearTickOptions {
  fixedStepSize?: number;
}


export interface ChartAnnotationOptions {
  events?: string [];
  drawTime?: string; // (default)
  dblClickSpeed?: number;
  annotations?: ChartAnnotation[];
}

export interface ChartAnnotation {
  id?: string; // optional
  type?: string;
  mode?: string;
  scaleID?: string;
  previousValue?: number;
  value?: string;
  endValue?: string;
  xScaleID?: string;
  yScaleID?: string;
  xMin?: number;
  xMax?: number;
  yMin?: number;
  yMax?: number;
  borderColor?: ChartColor;
  borderWidth?: number;
  borderDash?: number[];
  drawTime?: string;
  borderDashOffset?: number;
  label?: ChartAnnotationLabel;
  onMouseenter?: (any?: any) => any;
  onMouseover?: (any?: any) => any;
  onMouseleave?: (any?: any) => any;
  onMouseout?: (any?: any) => any;
  onMousemove?: (any?: any) => any;
  onMousedown?: (any?: any) => any;
  onMouseup?: (any?: any) => any;
  onClick?: (any?: any) => any;
  onDblclick?: (any?: any) => any;
  onContextmenu?: (any?: any) => any;
  onWheel?: (any?: any) => any;
}

export interface ChartAnnotationLabel {
  // Background color of label, default below
  backgroundColor?: ChartColor;

  // Font family of text, inherits from global
  fontFamily?: string;

  // Font size of text, inherits from global
  fontSize?: number;

  // Font style of text, default below
  fontStyle?: string;

  // Font color of text, default below
  fontColor?: ChartColor;

  // Padding of label to add left/right, default below
  xPadding?: number;

  // Padding of label to add top/bottom, default below
  yPadding?: number;

  // Radius of label rectangle, default below
  cornerRadius?: number;

  // Anchor position of label on line, can be one of?: top, bottom, left, right, center. Default below.
  position?: string;

  // Center Label in the segment previous of the vertical line
  centeredWithPreviousSegment?: boolean;

  // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
  // For horizontal lines positioned left or right, negative values move
  // the label toward the edge, and negative values toward the center.
  xAdjust?: number;

  // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
  // For vertical lines positioned top or bottom, negative values move
  // the label toward the edge, and negative values toward the center.
  yAdjust?: number;

  // Whether the label is enabled and should be displayed
  enabled?: boolean;

  // Text to display in label - default is null
  content?: string;
}


