<div class="content-wrap" style="margin: -24px">
  <h3 matDialogTitle class="dialog-title d-flex align-items-start"><i class="fa-solid fa-circle-exclamation text-danger mt-1"></i><span class="ml-1">{{ title }}</span></h3>

  <mat-dialog-content>
    <p  class="dialog-message">
      <b>Cet événement</b> et toutes les  <b> {{ event.races.length }} courses associées</b>
      seront définitivement supprimées sans annulation possible.
    </p>
    <p class="dialog-message">
      <b>Vous ne pourrez plus récupérer les données enregistrées.</b>
    </p>
    <div class="form-group __input">
      <mat-form-field class="_wrap_input">
        <label i18n>Pour confirmer veuillez écrire SUPPRIMER :</label>
        <input matInput type="text" [(ngModel)]="confirmDeleteText"  (ngModelChange)="checkInputDelete($event)" name="confirmDeleteText" id="confirmDeleteText" required>
        <mat-error>Veuillez écrire supprimer</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-end" style="width: 100%">
      <button type="button" matDialogClose class="_sp_btn _white"><span>Annuler</span></button>
      <button type="submit" class="_sp_btn _danger" (click)="dialogRef.close('confirm')"
              color="accent" [disabled]="btnDisabled"><span>Confirmer</span></button>
    </div>
  </mat-dialog-actions>

</div>
