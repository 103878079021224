<div class="local-spinner-overlay d-flex align-items-center justify-content-center" *ngIf="active">
  <!--<mat-card class="loading-content">-->
    <!--<mat-card-content>-->
      <!--<div class="d-flex flex-column align-items-center">-->
        <mat-spinner class="local-spinner mx-auto" ></mat-spinner>
        <p>{{message}}</p>
      <!--</div>-->
    <!--</mat-card-content>-->
  <!--</mat-card>-->
</div>

