import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
             selector: '[scroller]'
           })
export class ScrollerDirective {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    renderer.listen(elRef.nativeElement, 'wheel', (e) => {
                      if (elRef.nativeElement.scrollTop + elRef.nativeElement.offsetHeight < elRef.nativeElement.scrollHeight) {
                        if (e.deltaY < 0) {
                          e = e || window.event;
                          if (e.preventDefault) {
                            e.preventDefault();
                          }
                          e.returnValue = false;
                        }
                      }
                    }
    );

  }

}
