import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { SpinnerService } from '../../../services/common/spinner.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';

@Component({
             selector: 'app-nav-orga',
             templateUrl: 'nav-orga.component.html',
             styleUrls: ['nav-orga.component.scss']
           })
export class NavOrgaComponent extends BaseComponent implements OnInit {

  _minimize = false;


  constructor(private spinner: SpinnerService,
              public viewContainerRef: ViewContainerRef,
              private router: Router,
              protected userService: UserService) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  logout(event) {
    event.preventDefault();
    this.userService.logout();
    this.router.navigate(['/organizer']);
  }

  afterInit() {

  }

}
