<div class="uploader-wrap w-100 ">
  <div class="d-flex flex-column">
    <div ng2FileDrop
         class="upload-zone d-flex flex-column justify-content-center"
         [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
         (fileOver)="fileOverBase($event)"
         [uploader]="uploader">
      <div class="d-flex flex-column">
        <input type="file" ng2FileSelect
               [uploader]="uploader"
               [disabled] = "currentImageCount >= maxUpload"
        >
        <i class="fa-solid fa-camera"></i>
        <p>Glissez-déposez une images ou cliquez ici
          <br>Extensions autorisées : {{ authorizedExtensionsLabel }}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="uploadFile" class="progress-bar-wrap align-self-center w-100">
  <mat-progress-bar
    class="example-margin"
    color="accent"
    mode="determinate"
    [value]="uploadProgress"
    [bufferValue]="bufferValue">
  </mat-progress-bar>
</div>

<mat-card *ngIf="roadbookImageUrl" class="picture-card w-75">
  <img mat-card-image [src]="sanitizer.bypassSecurityTrustUrl(roadbookImageUrl)">
  <div class="mask"></div>
  <button
    mat-mini-fab
    color="accent"
    class="btn-delete"
    (click)="removeBg()"
  >
    <i class="fa fa-close"></i>
  </button>
</mat-card>
