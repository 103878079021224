import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NavDefaultComponent } from '../nav-default.component';
import { NavService } from '../../../services/common/nav.service';
import { SpinnerService } from '../../../services/common/spinner.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { BaseComponent } from '../../base.component';

@Component({
             selector: 'app-nav-trailer',
             templateUrl: 'nav-trailer.component.html',
             styleUrls: ['nav-trailer.component.scss']
           })
export class NavTrailerComponent extends BaseComponent implements OnInit {

  _minimize = false;

  constructor(private spinner: SpinnerService,
              public viewContainerRef: ViewContainerRef,
              private router: Router,
              protected userService: UserService) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {

  }

}
