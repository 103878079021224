import { TerrainModel } from './terrain.model';
import { DisciplineModel } from './discipline.model';
import { RaceModel } from './race.model';
import { UserNotificationModel } from './user.notification.model';
import { RoadbookModel } from './roadbook.model';
import { SelectItemModel } from './select.item.model';
import { AvatarModel } from './avatar.model';


export const TRAILER = 'trailer';
export const ORGANIZER = 'organizer';


export interface TokenModel {
  id: number;
  token: string;
  createdAt: Date;
  user: UserModel;
}

/**
 * Created by macbookaxel on 16/06/2016.
 */
export class UserModel {

  id: number;
  lastname: string;
  firstname: string;
  username: string;
  password: string;

  avatar: AvatarModel;

  matchingPasswords: {
    password: string,
    confirm_password: string
  };

  email: string;
  type_user: string;

  // _isProfilTrailer:boolean;
  // _isProfilOrganizer:boolean;

  address: string;
  locality: string;
  postalCode: string;
  country: string;
  bloodGroup: string;

  // Notifications
  isNotificationsAllowed: boolean;

  // CGU
  tos: boolean;

  // trailer
  age: number;
  _birthdate: Date = null;
  weight: number;
  size: number;
  vma: number;
  ascendingSpeed: number;
  averageSpeed: number;

  // emergency

  emergencyFirstname: string;
  emergencyLastname: string;
  emergencyEmail: string;
  emergencyPhone: string;

  // préférence course :
  baseCampLocation: string;
  baseCampLatitude: number;
  baseCampLongitude: number;

  preferenceMaxDistanceFromBasecamp: number;
  preferenceTerrain: TerrainModel;
  preferenceTerrainId: number;
  preferenceDiscipline: DisciplineModel;
  preferenceDisciplineId: number;
  preferenceAscendingElevation: [];
  preferenceDistance: [];

  preferenceIsNightRace: boolean;
  preferenceHasTimeLimit: boolean;
  preferenceHasUtmbPoint: boolean;

  facebookPage: string;
  instagramPage: string;

  favouriteRaces: Array<RaceModel> = [];

  userNotifications: Array<UserNotificationModel> = [];

  created: Date;

  // orga
  organizm: string;

  website: string;

  roadbooks: Array<RoadbookModel> = [];

  // Social Connect
  uid: number;
  provider: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  _isProfilOrganizer() {
    return this.type_user === 'organizer';
  }

  _isProfilTrailer() {
    return this.type_user === 'trailer';
  }

  get birthdate(): Date {
    return this._birthdate;
  }

  set birthdate(birthdate: Date) {
    this._birthdate = new Date(birthdate);
  }
}
