<ng-container *ngIf="events?.length > 0; else noEvents">
  <event-item-cmp
    *ngFor="let currentEvent of events |  slice:pageSize*(page):pageSize*(page+1); let i=index; first as isFirstLine;"
    [event]="currentEvent"
    [id]="i"
    [isFirstLine]="isFirstLine"
    [showItemActions]="showItemActions"
    (mouseenter)="mouseEnter(currentEvent.id)"
    (mouseleave)="mouseLeave(currentEvent.id)"></event-item-cmp>

  <div class="pagination-wrap ">
    <paginator [length]="events?.length" [pageSize]=pageSize (pageChanged)="handlePageChange($event)"></paginator>
  </div>
</ng-container>
<ng-template #noEvents><span>Il n'y a actuellement aucun événement correspondant à votre recherche</span></ng-template>
