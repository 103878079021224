/**
 * Created by macbookaxel on 30/05/2017.
 */

import { AfterViewInit, Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ORGANIZER, TRAILER, UserModel } from '../../models/user.model';
import { BaseComponent } from '../base.component';
import { UserService } from '../../services/user/user.service';
import { SpinnerService } from '../../services/common/spinner.service';
import { SnackbarService } from '../../services/notifications/snackbar.service';
import { DataService } from '../../services/common/data.service';
import { DialogService } from '../../services/common/dialog.service';
import { TrailerModel } from '../../models/trailer.model';
import { OrganizerModel } from '../../models/organizer.model';
import { UsersValidator } from '../../shared/validators/users.validator';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from '../../services/common/error.service';
import { SearchParam } from '../../classes/search-param';
import { AvatarModel } from '../../models/avatar.model';

@Component({
             selector: 'user-profile',
             template: ''
           })
export class UserProfileComponent extends BaseComponent implements OnInit, AfterViewInit {

  user: TrailerModel | OrganizerModel;

  userDataForm: UntypedFormGroup;

  idCtrl: UntypedFormControl;
  type_userCtrl: UntypedFormControl;
  lastnameCtrl: UntypedFormControl;
  firstnameCtrl: UntypedFormControl;
  avatarCtrl: UntypedFormControl;
  facebookPageCtrl: UntypedFormControl;
  instagramPageCtrl: UntypedFormControl;
  emailCtrl: UntypedFormControl;
  passwordCtrl: UntypedFormControl;
  confirmPasswordCtrl: UntypedFormControl;
  matchingPasswords: UntypedFormGroup;
  isNotificationsAllowedCtrl: UntypedFormControl;

  avatars: Array<AvatarModel>;

  constructor(protected userService: UserService,
              protected formBuilder: UntypedFormBuilder,
              protected route: ActivatedRoute,
              protected router: Router,
              protected spinner: SpinnerService,
              protected snackbarService: SnackbarService,
              protected dataService: DataService,
              protected dialogService: DialogService,
              protected errorService: ErrorService,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.idCtrl = new UntypedFormControl(null);
    this.type_userCtrl = new UntypedFormControl(null);
    this.lastnameCtrl = new UntypedFormControl(null, Validators.compose([Validators.required]));
    this.firstnameCtrl = new UntypedFormControl(null, Validators.compose([Validators.required]));
    this.avatarCtrl = new UntypedFormControl(null);

    this.emailCtrl = new UntypedFormControl(null, Validators.compose([Validators.required,
                                                                Validators.minLength(3),
                                                                UsersValidator.validateEmail]));

    this.passwordCtrl = new UntypedFormControl('', Validators.compose([Validators.minLength(8)]));
    this.confirmPasswordCtrl = new UntypedFormControl('', Validators.compose([Validators.minLength(8)]));

    this.matchingPasswords = this.formBuilder.group({
                                                      password: this.passwordCtrl,
                                                      confirm_password: this.confirmPasswordCtrl
                                                    }, {validator: UsersValidator.matchingPassword});


    this.facebookPageCtrl = new UntypedFormControl('', Validators.compose([UsersValidator.validateWebsite]));
    this.instagramPageCtrl = new UntypedFormControl('', Validators.compose([UsersValidator.validateWebsite]));


    this.isNotificationsAllowedCtrl = new UntypedFormControl(this.user.isNotificationsAllowed);
  }

  afterInit() {
    this.user = new UserModel(this.loggedInUser);
  }

  ngAfterViewInit(): void {
    // this.enableUnsaveChangesDetection();
  }

  save() {
    if (this.userDataForm.valid) {
      this.spinner.start('Sauvegarde en cours...');
      this.userService.patchUser(this.userDataForm.value)
        .subscribe((editedUser: UserModel) => {
                     this.userService.updateAuthenticatedUser(editedUser);
                     this.unsavedChanges = false;
                     this.snackbarService.openSuccess('Informations modifiées avec succès');
                     this.spinner.stop();
                     this.router.navigate(['/', editedUser.type_user, 'home']);
                   },
                   error => {
                     this.snackbarService.openDanger(error.errorMessage);
                     this.spinner.stop();
                   }
        );
    }
  }

  protected handleControlChanges() {
    this.passwordCtrl.valueChanges.subscribe(pass => {
      if (pass) {
        this.confirmPasswordCtrl.setValidators([Validators.required, Validators.minLength(8)]);
      } else {
        this.confirmPasswordCtrl.clearValidators();
      }
      this.confirmPasswordCtrl.updateValueAndValidity();
    });

    this.emailCtrl.valueChanges.pipe(filter(() => this.emailCtrl.valid),
                                     switchMap((email) => this.userService.checkUserEmailAvailable(email))).subscribe((isAvailable) => {

      this.emailCtrl.setErrors((!isAvailable) ? {emailAvailability: (!isAvailable)} : null);

    }, error => {
      this.errorService.handleError(error);
    });

    this.userDataForm.valueChanges.subscribe((data) => {
      this.unsavedChanges = this.userDataForm.dirty;
    });

  }


  deleteMyAccount() {

    this.dialogService
      .confirm('Confirmation de la suppression de votre compte',
               'Etes-vous sûr de vouloir supprimer votre compte ? Cette action est définitive ! ', this.viewContainerRef)
      .subscribe(res => {

        if (typeof res != 'undefined') {
          if (res == 'confirm') { // confirmation d'annulation de la course

            return this.userService.deleteUser(this.user)
              .subscribe(
                response => {
                  this.userService.logout();
                  this.router.navigate(['/']);
                  this.snackbarService.openSuccess('Votre compte a été supprimé avec succès');
                },
                error => {
                  this.snackbarService.openDanger('Erreur lors de la suppression du compte');
                });
          }
        }

      });
  }

  protected enableUnsaveChangesDetection() {
    this.userDataForm.valueChanges
      .subscribe(data => {
        this.unsavedChanges = this.userDataForm.dirty;
      });
  }

  protected loadAvatars(typeAvatar: string = TRAILER) {
    // get terrains dropdownlist
    this.dataService.getDropDownList('avatars', [new SearchParam('type', typeAvatar)])
      .pipe(tap((avatars: AvatarModel[]) => {
        if (this.user.avatar) {
          this.avatarCtrl.setValue(avatars.find(i => +i.id === +this.user.avatar.id));
        }
      }))
      .subscribe((avatars: AvatarModel[]) => {
                   this.avatars = avatars;
                 }
      );
  }

}
