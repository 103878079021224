<div class="_background_blur"></div>

<h3 class="_grey _center _search_title">Rechercher un course</h3>

<div class="__contain_search" [class.mat-elevation-z2]="showFastResult">
  <form
    (ngSubmit)="searchRace()"
    [formGroup]="searchRaceForm"
    class="_search_lite d-flex flex-column px-1 px-md-3 px-lg-4 position-relative"
    style="width: 100%"
  >
    <div
      class="d-flex flex-row align-items-center flex-wrap flex-lg-nowrap fieldset"
    >
      <div class="col-4 flex-fill form-group __input">
        <mat-form-field class="_wrap_input _input_search px-3 py-0">
          <div class="_label_abs">
            <i class="fa-solid fa-magnifying-glass"></i>
            <span i18n>Par nom</span>
          </div>
          <input
            class="_line"
            matInput
            formControlName="name"
            type="text"
            #searchInput
            (focus)="focus()"
            autocomplete="off"
          />
          <i
            *ngIf="searchInput.value"
            matSuffix
            class="icon-splits_circle-clear splits-icon-md clear-search-field"
            (click)="clear()"
          ></i>
        </mat-form-field>
      </div>

      <div class="col-4 flex-fill form-group __input">
        <mat-form-field class="_wrap_input _input_search px-3 py-0">
          <div class="_label_abs">
            <i class="fa-solid fa-location-pin"></i>
            <span i18n>Par Ville</span>
          </div>
          <input
            class="_line"
            matInput
            formControlName="location"
            type="text"
            data-refererComponent="lite"
            appPlaceAutocomplete
            #locationAutocomplete
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="col-4 flex-fill form-group __input __search_range">
        <mat-form-field class="_wrap_input _input_search px-3 py-0 _no_border">
          <div class="pl-2 _label_abs">
            <span i18n>Par date</span>
          </div>
          <mat-date-range-input
            [formGroup]="range"
            [min]="today"
            [rangePicker]="picker"
            class="d-flex flex-wrap __input_s_range"
            (click)="picker.open()"
          >
            <input
              class="_line d-flex"
              matStartDate
              formControlName="start"
              placeholder="Début"
            />
            <input
              class="_line d-flex"
              matEndDate
              formControlName="end"
              placeholder="Fin"
              [min]="startCtrl.value"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="
              startCtrl.hasError('matStartDateInvalid') ||
              endCtrl.hasError('matEndDateInvalid')
            "
            i18n
          >
            Dates invalides
          </mat-error>
        </mat-form-field>
      </div>

      <div class="btn-action-wrap _min_marge">
        <button
          type="submit"
          class="_sp_btn _inline _blue"
          i18n
          *ngIf="!minimized; else btnIconSearch"
        >
          <span>Commencer</span>
        </button>
        <ng-template #btnIconSearch>
          <button
            mat-icon-button
            aria-label="Rechercher une course"
            class="_sp_btn _inline_icon _blue _search_btn"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </ng-template>
      </div>
    </div>

    <div
      *ngIf="showFastResult && nameCtrl.value !== ''"
      class="list-results-wrap align-self-center"
    >
      <mat-selection-list
        *ngIf="itemsLoaded; else skeletonLoadResult"
        dense
        [multiple]="false"
        class="w-100"
      >
        <div
          *ngIf="resultEvents?.length == 0 && resultRaces?.length == 0"
          class="clearfix"
        >
          <h3
            matSubheader
            *ngIf="
              searchInput.value &&
              resultEvents?.length == 0 &&
              resultRaces?.length == 0
            "
          >
            Aucun résultat
          </h3>
        </div>
        <div
          *ngIf="resultEvents?.length > 0 || resultRaces?.length > 0"
          class="clearfix mb-3"
        >
          <div *ngIf="resultEvents?.length > 0" class="mb-2">
            <div *ngIf="resultEvents?.length > 0" class="count text-left">
              <span class="accent">{{ resultEvents?.length }}</span>
              événement(s) trouvé(s)
            </div>

            <mat-list-option
              *ngFor="
                let event of resultEvents | slice : 0 : nbResultToDisplay;
                let i = index
              "
              [value]="event"
              disableRipple
            >
              <div class="d-flex align-items-center">
                <span (click)="selectItem('events', event)">{{
                  event.name
                }}</span>
                <div class="d-flex distances-wrap">
                  <div
                    *ngFor="let race of event?.races; let i = index"
                    class="distance-item __distance"
                    (click)="showRace(event, race.id)"
                    role="button"
                  >
                    <span class="__d">{{ race?.name }}</span>
                  </div>
                </div>
                <div
                  class="flex-fill"
                  (click)="selectItem('events', event)"
                ></div>
              </div>
            </mat-list-option>
          </div>

          <div class="">
            <a (click)="searchRace()" class="_sp_btn_arrow _right">
              <div class="d-flex">
                <span>Voir tous les résultats</span>
                <mat-icon>arrow_forward</mat-icon>
              </div>
            </a>
          </div>
        </div>
      </mat-selection-list>

      <ng-template #skeletonLoadResult>
        <mat-progress-bar
          mode="indeterminate"
          color="primary"
        ></mat-progress-bar>
        <div class="p-3">Recherche en cours...</div>
      </ng-template>
    </div>
  </form>
</div>
