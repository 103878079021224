import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
             selector: 'estimation-spinner',
             templateUrl: './estimation-spinner.component.html',
             styleUrls: ['./estimation-spinner.component.scss']
           })
export class EstimationSpinnerComponent implements OnInit {

  @Input() percent: number;
  @Input() height = 60;
  @Input() labelFontSize = 0.7;
  @Input() showFullContent = false;
  @Input() globalEstimation = false;

  spinnerColor: string;
  spinnerTextColor: string;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.refreshSpinnerColors();
  }

  /** déclenchement de la mise à jour du composent au change du roadbook
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (changes.percent) {

      this.refreshSpinnerColors();
    }
  }

  /**
   *
   */
  refreshSpinnerColors() {
    this.setSpinnerColor();
    this.setSpinnerTextColor();
  }

  /**
   *
   * @param percent
   * @returns {string}
   */
  setSpinnerColor() {
    // if (this.percent >= 0 && this.percent < 50) {
    //     this.spinnerColor = 'accent';
    // }
    // if (this.percent >= 50 && this.percent < 75) {
    //     this.spinnerColor = 'warn';
    // }
    // if (this.percent >= 75) {
    //     this.spinnerColor = 'primary';
    // }
    this.spinnerColor = 'primary';
  }

  /**
   *
   * @param percent
   * @returns {string}
   */
  setSpinnerTextColor() {
    // if (this.percent >= 0 && this.percent < 50) {
    //     this.spinnerTextColor = 'text-danger';
    // }
    // if (this.percent >= 50 && this.percent < 75) {
    //     this.spinnerTextColor = 'text-warning';
    // }
    // if (this.percent >= 75) {
    //     this.spinnerTextColor = 'text-info';
    // }

    this.spinnerTextColor = 'text-info';
  }

  getWrapStyle() {
    const style = `height: ${this.height}px; width:${this.height}px;`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getValueStyle() {
    const style = `line-height: ${this.height}px; font-size:${this.labelFontSize}rem;`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
