import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {UploadInputModel} from './upload-input.model';
import {RaceModel} from '../../../models/race.model';
import {HttpService} from '../../../services/http/http.service';
import {FileService} from '../../../services/common/file.service';
import {SnackbarService} from '../../../services/notifications/snackbar.service';
import {UserService} from '../../../services/user/user.service';
import {RaceOffModel} from '../../../models/race-off.model';

@Component({
  selector: 'uploader-cmp',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})

export class UploaderComponent implements OnInit {

  // course lié à ce GPX
  @Input() race: RaceModel | RaceOffModel;

  token: any;
  files: UploadFile[];
  countTreated: number;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;

  authorizedExtension = 'gpx';

  showItemList: boolean;

  @Input() relatedId: number;
  @Input() uploadUrl: string;
  @Input() multiple: boolean;
  @Input() maxFileSize = 3000000;
  @Input() relatedObject: string;

  @Output() uploadComplete = new EventEmitter<any>();
  @Output() readonly addedToQueue = new EventEmitter<any>();
  @Output() readonly removedOfQueue = new EventEmitter<any>();

  constructor(private userService: UserService, private httpService: HttpService,
              private fileService: FileService,
              private snackbarService: SnackbarService) {

    this.options = {
      concurrency: 1,
      maxUploads: 10
    };
    this.files = [];
    this.countTreated = 0;
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = this.humanizeBytes;
    this.showItemList = false;
  }

  ngOnInit(): void {
    this.token = this.userService.getTokenUser();
  }

  onUploadOutput(output: UploadOutput): void {

    const body = {description: '', action: JSON.stringify({id: this.relatedId})};

    if (output.type === 'allAddedToQueue') {

      const event: UploadInputModel = {
        type: 'uploadAll',
        url: this.uploadUrl,
        fieldName: 'race_gpx',
        method: 'POST',
        includeWebKitFormBoundary: true,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${this.token.token}`
        }
      };

      this.uploadInput.emit(event);

    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {

      const ext = this.fileService.extractFileExtensionFromFilename(output.file.nativeFile.name);

      if (ext != this.authorizedExtension) {
        this.showError('Extension de fichier incorrect : ' + this.authorizedExtension + ' attendu');
      } else if (output.file.size > this.maxFileSize) {
        this.showError('La taille du fichier dépasse la limite autorisée');
      } else {
        this.showItemList = true;
        // réinit if not multiple
        if (!this.multiple) {
          this.files = [];
        }
        this.files.push(output.file);
        this.addedToQueue.emit(output.file.nativeFile);
      }
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.showItemList = true;
      const index = this.files.findIndex(
        file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
      this.removedOfQueue.emit(output.file.nativeFile);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      this.showError('Fichier rejeté');
      console.dir(output);
      this.countTreated++;
    } else if (output.type === 'done') {
      let message = '';
      if (output.file.responseStatus !== 201) { // Or whatever your return code status is
        if (typeof output.file.response.error !== 'undefined') {
          message = output.file.response.error.code + ' - ' + output.file.response.error.message;
          this.showError(message);
        }
      } else {
        message = 'Fichier GPX uploadé avec succès';
        this.showItemList = false;
        this.uploadComplete.emit(output.file.response);
      }
      this.countTreated++;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
  }

  removeAllFiles(): void {
    this.uploadInput.emit({type: 'removeAll'});
  }

  showError(message) {
    this.snackbarService.openDanger(message);
  }
}

