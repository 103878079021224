import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { RaceModel } from "../../../models/race.model";
import { RaceChartComponent } from "../../race-chart/race-chart.component";
import { BaseComponent } from "../../base.component";
import { TRAILER, UserModel } from "../../../models/user.model";
import { environment } from "../../../../environments/environment";
import { RaceService } from "../../../services/race/race.service";
import { UserService } from "../../../services/user/user.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { PoiModel } from "../../../models/Pois/poi.model";
import { PoiTypeModel, PoiTypes } from "../../../models/Pois/poi.type.model";
import { TrailerModel } from "../../../models/trailer.model";
import { OrganizerModel } from "../../../models/organizer.model";
import { Router } from "@angular/router";
import { DialogService } from "../../../services/common/dialog.service";
import { map, tap } from "rxjs/operators";
import { Trkpt } from "../../../classes/trkpt";
import { GpxService } from "../../../services/race/gpx.service";
import { RACE_TYPE_ENUM } from "../../../enums/roadbook.enum copy";

@Component({
  selector: "app-race-show",
  templateUrl: "./race-show.component.html",
  styleUrls: ["./race-show.component.scss"],
})
export class RaceShowComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() race: RaceModel;

  // ## GMAP ##
  // latitude par défaut de la France
  lat = 46.227638;
  lng = 2.213749000000007;
  zoomLevel = 5;

  // ## CHART ##
  chartWidth: string;
  chartHeight: string;

  @ViewChild("gmap", { static: true }) gmap;
  @ViewChild("raceChart") raceChart: RaceChartComponent;

  raceLiked: boolean;
  likeAnimation = "bobble-out";
  user: TrailerModel | OrganizerModel;

  countRavitos: number;
  isPublished: boolean;
  isArchived: boolean;
  isCanceled: boolean;

  constructor(
    protected userService: UserService,
    private raceService: RaceService,
    private gpxService: GpxService,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialogService: DialogService
  ) {
    super(userService, viewContainerRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.chartWidth = "80vw";
    this.chartHeight = "50vh";
    this.raceLiked = false;
  }

  /** déclenchement de la mise à jour du composent au change de la race
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.race) {
      const currentRaceInstance: SimpleChanges = changes.race.currentValue;
      const previousRaceInstance: SimpleChanges = changes.race.previousValue;

      if (
        typeof currentRaceInstance !== "undefined" &&
        currentRaceInstance !== previousRaceInstance
      ) {
        this.refreshRace();
      }
    }
  }

  afterInit() {
    // nothing to do
    this.user = this.loggedInUser;
  }

  private refreshRace() {
    this.lat = this.race.latitude;
    this.lng = this.race.longitude;
    this.zoomLevel = 10;

    this.isPublished = this.raceService.isPublished(this.race);
    this.isArchived = this.raceService.isArchived(this.race);
    this.isCanceled = this.raceService.isCanceled(this.race);

    this.countRavitos = 0;
    if (this.race.poisOrga) {
      this.race.poisOrga.forEach((poiOrga: PoiModel) => {
        this.countRavitos += poiOrga.poiTypes.filter((poiType: PoiTypeModel) =>
          [PoiTypes.FOOD_BEV, PoiTypes.BEV].includes(poiType.id)
        ).length;
      });
    }

    if (this.race.isGpxVisible) {
      this.setKmlLayer(this.race.kml);
    }

    this.checkLike();

    // ajout du marqueur du départ sur la map
    this.setStartPointMarker();

    // si on a un tracé de la course on affiche le profil d'altitude
    this.setElevationProfil();
  }

  /**
   *
   */
  checkLike() {
    this.raceLiked =
      this.user &&
      typeof this.user.favouriteRaces !== "undefined" &&
      this.user.favouriteRaces.length > 0 &&
      this.user.favouriteRaces.some((r) => r.id === this.race.id);
  }

  /**
   *
   */
  private setStartPointMarker() {
    this.gmap.addOneMarker(
      this.race.id,
      "Départ",
      this.race.latitude,
      this.race.longitude,
      5,
      true
    );
  }

  /** Ajout d'une couche KML sur la map
   *
   * @param url
   */
  setKmlLayer(kmlFilename) {
    // si un tracé Kml a été défini pour la course on set la couche KML sur la map
    if (kmlFilename != null) {
      // Positionnement de la couche KML pour le tracé de la course
      const url = environment.webrootApi + environment.kmlPath + kmlFilename;

      this.gmap.addKmlLayer(url);
    }
  }

  /**
   * Récupère le profil d'élévation de la course
   */
  setElevationProfil() {
    if (typeof this.race.gpx !== "undefined") {
      // lecture de la liste des points du tracé
      this.gpxService.getTrackByFilename(this.race.gpx).subscribe(
        (trackDatas) => {
          // this.race.trkpts = trkpts;
          const trkpts: Array<Trkpt> = trackDatas.datas;
          const startPoint: Trkpt = trkpts[0];
          const finishPoint: Trkpt = trkpts[trkpts.length - 1];
          this.gmap.addStartPointMarker(startPoint);
          this.gmap.addFinishPointMarker(finishPoint);
          this.gmap.addPoisOrgaMarkers(this.race.poisOrga);
          this.raceChart.generateElevationChart(
            this.race,
            trkpts,
            trackDatas.distanceThreshold,
            this.race.pois
          );
        },
        (error) => {
          this.snackbarService.openDanger(
            "Erreur lors de la récupération du tracé de la course"
          );
        }
      );
    } else {
      // this.spinner.stop();
    }
  }

  createRoadbookOrRegister() {
    this.router.navigate([
      "/trailer/roadbooks",
      RACE_TYPE_ENUM.race,
      this.race.id,
      "new",
    ]);
  }

  /**
   *
   */
  toggleLike() {
    if (this.raceLiked) {
      this.likeAnimation = "bobble-in";
      if (this.user.favouriteRaces.length > 0) {
        this.user.favouriteRaces.forEach((race, i) => {
          if (race.id === this.race.id) {
            // Suppression du poi des points du graph
            this.user.favouriteRaces.splice(i, 1);
          }
        });
      }
    } else {
      this.likeAnimation = "bobble-out";
      this.user.favouriteRaces.push(this.race);
    }

    this.userService
      .patchUser(this.user)
      .pipe(
        tap(() => (this.raceLiked = !this.raceLiked)),
        tap((editedUser: UserModel) =>
          this.userService.updateAuthenticatedUser(this.user)
        )
      )
      .subscribe(
        (editedUser: UserModel) => {
          const message = this.raceLiked
            ? "Course ajoutée de vos favoris"
            : "Course retirée de vos favoris";
          this.snackbarService.openSuccess(message);
        },
        (error) => {
          const message = this.raceLiked
            ? "Erreur lors de l'ajout au favoris"
            : "Erreur lors du retrai de vos favoris";
          this.raceLiked = !this.raceLiked;
        }
      );
  }
}
