import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EventService } from '../../../services/event/event.service';
import { EventModel } from '../../../models/event.model';
import { SearchParam } from '../../../classes/search-param';
import { UserService } from '../../../services/user/user.service';
import { BaseComponent } from '../../base.component';
import { SpinnerService } from '../../../services/common/spinner.service';

import { Subscription } from 'rxjs';
import { OrganizerModel } from '../../../models/organizer.model';

@Component({

             selector: 'event-list-cmp',
             templateUrl: './event-list.component.html',
             styleUrls: ['./event-list.component.scss'],
             viewProviders: [Title]
           })
export class EventListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showItemActions;
  @Input() status: string;
  @Output() refreshAll: EventEmitter<boolean> = new EventEmitter<boolean>();

  // #PAGINATION #
  page = 0;
  pageSize = 5;

  @Input() events: Array<EventModel>;
  eventSelected: EventModel;

  private searchEventParams: Array<SearchParam>;
  private organizer: OrganizerModel;

  @Output() enterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() leaveEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() listLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private title: Title,
              private _eventService: EventService,
              protected userService: UserService,
              private spinner: SpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);

    this.title = title;
    this.searchEventParams = new Array<SearchParam>();

  }

  /**
   *  Init composant
   */
  ngOnInit() {
    super.ngOnInit();
    this.status = this.route.snapshot.data.status;
  }

  ngAfterViewInit() {
    this.listLoaded.emit(true);
  }

  /**
   * App
   */
  afterInit() {
    this.organizer = this.loggedInUser;
  }

  handlePageChange(pageChange) {
    this.pageSize = pageChange.pageSize;
    this.page = pageChange.pageIndex;
  }

  mouseEnter(itemId: number) {
    setTimeout(() => {
      this.enterEvent.emit(itemId);
    }, 300);
  }

  mouseLeave(itemId: number) {
    setTimeout(() => {
      this.leaveEvent.emit(itemId);
    }, 300);
  }
}
