export const environment = {
  production: true,
  maintenance: false,
  siteTitle: 'Splits - Estimez vous :',
  envName: 'staging',
  appUrl: 'https//preprod.splits.fr',
  restApiUrl: 'https://backoffice.preprod.splits.fr',
  webrootApi: 'https://backoffice.preprod.splits.fr/',
  forgotPasswordUrl: 'https://account.preprod.splits.fr/resetting/request',

  uploadPath: 'uploads',
  kmlPath: 'uploads/kml/',
  gpxPath: 'uploads/gpx/',
  roadbookPath: 'uploads/roadbooks/',
  roadbookPictureFolder: '/pictures/',
  roadbookPdfFolder: '/pdf/',
  backgroundPath: 'assets/img/app/background/',

  mediaDistPath: '/uploads/medias/',


  // URL API Sunset/Sunrise/Timezone
  sunsetSunriseApiUrl: 'https://api.sunrise-sunset.org/json',
  sunsetSunriseApiUsername: 'splits',

  // Facebook Connect
  facebookApiId: '1925169864414169',

  gmap: {
    apiKey: 'AIzaSyA8p-KcO_WPI3mv6uhRlGG_pxFrPvIcaIg',
    default: {
      lat: 46.227638,
      lng: 2.213749000000007,
      zoom: 5
    }
  }
  ,
  trailer: {
    averageSpeed: 4.7
  }
};
