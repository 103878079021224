import { EventEmitter, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { HttpService } from "../http/http.service";
import { RaceModel } from "../../models/race.model";
import { SearchParam } from "../../classes/search-param";
import { Trkpt } from "../../classes/trkpt";
import { SearchRaceCriterias } from "../../classes/searchRaceCriterias";
import { SearchService } from "../common/search.service";
import { UserModel } from "../../models/user.model";
import { EventService } from "../event/event.service";
import { DatetimeService } from "../common/datetime.service";
import { map } from "rxjs/operators";
import { RaceOffModel } from "../../models/race-off.model";

@Injectable({
  providedIn: "root",
})
export class RaceService {
  race: Observable<RaceModel>;

  trkpts: Observable<Array<Trkpt>>;

  listRaces: Observable<Array<RaceModel>>;

  racePath = "/api/races/";

  private searchParams: Array<SearchParam> = [];

  _emitter: EventEmitter<any> = new EventEmitter();

  searchRaceCriterias: SearchRaceCriterias;

  constructor(
    private http: HttpService,
    private searchService: SearchService,
    eventService: EventService,
    private datetimeService: DatetimeService
  ) {}

  /** Récupération de la liste complète des événements
   *
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  getRaces(searchParams: Array<SearchParam>) {
    this.searchParams = searchParams;
    this.listRaces = this.http.get(this.racePath, this.searchParams);
    return this.listRaces;
  }

  getRacesByScope(
    searchScope: string,
    loggedInUser: UserModel = null,
    limit: number = null
  ) {
    this.setSearchRaceCriterias(searchScope, loggedInUser, limit);
    return this.searchService.searchElementByCriterias(
      "race",
      this.searchRaceCriterias
    );
  }

  getRaceTrackByFilename(gpxFilename: string): Observable<any> {
    this.searchParams = [];
    this.searchParams.push(new SearchParam("gpxFilename", gpxFilename));
    return this.http.get("/track/by_filename", this.searchParams);
  }

  public getRaceGpx(raceId: number): Observable<any> {
    return this.http.getBlob(this.racePath + raceId + "/download_gpx", null);
  }

  /** Retourne les détails d'une course
   *
   * @returns {Observable<RaceModel>}
   */
  getOneRace(raceId: number): Observable<RaceModel> {
    return this.http.get<RaceModel>(this.racePath + raceId, null).pipe(
      map((race: RaceModel) => {
        return new RaceModel(race);
      })
    );
  }

  /**
   *  retourne la distance séparant le basecamp du trailer avec le départ de la course
   * @param raceId
   * @param userId
   * @returns {*}
   */
  getRaceProximity(raceId: number, userId: number): Observable<any> {
    const proximity = this.http.get(
      this.racePath + raceId + "/proximity/" + userId,
      null
    );
    return proximity;
  }

  /** POST event
   *
   * @param event
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  postRaces(race: RaceModel): Observable<RaceModel> {
    return this.http.post("/api/races", race);
  }

  putRaces(race: RaceModel): Observable<RaceModel> {
    return this.http.put(this.racePath + race.id, race);
  }

  patchRaces(race: RaceModel): Observable<RaceModel> {
    return this.http.patch(this.racePath + race.id, race);
  }

  updateRaceStatus(race: RaceModel) {
    return this.http.put(this.racePath + race.id + "/status", race);
  }

  duplicateRace(race: RaceModel) {
    return this.http.post(this.racePath + race.id + "/duplicate", race);
  }

  createFallbackCourse(race: RaceModel) {
    return this.http.post(this.racePath + race.id + "/fallback", race);
  }

  deleteRace(race: RaceModel) {
    return this.http.delete(this.racePath + race.id, race);
  }

  searchByName(term: string): Observable<Array<RaceModel>> {
    this.searchParams.push(new SearchParam("name", term));
    this.listRaces = this.http.get(this.racePath, this.searchParams);
    return this.listRaces;
  }

  setSearchRaceCriterias(
    searchScope: string,
    loggedInUser: UserModel,
    limit: number
  ) {
    this.searchRaceCriterias = new SearchRaceCriterias(loggedInUser);

    switch (searchScope) {
      case "profile":
        this.searchRaceCriterias.ascendingElevationRange =
          loggedInUser.preferenceAscendingElevation;
        this.searchRaceCriterias.distanceRange =
          loggedInUser.preferenceDistance;
        this.searchRaceCriterias.terrainId = loggedInUser.preferenceTerrain
          ? loggedInUser.preferenceTerrain.id
          : null;
        this.searchRaceCriterias.disciplineId =
          loggedInUser.preferenceDiscipline
            ? loggedInUser.preferenceDiscipline.id
            : null;
        this.searchRaceCriterias.hasTimeLimit =
          loggedInUser.preferenceHasTimeLimit;
        this.searchRaceCriterias.hasUtmbPoint =
          loggedInUser.preferenceHasUtmbPoint;
        this.searchRaceCriterias.isNightRace =
          loggedInUser.preferenceIsNightRace;
        this.searchRaceCriterias.locality = loggedInUser.baseCampLocation;
        this.searchRaceCriterias.latitude = loggedInUser.baseCampLatitude;
        this.searchRaceCriterias.longitude = loggedInUser.baseCampLongitude;
        this.searchRaceCriterias.proximity =
          loggedInUser.preferenceMaxDistanceFromBasecamp;
        this.searchRaceCriterias.status = ["published"];
        this.searchRaceCriterias.limit = limit ? limit : null;
        break;
      case "archived":
        this.searchRaceCriterias.status = ["archived"];
        break;
      case "recent":
        this.searchRaceCriterias.newest = true;
        this.searchRaceCriterias.limit = limit ? limit : null;
        this.searchRaceCriterias.status = ["published"];
        break;
      case "default":
      case "favourite":
        this.searchRaceCriterias.limit = limit ? limit : null;
        // tous les statuts mais classé par published et ensuite archivée
        break;
      case "incoming":
        const today: Date = new Date();
        this.searchRaceCriterias.dateSearchStartTimestamp =
          this.datetimeService.getTimestampInSecond(today);
        this.searchRaceCriterias.limit = limit ? limit : null;
        this.searchRaceCriterias.status = ["published"];
        break;
      default:
        break;
    }
  }

  isCanceled(race: RaceModel): boolean {
    return race.status === "canceled";
  }

  isDraft(race: RaceModel): boolean {
    return race.status === "draft";
  }

  isPublished(race: RaceModel): boolean {
    return (
      race.status === "published" &&
      this.datetimeService.getIntervalToToday(race.date) >= 0
    );
  }

  isArchived(race: RaceModel): boolean {
    return (
      race.status === "archived" ||
      (race.status === "published" &&
        this.datetimeService.getIntervalToToday(race.date)) < 0
    );
  }
}
