import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { RoadbookModel } from '../../../models/roadbook.model';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { TrailerModel } from '../../../models/trailer.model';
import { OrganizerModel } from '../../../models/organizer.model';

@Component({
             selector: 'app-roadbook-list',
             templateUrl: './roadbook-list.component.html',
             styleUrls: ['./roadbook-list.component.css']
           })
export class RoadbookListComponent extends BaseComponent implements OnInit {

  @Input() roadbooks: Array<RoadbookModel> = [];

  // #PAGINATION #
  page = 0;
  @Input() pageSize = 5;

  @Output() enterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() leaveEvent: EventEmitter<number> = new EventEmitter<number>();
  user: TrailerModel | OrganizerModel;

  constructor(protected userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {
    this.user = this.loggedInUser;
  }


  mouseEnter(itemId: number) {
    setTimeout(() => {
      this.enterEvent.emit(itemId);
    }, 300);
  }

  mouseLeave(itemId: number) {
    setTimeout(() => {
      this.leaveEvent.emit(itemId);
    }, 300);
  }

  handlePageChange(pageChange) {
    this.pageSize = pageChange.pageSize;
    this.page = pageChange.pageIndex;
  }
}
