import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";

import { RACE_TYPE_ENUM } from "../../../../enums/roadbook.enum copy";
import { EventModel } from "../../../../models/event.model";
import { RaceOffModel } from "../../../../models/race-off.model";
import { RaceModel } from "../../../../models/race.model";
import { RoadbookModel } from "../../../../models/roadbook.model";
import { TerrainModel } from "../../../../models/terrain.model";
import { DatetimeService } from "../../../../services/common/datetime.service";
import { DialogService } from "../../../../services/common/dialog.service";
import { FileService } from "../../../../services/common/file.service";
import { SpinnerService } from "../../../../services/common/spinner.service";
import { SnackbarService } from "../../../../services/notifications/snackbar.service";
import { RoadbookService } from "../../../../services/roadbook/roadbook.service";
import { BaseComponent } from "../../../base.component";
import { UserService } from "../../../../services/user/user.service";
import { TrailerModel } from "../../../../models/trailer.model";
import { OrganizerModel } from "../../../../models/organizer.model";

@Component({
  selector: "app-roadbook-list-item",
  templateUrl: "./roadbook-list-item.component.html",
  styleUrls: ["./roadbook-list-item.component.scss"],
})
export class RoadbookListItemComponent extends BaseComponent implements OnInit {
  @Input() roadbook: RoadbookModel;
  race: RaceOffModel | RaceModel;
  event: EventModel;
  terrain?: TerrainModel;

  intervalSoonLimit = 2592000; // limite  en secondes  à partir de laquelle on passe en mode warning (30 jours)
  intervalImminentLimit = 432000; // limite de jour à partir de laquelle on passe en mode danger (5 jours)
  intervalToRace!: number;
  intervalToRaceLabel: string;
  intervalToRacePrefix: string;
  user: TrailerModel | OrganizerModel;

  constructor(
    protected userService: UserService,
    public viewContainerRef: ViewContainerRef,
    private datetimeService: DatetimeService,
    private router: Router,
    private roadbookService: RoadbookService,
    private fileService: FileService,
    private spinner: SpinnerService,
    private snackbarService: SnackbarService,
    private dialogsService: DialogService
  ) {
    super(userService, viewContainerRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    let race: RaceOffModel | RaceModel;

    if (this.roadbook.raceOff) {
      race = this.roadbook.raceOff as RaceOffModel;
      this.roadbook.race = race;
      this.terrain = null;
    } else {
      race = this.roadbook.race as RaceModel;
      this.terrain = race.event.terrain;
    }

    this.race = race;

    this.getIntervalToRace(race);
  }

  afterInit() {
    // nothing to do
    this.user = this.loggedInUser;
    console.log("this.user :>> ", this.user);
  }

  editRoadbook() {
    this.router.navigate(["/trailer/roadbooks", this.roadbook.id, "edit"]);
  }

  /** Retourne l'échéance
   *
   * @param date
   * @returns {number}
   */
  getIntervalToRace(race: RaceModel | RaceOffModel) {
    const fullRaceDepartureDate = race.date;

    const label = this.getIntervalLabel(this.intervalToRace);
    this.intervalToRaceLabel = label.intervalLabel;
    this.intervalToRacePrefix = label.intervalPrefix;

    this.intervalToRace = this.datetimeService.getIntervalToToday(
      fullRaceDepartureDate,
      "seconds"
    );
  }

  /**
   *
   * @param date
   * @returns {string}
   */
  getIntervalLabel(intervalInSeconds: any) {
    return this.datetimeService.getIntervalLabel(intervalInSeconds);
  }

  public downloadRoadbookPdf() {
    // si on a des roadbook sur la course

    const extraAction = "Non, télécharger la version standard";

    this.dialogsService
      .confirm(
        "Téléchargement du roadbook",
        "Avant de le télécharger, souhaitez-vous personnaliser votre roadbook ?",
        this.viewContainerRef,
        extraAction,
        null,
        "Oui, Personnaliser mon roadbook "
      )
      .pipe(filter((res) => res && (res === "confirm" || res === "extra")))
      .subscribe(
        (res) => {
          if (res === "extra") {
            this.previewOrDowloadRoadbookPdf("download");
          } else if (res === "confirm") {
            this.roadbookService.currentRoadbook = this.roadbook;
            this.router.navigate([
              "/trailer/roadbooks/" + this.roadbook.id + "/edit/passport",
            ]);
          }
        },
        (error) => {
          this.snackbarService.openDanger(
            "Erreur lors de l'annulation de la course"
          );
        }
      );
  }

  private previewOrDowloadRoadbookPdf(action = "preview") {
    const messageSpinner = "Téléchargement du roadbook en cours...";

    this.spinner.start(messageSpinner);

    return this.roadbookService.getRoadbookPdfFile(this.roadbook.id).subscribe(
      (dataBlob) => {
        if (action === "preview") {
          this.fileService.previewFile(dataBlob, "application/pdf");
        }
        if (action === "download") {
          this.fileService.downloadFile(
            dataBlob,
            "splits_roadbook_" + this.roadbook.id + ".pdf",
            "application/pdf"
          );
        }

        this.spinner.stop();
      },
      () => {
        this.snackbarService.openDanger(
          "Erreur lors de la génération du PDF",
          "OK"
        );
        this.spinner.stop();
      }
    );
  }

  /** Redirection vers la validation du roadbook
   *
   * @param roadbook
   */
  validateRoadbook() {
    this.router.navigate(["/trailer/roadbooks", this.roadbook.id, "validate"]);
  }

  /** Redirection vers la validation du roadbook
   *
   * @param roadbook
   */
  statisticsRoadbook() {
    this.router.navigate([
      "/trailer/roadbooks",
      this.roadbook.id,
      "statistics",
    ]);
  }

  editOrValidateRoadbook() {
    if (this.intervalToRace > 0) {
      this.editRoadbook();
    } else {
      if (
        this.roadbook.status === "draft" ||
        this.roadbook.status === "complete"
      ) {
        this.validateRoadbook();
      } else if (this.roadbook.status === "validated") {
        this.statisticsRoadbook();
      }
    }
  }
}
