import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/common/spinner.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { DataService } from '../../../services/common/data.service';
import { UserModel } from '../../../models/user.model';
import { NotificationModel } from '../../../models/notification.model';
import { NotificationService } from '../../../services/notifications/notification.service';
import { RaceService } from '../../../services/race/race.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { finalize } from 'rxjs/operators';

@Component({
             selector: 'send-notification-cmp',
             templateUrl: './send-notification.component.html',
             styleUrls: ['./send-notification.component.scss']
           })
export class SendNotificationComponent implements OnInit, OnDestroy {

  notificationToSend: NotificationModel = new NotificationModel();

  sender: UserModel;
  NotificationModel: Array<UserModel>;

  public Editor = ClassicEditor;

  constructor(protected spinner: SpinnerService,
              protected snackbarService: SnackbarService,
              protected dataService: DataService,
              public dialogRef: MatDialogRef<SendNotificationComponent>,
              public notificationService: NotificationService,
              public raceService: RaceService) {
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {

  }

  sendNotification() {
    this.spinner.start('Envoi en cours...');

    return this.notificationService.sendNotification(this.notificationToSend).pipe(finalize(() => this.spinner.stop()))
      .subscribe(
        response => {
          this.snackbarService.openSuccess('Notification envoyée avec succès');
          this.dialogRef.close();
        },
        error => {
          this.snackbarService.openDanger('Erreur lors de l\'envoi de la notification');
        });
  }

}
