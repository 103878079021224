import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NavbarState } from '../../classes/OverlayState';
import { SearchService } from './search.service';
import { ShowHideState } from '../../classes/LoaderState';

@Injectable({
              providedIn: 'root'
            })
export class ToolbarService {

  public quickSearchStateSubject = new BehaviorSubject<ShowHideState>({show: false});
  quickSearchState = this.quickSearchStateSubject.asObservable();

  constructor() {
  }

  showQuickSearch() {
    this.quickSearchStateSubject.next({show: true} as ShowHideState);
  }

  hideQuickSearch() {
    this.quickSearchStateSubject.next({show: false} as ShowHideState);
  }
}
