import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { MatDialog ,MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { RaceModel } from '../../../models/race.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EventModel } from '../../../models/event.model';
import { RaceService } from '../../../services/race/race.service';
import { DialogService } from '../../../services/common/dialog.service';
import { SendNotificationComponent } from '../../dialogs/send-notification/send-notification.component';
import { BaseComponent } from '../../base.component';
import { UserService } from '../../../services/user/user.service';
import { UserModel } from '../../../models/user.model';
import { NotificationModel } from '../../../models/notification.model';
import { NOTIFICATION_TYPE } from '../../../enums/notification-type.enum';
import { SnackbarService } from '../../../services/notifications/snackbar.service';

@Component({

             selector: 'race-item-cmp',
             templateUrl: './race-item.component.html',
             styleUrls: ['./race-item.component.scss']
           })

export class RaceItemComponent extends BaseComponent {

  hasError = false;

  user: UserModel;

  @Input() eventParent: EventModel;
  @Input() race: RaceModel;
  @Input() canModify: boolean;
  @Input() isLastLine: boolean;
  @Input() id: number;
  @Output() raceSelected: EventEmitter<RaceModel> = new EventEmitter<RaceModel>();
  @Output() raceDeleted: EventEmitter<any> = new EventEmitter();
  @Output() raceAdded: EventEmitter<RaceModel> = new EventEmitter<RaceModel>();

  dialogSendNotificationRef: MatDialogRef<any>;

  constructor(protected userService: UserService,
              private raceService: RaceService,
              public router: Router,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private dialogsService: DialogService,
              public viewContainerRef: ViewContainerRef,
              public dialogService: DialogService, public snackbarService: SnackbarService) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
   super.ngOnInit();
  }

  afterInit() {
    // nothing to do
    this.user = this.loggedInUser;
  }


  showRace() {
    this.raceSelected.emit(this.race);
    this.router.navigate(['/organizer/races/show', this.race.id]);
  }

  publishRace() {

    // si on a des roadbook sur la course
    let title: string = null;
    const extraAction: string = null;
    let extraMessage: string = null;


    if (!this.race.gpx) {
      title = 'Vous souhaitez publier la course sans son tracé GPX car il n\'est pas encore validé ?';
      extraMessage = 'Nous vous offrons la possibilité de le télécharger ultérieurement.' + '<br>';
    } else {
      title = 'Vous souhaitez publier la course avec son tracé GPX ?';
      extraMessage = 'Nous vous informons que le profil de course ne pourra pas être modifié une fois la course publiée.' + '<br>';
      extraMessage += 'Vous pourrez toutefois annuler puis supprimer la course ou contacter l\'équipe support si besoin.' + '<br>';
      extraMessage += 'Les POIs resteront modifiables ainsi que toutes les autres données.' + '<br>';
    }


    this.dialogService
      .confirm(title, 'Souhaitez-vous confirmer la publication de cette course?', this.viewContainerRef, extraAction,
               extraMessage)
      .subscribe(res => {

        if (typeof res != 'undefined' && res == 'confirm') {
          this.race.status = 'published';
          this.raceService.updateRaceStatus(this.race).subscribe(() => {
            this.snackbarService.openSuccess('Course publiée avec succès');
          }, error => {
            this.snackbarService.openDanger('Erreur lors de la publication de la course');
          });
        }
      });

  }

  editRace() {
    this.router.navigate(['/organizer/races/edit', this.race.id]);
  }

  cancelRace() {

    // si on a des roadbook sur la course

    let extraAction: string = null;
    let extraMessage: string = null;

    if (typeof this.race.roadbooks !== 'undefined' && this.race.roadbooks.length > 0) {
      extraAction = 'Notifier les coureurs';
      extraMessage = this.race.roadbooks.length + ' roadbook(s) créé(s) sur cette course. ' + '<br>' + 'Nous vous suggerons d\'envoyer une notification aux coureurs avant d\'annuler la course.';
    }


    this.dialogService
      .confirm('Confirmation de l\'annulation de la course', 'Etes-vous sûr de vouloir annuler cette course ? ',
               this.viewContainerRef, extraAction, extraMessage)
      .subscribe(res => {

        if (typeof res != 'undefined') {

          if (res == 'extra') {
            const defaultTitle: string = 'Annulation de la course ' + this.race.name;
            const defaultSubject: string = 'Annulation de la course ' + this.race.name;
            const defaultContent: string = 'Nous vous informons que la course ' + this.race.name + ' a dû être annulée....';

            this.notifyTrailers(defaultTitle, defaultSubject, defaultContent);
          } else if (res == 'confirm') { // confirmation d'annulation de la course
            this.race.status = 'canceled';
            this.raceService.updateRaceStatus(this.race).subscribe(() => {
              this.snackbarService.openSuccess('Course annulée avec succès');
            }, error => {
              this.snackbarService.openDanger('Erreur lors de l\'annulation de la course');
            });
          }
        }
      });
  }

  deleteRace() {

    this.dialogsService
      .confirm('Confirmation de la suppression', 'Etes vous sur de vouloir supprimer cette course ?',
               this.viewContainerRef)
      .subscribe(res => {
        if (typeof res != 'undefined' && res == 'confirm') {
          return this.raceService.deleteRace(this.race)
            .subscribe(
              response => {
                this.raceDeleted.emit(this.race);
              },
              error => {
                this.hasError = true;
              });
        }
      });
  }

  /**
   * Création du parcours de repli
   */
  createFallbackRoute() {

    // si on a des roadbook sur la course

    const extraAction: string = null;
    let extraMessage: string = null;

    extraMessage = 'La création d\'un parcours de repli entraînera  : ' + '<br>';
    extraMessage += '- L\'annulation de la course actuelle avec notifications aux coureurs' + '<br>';
    extraMessage += '- L\'annulation de tous les roadbooks créés sur cette course par les traileurs' + '<br>';
    extraMessage += '- La création du parcours de repli que vous devrez compléter';

    this.dialogService
      .confirm('Confirmation de la création du parcours de repli',
               'Etes-vous sûr de vouloir créer un parcours de repli pour cette course ? ', this.viewContainerRef,
               extraAction, extraMessage)
      .subscribe(res => {

        if (typeof res != 'undefined') {
          if (res == 'confirm') { // confirmation d'annulation de la course
            this.race.status = 'canceled';

            return this.raceService.createFallbackCourse(this.race)
              .subscribe(
                fallbackRace => {
                  this.router.navigate(['/organizer/races/edit', fallbackRace.id]);
                  this.snackbarService.openSuccess(
                    'Parcours de repli initialisé avec succès. Veuillez modifier les informations de la course.');
                },
                error => {
                  this.hasError = true;
                  this.snackbarService.openDanger('Erreur lors de la création du parcours de repli');
                });
          }
        }

      });

    // envoi de notification
  }


  notifyTrailers(defaultSubject?: string, defaultTitle?: string, defaultContent?: string) {

    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = '680px';

    const notificationToSend = new NotificationModel();
    notificationToSend.sender = this.loggedInUser;
    notificationToSend.emailNotification = true;
    notificationToSend.internNotification = true;
    notificationToSend.type = NOTIFICATION_TYPE.INFO;
    notificationToSend.retrieveRecipientsFromRelatedObject = true;
    notificationToSend.relatedObject = 'Race';
    notificationToSend.relatedObjectId = this.race.id;

    if (defaultTitle) { notificationToSend.title = defaultTitle; }
    if (defaultSubject) { notificationToSend.subject = defaultSubject; }
    if (defaultContent) { notificationToSend.content = defaultContent; }

    this.dialogSendNotificationRef = this.dialog.open(SendNotificationComponent, dialogConfig);

    // on renseigne les champs du form
    this.dialogSendNotificationRef.componentInstance.notificationToSend = notificationToSend;

    // La popup renvoi le poi créé après fermeture de la popup
    this.dialogSendNotificationRef.afterClosed().subscribe((ret: any) => {
      if ('undefined' !== typeof ret) {
      }
      this.dialogSendNotificationRef = null;
    });
  }

}
