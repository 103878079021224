<div class="race-list-items-wrap">
  <app-race-orga-list-item
    *ngFor="let currentRace of races | slice:pageSize*(page):pageSize*(page+1); let i=index; last as isLastLine"
    [race]="currentRace"
    [isLastLine]="isLastLine"
    [canModify]="canModify"
    [id]="i"
    (mouseenter)="mouseEnter(currentRace.id)"
    (mouseleave)="mouseLeave(currentRace.id)"
  ></app-race-orga-list-item>

  <div class="pagination-wrap ">
    <paginator [length]="races?.length" [pageSize]=pageSize (pageChanged)="handlePageChange($event)"></paginator>
  </div>
</div>
