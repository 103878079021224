import { Injectable } from '@angular/core';

@Injectable({
              providedIn: 'root'
            })
export class CommonService {

  constructor() {
  }

  public scroll(state: boolean) {
    const body: any = document.body;

    if (state) {
      body.className = '';
    } else {
      body.className = 'no-scroll';
    }
  }


}
