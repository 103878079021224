import {AfterViewInit, Component, OnInit, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ConvertorService } from '../../services/common/convertor.service';
import { BaseComponent } from '../base.component';
import { UserService } from '../../services/user/user.service';
import { debounceTime } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
             selector: 'app-babass',
             templateUrl: './babass.component.html',
             styleUrls: ['./babass.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class BabassComponent extends BaseComponent implements OnInit, AfterViewInit{

  distanceCtrl = new UntypedFormControl('');
  estimatedAverageSpeedCtrl = new UntypedFormControl('');
  estimatedAveragePaceCtrl = new UntypedFormControl('');
  estimatedDurationCtrl = new UntypedFormControl('');
  estimatedDurationHourCtrl = new UntypedFormControl('');
  estimatedDurationMinCtrl = new UntypedFormControl('');
  estimatedDurationSecCtrl = new UntypedFormControl('');

  estimatedDurationHour: number;
  estimatedDurationMin: number;
  estimatedDurationSec: number;

  percentVma = 0;

  constructor(protected userService: UserService,
              public viewContainerRef: ViewContainerRef,
              public dialogRef: MatDialogRef<BabassComponent>,
              protected convertorService: ConvertorService) {
    super(userService, viewContainerRef);
  }


  ngOnInit() {
    super.ngOnInit();
    this.setDurationTimeFieldsFromDurationInSecond(0);
  }

  afterInit() {
  }

  ngAfterViewInit() {
    this.activateChangeDetection();
  }

  close() {
    this.dialogRef.close();
  }


  activateChangeDetection() {

    // ajustement des distance et élévation en fonction de la distance choisie
    this.distanceCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const speed = this.estimatedAverageSpeedCtrl.value;
      const distance = this.distanceCtrl.value;
      if (speed && distance) {
        this.estimatedDurationCtrl.setValue(this.convertorService.getDurationFromSpeed(distance, speed));
      }
    });

    this.estimatedAverageSpeedCtrl.valueChanges.pipe(debounceTime(300)).subscribe(speed => {

      this.calculateVma(speed);

      const pace: string = this.convertorService.getPaceFromSpeed(speed);
      const distance = this.distanceCtrl.value;
      this.estimatedAveragePaceCtrl.setValue(pace, {emitEvent: false});
      this.estimatedDurationCtrl.setValue(this.convertorService.getDurationFromSpeed(distance, speed));
    });

    this.estimatedAveragePaceCtrl.valueChanges.pipe(debounceTime(300)).subscribe(pace => {

      const speed: number = this.convertorService.getSpeedFromPace(pace);
      this.calculateVma(speed);
      const distance = this.distanceCtrl.value;
      this.estimatedDurationCtrl.setValue(this.convertorService.getDurationFromSpeed(distance, speed));
      this.estimatedAverageSpeedCtrl.setValue(speed, {emitEvent: false});
    });

    this.estimatedDurationCtrl.valueChanges.pipe(debounceTime(300)).subscribe(estimatedDurationInSecond => {

      this.setDurationTimeFieldsFromDurationInSecond(estimatedDurationInSecond);
      const distance = this.distanceCtrl.value;
      const speed: number = this.convertorService.getSpeedFromTime(distance, estimatedDurationInSecond);
      this.calculateVma(speed);
      const pace: string = this.convertorService.getPaceFromSpeed(speed);
      this.estimatedAveragePaceCtrl.setValue(pace, {emitEvent: false});
      this.estimatedAverageSpeedCtrl.setValue(speed, {emitEvent: false});
    });

    this.estimatedDurationHourCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const hour: number = value;
      const min: number = this.estimatedDurationMinCtrl.value;
      const sec: number = this.estimatedDurationSecCtrl.value;
      this.setEstimationDuration(hour, min, sec);
    });
    this.estimatedDurationMinCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const hour: number = this.estimatedDurationHourCtrl.value;
      const min: number = value;
      const sec: number = this.estimatedDurationSecCtrl.value;
      this.setEstimationDuration(hour, min, sec);

    });
    this.estimatedDurationSecCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const hour: number = this.estimatedDurationHourCtrl.value;
      const min: number = this.estimatedDurationMinCtrl.value;
      const sec: number = value;
      this.setEstimationDuration(hour, min, sec);
    });
  }


  /**
   *
   * @param hour
   * @param min
   * @param sec
   */
  setEstimationDuration(hour: number, min: number, sec: number) {
    if (typeof hour != 'undefined' && typeof min != 'undefined' && typeof sec != 'undefined') {
      this.estimatedDurationCtrl.setValue(this.convertorService.getTimeInSecond(hour, min, sec));
    }
  }


  /** Set des champs de temps
   *
   * @param _raceDistance
   * @param _speed
   */
  setDurationTimeFieldsFromDurationInSecond(durationInSecond) {
    const durationFull = this.convertorService.convertDurationFromSecondToFullJson(durationInSecond);

    if (typeof durationFull != 'undefined') {
      this.estimatedDurationHour = durationFull.hours;
      this.estimatedDurationMin = durationFull.minutes;
      this.estimatedDurationSec = durationFull.seconds;

      this.estimatedDurationHourCtrl.setValue(durationFull.hours, {emitEvent: false});
      this.estimatedDurationMinCtrl.setValue(durationFull.minutes, {emitEvent: false});
      this.estimatedDurationSecCtrl.setValue(durationFull.seconds, {emitEvent: false});

    }
  }

  /**
   *
   * @param speed
   */
  calculateVma(speed) {
    // maj du % de VMA
    if (this.loggedInUser.vma && speed != 0 && this.loggedInUser.vma != 0) {
      this.percentVma = Math.ceil((speed * 100 / this.loggedInUser.vma));
    } else {
      this.percentVma = 0;
    }
  }

}
