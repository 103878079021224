<div class="content-wrap p-2">
  <div matDialogTitle class="dialog-title">{{ title }}</div>

  <mat-dialog-content>
    <p *ngIf="extraMessage" class="dialog-message" [innerHtml]="extraMessage"></p>
    <p class="dialog-message" [innerHtml]="message"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-end" style="width: 100%">
      <button type="button" matDialogClose class="_sp_btn _white"><span>{{ noLabel || "Annuler"}}</span></button>
      <button type="button" matDialogClose class="_sp_btn _white" color="warn" (click)="dialogRef.close('extra')"
              *ngIf="extraAction"><span>{{ extraAction }}</span>
      </button>
      <button type="submit" class="_sp_btn _blue" (click)="dialogRef.close('confirm')"
              color="accent"><span>{{ yesLabel || "Confirmer"}}</span></button>
    </div>
  </mat-dialog-actions>

</div>

