import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Moment } from "moment";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";

import { SearchEventCriterias } from "../../../classes/searchEventCriterias";
import { EventModel } from "../../../models/event.model";
import { RaceModel } from "../../../models/race.model";
import { DatetimeService } from "../../../services/common/datetime.service";
import { SearchService } from "../../../services/common/search.service";
import { GoogleMapService } from "../../../services/common/google-map.service";

@Component({
  selector: "app-search-box-lite",
  templateUrl: "./search-box-lite.component.html",
  styleUrls: ["./search-box-lite.scss"],
})
export class SearchBoxLiteComponent implements OnInit, AfterViewInit {
  resultEvents: Array<EventModel> = [];
  resultRaces: Array<RaceModel> = [];
  items: Array<any> = [];

  showResult: boolean;

  nbResultToDisplay = 5;

  searchRaceForm: UntypedFormGroup;

  range: UntypedFormGroup;

  nameCtrl: UntypedFormControl;
  locationCtrl: UntypedFormControl;
  startCtrl: UntypedFormControl;
  dateSearchStartTimestampCtrl: UntypedFormControl;
  endCtrl: UntypedFormControl;
  dateSearchEndTimestampCtrl: UntypedFormControl;
  latCtrl: UntypedFormControl;
  lonCtrl: UntypedFormControl;
  localityCtrl: UntypedFormControl;
  postalCodeCtrl: UntypedFormControl;
  countryCtrl: UntypedFormControl;
  administrativeAreaLevel2Ctrl: UntypedFormControl;
  administrativeAreaLevel1Ctrl: UntypedFormControl;

  today: Date = new Date();

  @ViewChild(HTMLInputElement) locationAutocomplete!: HTMLInputElement;
  public subPlaceChange!: Subscription;

  @Input() minimized = false;
  showFastResult: boolean;
  private scope: string[] = ["event"];
  itemsLoaded = false;

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private searchService: SearchService,
    private datetimeService: DatetimeService,
    private googleMapService: GoogleMapService
  ) {
    this.nameCtrl = new UntypedFormControl("");
    this.locationCtrl = new UntypedFormControl("");
    this.startCtrl = new UntypedFormControl("");
    this.dateSearchStartTimestampCtrl = new UntypedFormControl("");
    this.endCtrl = new UntypedFormControl("");
    this.dateSearchEndTimestampCtrl = new UntypedFormControl("");
    this.latCtrl = new UntypedFormControl("");
    this.lonCtrl = new UntypedFormControl("");
    this.localityCtrl = new UntypedFormControl("");
    this.postalCodeCtrl = new UntypedFormControl("");
    this.countryCtrl = new UntypedFormControl("");
    this.administrativeAreaLevel2Ctrl = new UntypedFormControl("");
    this.administrativeAreaLevel1Ctrl = new UntypedFormControl("");

    this.range = new UntypedFormGroup({
      start: this.startCtrl,
      end: this.endCtrl,
    });

    this.searchRaceForm = fb.group({
      name: this.nameCtrl,
      location: this.locationCtrl,
      latitude: this.latCtrl,
      longitude: this.lonCtrl,
      locality: this.localityCtrl,
      postalCode: this.postalCodeCtrl,
      country: this.countryCtrl,
      administrativeAreaLevel2: this.administrativeAreaLevel2Ctrl,
      administrativeAreaLevel1: this.administrativeAreaLevel1Ctrl,
      dateSearchStartTimestamp: this.dateSearchStartTimestampCtrl,
      dateSearchEndTimestamp: this.dateSearchEndTimestampCtrl,
    });
  }

  ngOnInit(): void {
    this.handleLocationChanges();
  }

  ngAfterViewInit(): void {
    // Recherche prédictive
    this.nameCtrl.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(() => {
          this.itemsLoaded = false;
        }),
        switchMap((term) => {
          if (term !== null && term !== "") {
            this.showFastResult = true;
            return this.fastSearchInScope(term, this.scope).pipe(
              catchError(() => of([]))
            );
          } else {
            this.showFastResult = false;
            return of([]);
          }
        })
      )
      .subscribe(
        (items) => {
          this.handleFastSearchResult(items);
        },
        (error) => {
          console.log("Error :" + error);
        }
      );

    this.range.valueChanges.subscribe((range) => {
      if (range.start && range.end) {
        const start: Moment = range.start;
        const end: Moment = range.end;
        // on transforme les dates au format timestamp pour la recherche
        this.dateSearchStartTimestampCtrl.setValue(
          start.toDate()
            ? this.datetimeService.getTimestampInSecond(start.toDate())
            : null
        );
        this.dateSearchEndTimestampCtrl.setValue(
          end.toDate()
            ? this.datetimeService.getTimestampInSecond(end.toDate())
            : null
        );
      }
    });
  }

  searchRace(): void {
    this.router.navigate(["events"], {
      queryParams: this.searchRaceForm.value,
    });
  }

  /**
   *
   * @param term
   */
  fastSearchInScope(term: string, scope: string[] = null): Observable<any> {
    const observableBatch = [];

    const searchEventCriterias: SearchEventCriterias =
      new SearchEventCriterias();
    searchEventCriterias.statuses = ["published", "archived"];
    searchEventCriterias.hasPublishedRaces = true;

    if (scope) {
      scope.forEach((currentScope) => {
        searchEventCriterias.name = term;

        // on recherche par le nom de l'élément
        observableBatch.push(
          this.searchService
            .searchElementByCriterias(currentScope, searchEventCriterias)
            .pipe(
              map((items) => {
                return { scope: currentScope, items };
              })
            )
        );

        // si le scope est race, on doit aussi chercher par le nom de l'événement
        if (currentScope === "race") {
          searchEventCriterias.name = term;
          observableBatch.push(
            this.searchService
              .searchElementByCriterias(currentScope, searchEventCriterias)
              .pipe(
                map((items) => {
                  return { scope: "event", items };
                })
              )
          );
        }
      });
    }

    return forkJoin(observableBatch);
  }

  /** Gestion de l'affichage des résultats
   *
   * @param items
   */
  private handleFastSearchResult(_items) {
    const cache = {};

    this.itemsLoaded = true;

    this.showFastResult = true;

    this.items = _items;

    this.resultEvents = [];
    this.resultRaces = [];

    // sauvegatde du dernier résultat de recherche pour être utilisé plus tard dans la page plus de résultat
    this.searchService.lastResults.next(this.items);

    this.items.forEach((items) => {
      switch (items.scope) {
        case "event":
          this.resultEvents = this.resultEvents.concat(items.items);
          break;
        case "race":
          this.resultRaces = this.resultRaces.concat(items.items);
          // on supprime les doublons sur la liste des courses car les races peuvent arriver de la recherche direct par le nom de la course
          // mais aussi par le nom de l'événement

          this.resultRaces = this.resultRaces.filter((elem) => {
            return cache[elem.id] ? 0 : (cache[elem.id] = 1);
          });
          break;
        case "pois":
          break;
        default:
          break;
      }
    });

    // on pousse les derniers résultats trouvé pour le reste de l'appli
    this.searchService.lastEventResults.next(this.resultEvents);
    this.searchService.lastRaceResults.next(this.resultRaces);
  }

  private handleLocationChanges() {
    this.subPlaceChange = this.googleMapService.placeObservable
      .pipe(
        filter(
          (res) =>
            res.refererComponent === "full" || res.refererComponent === "lite"
        ),
        map((res) => res.place),
        tap((place) => {
          this.locationCtrl.setValue(
            this.googleMapService.getFormattedAddress(place)
          );
          this.latCtrl.setValue(this.googleMapService.getLatitude(place));
          this.lonCtrl.setValue(this.googleMapService.getLongitude(place));
          this.localityCtrl.setValue(this.googleMapService.getCity(place));
          this.postalCodeCtrl.setValue(
            this.googleMapService.getPostCode(place)
          );
          this.countryCtrl.setValue(this.googleMapService.getCountry(place));
          this.administrativeAreaLevel1Ctrl.setValue(
            this.googleMapService.getState(place)
          );
          this.administrativeAreaLevel2Ctrl.setValue(
            this.googleMapService.getDistrict(place)
          );
        })
      )
      .subscribe();
  }

  focus() {
    // on relance la recherche avec ce qu'on a dans le champ
    const term = this.nameCtrl.value;
    if (term) {
      this.showFastResult = true;
    }
  }

  clear() {
    this.nameCtrl.reset();
    this.items = [];
    this.resultEvents = [];
    this.resultRaces = [];
    this.showFastResult = false;
  }

  selectItem(element: string, event: any) {
    this.router.navigate(["/events", event.id, "show"]);
    this.showFastResult = false;
  }

  showRace(event: any, raceId: number) {
    this.router.navigate(["/events", event.id, "show", raceId, "race"]);
    this.showFastResult = false;
  }

  resetLocationCtrl() {
    this.localityCtrl.reset();
    this.countryCtrl.reset();
    this.postalCodeCtrl.reset();
    this.administrativeAreaLevel2Ctrl.reset();
    this.administrativeAreaLevel1Ctrl.reset();
  }

  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    this.showFastResult = false;
  }
}
