import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  ChartXAxe,
  ChartYAxe,
  LinearScale,
  ScaleTitleOptions,
  TimeScale,
} from "chart.js";
import moment from "moment";
import { map } from "rxjs/operators";

import { ChartElevationPoint } from "../../classes/chart-elevation-point";
import {
  ChartAnnotation,
  ChartAnnotationOptions,
  CustomChart,
  CustomChartConfiguration,
  CustomChartData,
  CustomChartDataSets,
  CustomChartOptions,
  CustomChartPoint,
  CustomLinearTickOptions,
} from "../../classes/chart.js/custom.chart.js";
import { Trkpt } from "../../classes/trkpt";
import { PoiModel } from "../../models/Pois/poi.model";
import { PoiOrganizerModel } from "../../models/Pois/poi.organizer.model";
import { PoiTrailerModel } from "../../models/Pois/poi.trailer.model";
import { PoiTypeModel, PoiTypes } from "../../models/Pois/poi.type.model";
import { RaceOffModel } from "../../models/race-off.model";
import { RaceModel } from "../../models/race.model";
import { RoadbookModel } from "../../models/roadbook.model";
import { TrailerModel } from "../../models/trailer.model";
import { ORGANIZER, TRAILER, UserModel } from "../../models/user.model";
import { ChartService } from "../../services/chart/chart.service";
import { DatetimeService } from "../../services/common/datetime.service";
import { SnackbarService } from "../../services/notifications/snackbar.service";
import { UserService } from "../../services/user/user.service";
import { BaseComponent } from "../base.component";
import { AddEstimationComponent } from "../pois/add-estimation/add-estimation.component";
import { AddPoiComponent } from "../pois/add-poi/add-poi.component";
import { EditPoiComponent } from "../pois/edit-poi/edit-poi.component";

@Component({
  selector: "race-chart-cmp",
  templateUrl: "./race-chart.component.html",
  styleUrls: ["./race-chart.component.scss"],
})
export class RaceChartComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  // statut du graphique. Chargé ou non
  loaded = false;
  race: RaceModel | RaceOffModel;
  // éphéméride de la course
  ephemeris: any[];

  trkpts: Array<Trkpt> = [];

  distanceThresholdInKm: number;

  pois: Array<PoiModel> = [];

  @Input() poisOrga: Array<PoiModel> = [];
  @Input() poisTrailer: Array<PoiModel> = [];

  myChart: CustomChart; // mon graph
  linearChartData: CustomChartData; // donnée du graph

  chartDataSets: CustomChartDataSets[] = [];
  elevationDataSetSegments: CustomChartDataSets[] = []; //

  poisDataSet: CustomChartDataSets; // dataset pour les Pois
  startPointDataSet: CustomChartDataSets; // dataset pour les start et finish point
  finishPointDataSet: CustomChartDataSets; // dataset pour les start et finish point

  // Datasets pour les POIS orga
  foodStationsDataSet: CustomChartDataSets;
  beverageStationsDataSet: CustomChartDataSets;
  lifeHousesDataSet: CustomChartDataSets;
  timeLimitsDataSet: CustomChartDataSets;
  checkPointsDataSet: CustomChartDataSets;
  shuttlesDataSet: CustomChartDataSets;
  companionsDataSet: CustomChartDataSets;

  // POIS
  chartDataPois: CustomChartPoint[] = []; // ordonnée des POIS
  chartFoodStations: CustomChartPoint[] = []; // ordonnée des POIS
  chartBeverageStations: CustomChartPoint[] = []; // ordonnée des POIS
  chartLifeHouses: CustomChartPoint[] = []; // ordonnée des POIS
  chartTimeLimits: CustomChartPoint[] = []; // ordonnée des POIS
  chartCheckPoints: CustomChartPoint[] = []; // ordonnée des POIS
  chartShuttles: CustomChartPoint[] = []; // ordonnée des POIS
  chartCompanions: CustomChartPoint[] = []; // ordonnée des POIS
  // START
  chartStartPoint: CustomChartPoint[] = []; // ordonnée des POIS
  // FINISH
  chartFinishPoint: CustomChartPoint[] = []; // ordonnée des POIS

  ctx: any;

  tickYOptions: CustomLinearTickOptions;
  elevationAxe: ChartYAxe;
  distanceAxe: ChartXAxe;
  tickXDistanceAxe: CustomLinearTickOptions;
  scaleTitleOptionsDistanceAxe: ScaleTitleOptions;
  timeAxe: ChartXAxe;
  timeScale: TimeScale;
  tickXTimeAxe: CustomLinearTickOptions;
  scaleTitleOptionsTimeAxe: ScaleTitleOptions;

  chartXAxes: ChartXAxe[];
  chartYAxes: ChartYAxe[];

  chartAnnotations: Array<ChartAnnotation> = [];
  chartAnnotationOptions: ChartAnnotationOptions;

  chartScales: LinearScale;

  chartOptions: CustomChartOptions;

  chartConfiguration: CustomChartConfiguration;

  previousXValue: number;

  timeLabels: string[];

  verticalLineArr: any[] = [];

  maxDistance: number;

  @Input() poiOrigin = "all"; // Origin des POIS que l'on va trailer / afficher (Orga, trailer, tous)

  @ViewChild("chartSpinner", { static: true }) chartSpinner;
  @ViewChild("raceChart", { static: true }) raceChart;

  @Input() readOnlyMode = false;

  // Race Chart
  @Input() chartWidth = "80vw";
  @Input() chartHeight = "40vh";

  @Input() averageSpeed = 7;
  @Input() isStatisticsMode: boolean;

  @Output() public chartClick: EventEmitter<any> = new EventEmitter();

  @Output() poiAdded: EventEmitter<PoiModel> = new EventEmitter<PoiModel>();
  @Output() poiEdited: EventEmitter<PoiModel> = new EventEmitter<PoiModel>();
  @Output() poiDeleted: EventEmitter<PoiModel> = new EventEmitter<PoiModel>();

  dialogAddPoiRef: MatDialogRef<AddPoiComponent>;
  dialogEditPoiRef: MatDialogRef<EditPoiComponent>;
  dialogPoiEstimationRef: MatDialogRef<AddEstimationComponent>;

  imageGraph: any = new Image();

  // icones

  poitTrailerImg: HTMLImageElement;
  startPointImg: HTMLImageElement;
  finishPointImg: HTMLImageElement;
  poiPointImg: HTMLImageElement;
  foodStationPointImg: HTMLImageElement;
  beverageStationPointImg: HTMLImageElement;
  lifeHousePointImg: HTMLImageElement;
  timeLimitPointImg: HTMLImageElement;
  checkPointImg: HTMLImageElement;
  shuttlePointImg: HTMLImageElement;
  companionPointImg: HTMLImageElement;

  heightIcon = 28;
  widthIcon = 28;

  highestElevation = 0;
  private offsetedModels: Array<any> = [];

  constructor(
    public dialog: MatDialog,
    private datetimeService: DatetimeService,
    public chartService: ChartService,
    protected userService: UserService,
    private snackbarService: SnackbarService,
    public viewContainerRef: ViewContainerRef
  ) {
    super(userService, viewContainerRef);

    this.poitTrailerImg = new Image(this.heightIcon, this.widthIcon);
    this.startPointImg = new Image(this.heightIcon, this.widthIcon);
    this.finishPointImg = new Image(this.heightIcon, this.widthIcon);
    this.poiPointImg = new Image(this.heightIcon, this.widthIcon);
    this.foodStationPointImg = new Image(this.heightIcon, this.widthIcon);
    this.beverageStationPointImg = new Image(this.heightIcon, this.widthIcon);
    this.lifeHousePointImg = new Image(this.heightIcon, this.widthIcon);
    this.timeLimitPointImg = new Image(this.heightIcon, this.widthIcon);
    this.checkPointImg = new Image(this.heightIcon, this.widthIcon);
    this.shuttlePointImg = new Image(this.heightIcon, this.widthIcon);
    this.companionPointImg = new Image(this.heightIcon, this.widthIcon);

    this.poitTrailerImg.src =
      "/assets/icons/chart/Picto_Roadbook_POI_Trailer_Green.png";
    this.startPointImg.src = "/assets/icons/chart/Picto_Roadbook_Start.png";
    this.finishPointImg.src = "/assets/icons/chart/Picto_Roadbook_Finish.png";
    this.foodStationPointImg.src =
      "/assets/icons/chart/Picto_Roadbook_FoodAndBeverage.png";
    this.beverageStationPointImg.src =
      "/assets/icons/chart/Picto_Roadbook_Beverage.png";
    this.lifeHousePointImg.src =
      "/assets/icons/chart/Picto_Roadbook_LifeHouse.png";
    this.timeLimitPointImg.src =
      "/assets/icons/chart/Picto_Roadbook_TimeLimit.png";
    this.checkPointImg.src =
      "/assets/icons/chart/Picto_Roadbook_Checkpoint.png";
    this.shuttlePointImg.src = "/assets/icons/chart/Picto_Roadbook_Navette.png";
    this.companionPointImg.src =
      "/assets/icons/chart/Picto_Roadbook_Accompagnant.png";
  }

  ngOnInit() {
    super.ngOnInit();
    this.chartService.refreshGraphSubject.subscribe(() => {
      this.refreshElevationChart();
    });
  }

  afterInit() {
    return;
  }

  ngAfterViewInit() {
    this.tickYOptions = {
      beginAtZero: false,
      maxTicksLimit: 15,
      fontSize: 12,
      padding: 40,
      showLabelBackdrop: true,
      max: 5000,
    };

    this.elevationAxe = {
      id: "y-ele",
      ticks: this.tickYOptions,
      stacked: false,
      gridLines: {
        display: true,
        drawBorder: false,
      },
    };

    this.tickXDistanceAxe = {
      // maxTicksLimit: 15,
      autoSkipPadding: 1,
      max: 73,
    };

    this.tickXTimeAxe = {
      maxTicksLimit: 15,
      showLabelBackdrop: true,
      backdropColor: "#000000",
    };

    this.scaleTitleOptionsDistanceAxe = {
      labelString: "Distance",
      display: true,
    };
    this.scaleTitleOptionsTimeAxe = {
      labelString: "Temps",
      display: true,
    };

    this.distanceAxe = {
      id: "x-dst",
      type: "linear",
      position: "bottom",
      gridLines: {
        display: false,
      },
      ticks: this.tickXDistanceAxe,
      scaleLabel: this.scaleTitleOptionsDistanceAxe,
    };

    this.timeScale = {
      displayFormats: {
        minute: "h:mm",
      },
    };

    this.timeAxe = {
      id: "x-time",
      // type: 'linear',
      position: "bottom",
      gridLines: {
        display: false,
        drawTicks: true,
        drawBorder: true,
      },
      ticks: this.tickXTimeAxe,
      time: this.timeScale,
      scaleLabel: this.scaleTitleOptionsTimeAxe,
    };

    this.chartYAxes = [this.elevationAxe];

    this.chartXAxes = [
      this.distanceAxe,
      // this.timeAxe
    ];

    this.chartScales = {
      yAxes: this.chartYAxes,
      xAxes: this.chartXAxes,
    };

    // configuration courbe d'altitude
    this.poisDataSet = {
      label: "Point d'intéret",
      fill: true,
      backgroundColor: "#A9A9A9",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0, // ne pas afficher les points
      pointStyle: this.poitTrailerImg,
      pointRadius: 7,
      pointHoverRadius: 7,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBorderWidth: 4,
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.startPointDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.startPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.finishPointDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.finishPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.foodStationsDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.foodStationPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.beverageStationsDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.beverageStationPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.timeLimitsDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.timeLimitPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.checkPointsDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.checkPointImg,
      pointRadius: 7, // ne pas afficher les points
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.lifeHousesDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.lifeHousePointImg,
      pointRadius: 7, // ne pas afficher les points
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.shuttlesDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.shuttlePointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    // configuration courbe d'altitude
    this.companionsDataSet = {
      label: "",
      fill: false,
      backgroundColor: "none",
      borderColor: "#0AD6AF",
      pointBackgroundColor: "#fff",
      borderWidth: 1,
      lineTension: 0,
      pointStyle: this.companionPointImg,
      pointRadius: 7,
      pointHoverRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBackgroundColor: "#fff",
      showLine: false,
      spanGaps: false,
    };

    //// gestion des lignes verticales

    this.chartAnnotationOptions = {
      annotations: this.chartAnnotations,
      drawTime: "afterDatasetsDraw",
    };

    const copyThis: any = this;

    // OPTION DU GRAPH
    this.chartOptions = {
      tooltips: {
        mode: "nearest",
        backgroundColor: "#04324A",
        titleFontColor: "#0AD6AF",
        bodyFontColor: "#fff",
        titleFontSize: 14,
        titleAlign: "center",
        titleMarginBottom: 5,
        bodyFontSize: 12,
        caretPadding: 20,
        borderWidth: 2,
        cornerRadius: 5,
        position: "nearest",
        borderColor: "#04324A",
        displayColors: false,
        xPadding: 10,
        yPadding: 10,
        callbacks: {
          title(tooltipItems) {
            let title = "";

            if (typeof tooltipItems[0] !== "undefined") {
              const item = tooltipItems[0];

              let matchingPoi: PoiModel;

              switch (item.datasetIndex) {
                case 0:
                  title = "Départ";
                  break;
                case 1:
                  title = "Arrivée";
                  break;
                case 2:
                  matchingPoi = copyThis.getMatchingPoi(
                    copyThis.poisTrailer,
                    item.xLabel
                  );
                  title = matchingPoi ? matchingPoi.name : "";
                  break;
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                  matchingPoi = copyThis.getMatchingPoi(
                    copyThis.poisOrga,
                    item.xLabel
                  );
                  title = matchingPoi ? matchingPoi.name : "";
                  break;
              }
            }
            return title;
          },
          label(tooltipItem) {
            const trkpt: Trkpt = copyThis.chartService.getTrkptByX(
              tooltipItem.xLabel,
              copyThis.trkpts
            );

            let timelimitLabel: string = null;

            if (tooltipItem.datasetIndex === 7) {
              const matchingPoi: PoiModel = copyThis.getMatchingPoi(
                copyThis.poisOrga,
                tooltipItem.xLabel
              );
              timelimitLabel = "Barrière horaire : ";
              timelimitLabel +=
                typeof matchingPoi.timeLimit != "undefined" &&
                matchingPoi.timeLimit
                  ? matchingPoi.timeLimit
                  : "non définie";
            }
            const altitudeLabel: string =
              "Altitude : " + Number(tooltipItem.yLabel).toFixed(0) + " m";
            const distanceLabel: string =
              "Distance : " + Number(tooltipItem.xLabel).toFixed(1) + " km";

            let elevationLabel: string;
            if (trkpt) {
              elevationLabel =
                "D+ : " +
                trkpt.cumul_positive_elevation +
                " m / D- : " +
                trkpt.cumul_negative_elevation +
                " m";
            }

            const arrLabel: Array<any> = [
              distanceLabel,
              elevationLabel,
              altitudeLabel,
            ];
            if (timelimitLabel) {
              arrLabel.push(timelimitLabel);
            }

            return arrLabel;
          },
        },
      },
      scales: this.chartScales,
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      hover: {
        mode: "nearest",
        onHover(e: any) {
          const point = this.getElementAtEvent(e);
          if (point.length) {
            e.target.style.cursor = "pointer";
          } else {
            e.target.style.cursor = "default";
          }
        },
      },
      annotation: {
        annotations: [
          {
            type: "line",
            id: "hLine",
            mode: "horizontal",
            scaleID: "y-ele",
            value: "850", // data-value at which the line is drawn
            borderWidth: 2,
            borderColor: "black",
          },
        ],
      },
      animation: {
        duration: 400,
        easing: "easeOutExpo",
      },
      layout: {
        padding: {
          left: 0,
          right: 15,
          top: 50,
          bottom: 0,
        },
      },
      title: {
        display: false,
        lineHeight: 1,
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
    };

    // CONFIGURATION DU GRAPH
    this.chartConfiguration = {
      type: "line",
      options: this.chartOptions,
      plugins: [
        {
          afterUpdate: (chart) => {
            // This will be triggered after every update of the chart
            // We get the dataset and set the offset here
            const linearChartData: CustomChartData = chart.config.data;
            const datasets: CustomChartDataSets[] = linearChartData.datasets;

            const startPointDataset: CustomChartDataSets = datasets[0];
            const finishPointDataset: CustomChartDataSets = datasets[1];
            const poisTrailerDataset: CustomChartDataSets = datasets[2];
            const foodStationsDataset: CustomChartDataSets = datasets[3];
            const beverageStationsDataset: CustomChartDataSets = datasets[4];
            const checkPointsDataset: CustomChartDataSets = datasets[5];
            const lifeHousesDataset: CustomChartDataSets = datasets[6];
            const timeLimitsDataset: CustomChartDataSets = datasets[7];
            const shuttlesDataset: CustomChartDataSets = datasets[8];
            const companionsDataSet: CustomChartDataSets = datasets[9];

            this.offsetedModels = [];
            this.offsetDataset(chart, startPointDataset, null, 40, "chart");
            this.offsetDataset(chart, finishPointDataset, null, 40, "chart");
            this.offsetDataset(chart, poisTrailerDataset, null, 40, "chart");
            this.offsetDataset(chart, foodStationsDataset, null, 40, "chart");
            this.offsetDataset(
              chart,
              beverageStationsDataset,
              null,
              40,
              "chart"
            );
            this.offsetDataset(chart, lifeHousesDataset, null, 40, "chart");
            this.offsetDataset(chart, checkPointsDataset, null, 40, "chart");
            this.offsetDataset(chart, timeLimitsDataset, null, 40, "chart");
            this.offsetDataset(chart, shuttlesDataset, null, 40, "chart");
            this.offsetDataset(chart, companionsDataSet, null, 40, "chart");

            // this.initVerticaleForDataset(lifeHousesDataset, chart);
          },

          afterDatasetsDraw: (chart) => {
            // This will be triggered after every update of the chart
            // We get the dataset and set the offset here
            const linearChartData: CustomChartData = chart.config.data;
            const datasets: CustomChartDataSets[] = linearChartData.datasets;

            const poisDataset: CustomChartDataSets = datasets[2];
            const foodStationsDataset: CustomChartDataSets = datasets[3];
            const beverageStationsDataset: CustomChartDataSets = datasets[4];
            const lifeHousesDataset: CustomChartDataSets = datasets[6];
            const checkPointsDataset: CustomChartDataSets = datasets[5];
            const timeLimitsDataset: CustomChartDataSets = datasets[7];
            const shuttlesDataset: CustomChartDataSets = datasets[8];
            const companionsDataSet: CustomChartDataSets = datasets[9];

            this.verticalLineArr = [];

            // on désine d'abord les lignes verticales orga
            this.initVerticaleForDataset(
              chart,
              foodStationsDataset,
              poisDataset
            );
            this.initVerticaleForDataset(
              chart,
              beverageStationsDataset,
              poisDataset
            );
            this.initVerticaleForDataset(chart, lifeHousesDataset, poisDataset);
            this.initVerticaleForDataset(
              chart,
              checkPointsDataset,
              poisDataset
            );
            this.initVerticaleForDataset(chart, timeLimitsDataset, poisDataset);
            this.initVerticaleForDataset(chart, shuttlesDataset, poisDataset);
            this.initVerticaleForDataset(chart, companionsDataSet, poisDataset);

            this.drawVerticalLines(chart, 5);

            this.verticalLineArr = [];

            // on désine ensuite les lignes verticales trailer
            this.initVerticaleForDataset(chart, poisDataset, null, "#0AD6AF");
            this.drawVerticalLines(chart, 5);

            // affichage des lignes au survol des points survol du point

            const chart_type = chart.config.type;
            if (
              chart.tooltip._active &&
              chart.tooltip._active.length &&
              chart_type === "line"
            ) {
              const activePoint = chart.tooltip._active[0],
                ctx = chart.chart.ctx,
                x_axis = chart.scales["x-dst"],
                y_axis = chart.scales["y-ele"],
                x = activePoint.tooltipPosition().x,
                topY = activePoint.tooltipPosition().y,
                bottomY = y_axis.bottom;
              // draw line
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY + 7);
              ctx.lineTo(x, bottomY + 1);
              ctx.setLineDash([2, 3]);
              ctx.lineWidth = 2;
              ctx.strokeStyle = "#2394FC";
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ],
    };

    this.ctx = this.raceChart.nativeElement;

    // gestion du click sur le chart
    this.chartOptions.onClick = (event: any, clickedPoint: Array<any>) => {
      // poi correspondant au click ?
      if (clickedPoint.length > 0) {
        const poiToEdit = this.extractPoiToEdit(clickedPoint);
        this.chartClick.emit({ event, clickedPoint, poiToEdit });
      }
    };

    this.myChart = new CustomChart(this.ctx, this.chartConfiguration);
  }

  /** Gestion du décalage des Pictos Orga par rapport à la courbe
   *
   * @param dataset
   * @param offsetX
   * @param offsetY
   */
  offsetDataset(
    chart,
    dataset: CustomChartDataSets,
    offsetX: number = null,
    offsetY: number = null,
    offsetOrigin = "top"
  ) {
    const y_axis = chart.scales["y-ele"];
    const topY = y_axis.top - 150;

    if (typeof dataset !== "undefined") {
      // on recherche le premier index du tableau des meta car il s'incrémente au fur et à mesure des updates du graph
      let index: any;
      for (const key in dataset._meta) {
        index = key;
        break;
      }
      // For every data in the dataset ...
      for (let i = 0; i < dataset._meta[index].data.length; i++) {
        // We get the model linked to this data
        const model = dataset._meta[index].data[i]._model;
        // And add the offset to the `x` property

        model.x += offsetX;
        if (offsetOrigin === "top") {
          model.y = topY + offsetY;
        } else if (offsetOrigin === "chart") {
          model.y -= offsetY;
        }
        // gestion des POIS qui se superpose, on ajoute de l'offset au novueau poi
        this.offsetedModels.forEach((offsetedModel) => {
          if (offsetedModel.x === model.x && offsetedModel.y === model.y) {
            model.y -= 30;
          }
        });
        this.offsetedModels.push({ i, dataset, x: model.x, y: model.y });
      }
    }
  }

  /**
   *
   * @param dataset
   * @param chart
   */
  initVerticaleForDataset(
    chart,
    dataset: CustomChartDataSets,
    poiDatasetToCheck: CustomChartDataSets,
    color = "#2394FC"
  ) {
    const y_axis = chart.scales["y-ele"];

    if (typeof dataset !== "undefined") {
      // on recherche le premier index du tableau des meta car il s'incrémente au fur et à mesure des updates du graph
      let index: any;
      for (const key in dataset._meta) {
        index = key;
        break;
      }

      // For every data in the dataset ...
      for (let i = 0; i < dataset._meta[index].data.length; i++) {
        // We get the model linked to this data
        const model = dataset._meta[index].data[i]._model;

        let bottomY = y_axis.bottom;

        if (poiDatasetToCheck) {
          for (let j = 0; j < poiDatasetToCheck._meta[index].data.length; j++) {
            const modelPoi = poiDatasetToCheck._meta[index].data[j]._model;
            if (modelPoi.x == model.x) {
              bottomY = modelPoi.y - 10;
            }
          }
        }

        // on stock la ligne à  afficher une seule ligne max par abscice
        this.verticalLineArr.push({
          x: model.x,
          y: model.y + 15,
          color,
          bottomY,
        });
      }
    }
  }

  /**
   *
   */
  drawVerticalLines(chart, offsetY = 0) {
    const ctx = chart.chart.ctx;

    // draw line
    ctx.save();

    const verticalLineArrSorted: any[] = this.verticalLineArr.sort((l1, l2) => {
      // on classe par x croissant et y décroissant au cas ou on est plusieurs x égaux
      return l1.x === l2.x ? -1 * (l1.y - l2.y) : l1.x - l2.x;
    });

    let xSave = 0;

    verticalLineArrSorted.forEach((line) => {
      if (xSave !== line.x) {
        ctx.beginPath();
        ctx.moveTo(line.x, line.y + offsetY);
        ctx.lineTo(line.x, line.bottomY + 1);
        ctx.setLineDash([2, 3]);
        ctx.lineWidth = 2;
        ctx.strokeStyle = line.color;
        ctx.stroke();

        xSave = line.x;
      }
    });

    ctx.restore();
  }

  /**
   *
   * @param datasetIndex
   * @returns {number}
   */
  public getPoiOrgaTypeIdCorrespondance(datasetIndex: number) {
    let poiTypeId: number;

    switch (datasetIndex) {
      case 3:
        poiTypeId = PoiTypes.FOOD_BEV;
        break;
      case 4:
        poiTypeId = PoiTypes.BEV;
        break;
      case 5:
        poiTypeId = PoiTypes.CHECK_POINT;
        break;
      case 6:
        poiTypeId = PoiTypes.LIFE_HOUSE;
        break;
      case 7:
        poiTypeId = PoiTypes.TIME_LIMIT;
        break;
      case 0:
      case 1:
      case 2:
      default:
        break;
    }

    return poiTypeId;
  }

  /** Clear la liste des lignes verticales
   *
   */
  clearVerticalLines() {
    this.chartDataPois.forEach((currPoi, i) => {
      // suppression de la ligne verticale correspondante
      this.removeOneVerticalLine(currPoi.id);
    });
  }

  clear() {
    // this.chartConfiguration.plugins = [];
    this.myChart.clear();
    this.myChart.destroy();
    this.myChart = null;
  }

  reinit() {
    this.myChart = new CustomChart(this.ctx, this.chartConfiguration);
  }

  /**
   *
   * @param xValue
   * @param lineColor
   */
  addVerticalLine(
    id: string,
    xValue,
    xPreviousValue = 0,
    yMaxValue,
    lineColor: string,
    labelFontColor: string = "#000",
    labelPosition: string,
    labelValue: string
  ) {
    // let plannedSegmentTime:number = 90;
    // let plannedSegmentSpeed:number = 13;
    //
    //// une ligne verticale
    // let verticalLine:ChartAnnotation = {
    //    // optional annotation ID (must be unique)
    //    id: 'vertical-line-' + id,
    //    type: 'line',
    //    //type: 'box',
    //    // set to 'vertical' to draw a vertical line
    //    mode: 'vertical',
    //    // ID of the scale to bind onto
    //    scaleID: 'x-dst',
    //    // Data value to draw the line at
    //    value: xValue,
    //    previousValue: xPreviousValue,
    //    // Optional value at which the line draw should end
    //    //endValue: 120,
    //
    //    //## Si on utilise la box
    //    xScaleID: 'x-dst',
    //    yScaleID: 'y-ele',
    //
    //    yMax: yMaxValue, // hauteur max de la ligne (en fonction de l'axe Y)
    //
    //    label: {
    //        // Background color of label, default below
    //        backgroundColor: 'transparent',
    //        fontStyle: "normal",
    //        fontColor: labelFontColor,
    //        enabled: true,
    //        //content: 'Temps prévu : '+ plannedSegmentTime +' \n\r Vitesse : ' + plannedSegmentSpeed,
    //        content: labelValue,
    //        position: labelPosition,
    //        centeredWithPreviousSegment: true,
    //        fontSize: 10,
    //        xAdjust: 5,
    //        yAdjust: 20
    //    },
    //
    //    // Line color
    //    borderColor: lineColor,
    //    // Line width
    //    borderWidth: 2,
    //    // Line dash
    //    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
    //    borderDash: [5, 5],
    //    // Line Dash Offset
    //    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
    //    borderDashOffset: 5,
    // };
    //
    // this.chartAnnotations.push(verticalLine);
    // this.chartAnnotations = this.chartAnnotations.sort((n1, n2) => n1.value - n2.value);
  }

  /** Suppression d'une ligne vertical
   *
   * @param xValue
   */
  removeOneVerticalLine(id) {
    this.chartAnnotations.forEach((verticalLine, i) => {
      if (verticalLine.id === "vertical-line-" + id) {
        // Suppression du poi des points du graph
        this.chartAnnotations.splice(i, 1);
      }
    });
  }

  /**
   *
   * @param trkpts
   */
  generateElevationChart(
    race: RaceModel | RaceOffModel,
    trkpts: Array<Trkpt>,
    distanceThresholdInMeter = 10,
    poisOrga: Array<PoiModel> = null,
    poisTrailer: Array<PoiModel> = null
  ) {
    this.race = race;

    if (race.pois) {
      this.pois = race.pois;
    }

    if (poisOrga) {
      this.poisOrga = poisOrga;
    }
    if (poisTrailer) {
      this.poisTrailer = poisTrailer;
    }

    if (trkpts) {
      this.trkpts = trkpts;
    }

    this.distanceThresholdInKm = distanceThresholdInMeter / 1000;

    if (this.trkpts) {
      this.chartSpinner.start();

      this.setListOfPois();

      this.myChart.update(); // necessaire pour raffraichir les lignes verticales

      this.setStartPoint();

      this.setElevationData();
    }
  }

  /**
   *
   */
  private finalizeChart() {
    this.setDataSets();

    // valeur max sur l'axe correspond à l'élévation max arrondi à la centaine supérieur à laquelle on rajoute 300m avec un minimum de 800 d'altitude

    const offset = this.highestElevation > 3500 ? 800 : 300;

    this.myChart.config.options.scales.yAxes[0].ticks.max =
      Math.ceil(this.highestElevation / 100) * 100 + offset;

    this.myChart.update();

    this.chartSpinner.stop();
    // Création d'une image du graph
    this.imageGraph = new Image();
    this.imageGraph.src = this.myChart.toBase64Image();
  }

  /** Raffraichir le graph
   *
   */
  refreshElevationChart() {
    if (typeof this.trkpts !== "undefined") {
      this.chartSpinner.start();

      this.setListOfPois();

      this.setStartPoint();

      this.setElevationData();
    }
  }

  /** Raffraichir le graph
   *
   */
  refreshElevationChartOneElement(element: string) {
    // rafraichir un seul élément du graph.
    // A completer en fonction
    switch (element) {
      case "pois":
        this.setListOfPois();
        break;
    }

    this.myChart.update();
  }

  /**
   *  Positionnement des data sets
   */
  private setDataSets() {
    // init data sets
    this.chartDataSets = [];

    this.chartDataSets.push(this.startPointDataSet); // index 0
    this.chartDataSets.push(this.finishPointDataSet); // index 1
    this.chartDataSets.push(this.poisDataSet); // index 2

    this.chartDataSets.push(this.foodStationsDataSet); // index 3
    this.chartDataSets.push(this.beverageStationsDataSet); // index 4
    this.chartDataSets.push(this.checkPointsDataSet); // index 5
    this.chartDataSets.push(this.lifeHousesDataSet); // index 6
    this.chartDataSets.push(this.timeLimitsDataSet); // index 7
    this.chartDataSets.push(this.shuttlesDataSet); // index 8
    this.chartDataSets.push(this.companionsDataSet); // index 9

    // on ajout tous les différents segments de courbe
    this.elevationDataSetSegments.forEach(
      (elevationSegment: CustomChartDataSets) => {
        this.chartDataSets.push(elevationSegment); // index 10
      }
    );

    this.linearChartData = {
      labels: this.timeLabels,
      datasets: this.chartDataSets,
    };

    this.chartConfiguration.data = this.linearChartData;
  }

  /** Positionne le point de départ et d'arrivée
   *
   * @param trkpts
   */
  public setStartPoint() {
    // Init des tableaux de données
    this.chartStartPoint = new Array<CustomChartPoint>();

    if (this.trkpts) {
      const startPoint: CustomChartPoint =
        this.chartService.getCustomChartPointFromTrkpt(this.trkpts[0]);
      const finishPoint: CustomChartPoint =
        this.chartService.getCustomChartPointFromTrkpt(
          this.trkpts[this.trkpts.length - 1]
        );

      this.chartStartPoint.push(startPoint);

      this.tickXDistanceAxe.fixedStepSize = 20;

      this.chartConfiguration.options.scales.xAxes[0].ticks.max =
        +finishPoint.x;
    }

    this.startPointDataSet.data = this.chartStartPoint;
  }

  /** Initialise les données du graph
   *
   * @param trkpts
   */
  public setElevationData() {
    if (this.trkpts) {
      let considerEphemeris = false;

      // on récup l'éphémeride pour la création ou non des segments de nuits
      const ephemerisDateLocationRequestData = {
        date: new Date(this.race.date.toString()),
        lat: this.race.latitude,
        lon: this.race.longitude,
      };
      this.datetimeService
        .getEphemerisMultipleDays(ephemerisDateLocationRequestData, 2)
        .pipe(
          map((ephemeris) => {
            considerEphemeris = true;
            this.ephemeris = ephemeris;
            this.race.ephemeris = [];
            this.ephemeris.forEach((eph: any, i) => {
              if (eph.status === "OK" && typeof eph.results !== "undefined") {
                this.race.ephemeris.push(eph.results);
              }
            });
          })
        )
        .subscribe(
          () => {
            this.createElevationDataSegments(considerEphemeris);
            this.finalizeChart();
          },
          (error) => {
            considerEphemeris = false;
            this.snackbarService.openWarning(
              "L'éphéméride est indisponible pour le moment"
            );
            this.createElevationDataSegments(considerEphemeris);
            this.finalizeChart();
          }
        );
    }
  }

  /**
   *
   * @param considerEphemeris
   * @private
   */
  private createElevationDataSegments(considerEphemeris: boolean = false) {
    // Init des tableaux de données
    let chartDataElevation: CustomChartPoint[] = [];
    this.timeLabels = [];
    this.elevationDataSetSegments = [];

    const raceDepartureDateTime: Date = new Date(this.race.date);

    let isNightSegment =
      considerEphemeris && raceDepartureDateTime
        ? this.isNightSegment(raceDepartureDateTime)
        : false;

    let isNightSegmentCurrentState = isNightSegment;

    let cumulDistInBetween = 0;

    const averageSpeed = 7;
    let distanceToTravel = 0;
    const distanceReference = 0;
    let timeReference: Date = raceDepartureDateTime;

    this.highestElevation = 0;

    // parcours des points du graph
    this.trkpts.forEach((trkpt, index) => {
      const currentCustomChartPoint: CustomChartPoint =
        this.chartService.getCustomChartPointFromTrkpt(trkpt);

      // DOIT ON AFFICHER LE POINT ?
      // on affiche le premier point dans tous les cas

      const isFirstPoint: boolean = index === 0;
      const isLastPoint: boolean = index === this.trkpts.length - 1;

      // SI c'est le pas le premier point ET
      // Si la distance qui sépare le point du précédent est < à distancethreshold, on skipe le point
      cumulDistInBetween += +trkpt.distance_in_between;

      if (
        isFirstPoint ||
        isLastPoint ||
        cumulDistInBetween >= this.distanceThresholdInKm
      ) {
        chartDataElevation.push(currentCustomChartPoint);

        if (raceDepartureDateTime) {
          // GESTION DE L'ECHELLE DES TEMPS
          // on affiche l'échelle de temps si l'heure de départ a été précisée et donc qu'on peut calculer les heures de passages
          // heure de passage par défaut
          // on parcours les POIs et on vérifie s'il le point est compris entre 2 POis

          // const poisSorted: PoiModel[] = this.poisTrailer.sort((n1, n2) => n1.distance - n2.distance);
          //
          // poisSorted.some((poi: PoiModel, index: number) => {
          //
          //   if (trkpt.total_distance <= poi.distance) {
          //     averageSpeed = (this.isStatisticsMode) ? poi.measuredAverageSpeed : poi.estimatedAverageSpeed;
          //     timeReference = (index === 0) ? raceDepartureDateTime : (this.isStatisticsMode)
          //                                                             ? poisSorted[index - 1].measuredTimeOfArrival
          //                                                             : poisSorted[index - 1].estimatedTimeOfArrivalCtrl;
          //     distanceReference = (index === 0) ? 0 : poisSorted[index - 1].distance;
          //     return true;
          //   } else {
          //     if (index == poisSorted.length - 1) {
          //       averageSpeed = (this.isStatisticsMode) ? poi.measuredAverageSpeed : poi.estimatedAverageSpeed;
          //       timeReference = (this.isStatisticsMode) ? poi.measuredTimeOfArrival : poi.estimatedTimeOfArrivalCtrl;
          //       distanceReference = poi.distance;
          //       return true;
          //     }
          //   }
          // });

          distanceToTravel = trkpt.total_distance - distanceReference;

          if (timeReference) {
            if (typeof timeReference == "string") {
              timeReference = moment(
                timeReference,
                "YYYY-MM-DD HH:mm:ss"
              ).toDate();
            }

            const passingTime: Date = this.getPassingTime(
              timeReference,
              distanceToTravel,
              averageSpeed
            );

            // on passe seulement les demis heure et les heures pleines
            this.timeLabels.push(moment(passingTime).format("HH:mm"));

            isNightSegment =
              considerEphemeris && raceDepartureDateTime
                ? this.isNightSegment(passingTime)
                : false;
          }
        }

        if (isNightSegmentCurrentState !== isNightSegment) {
          // this.addVerticalLine('ndef', trkpt.dst, 0, trkpt.ele, 'transparent', '#fcff6e', 'center', 'NUIT');

          this.newElevationDataSegment(
            chartDataElevation,
            isNightSegmentCurrentState
          );
          isNightSegmentCurrentState = isNightSegment;
          // init un nouveau segment qui commence par le dernier point entré.
          // permet de faire la connexion avec les différents segment et éviter
          // un blanc entre les segments jour et nui
          chartDataElevation = [];
          chartDataElevation.push(currentCustomChartPoint);
        }
        cumulDistInBetween = 0;

        this.highestElevation =
          trkpt.ele > this.highestElevation ? trkpt.ele : this.highestElevation;
      }
    });
    // création du dernier segment
    this.newElevationDataSegment(chartDataElevation, isNightSegment);
  }

  /**
   * récupération de l'éphéméride
   */
  private retrieveRaceEphemeris() {
    // on récupère l'éphéméride correspondante à la course.
    return;
  }

  /** Retourne le temps de passage exprmié en ms
   *
   * @param distance en km
   * @param averageSpeed en km/h
   * @returns temps passé en ms
   */
  public getPassingTime(
    timeReference: Date,
    distance: number,
    averageSpeed: number
  ): Date {
    // temps de parcours depuis le début de la course
    const durationInMs: number =
      averageSpeed > 0 ? (distance / averageSpeed) * 3600 * 1000 : null;

    const passingTime: Date = new Date(timeReference.getTime() + durationInMs);

    return passingTime;
  }

  /** Segment de nuit ?
   *
   * @param timeToCheck
   * @returns {boolean}
   */
  public isNightSegment(timeToCheck: Date): boolean {
    let isNight = false;

    const fullEphemeris = [];

    if (typeof this.race.ephemeris != "undefined") {
      this.race.ephemeris.forEach((eph) => {
        fullEphemeris.push({ period: "sunrise", date: eph.sunrise });
        fullEphemeris.push({ period: "sunset", date: eph.sunset });
      });

      const ephAfter = fullEphemeris.filter((eph) => {
        const date: Date = new Date(eph.date.toString());
        if (timeToCheck.getTime() < date.getTime()) {
          return true;
        }
      });

      const ephBefore = fullEphemeris.filter((eph) => {
        const date: Date = new Date(eph.date.toString());
        if (timeToCheck.getTime() > date.getTime()) {
          return true;
        }
      });

      let borneInf = null;
      let borneSupp = null;

      if (ephBefore.length > 0) {
        borneInf = ephBefore[ephBefore.length - 1];
      }

      if (ephAfter.length > 0) {
        borneSupp = ephAfter[0];
      }

      if (borneInf && borneSupp) {
        if (borneInf.period == "sunrise" && borneSupp.period == "sunset") {
          isNight = false;
        }

        if (borneInf.period == "sunset" && borneSupp.period == "sunrise") {
          isNight = true;
        }
      } else {
        if (borneInf) {
          if (borneInf.period == "sunrise") {
            isNight = false;
          }
          if (borneInf.period == "sunset") {
            isNight = true;
          }
        }
        if (borneSupp) {
          if (borneSupp.period == "sunrise") {
            isNight = true;
          }
          if (borneSupp.period == "sunset") {
            isNight = false;
          }
        }
      }
    }

    return isNight;
  }

  /** Initialise les Pois sur le graph
   *
   * @param pois
   */
  public setListOfPois() {
    // init des list des points
    this.previousXValue = 0;
    this.chartDataPois = [];
    this.chartFoodStations = [];
    this.chartBeverageStations = [];
    this.chartCheckPoints = [];
    this.chartTimeLimits = [];
    this.chartLifeHouses = [];
    this.chartShuttles = [];
    this.chartCompanions = [];
    this.chartFinishPoint = [];
    this.chartAnnotations.splice(0, this.chartAnnotations.length);

    // positionnement des points
    this.setPois(this.poisOrga);

    this.setPois(this.poisTrailer);

    this.chartAnnotationOptions.annotations = this.chartAnnotations;
    // this.myChart.config.options.annotation = this.chartAnnotationOptions;
  }

  /**
   *
   * @param pois
   */
  private setPois(pois: Array<PoiModel> = null) {
    if (pois) {
      // on retrie le tableau des POIS dans l'ordre croissant des distances
      const poisSorted: PoiModel[] = pois.sort(
        (n1, n2) => n1.distance - n2.distance
      );
      poisSorted.forEach((poi: PoiModel) => {
        // this.pois.forEach((poi :PoiModel) => {
        const chartPoint: CustomChartPoint =
          this.chartService.getCustomChartPointFromPoi(poi);
        if (
          poi.origin === ORGANIZER &&
          typeof poi.poiTypes &&
          poi.poiTypes.length > 0
        ) {
          poi.poiTypes.forEach((poiType: PoiTypeModel) => {
            switch (poiType.id) {
              case PoiTypes.FOOD_BEV:
                this.chartFoodStations.push(chartPoint);
                break;
              case PoiTypes.BEV:
                this.chartBeverageStations.push(chartPoint);
                break;
              case PoiTypes.LIFE_HOUSE:
                this.chartLifeHouses.push(chartPoint);
                break;
              case PoiTypes.TIME_LIMIT:
                this.chartTimeLimits.push(chartPoint);
                break;
              case PoiTypes.CHECK_POINT:
                this.chartCheckPoints.push(chartPoint);
                break;
              case PoiTypes.SHUTTLE:
                this.chartShuttles.push(chartPoint);
                break;
              case PoiTypes.COMPANION:
                this.chartCompanions.push(chartPoint);
                break;
              case PoiTypes.FINISH:
                this.chartFinishPoint.push(chartPoint);
                break;
              default:
                this.chartDataPois.push(chartPoint);
                break;
            }
          });
        } else {
          this.chartDataPois.push(chartPoint);
        }

        // Pour chaque POI on rajoute une ligne vertical de séparation
        // const durationFull = this.convertorService.convertDurationFromSecondToFullJson(poi.estimatedDuration);
        //
        // this.addVerticalLine(chartPoint.id, chartPoint.x, this.previousXValue, chartPoint.y, '#afbad3', '#000',
        //                      'bottom', 'Temps prévu : ' +
        //                        this.convertorService.convertNumberToString(durationFull.hours, 2) + 'h' +
        //                        this.convertorService.convertNumberToString(durationFull.minutes, 2));

        this.previousXValue = +chartPoint.x;
      });
    }
    this.poisDataSet.data = this.chartDataPois;
    this.foodStationsDataSet.data = this.chartFoodStations;
    this.beverageStationsDataSet.data = this.chartBeverageStations;
    this.checkPointsDataSet.data = this.chartCheckPoints;
    this.timeLimitsDataSet.data = this.chartTimeLimits;
    this.lifeHousesDataSet.data = this.chartLifeHouses;
    this.finishPointDataSet.data = this.chartFinishPoint;
    this.shuttlesDataSet.data = this.chartShuttles;
    this.companionsDataSet.data = this.chartCompanions;
  }

  /** Ajout d'un Poi sur le graph
   *
   * @param poi
   */
  public addOnePoi(poi: PoiOrganizerModel | PoiTrailerModel): void {
    // ajout du nouveau poi à la liste des pois de la course

    if (poi.origin === ORGANIZER) {
      this.poisOrga.push(poi);
    } else if (poi.origin === TRAILER) {
      const raceDepartureDateTime: Date = this.race.date;

      let timeRef: Date = raceDepartureDateTime;

      this.poisTrailer.some((p, index) => {
        if (p.distance > poi.distance) {
          timeRef =
            index === 0
              ? raceDepartureDateTime
              : this.poisTrailer[index - 1].estimatedTimeOfArrival;
          return true;
        } else {
          if (index === this.poisTrailer.length - 1) {
            timeRef = p.estimatedTimeOfArrival;
            return true;
          }
        }
      });

      poi.estimatedTimeOfArrival = this.datetimeService.getTimeOfArrival(
        timeRef,
        poi.estimatedDuration
      );

      this.poisTrailer.push(poi);
    }
  }

  /** Edition d'un POI
   *
   * @param poi
   */
  public editOnePoi(poi: PoiModel): void {
    // on supprime l'ancien POI
    this.removeOnePoi(poi);
    // on raffraichi seulement les POIS avec leur lignes et leur label
    this.refreshElevationChartOneElement("pois");
    // on ajoute à nouveau le poi
    this.addOnePoi(poi);

    // raffraichi tout le graph
    // this.refreshElevationChart();
  }

  /** Suppression d'un Poi sur le graph
   *
   * @param poi
   */
  public removeOnePoi(poi: PoiModel): void {
    let poisWrk: Array<PoiModel>;

    if (poi.origin === ORGANIZER) {
      poisWrk = this.poisOrga;
    } else if (poi.origin == TRAILER) {
      poisWrk = this.poisTrailer;
    }

    poisWrk.forEach((currPoi, i) => {
      if (poi.id && currPoi.id == poi.id) {
        // Pour les POI déjà enregistré en base
        // Suppression du poi des points du graph
        poisWrk.splice(i, 1);
      } else if (!poi.id && currPoi.internId == poi.internId) {
        // pour les POIS nouvellement créé et pas encore en base
        // Suppression du poi des points du graph
        poisWrk.splice(i, 1);
      }
    });
  }

  /**
   * Retourne un potentiel POI qui a été ajouté à la distance
   */
  public getMatchingPoi(
    pois: Array<PoiModel>,
    distance: number,
    poiType?: PoiTypes
  ) {
    return this.chartService.getPoiFromChartPoint(pois, distance, poiType);
  }

  /**
   *
   * @param point
   * @private
   */
  private extractPoiToEdit(point: any) {
    let poiToEdit: PoiModel = null;

    // recup des données du point en fonction de son index sur le graph
    const chartElevationPoint: ChartElevationPoint = point
      ? this.getPointDatas(point)
      : new ChartElevationPoint();
    switch (chartElevationPoint.datasetIndex) {
      case 0:
      case 1:
        break;
      case 2:
        poiToEdit = this.getMatchingPoi(
          this.poisTrailer,
          chartElevationPoint.dst
        );
        break;
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        poiToEdit = this.getMatchingPoi(this.poisOrga, chartElevationPoint.dst);
        break;
      default:
        break;
    }

    if (!poiToEdit && !this.readOnlyMode) {
      // POI à ajouter
      poiToEdit = new PoiModel();
      poiToEdit.idx = chartElevationPoint.index;
      poiToEdit.distance = chartElevationPoint.dst;
      poiToEdit.distanceInput = chartElevationPoint.dst;
      poiToEdit.elevation = chartElevationPoint.ele;
      poiToEdit.latitude = chartElevationPoint.lat;
      poiToEdit.longitude = chartElevationPoint.lon;
      poiToEdit.origin = this.poiOrigin;
    }

    return poiToEdit;
  }

  /** Gestion de l'aguillage pour ouverture de la popup d'édition ou modification d'un POI
   *
   * @param point
   * @param race
   */
  public openAddOrEditPoiDialog(
    poiToEdit: PoiModel,
    race: RaceModel | RaceOffModel,
    loggedInUser: UserModel
  ) {
    // si on a le droit d'édition
    if (!this.readOnlyMode) {
      if (poiToEdit.id || poiToEdit.internId) {
        // On vérifie que le user a le droit de modifier son point
        const poiOwnerId = poiToEdit.user
          ? poiToEdit.user.id
          : poiToEdit.userId
          ? poiToEdit.userId
          : "undefined";
        if (poiOwnerId === loggedInUser.id) {
          this.openEditPoiDialog(poiToEdit, race, loggedInUser);
        }
      } else {
        this.openAddPoiDialog(poiToEdit, race, loggedInUser);
      }
    }
  }

  /** Parametrage et ouverture de la popup d'ajout d'un POI
   *
   * @param chartElevationPoint
   * @param race
   * @param origin
   */
  private openAddPoiDialog(
    poiToCreate: PoiModel,
    race: RaceModel | RaceOffModel,
    user: UserModel
  ) {
    if (!this.readOnlyMode) {
      // config popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;

      if (race instanceof RaceOffModel) {
        poiToCreate.raceOff = new RaceOffModel();
        poiToCreate.raceOff.id = race.id;
        poiToCreate.raceOff.date = race.date;
      } else {
        poiToCreate.race = new RaceModel();
        poiToCreate.race.id = race.id;
        poiToCreate.race.date = race.date;
      }

      const owner = new UserModel();
      owner.id = user.id;
      poiToCreate.user = owner;

      dialogConfig.data = {
        poi: poiToCreate,
        pois: this.poisOrga,
        race,
        trkpts: this.trkpts,
      };

      this.dialogAddPoiRef = this.dialog.open(AddPoiComponent, dialogConfig);

      // La popup renvoi le poi créé après fermeture de la popup
      this.dialogAddPoiRef.afterClosed().subscribe((ret: any) => {
        if (
          typeof ret !== "undefined" &&
          typeof ret.poi !== "undefined" &&
          ret.action === "add"
        ) {
          this.addOnePoi(ret.poi);
          //// rafraichi tout le graph
          this.refreshElevationChart();

          this.poiAdded.emit(ret.poi);
        }
        this.dialogAddPoiRef = null;
      });
    }
  }

  /**Parametrage et ouverture de la popup de modification d'un POI
   *
   * @param poiToEdit
   * @param race
   * @param origin
   */
  private openEditPoiDialog(
    poiToEdit: PoiModel,
    race: RaceModel | RaceOffModel,
    user: UserModel
  ) {
    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;

    // TRICK : au GET de la course et des POIS, l'enfant Race du POI n'est pas retourné par le backoffice.
    // pour ne pas avoir à récupérer le POI manuellement avec son enfant Race, on fait un tour de passe passe
    // en recréent un enfant Race du POI et en initialisant que le race.id. Si on fait poi.race = race,
    // on se retrouve avec un object Race qui a lui meme les POIS, qui a lui même les race etc...et ca JSON il n'aime pas..

    if (race instanceof RaceOffModel) {
      poiToEdit.raceOff = new RaceOffModel();
      poiToEdit.raceOff.id = race.id;
    } else {
      poiToEdit.race = new RaceModel();
      poiToEdit.race.id = race.id;
    }
    poiToEdit.user = new UserModel();
    poiToEdit.user.id = user.id;
    poiToEdit.user.type_user = user.type_user;

    dialogConfig.data = {
      poi: poiToEdit,
      pois: this.poisOrga,
      race,
      trkpts: this.trkpts,
    };

    this.dialogEditPoiRef = this.dialog.open(EditPoiComponent, dialogConfig);

    // La popup renvoi le poi modifié ou supprimé après fermeture de la popup
    this.dialogEditPoiRef.afterClosed().subscribe((ret: any) => {
      // test sur le retour et l'action.
      if (typeof ret !== "undefined" && typeof ret.poi !== "undefined") {
        if (ret.action == "delete") {
          this.removeOnePoi(ret.poi);

          this.poiDeleted.emit(ret.poi);
        } else if (ret.action === "edit") {
          this.editOnePoi(ret.poi);

          this.poiEdited.emit(ret.poi);
        }
        // raffraichir tout le graph
        this.refreshElevationChart();
      }
      this.dialogAddPoiRef = null;
    });
  }

  /**Parametrage et ouverture de la popup de modification d'un POI
   *
   * @param poiToEdit
   * @param race
   * @param origin
   */
  public openPoiEstimationDialog(
    poiToEstimate: PoiModel,
    roadbook: RoadbookModel,
    trailer: TrailerModel,
    isValidationMode = false,
    isStatisticMode = false
  ) {
    console.log("poiToEstimate :>> ", poiToEstimate);
    if (poiToEstimate) {
      // config popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.panelClass = "_poiDialog";
      dialogConfig.width = "500px";
      dialogConfig.maxWidth = "95%";

      // TRICK : au GET de la course et des POIS, l'enfant Race du POI n'est pas retourné par le backoffice.
      // pour ne pas avoir à récupérer le POI manuellement avec son enfant Race, on fait un tour de passe passe
      // en recréent un enfant Race du POI et en initialisant que le race.id. Si on fait poi.race = race,
      // on se retrouve avec un object Race qui a lui meme les POIS, qui a lui même les race etc...et ca JSON il n'aime pas...
      poiToEstimate.race = new RaceModel();
      poiToEstimate.race.id = roadbook.race.id;
      poiToEstimate.race.date = roadbook.race.date;
      poiToEstimate.race.departureTime = roadbook.race.departureTime;

      poiToEstimate.user = new UserModel();
      poiToEstimate.user.id = trailer.id;
      poiToEstimate.user.type_user = trailer.type_user;

      const pois = [...this.poisOrga, ...this.poisTrailer].sort(
        (n1, n2) => n1.distance - n2.distance
      );

      const followingPois = pois.filter(
        (poi) => poi.distance > poiToEstimate.distance
      );
      const followingPoi =
        followingPois && followingPois.length > 0 ? followingPois[0] : null;
      poiToEstimate.user.averageSpeed =
        followingPoi && followingPoi.trailerEstimation
          ? followingPoi.trailerEstimation.estimatedAverageSpeed
          : roadbook.defaultAverageSpeed;

      dialogConfig.data = {
        poi: poiToEstimate,
        pois,
        race: roadbook.race,
        trkpts: this.trkpts,
        isValidationMode,
        isStatisticMode,
      };

      this.dialogPoiEstimationRef = this.dialog.open(
        AddEstimationComponent,
        dialogConfig
      );

      // La popup renvoi le poi modifié ou supprimé après fermeture de la popup
      this.dialogPoiEstimationRef.afterClosed().subscribe((ret: any) => {
        // test sur le retour et l'action.
        if (typeof ret !== "undefined" && typeof ret.poi !== "undefined") {
          if (ret.action === "editEstimation") {
            this.poiEdited.emit(ret.poi);
          } else if (ret.action === "addEstimation") {
            if (ret.poi.origin === ORGANIZER) {
              this.poiEdited.emit(ret.poi);
            } else {
              this.poiAdded.emit(ret.poi);
            }
          } else if (ret.action === "deleteEstimation") {
            this.poiDeleted.emit(ret.poi);
          }
          //// rafraichi tout le graph
          this.refreshElevationChart();
        }
        this.dialogAddPoiRef = null;
      });
    }
  }

  /** Création d'un nouveau segment de courbe d'élévation
   *
   * @param chartDataElevation
   */
  private newElevationDataSegment(
    chartDataElevation: CustomChartPoint[],
    isNightSegment: boolean
  ) {
    // configuration du segment
    const elevationDataSetSegment: CustomChartDataSets = {
      label: "Elevation",
      backgroundColor: this.getSegmentBackGroundColor(isNightSegment),
      xAxisID: "x-dst",
      // backgroundColor:'rgba(235,241,252,1)',
      // borderColor: '#929fb7',
      borderColor: "#ffffff",
      borderWidth: 2,
      lineTension: 0.2,
      borderCapStyle: "round",
      pointHitRadius: 3,
      pointHoverRadius: 7,
      pointRadius: 0, // ne pas afficher les points
      pointBorderWidth: 3,
      pointHoverBorderColor: "#0AD6AF",
      pointHoverBorderWidth: 4,
      pointHoverBackgroundColor: "#fff",
      pointStyle: "cross",
      hoverRadius: 10,
    };

    elevationDataSetSegment.data = chartDataElevation;
    this.elevationDataSetSegments.push(elevationDataSetSegment);
  }

  /** Alterne la coloration d'un segment sur le graph
   *
   * @returns {string}
   */
  private getSegmentBackGroundColor(isNisNightSegment = false): string {
    const dayTimeColor = "#afbad3";
    const nightTimeColor = "#003063";

    return isNisNightSegment ? nightTimeColor : dayTimeColor;
  }

  /** Retourne un point du graph avec ses coordonnées
   *
   * @param element
   * @returns {CustomChartPoint}
   */
  public getPointDatas(element: any): ChartElevationPoint {
    const datasetIndex = element[0]._datasetIndex;
    const chartPoint: CustomChartPoint =
      this.chartDataSets[datasetIndex].data[element[0]._index]; // point du graph

    const chartElevationPoint = new ChartElevationPoint(); // contient les coordonnées du point ainsi que son index dans le graph

    chartElevationPoint.index = element[0]._index;
    chartElevationPoint.ele = +chartPoint.y;
    chartElevationPoint.dst = +chartPoint.x;
    chartElevationPoint.lat = +chartPoint.lat;
    chartElevationPoint.lon = +chartPoint.lon;
    chartElevationPoint.datasetIndex = +datasetIndex;

    return chartElevationPoint;
  }

  /**
   * Sauvegarde du graph en png
   */
  public getChartInBase64Image() {
    return this.myChart.toBase64Image();
  }

  ngOnDestroy() {
    this.ctx.remove();
    this.myChart.clear();
    this.myChart.destroy();
    this.myChart = null;
  }
}
