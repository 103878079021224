<mat-dialog-content>
  <div id="notification-form-wrap">
    <a (click)="close()" class="close" aria-label="Close"><i class="icon-splits_clear splits-icon-md"></i></a>
    <div matDialogTitle class="dialog-title">Envoyer une notification aux coureurs</div>
    <form (ngSubmit)="sendNotification()" #notificationForm="ngForm" style="width: 100%;padding-top: 0px;">

      <div class="row">
        <div class="col-md-12">
          <div class="form-group __input">
            <mat-form-field class="_wrap_input">
              <label i18n>Sujet du message *</label>
              <input matInput maxlength="100" style="width: 100%"
                     type="text"
                     [(ngModel)]="notificationToSend.subject"
                     name="subject" id="subject" required #subject>
              <mat-hint align="end">{{subject.value.length}} / 100</mat-hint>
              <mat-error>Champ obligatoire</mat-error>
            </mat-form-field>
          </div>


          <div class="form-group __input">
            <mat-form-field class="_wrap_input">
              <label i18n>Titre du message *</label>
              <input matInput maxlength="100" style="width: 100%" type="text"
                     name="title" id="title" [(ngModel)]="notificationToSend.title" #title required>
              <mat-hint align="end">{{title.value.length}} / 100</mat-hint>
              <mat-error>Champ obligatoire</mat-error>
            </mat-form-field>
          </div>

          <div class="ckeditor-wrap">
            <label i18n>Votre message *</label>
            <ckeditor
              [editor]="Editor"
              [(ngModel)]="notificationToSend.content"
              name="content"
              [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }"
              #ckeditor>
            </ckeditor>
          </div>
        </div>
      </div>

      <mat-dialog-actions>
        <div class="d-flex justify-content-center btn-action-wrap _full">
          <button matDialogClose class="_sp_btn _white" type="button">Annuler</button>
          <button type="submit" [disabled]="!notificationForm.form.valid" class="_sp_btn _blue"
          >Envoyer
          </button>
        </div>
      </mat-dialog-actions>

    </form>
  </div>

</mat-dialog-content>
