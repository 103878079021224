<h3>À propos de vous</h3>
<mat-horizontal-stepper
  #stepper
  class="mx-auto"
  labelPosition="bottom"
  linear="true"
  [selectedIndex]="defaultSelectedIndex"
>
  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
  <mat-step [stepControl]="registerTrailerForm" state="init-trailer">
    <form [formGroup]="registerTrailerForm" (ngSubmit)="save()">
      <div class="form-group">
        <div class="d-flex flex-column">
          <div class="form-group __input">
            <mat-form-field class="_wrap_input">
              <label i18n>Votre prénom *</label>
              <input
                matInput
                type="text"
                formControlName="firstname"
                required
              />
              <mat-error>Champ obligatoire</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group __input">
            <mat-form-field class="_wrap_input">
              <label i18n>Votre nom *</label>
              <input matInput type="text" formControlName="lastname" required />
              <mat-error>Champ obligatoire</mat-error>
            </mat-form-field>
          </div>

          <div class="form-group __checkbox">
            <label i18n>Choisir mon avatar</label>
            <div
              *ngIf="avatars; else skeletonLoaderAvatars"
              class="avatars-wrap d-flex flex-wrap align-items-end justify-content-start ml-2 my-2 __checkbox_icon"
            >
              <div
                *ngFor="let avatar of avatars; let i = index"
                class="avatar-item d-flex justify-content-center"
              >
                <ng-container>
                  <div
                    class="_contain_card d-flex align-items-center"
                    [ngClass]="{ __checked: radioAvatar.checked }"
                  >
                    <div class="avatar-icon-wrap _md">
                      <input
                        type="radio"
                        [id]="'avatar-' + avatar.id"
                        [value]="avatar"
                        formControlName="avatar"
                        #radioAvatar
                        class="d-none"
                      />
                      <label [for]="'avatar-' + avatar.id">
                        <div
                          role="button"
                          [ngClass]="[
                            'avatar-icon __icon _bg-size-90',
                            '_icon-avatar-' + avatar.code
                          ]"
                        ></div>
                      </label>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-template #skeletonLoaderAvatars>
              <ngx-skeleton-loader
                count="3"
                appearance="circle"
                [theme]="{
                  width: '100px',
                  height: '100px',
                  margin: '0 0.5rem'
                }"
              ></ngx-skeleton-loader>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="_sp_btn _blue _inline" mat-button>
          <span>Créer mon espace</span>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step state="event-done">
    <h4 class="text-success" i8n>Votre compte a été initialisé</h4>
    <p>
      On se retrouve dans votre espace trailer pour compléter votre profil et
      créer vos premiers roadbooks !
    </p>
    <button
      type="button"
      mat-button
      class="_sp_btn _blue _inline"
      i18n
      matTooltip="Allez"
      matTooltipShowDelay="1000"
      aria-label="Bouton Allez"
      (click)="login()"
      matDialogClose
    >
      <span>Allez !</span>
    </button>
  </mat-step>
</mat-horizontal-stepper>
