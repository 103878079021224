import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ORGANIZER, TRAILER, UserModel } from '../../models/user.model';
import { UserService } from '../../services/user/user.service';
import { SpinnerService } from '../../services/common/spinner.service';
import { environment } from '../../../environments/environment';

import { Subscription } from 'rxjs';
import { SnackbarService } from '../../services/notifications/snackbar.service';

// SOCIAL LOGIN
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ErrorService } from '../../services/common/error.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../services/common/dialog.service';

@Component({

             selector: 'login-form',
             templateUrl: './login-form.component.html',
             styleUrls: ['./login-form.component.scss']
           })

export class LoginFormComponent implements OnInit {

  forgotPasswordUrl: string = environment.forgotPasswordUrl;

  authenticationFailed = false;
  // FORM
  loginForm: UntypedFormGroup;
  emailCtrl: UntypedFormControl;
  passwordCtrl: UntypedFormControl;
  type_userCtrl: UntypedFormControl;

  submitted = false;

  public user: UserModel = new UserModel();

  private isLoggedIn: boolean;

  retrievedUser: UserModel = new UserModel();

  sub: Subscription;

  public socialLoginClick = false;

  @Input() hasSocialSignIn = false;

  @Input() typeUserToLogin: string = TRAILER;
  @Input() callbackUrl?: string;

  constructor(public router: Router,
              private dialogService: DialogService,
              private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private spinner: SpinnerService,
              private snackbarService: SnackbarService,
              private socialAuthService: SocialAuthService,
              private errorService: ErrorService) {
    this.emailCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));
    this.passwordCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));
    this.type_userCtrl = new UntypedFormControl('', Validators.compose([Validators.required]));
  }

  ngOnInit() {
    this.submitted = false;
    //

    this.loginForm = this.formBuilder.group({
                                              email: this.emailCtrl,
                                              password: this.passwordCtrl,
                                              type_user: this.type_userCtrl
                                            });


    this.type_userCtrl.setValue(this.typeUserToLogin);

    this.userService.isUserLoggedIn.subscribe(value => this.isLoggedIn = value);

    // HANDLE DE LA CONNEXION VIA FB ou GOOGLE pour les Trailers
    this.socialAuthService.authState.subscribe((socialUser: SocialUser) => {

      if (socialUser != null && this.socialLoginClick) {

        this.spinner.start('Connexion...');

        const splittedName = socialUser.name.split(' ', 2);
        const firstname = splittedName[0];
        const lastname = splittedName[1];

        this.user.firstname = firstname;
        this.user.lastname = lastname;
        this.user.email = socialUser.email;
        this.user.uid = +socialUser.id;
        this.user.provider = socialUser.provider;
        // par défaut TOS et réception NEWS par EMAIL accepté
        this.user.isNotificationsAllowed = true;
        this.user.tos = true;

        this.userService.handleSocialConnect(this.user).subscribe((response) => {
          this.router.navigate(['/trailer/dashboard']);
        }, (error) => {

          this.spinner.stop();
          this.snackbarService.openDanger(error);
          this.authenticationFailed = true;
        });
      }
    });
  }

  /** Gestion de la connexoin manuelle via form de connexion
   *
   * @param user
   * @returns {Subscription}
   */
  login() {
    if (this.loginForm.valid) {
      // start spinner
      this.spinner.start('Connexion...');

      this.submitted = true;

      const userToLog: UserModel = this.loginForm.value;
      userToLog.email.toLowerCase();

      this.userService.login(userToLog).subscribe((user: UserModel) => {

                                                    if (this.callbackUrl) {
                                                      this.router.navigateByUrl(this.callbackUrl);
                                                    } else {
                                                      this.router.navigate(['/', user.type_user, 'home']);
                                                    }
                                                    // close dialog if opened
                                                    this.dialogService.triggerCloseDialog.next(true);

                                                    this.spinner.stop();
                                                  },
                                                  (error) => {
                                                    this.snackbarService.openDanger(error.message);
                                                    this.authenticationFailed = true;
                                                    this.spinner.stop();
                                                  }
      );
    }
  }

  /**
   * Connexion avec Google
   */
  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  /**
   * Connexion avec Facebook
   */
  signInWithFB() {
    this.socialLoginClick = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  navToHome() {
    this.router.navigate(['/trailer/dashboard']);
  }


}
