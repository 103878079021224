import { Injectable } from '@angular/core';

import { Subject ,  Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { MediaModel } from '../../models/media.model';
import { SearchParam } from '../../classes/search-param';



@Injectable({
  providedIn: 'root'
})
export class MediaService {

    listMedias: Observable<Array<MediaModel>>;
    media: Observable<MediaModel>;

    mediasUrl = '/api/medias';
    oneMediaUrl = '/api/media/';
    randomMediasUrl = '/api/medias/random';

    public backgroundIndexMedia: Subject<string> = new Subject<string>();

    constructor(private http: HttpService) {
    }

    /** Récupération de la liste complète des événements
     *
     * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
     */
    getMedias(searchParams: Array<SearchParam>) {
        this.listMedias = this.http.get(this.mediasUrl, searchParams);
        return this.listMedias;
    }

    /** Retourne les détails d'une course
     *
     * @returns {Observable<RaceModel>}
     */
    getRandomMedias(searchParams: Array<SearchParam>) {
        this.listMedias = this.http.get(this.randomMediasUrl, searchParams);
        return this.listMedias;
    }

}
