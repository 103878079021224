<div class="content-wrap p-2">
  <div matDialogTitle class="dialog-title">Vitesse moyenne par défaut</div>

  <mat-dialog-content>
    <p class="dialog-message">Les temps proposés sont calculés à partir d'une vitesse moyenne de :</p>
    <div class="d-flex justify-content-center align-items-baseline">
      <mat-form-field appearance="legacy" class="w-30">
        <input matInput type="number"
               [(ngModel)]="data.averageSpeed" name="averageSpeed" required class="_sa_input average-speed">
      </mat-form-field>
      <div style="font-size: 2.2rem">km/h</div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-center" style="width: 100%">
      <button type="submit" class="_sp_btn _blue" [mat-dialog-close]="data.averageSpeed"
              color="accent"><span>C'est ok pour moi</span></button>
    </div>
  </mat-dialog-actions>

</div>
