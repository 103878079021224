<div class="event-item d-block d-md-flex align-items-center flex-grow-1 p-3" (click)="selectEvent()">
  <div class="logo-event-wrap">
    <div [className]="'__avatar_event _avatar-'+event?.terrain?.code"></div>
  </div>
  <div class="d-flex align-items-center flex-grow-1">
    <div class="details-wrap d-flex flex-column pt-3 pt-xs-3 pt-sm-3 pt-md-0 pl-md-3 w-100 flex-grow-1">
      <div class="d-flex _name p-1">
        <h4><a (click)="selectEvent()"> {{ event?.name }}</a></h4>
      </div>
      <div class="_location pl-1 ">
        <p><span class="event_date">{{ dateEventDisplay }}</span>{{ event?.locality }} <span
          *ngIf="event?.postalCode"> ({{event?.postalCode | slice:0:2 }}
          ) </span><span>  {{ event?.country }}</span></p>
      </div>
    </div>
    <div class="d-flex flex-wrap distances-wrap">
      <div *ngFor="let race of event?.races; let i=index" class="distance-item __distance">
          <span class="__d">{{ race?.distance | number : '1.0-2'
            }} km</span>
      </div>
    </div>
  </div>
</div>
