import { Directive, ElementRef, OnInit } from "@angular/core";
import { GoogleMapService } from "../services/common/google-map.service";

@Directive({
  selector: "[appPlaceAutocomplete]",
})
export class PlaceAutocompleteDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private googlePlaceService: GoogleMapService
  ) {}
  ngOnInit(): void {
    this.googlePlaceService.getPlaceAutocomplete(this.el);
  }
}
