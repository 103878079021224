<nav mat-tab-nav-bar class="_toolbar_nav">
  <div class="w-100 px-5">
    <a routerLink [routerLink]="['/trailer/profile/personnal-datas']">
      <i class="fa-solid fa-user"></i>
      <span>Paramètres du compte</span>
    </a>
    <a routerLink [routerLink]="['/trailer/profile/trailer-datas']">
      <i class="fa-solid fa-person-running"></i>
      <span>Mon profil Trailer</span>
    </a>
    <a routerLink [routerLink]="['/trailer/profile/race-preferences']">
      <i class="fa-solid fa-mountain"></i>
      <span>Préférences de courses</span>
    </a>
  </div>
</nav>
