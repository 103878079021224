<!--<div class="overlay" [ngClass]="{show: _show}" (click)="hide()"></div>-->
<div
  #searchWrap
  [ngClass]="{ show: _show }"
  class="p-3"
  id="advanced-search-wrap"
>
  <div class="advanced-search-content">
    <div class="advanced-search-form">
      <form
        (ngSubmit)="applySearch()"
        [formGroup]="searchRaceForm"
        class="_search_lite d-flex flex-column"
        style="width: 100%"
      >
        <div class="h4">
          Recherche avancée
          <a (click)="hide()" aria-label="Close" class="close"
            ><i class="fa-solid fa-xmark"></i
          ></a>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group __input">
              <mat-form-field class="_wrap_input">
                <label i18n>Nom de la course</label>
                <input #name formControlName="name" matInput type="text" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="flex-fill form-group __input _marge_input">
              <label i18n class="pb-0 mb-O">Date de la course</label>
              <mat-form-field class="_wrap_input _input_search _no_border px-0">
                <mat-date-range-input
                  (click)="picker.open()"
                  [formGroup]="range"
                  [min]="today"
                  [rangePicker]="picker"
                >
                  <input formControlName="start" matStartDate />
                  <input
                    [min]="startCtrl.value"
                    formControlName="end"
                    matEndDate
                  />
                </mat-date-range-input>

                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error
                  *ngIf="
                    startCtrl.hasError('matStartDateInvalid') ||
                    endCtrl.hasError('matEndDateInvalid')
                  "
                  i18n
                >
                  Dates invalides
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group __slider">
              <label>Distance (km)</label>
              <ngx-slider
                formControlName="distanceRange"
                [options]="configSliderDistance"
              ></ngx-slider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group __slider">
              <label>Dénivelé + (m)</label>
              <ngx-slider
                formControlName="ascendingElevationRange"
                [options]="configSliderElevation"
              ></ngx-slider>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group __input">
              <mat-form-field class="_wrap_input">
                <label i18n>Lieu de départ</label>
                <input
                  data-refererComponent="full"
                  appPlaceAutocomplete
                  #locationAutocomplete
                  formControlName="inputAddress"
                  id="autocomplete"
                  matInput
                  name="autocomplete"
                  type="text"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group __slider">
              <label>Rayon de recherche (km)</label>

              <ngx-slider
                formControlName="proximity"
                [options]="configSliderProximity"
              ></ngx-slider>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <div class="form-group __checkbox">
              <label i18n>Thématiques</label>
              <div
                *ngIf="raceTags; else skeletonLoaderRaceTags"
                class="tags-wrap d-flex flex-wrap align-items-end justify-content-start my-2 __checkbox_icon"
              >
                <div
                  *ngFor="let raceTag of raceTags.slice(0, 8); let i = index"
                  class="tag-item d-flex justify-content-center"
                >
                  <ng-container>
                    <div
                      class="_contain_card d-flex align-items-center"
                      [ngClass]="{ __checked: checkboxRaceTag.checked }"
                    >
                      <div class="tag-icon-wrap _md">
                        <input
                          type="checkbox"
                          [id]="'tag-' + raceTag.id"
                          [value]="raceTag.id"
                          (change)="onTagSelectedChange($event)"
                          #checkboxRaceTag
                          class="d-none"
                        />
                        <label [for]="'tag-' + raceTag.id">
                          <div
                            role="button"
                            [ngClass]="[
                              'tag-icon __icon',
                              '_icon-' + raceTag.code
                            ]"
                          ></div>
                        </label>
                        <span class="tag-label flex-wrap">{{
                          raceTag.name
                        }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <accordion class="w-100 mt-2">
                <accordion-group
                  #thematicAccordionGroup
                  [isOpen]="isThematicAccordionOpen"
                >
                  <div
                    *ngIf="raceTags; else skeletonLoaderRaceTags"
                    class="tags-wrap d-flex flex-wrap align-items-end justify-content-start my-2 __checkbox_icon"
                  >
                    <div
                      *ngFor="let raceTag of raceTags.slice(8); let i = index"
                      class="tag-item d-flex justify-content-center"
                    >
                      <ng-container>
                        <div
                          class="_contain_card d-flex align-items-center"
                          [ngClass]="{ __checked: checkboxRaceTag.checked }"
                        >
                          <div class="tag-icon-wrap _md">
                            <input
                              type="checkbox"
                              [id]="'tag-' + raceTag.id"
                              [value]="raceTag.id"
                              (change)="onTagSelectedChange($event)"
                              #checkboxRaceTag
                              class="d-none"
                            />
                            <label [for]="'tag-' + raceTag.id">
                              <div
                                role="button"
                                [ngClass]="[
                                  'tag-icon __icon',
                                  '_icon-' + raceTag.code
                                ]"
                              ></div>
                            </label>
                            <span class="tag-label flex-wrap">{{
                              raceTag.name
                            }}</span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </accordion-group>
              </accordion>

              <button
                type="button"
                class="_sp_btn _white_green mx-0"
                mat-button
                disableRipple="true"
                (click)="isThematicAccordionOpen = !isThematicAccordionOpen"
              >
                <span>{{
                  thematicAccordionGroup.isOpen
                    ? "Voir moins de thématique"
                    : "Voir plus de thématique"
                }}</span>
                <i
                  class="fa-solid"
                  [ngClass]="{
                    'fa-chevron-up': thematicAccordionGroup.isOpen,
                    'fa-chevron-down': !thematicAccordionGroup.isOpen
                  }"
                  style="margin-bottom: 3px; margin-left: 1rem"
                ></i>
              </button>

              <ng-template #skeletonLoaderRaceTags>
                <ngx-skeleton-loader
                  count="8"
                  appearance="circle"
                  [theme]="{ width: '60px', height: '60px', margin: '16px' }"
                ></ngx-skeleton-loader>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="row"></div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group _no_marge __select">
              <mat-form-field class="_wrap_input _small" style="width: 100%">
                <label i18n>Surface *</label>
                <mat-select
                  class="__chp_select"
                  formControlName="terrain"
                  required
                >
                  <mat-option value>--</mat-option>
                  <mat-option
                    *ngFor="let terrain of terrains"
                    [value]="terrain.id"
                  >
                    {{ terrain.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group _no_marge __select">
              <mat-form-field class="_wrap_input" style="width: 100%">
                <label i18n>Discipline</label>
                <mat-select class="__chp_select" formControlName="discipline">
                  <mat-option value>--</mat-option>
                  <mat-option
                    *ngFor="let discipline of disciplines"
                    [value]="discipline.id"
                  >
                    {{ discipline.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group __select">
              <mat-form-field class="_wrap_input">
                <label i18n>UTMB Index</label>
                <mat-select class="__chp_select" formControlName="utmbIndex">
                  <mat-option value>Aucun</mat-option>
                  <mat-option
                    *ngFor="let utmbIndexKey of utmbIndexKeys"
                    [value]="utmbIndexKey"
                  >
                    {{ utmbIndexes[utmbIndexKey] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group __slider">
              <label>Running Stones</label>
              <ngx-slider
                formControlName="runningStones"
                [options]="configSliderRunningStones"
              ></ngx-slider>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group __slider">
              <label>Prix maximum du dossard (€)</label>
              <ngx-slider
                formControlName="maxPrice"
                [options]="configSliderPrice"
              ></ngx-slider>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <mat-checkbox formControlName="hasTimeLimit">
                Barrières horaires:
              </mat-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <mat-checkbox formControlName="isNightRace">
                Nocturne
              </mat-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <mat-checkbox formControlName="arePolesProhibited">
                Bâtons interdits
              </mat-checkbox>
            </div>
          </div>
        </div>
      </form>
      <div
        class="button-action-wrap d-flex align-items-center justify-content-center flex-wrap"
      >
        <button (click)="clearFilters()" class="_sp_btn _white" type="reset">
          Effacer les filtres
        </button>
        <button (click)="applySearch()" class="_sp_btn _blue" type="button">
          Rechercher
        </button>
      </div>
    </div>
  </div>
</div>
