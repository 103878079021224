import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { RaceModel } from '../../../models/race.model';
import { RaceService } from '../../../services/race/race.service';

@Component({
    selector: 'race-complete-bar',
    templateUrl: './race-complete-bar.component.html',
    styleUrls: ['./race-complete-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RaceCompleteBarComponent implements OnInit, AfterViewInit {

    @Input() raceId: number;
    race: RaceModel;
    percentComplete: number;

    constructor(private raceService: RaceService) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {

        this.raceService.getOneRace(this.raceId)
            .subscribe((race: RaceModel) => {
                    this.race = race;

                    this.percentComplete = 25;

                    if (race.gpx) {
                        this.percentComplete += 25;
                    }

                    if (race.hasPoiOrga) {
                        this.percentComplete += 25;
                    }

                    if (race.description) {
                        this.percentComplete += 25;
                    }

                }
            );
    }
}
