import { Injectable } from '@angular/core';
import { JwtInterceptorService } from '../http/jwt-interceptor.service';



@Injectable({
  providedIn: 'root'
})
export class SessionService {

    constructor() {
    }

    setCookie(name: string, content: any) {
        window.localStorage.setItem(name, content);
    }

    getCookie(name: string) {
        return JSON.parse(window.localStorage.getItem(name));
    }

    clearSession() {
        window.localStorage.clear();
    }
}
