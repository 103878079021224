import { Component, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { PoiBaseComponent } from "../poi-base-component";
import { ORGANIZER } from "../../../models/user.model";
import { Validators } from "@angular/forms";

@Component({
  selector: "edit-poi-cmp",
  templateUrl: "./edit-poi.component.html",
  styleUrls: ["./edit-poi.component.scss"],
})
export class EditPoiComponent extends PoiBaseComponent implements OnInit {
  addSuccessfully: boolean;
  showTimeLimitInput: boolean;

  ngOnInit() {
    super.ngOnInit();
    super.buildForm();
    this.populatePoiInfoWithPreviousPoi(this.workingPoi.distance);
    if (this.workingPoi.origin === ORGANIZER) {
      this.poiTypesCtrl.setValidators([Validators.required]);
    }
    this.poiForm.patchValue(this.workingPoi);
    this.checkedPoiTypes = this.workingPoi.poiTypes;
  }

  poiTypeIsChecked(poiTypeId) {
    const isPoiTypeChecked =
      this.checkedPoiTypes.filter(
        (checkePoiType) => +checkePoiType.id === +poiTypeId
      ).length > 0;

    this.displayTimeLimit = this.timeLimitPoiIsChecked();

    return isPoiTypeChecked;
  }

  /** Sauvegarde de la course
   *
   * Recupère la nouvelle entité Race
   *
   * @returns {TeardownLogic|AnonymousSubscription|Subscription}
   */
  savePoi() {
    if (this.poiForm.valid) {
      super.savePoi();
      this.submitted = true;
      // on reporte les modifications du Poi de travail sur le POI
      // évite que si on annule ou on clique en dehors de la fenêtre, on édite quand même le POI
      this.poi = { ...this.workingPoi, ...this.poiForm.value };

      const ret = {
        poi: this.poi,
        action: "edit",
      };
      this.dialogRef.close(ret);
      this.snackbarService.openSuccess("Point d'intéret modifié avec succès");
    }
  }

  deletePoi() {
    // si le point a un id c'est qu'il est enregistré en base. On supprime de la bdd
    if (typeof this.poi.id !== "undefined") {
      this.poiService.deletePoi(this.poi).subscribe({
        next: () => {
          const ret = {
            poi: this.poi,
            action: "delete",
          };
          this.dialogRef.close(ret);
          this.snackbarService.openSuccess("Point d'intéret supprimé");
        },
        error: () => {
          this.hasError = true;
        },
      });
    } else {
      // supprime juste le POI virtuel
      const ret = {
        poi: this.poi,
        action: "delete",
      };
      this.dialogRef.close(ret);
      this.snackbarService.openSuccess("Point d'intéret supprimé");
    }
  }
}
