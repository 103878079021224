import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { UserModel } from '../../models/user.model';
import { SnackbarService } from '../notifications/snackbar.service';
import { SessionService } from '../common/session.service';
import { JwtInterceptorService } from '../http/jwt-interceptor.service';
import { OrganizerModel } from '../../models/organizer.model';
import { TrailerModel } from '../../models/trailer.model';
import { ErrorService } from '../common/error.service';
import { HttpStatusCode } from '@angular/common/http';
import { SearchParam } from '../../classes/search-param';
import {SocialAuthService} from '@abacritt/angularx-social-login';


@Injectable({
              providedIn: 'root'
            })
export class UserService {

  registeringUser: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  loggedInUser: TrailerModel | OrganizerModel;

  authenticateUrl = '/api/login_check';
  authenticateSocialUrl = '/api/social_login_check';
  getUserUrl = '/api/users/';

  registerTrailerUrl = '/api/trailers/register';
  registerOrganizerUrl = '/api/organizers/register';

  editTrailerUrl = '/api/trailers/';
  editOrganizerUrl = '/api/organizers/';

  // use this property for property binding
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userEvents: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);

  constructor(private http: HttpService,
              private router: Router,
              private socialAuthService: SocialAuthService,
              private snackbarService: SnackbarService,
              private sessionService: SessionService,
              private errorService: ErrorService,
              private jwtInterceptorService: JwtInterceptorService) {
    this.retrieveUser();
  }

  /** Login à l'API via couple Login/pass. L'API retourne un token JWT identifiant le user pour les prochains appels
   *
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param username
   * @param password
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  login(user: any): Observable<UserModel> {

    // on poste l'authentification et en cas de succès, on sauvegarde le token
    return this.http.post(this.authenticateUrl, user)
      .pipe(tap((token: any) =>
                  this.storeTokenUser(token.token)),
            catchError((e) => {
              const errorCode: any = this.errorService.extractErrorCode(e);
              e.message = (errorCode === HttpStatusCode.Unauthorized) ? 'Votre email et/ou votre mot de passe sont incorrects' : e.message;
              return throwError(e);
            }),
            switchMap(() => this.getUserByEmail(user)),
            map((authenticatedUser: UserModel) => {
              // // si le type du user recup correspond au type du user qui se connecte
              // if (authenticatedUser.type_user === user.type_user) {
              // on stock en session et cookies
              this.storeAuthenticatedUser(authenticatedUser);
              return authenticatedUser;
            }),
            catchError((error) => {
              return throwError(error);
            }));
  }

  /**
   *
   * @param user
   */
  storeAuthenticatedUser(authenticatedUser: UserModel) {
    // on stock le user authentifié
    window.localStorage.setItem('rememberMe', JSON.stringify(authenticatedUser));
    this.userEvents.next(authenticatedUser);
  }

  updateAuthenticatedUser(user: UserModel) {
    window.localStorage.removeItem('rememberMe');
    window.localStorage.setItem('rememberMe', JSON.stringify(user));
    this.userEvents.next(user);
  }

  /**
   *
   */
  retrieveUser() {
    const user = this.getStoredUser();
    const userToken = this.getTokenUser();
    const isTokenValid = this.jwtInterceptorService.isTokenValid(userToken);
    if (user && userToken && isTokenValid) {
      this.jwtInterceptorService.setJwtToken(userToken.token);
      this.userEvents.next(user);
    } else {
      // on clean tout
      this.logout();
    }
  }

  isLoggedIn(): boolean {
    const user = this.getStoredUser();
    const userToken = this.getTokenUser();
    const isTokenValid = this.jwtInterceptorService.isTokenValid(userToken);
    if (user && userToken && isTokenValid) {
      return !!user;
    } else {
      // on clean tout
      this.logout();
      return false;
    }
  }

  logout() {
    // logout du backend
    this.userEvents.next(null);
    this.jwtInterceptorService.removeJwtToken();
    window.localStorage.clear();
  }

  /** Get Unique user via username
   *
   */
  getUserByEmail(user: UserModel): Observable<UserModel> {
    return this.http.get(this.getUserUrl + user.email + '/by/email', null);
  }

  /** Get Unique user via username
   *
   */
  checkUserEmailAvailable(email: string): Observable<any> {
    const searchParams: Array<SearchParam> = [];
    searchParams.push(new SearchParam('email', email));
    return this.http.get('/api/check_email_available', searchParams);
  }

  registerTrailer(registration: TrailerModel | OrganizerModel) {
    return this.http.post(this.registerTrailerUrl, registration);
  }

  registerOrganizer(registration: TrailerModel | OrganizerModel) {
    return this.http.post(this.registerOrganizerUrl, registration);
  }

  patchTrailer(trailer: TrailerModel): Observable<UserModel> {
    return this.http.patch(this.editTrailerUrl + trailer.id, trailer);
  }

  patchOrganizer(organizer: OrganizerModel): Observable<UserModel> {
    return this.http.patch(this.editOrganizerUrl + organizer.id, organizer);
  }

  patchUser(user: UserModel): Observable<UserModel> {
    if (user.type_user === 'trailer') {
      return this.patchTrailer(user);
    } else if (user.type_user === 'organizer') {
      return this.patchOrganizer(user);
    } else {
      return of();
    }
  }

  deleteUser(user: UserModel) {
    return this.http.delete(this.getUserUrl + user.id, user);
  }


  /** Envoi du user Facebook connecté au backend pour connexion ou inscription
   *
   * @param user
   */
  handleSocialConnect(user: any) {
    return this.http.post(this.authenticateSocialUrl, user, false)
      // .retryWhen(errors => errors.delay(1500).take(15))// retry toutes les secondes, 15 fois
      .pipe(map((response: any) => {
        if (null != response) {
          const token = response.token;
          this.storeTokenUser(token.token);
          this.storeAuthenticatedUser(response.user);
        }
      }));
  }


  /**
   *
   * @param token
   */
  storeTokenUser(token: any) {
    // On store le Token JWT renvoyé par l'API
    const today: Date = new Date();
    const expirationDate: Date = new Date();
    expirationDate.setDate(today.getDate() + 7);
    window.localStorage.setItem('user-token', JSON.stringify({
                                                               token: JSON.stringify(token),
                                                               expires: expirationDate
                                                             }));
    this.jwtInterceptorService.setJwtToken(token);
  }

  getStoredUser() {
    const auth = window.localStorage.getItem('rememberMe');
    return (auth) ? JSON.parse(auth) : false;
  }

  getTokenUser() {
    const userToken = window.localStorage.getItem('user-token');
    return (userToken) ? JSON.parse(userToken) : false;
  }

}
