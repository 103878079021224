import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { NavigationEnd, Router } from '@angular/router';

import { Observable, throwError, Subject } from 'rxjs';

import { catchError, finalize, map, tap } from 'rxjs/operators';

import { SearchParam } from '../../classes/search-param';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../common/loader.service';
import { ErrorService } from '../common/error.service';
import { SessionService } from '../common/session.service';

@Injectable({
              providedIn: 'root'
            })
export class HttpService {

  baseUrl: string = environment.restApiUrl;

  headers: HttpHeaders = new HttpHeaders();
  body: any;
  responseType: any;

  params: HttpParams;


  constructor(private http: HttpClient,
              public loaderService: LoaderService,
              public errorService: ErrorService) {
    this.headers = new HttpHeaders();
  }


  /** Surcharge de la methode GET pour passage des entêtes d'authentification
   *
   * @param path
   * @param searchParams
   * @param showLoader // afficher ou non le loader
   * @returns {any}
   */
  get<T>(path: string, searchParams: Array<SearchParam>, showLoader: boolean = true): Observable<any> {

    if (showLoader) {
      this.showLoader();
    }

    this.body = '';

    this.responseType = 'json';

    return this.http.get<T>(`${this.baseUrl}${path}`, this.buildRequestOptions(searchParams))
      .pipe(map(res => res),
            finalize(() => this.onEnd())
      );
  }

  /** GET FILE
   *
   * @param path
   * @param searchParams
   * @param showLoader
   * @returns {any}
   */
  getBlob(path: string, searchParams: Array<SearchParam>, showLoader: boolean = true) {


    if (showLoader) {
      this.showLoader();
    }

    this.body = '';

    this.responseType = 'blob';

    return this.http.get(`${this.baseUrl}${path}`, this.buildRequestOptions(searchParams))
      .pipe(map(res => res),
            finalize(() => this.onEnd())
      );
  }


  /** GEt sur API EXTERNE
   *
   * @param url
   * @param searchParams
   * @returns {any}
   */
  getExternal(url: string, searchParams: Array<SearchParam>, needAuthorization: boolean = false, authHeader?: []) {

    this.body = '';

    if (!needAuthorization) {
      this.headers = this.headers.append('No-Auth', 'true');
    }

    return this.http.get(url, this.buildRequestOptions(searchParams))
      .pipe(map(res => res),
            finalize(() => {
              this.headers = new HttpHeaders();
              this.onEnd();
            }))
      ;
  }

  /** Surcharge de la methode POST pour passage des entêtes d'authentification
   *
   * @param path
   * @param searchParams
   * @param showLoader
   * @returns {any}
   */
  post(path: string, body: any, showLoader: boolean = true): Observable<any> {

    if (showLoader) {
      this.showLoader();
    }

    this.body = body;

    this.responseType = 'json';

    return this.http.post(`${this.baseUrl}${path}`, body, this.buildRequestOptions())
      .pipe(map(res => res),
            finalize(() => this.onEnd())
      );
  }

  /**
   *
   * @param path
   * @param searchParams
   * @param showLoader
   * @returns {any}
   */
  put(path: string, body: any, showLoader: boolean = true): Observable<any> {

    if (showLoader) {
      this.showLoader();
    }

    this.body = body;

    this.responseType = 'json';

    return this.http.put(`${this.baseUrl}${path}`, body, this.buildRequestOptions())
      .pipe(map(res => res),
            finalize(() => this.onEnd())
      );
  }


  /**
   *
   * @param path
   * @param searchParams
   * @param showLoader
   * @returns {any}
   */
  patch(path: string, body: any, showLoader: boolean = true): Observable<any> {

    if (showLoader) {
      this.showLoader();
    }

    this.body = body;

    this.responseType = null;

    return this.http.patch(`${this.baseUrl}${path}`, body, this.buildRequestOptions())
      .pipe(map(res => res),
            finalize(() => this.onEnd())
      );
  }

  /**
   *
   * @param path
   * @param body
   * @param showLoader
   */
  delete(path: string, body: any, showLoader: boolean = true): Observable<any> {
    if (showLoader) {
      this.showLoader();
    }

    this.body = body;

    this.responseType = null;

    return this.http.delete(`${this.baseUrl}${path}`, this.buildRequestOptions())
      .pipe(tap((res) => res, (error: any) => {
              this.onError(error);
            }),
            finalize(() => this.onEnd())
      );
  }

  buildRequestOptions(searchParams?: Array<SearchParam>): any {

    this.params = new HttpParams();

    if (searchParams) {
      searchParams.filter(sp => (sp.key && sp.value)).forEach(sp => {
        if (sp.value instanceof Array) {
          sp.value.forEach(v => this.params = this.params.append(`${sp.key.toString()}[]`, v));
        } else {
          this.params = this.params.append(sp.key, sp.value);
        }
      });
    }
    return {headers: this.headers, params: this.params, responseType: this.responseType};
  }


  private onError(err: any): void {
    // TODO
    // this.errorService.handleExpiredOrInvalidSessionError(err);
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

}
