<nav mat-tab-nav-bar [ngClass]="{'minimized': !expanded, 'expanded': expanded}">
    <div class="w-100">
        <a mat-tab-link
           *ngFor="let link of navLinks"
           [routerLink]="[link.href]"
           routerLinkActive="active"
           #rla="routerLinkActive"
           [routerLinkActiveOptions]="{exact: false}"
           [active]="rla.isActive"
           (click)="trigger()"
           class="d-flex justify-content-start">
            <i class="{{link.icon}} splits-icon-l">{{link.caption}}</i>
            <span class="label" >{{link.label}}</span>
        </a>
    </div>
</nav>
