import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IndexService } from '../services/common/index.service';

@Injectable({
              providedIn: 'root'
            })
export class AuthGuardMaintenanceGuard implements CanActivate {

  constructor(
    private indexService: IndexService, private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.indexService.inMaintenance()) {
      this.router.navigate(['/pages/maintenance']);
      return false;
    } else {
      return true;
    }
  }

}
