import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';
import {BaseComponent} from '../../base.component';
import {TRAILER} from '../../../models/user.model';
import {DialogService} from '../../../services/common/dialog.service';
import {SearchService} from '../../../services/common/search.service';

@Component({

  selector: 'nav-primary-cmp',
  templateUrl: './nav-primary.component.html',
  styleUrls: ['./nav-primary.component.scss']
})

export class NavPrimaryComponent extends BaseComponent implements OnInit {
  menuOpened: boolean;
  searchMobileOpened: boolean;

  @Input() typerUser = 'trailer';

  constructor(private router: Router, protected userService: UserService,
              private dialogService: DialogService,
              private searchService: SearchService,
              private route: ActivatedRoute,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
    this.menuOpened = false;
    this.searchMobileOpened = false;

    this.searchService.searchMobileOpened.subscribe((state) => this.searchMobileOpened = state);
  }


  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {
  }

  home() {
    this.menuOpened = false;
    this.router.navigate(['/']);
  }

  dashboard() {
    this.menuOpened = false;
    this.router.navigate(['/', this.loggedInUser?.type_user, 'home']);
  }

  favourites() {
    this.menuOpened = false;
    this.router.navigate(['/events'], {queryParams: {scope: 'favourite'}});
  }

  profile() {
    this.menuOpened = false;
    if (this.loggedInUser.type_user === TRAILER) {
      this.router.navigate(['/trailer/profile/personnal-datas']);
    } else {
      this.router.navigate([this.loggedInUser.type_user, 'profile']);
    }
  }

  openMenu() {
    this.menuOpened = true;
  }

  openSearchMobile() {
    this.searchMobileOpened = true;
  }

  toggleMenu() {
    if (this.menuOpened === true) {
      this.menuOpened = false;
    } else {
      this.menuOpened = true;
    }
  }

  toggleMenuSearch() {
    if (this.searchMobileOpened === true) {
      this.searchMobileOpened = false;
    } else {
      this.searchMobileOpened = true;
    }
  }

  logout() {
    this.menuOpened = false;
    this.userService.logout();
    this.router.navigate(['/', this.typerUser]);
  }

  signIn() {
    this.dialogService.openSignInPopup(this.typerUser, 0);
    this.menuOpened = false;
  }

  register() {
    this.dialogService.openSignInPopup(this.typerUser, 1);
    this.menuOpened = false;
  }

}
