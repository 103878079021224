import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {UserModel} from '../../../models/user.model';
import {UserService} from '../../../services/user/user.service';
import {BaseComponent} from '../../base.component';
import {RaceOffModel} from '../../../models/race-off.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-race-off-list',
  templateUrl: './race-off-list.component.html',
  styleUrls: ['./race-off-list.component.scss']
})
export class RaceOffListComponent extends BaseComponent implements OnInit {
  user: UserModel;

  hasError = false;

  @Input() racesOff: Array<RaceOffModel> = [];

  // #PAGINATION #
  page = 0;
  @Input() pageSize = 5;

  @Output() enterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() leaveEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(protected userService: UserService,
              public router: Router,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {
    this.user = this.loggedInUser;
  }


  mouseEnter(itemId: number) {
    setTimeout(() => {
      this.enterEvent.emit(itemId);
    }, 300);
  }

  mouseLeave(itemId: number) {
    setTimeout(() => {
      this.leaveEvent.emit(itemId);
    }, 300);
  }

  showRace(raceOff) {
    console.log(raceOff);
    this.router.navigate(['/trailer/races-off',raceOff.id,'show']);
  }

  handlePageChange(pageChange) {
    this.pageSize = pageChange.pageSize;
    this.page = pageChange.pageIndex;
  }
}
