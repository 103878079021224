import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'confirm-dialog-cmp',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public title: string;
  public message: string;
  public extraAction: string;
  public extraMessage: string;
  public noLabel: string;
  public yesLabel: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }


}
