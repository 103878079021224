<!--<div class="upload-box-uploader-wrap  align-self-center w-100">-->
<!--  <div (uploadOutput)="onUploadOutput($event)" [ngClass]="{'file-over': dragOver}" [options]="options"-->
<!--       [uploadInput]="uploadInput"-->
<!--       class="d-flex upload-zone align-items-center justify-content-center" ngFileDrop>-->
<!--    <mat-icon aria-hidden="false" aria-label="Déposer un fichier" class="upload-icon mat-icon-lg">cloud_upload-->
<!--    </mat-icon>-->
<!--    <div class="drop-label" style="font-size: 24px">Déposez un fichier gpx ici ou</div>-->
<!--    <button type="button" mat-raised-button-->
<!--            color="accent" class="rounded-button"> <span>Choisissez un fichier (.gpx)</span></button>-->
<!--    <input (uploadOutput)="onUploadOutput($event)" [options]="options" [uploadInput]="uploadInput"-->
<!--           accept=".gpx"-->
<!--           ngFileSelect-->
<!--           type="file"-->
<!--           [multiple]="multiple">-->
<!--  </div>-->
<!--</div>-->

<div class="col-xs-12 text-center">
    <div
            class="d-flex upload-zone align-items-center justify-content-center"
            [ngClass]="{'file-over': dragOver}"
            ngFileDrop
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            [ngClass]="{ 'is-drop-over': dragOver }"
    >
        <div class="upload-box-uploader-wrap d-flex flex-column align-items-center">
            <div class="drop-label">Glissez/Déposez un fichier gpx ici <br>ou</div>
            <button class="d-flex align-items-center _sp_btn _white _small" i18n style="position: relative">
                <i class="fa-solid fa-file-import"></i><span>Sélectionner un GPX</span>
                <input (uploadOutput)="onUploadOutput($event)" [options]="options" [uploadInput]="uploadInput"
                       accept=".gpx"
                       ngFileSelect
                       type="file"
                       multiple="false">
            </button>
        </div>

    </div>
</div>



<div class="upload-items-list-wrap" [hidden]="!showItemList || files.length == 0 ">
  <div class="upload-item" *ngFor="let f of files; let i = index;">
    <!--<i appIconFile [mimeType]="f.type" class="la upload-item-icon"></i>-->
    <div class="upload-item-content">
      <div class="d-inline-flex align-items-center filename">
        <div class="filename-label">
          <span>{{f.name | truncate : ['100', '...'] }}</span>
        </div>
        <div class="d-flex filename-remove" (click)="removeFile(f.id)">
          <mat-icon aria-hidden="false" aria-label="remove file">close</mat-icon>
        </div>

      </div>
      <div class="progress-content">
        <mat-progress-bar mode="determinate" [value]="f?.progress?.data?.percentage"></mat-progress-bar>
      </div>
      <div class="progress-text-content">
                <span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100"
                      [class.atipik-error]="f.responseStatus && f.responseStatus !== 201">
                    <span>{{ f.progress?.data?.percentage }}% </span>
                    <span *ngIf="f.progress?.data?.percentage === 0 && !f.responseStatus"
                          i18n="@@uploader.upload.pending">En attente...</span>
                    <span
                      *ngIf="f.progress?.data?.percentage > 0 && f.progress?.data?.percentage !== 100 && !f.responseStatus"
                      i18n="@@uploader.sending">Envoi...</span>
                    <span *ngIf="f.progress?.data?.percentage === 100 && !f.responseStatus"
                          i18n="@@uploader.upload.processing">Traitement...</span>
                    <span *ngIf="f.progress?.data?.percentage === 100 && f.responseStatus === 201 "
                          i18n="@@uploader.upload.done">Terminé</span>
                    <span *ngIf="f.responseStatus && f.responseStatus !== 201" i18n="@@uploader.upload.error">Erreur lors de l'envoi...</span>
                </span>
      </div>
    </div>
  </div>
</div>
