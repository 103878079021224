<div class="race-list-items-wrap py-3">

  <app-race-off-list-item *ngFor="let currentRaceOff of racesOff | slice:pageSize*(page):pageSize*(page+1); let i=index"
                          [raceOff]="currentRaceOff"
                          (click)="showRace(currentRaceOff);"
                          [id]="i"  (mouseenter)="mouseEnter(currentRaceOff.id)"
                          (mouseleave)="mouseLeave(currentRaceOff.id)"></app-race-off-list-item>

  <div class="pagination-wrap ">
    <paginator [length]="racesOff?.length" [pageSize]=pageSize (pageChanged)="handlePageChange($event)"></paginator>
  </div>
</div>

