import { Component, OnInit } from '@angular/core';
import { MatDialog  } from '@angular/material/dialog';
import { DialogService } from '../../services/common/dialog.service';
import { ORGANIZER, TRAILER } from '../../models/user.model';

@Component({

             selector: 'footer-cmp',
             templateUrl: './footer.component.html',
             styleUrls: ['./footer.component.scss']
           })
export class FooterComponent implements OnInit {

  constructor(public dialogService: DialogService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  registerTrailer() {
    this.dialogService.openSignInPopup(TRAILER, 1);
  }

  registerOrga() {
    this.dialogService.openSignInPopup(ORGANIZER, 1);
  }

}
