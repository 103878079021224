import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SearchService } from "../../../services/common/search.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { DatetimeService } from "../../../services/common/datetime.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Moment } from "moment";
import { Subscription } from "rxjs";
import { GoogleMapService } from "../../../services/common/google-map.service";

@Component({
  selector: "app-search-box-mobile",
  templateUrl: "./search-box-mobile.component.html",
  styleUrls: ["./search-box-mobile.component.scss"],
})
export class SearchBoxMobileComponent implements OnInit {
  searchRaceForm: UntypedFormGroup;

  range: UntypedFormGroup;

  nameCtrl: UntypedFormControl;
  locationCtrl: UntypedFormControl;
  startCtrl: UntypedFormControl;
  dateSearchStartCtrl: UntypedFormControl;
  endCtrl: UntypedFormControl;
  dateSearchEndCtrl: UntypedFormControl;
  latCtrl: UntypedFormControl;
  lonCtrl: UntypedFormControl;
  localityCtrl: UntypedFormControl;
  postalCodeCtrl: UntypedFormControl;
  countryCtrl: UntypedFormControl;
  administrativeAreaLevel2Ctrl: UntypedFormControl;
  administrativeAreaLevel1Ctrl: UntypedFormControl;

  today: Date = new Date();

  @Output() closeSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(HTMLInputElement) locationAutocomplete!: HTMLInputElement;
  public subPlaceChange!: Subscription;

  private scope: string[] = ["event"];

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private googleMapService: GoogleMapService
  ) {
    this.nameCtrl = new UntypedFormControl("");
    this.locationCtrl = new UntypedFormControl("");
    this.startCtrl = new UntypedFormControl("");
    this.dateSearchStartCtrl = new UntypedFormControl("");
    this.endCtrl = new UntypedFormControl("");
    this.dateSearchEndCtrl = new UntypedFormControl("");
    this.latCtrl = new UntypedFormControl("");
    this.lonCtrl = new UntypedFormControl("");
    this.localityCtrl = new UntypedFormControl("");
    this.postalCodeCtrl = new UntypedFormControl("");
    this.countryCtrl = new UntypedFormControl("");
    this.administrativeAreaLevel2Ctrl = new UntypedFormControl("");
    this.administrativeAreaLevel1Ctrl = new UntypedFormControl("");

    this.range = new UntypedFormGroup({
      start: this.startCtrl,
      end: this.endCtrl,
    });

    this.searchRaceForm = fb.group({
      name: this.nameCtrl,
      location: this.locationCtrl,
      latitude: this.latCtrl,
      longitude: this.lonCtrl,
      locality: this.localityCtrl,
      postalCode: this.postalCodeCtrl,
      country: this.countryCtrl,
      administrativeAreaLevel2: this.administrativeAreaLevel2Ctrl,
      administrativeAreaLevel1: this.administrativeAreaLevel1Ctrl,
      dateSearchStart: this.dateSearchStartCtrl,
      dateSearchEnd: this.dateSearchEndCtrl,
    });
  }
  ngOnInit(): void {
    this.handleLocationChanges();
  }

  private handleLocationChanges() {
    this.subPlaceChange = this.googleMapService.placeObservable
      .pipe(
        filter(
          (res) =>
            res.refererComponent === "full" || res.refererComponent === "lite"
        ),
        map((res) => res.place),
        tap((place) => {
          this.locationCtrl.setValue(
            this.googleMapService.getFormattedAddress(place)
          );
          this.latCtrl.setValue(this.googleMapService.getLatitude(place));
          this.lonCtrl.setValue(this.googleMapService.getLongitude(place));
          this.localityCtrl.setValue(this.googleMapService.getCity(place));
          this.postalCodeCtrl.setValue(
            this.googleMapService.getPostCode(place)
          );
          this.countryCtrl.setValue(this.googleMapService.getCountry(place));
          this.administrativeAreaLevel1Ctrl.setValue(
            this.googleMapService.getState(place)
          );
          this.administrativeAreaLevel2Ctrl.setValue(
            this.googleMapService.getDistrict(place)
          );
        })
      )
      .subscribe();
  }

  searchRace(): void {
    this.closeSearch.emit(true);
    this.router.navigate(["events"], {
      queryParams: this.searchRaceForm.value,
    });
  }

  resetLocationCtrl() {
    this.localityCtrl.reset();
    this.countryCtrl.reset();
    this.postalCodeCtrl.reset();
    this.administrativeAreaLevel2Ctrl.reset();
    this.administrativeAreaLevel1Ctrl.reset();
  }
}
