import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';

import {AppSplitsComponent} from './app-splits.component';
import {FooterComponent} from './components/footer/footer.component';
import {IndexOrganizerComponent} from './components/index-base/index-organizer/index-organizer.component';
import {IndexTrailerComponent} from './components/index-base/index-trailer/index-trailer.component';
import {IndexBaseComponent} from './components/index-base/index-base.component';

import {ROUTES} from './app-splits.routing.module';
import {NavPrimaryComponent} from './components/nav/nav-primary/nav-primary.component';
import {OverlayComponent} from './components/overlay/overlay.component';
import {CkEditorConfigPipe} from './pipes/ckeditor-config.pipe';
import {JwtInterceptorService} from './services/http/jwt-interceptor.service';

// set local to fr
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {environment} from '../environments/environment';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {SharedModule} from './shared/shared.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

import {MainSpinnerComponent} from './components/spinners/main-spinner/main-spinner.component';
import {LandingTrailerComponent} from './components/landing/landing-trailer/landing-trailer.component';
import {LandingOrgaComponent} from './components/landing/landing-orga/landing-orga.component';
import {EventCardComponent} from './components/events/event-card/event-card.component';
import {EventDirectoryComponent} from './components/events/event-directory/event-directory.component';
import {EventDirectoryItemComponent} from './components/events/event-directory-item/event-directory-item.component';
import {EventShowComponent} from './components/events/event-show/event-show.component';
import {EventsComponent} from './components/events/events.component';
import {RaceShowComponent} from './components/races/race-show/race-show.component';
import {SwiperModule} from 'swiper/angular';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {DialogService} from './services/common/dialog.service';
import {SnackbarService} from './services/notifications/snackbar.service';
import {SpinnerService} from './services/common/spinner.service';
import {ErrorService} from './services/common/error.service';
import {OverlayService} from './services/common/overlay.service';
import {LoaderService} from './services/common/loader.service';
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';
import {LandingRaceOffComponent} from './components/landing/landing-race-off/landing-race-off.component';


registerLocaleData(localeFr, 'fr');


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    HammerModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    SwiperModule,
    SocialLoginModule,
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    }),
    SharedModule
  ],
  declarations: [
    AppSplitsComponent,
    MainSpinnerComponent,
    IndexOrganizerComponent,
    IndexTrailerComponent,
    IndexBaseComponent,
    FooterComponent,
    NavPrimaryComponent,
    OverlayComponent,
    CkEditorConfigPipe,
    LandingTrailerComponent,
    LandingOrgaComponent,
    LandingRaceOffComponent,
    EventsComponent,
    EventCardComponent,
    EventDirectoryComponent,
    EventDirectoryItemComponent,
    EventShowComponent,
    RaceShowComponent,
    FooterComponent
  ],
  bootstrap: [AppSplitsComponent],
  providers: [
    DialogService,
    SnackbarService,
    SpinnerService,
    ErrorService,
    OverlayService,
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useExisting: JwtInterceptorService, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookApiId)
          }
        ]
      } as SocialAuthServiceConfig
    }
  ]
})
export class AppSplitsModule {
}
