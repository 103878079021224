<h3>Ajout d’un évènement en tant qu’organisateur</h3>
<mat-horizontal-stepper
  #stepper
  class="mx-auto _orga_stepper"
  labelPosition="bottom"
  linear="true"
  [selectedIndex]="defaultSelectedIndex"
>
  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
  <mat-step [stepControl]="registerOrganizerForm" state="event-orga">
    <form [formGroup]="registerOrganizerForm" (ngSubmit)="next()">
      <h4 i8n>A propos de vous et votre organisation</h4>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n
              >Nom de l'organisation (Association, entreprise, ...) *</label
            >
            <input matInput type="text" formControlName="organizm" required />
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="form-group">
          <div class="d-flex">
            <div class="col-6 pl-0">
              <div class="form-group __input">
                <mat-form-field class="_wrap_input">
                  <label i18n>Prénom *</label>
                  <input
                    matInput
                    type="text"
                    formControlName="firstname"
                    required
                  />
                  <mat-error>Champ obligatoire</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-6 pr-0">
              <div class="form-group __input">
                <mat-form-field class="_wrap_input">
                  <label i18n>Nom *</label>
                  <input
                    matInput
                    type="text"
                    formControlName="lastname"
                    required
                  />
                  <mat-error>Champ obligatoire</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n>Site web de l'organisation</label>
            <input
              matInput
              type="text"
              formControlName="organizmWebsite"
              #organizmWebsite
            />
            <mat-error *ngIf="organizmWebsiteCtrl.hasError('validateUrl')"
              >Veuillez saisir une url valide
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group __checkbox">
        <label i18n>Choisir mon avatar</label>
        <div
          *ngIf="avatars; else skeletonLoaderAvatars"
          class="avatars-wrap d-flex flex-wrap align-items-end justify-content-start ml-2 my-2 __checkbox_icon"
        >
          <div
            *ngFor="let avatar of avatars; let i = index"
            class="avatar-item d-flex justify-content-center"
          >
            <ng-container>
              <div
                class="_contain_card d-flex align-items-center"
                [ngClass]="{ __checked: radioAvatar.checked }"
              >
                <div class="avatar-icon-wrap _md">
                  <input
                    type="radio"
                    [id]="'avatar-' + avatar.id"
                    [value]="avatar"
                    formControlName="avatar"
                    #radioAvatar
                    class="d-none"
                  />
                  <label [for]="'avatar-' + avatar.id">
                    <div
                      role="button"
                      [ngClass]="[
                        'avatar-icon __icon _bg-size-90',
                        '_icon-avatar-' + avatar.code
                      ]"
                    ></div>
                  </label>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <ng-template #skeletonLoaderAvatars>
          <ngx-skeleton-loader
            count="3"
            appearance="circle"
            [theme]="{ width: '100px', height: '100px', margin: '0 0.5rem' }"
          ></ngx-skeleton-loader>
        </ng-template>
      </div>

      <div class="col-12 text-center">
        <button class="_sp_btn _blue _inline" mat-button matStepperNext>
          <span>Continuer</span>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="eventForm" state="event-name">
    <form [formGroup]="eventForm" (ngSubmit)="next()">
      <h4 i8n>Quel est le nom de votre événement ?</h4>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n>Nom de l'événement (UTMB, SaintéLyon, ...)*</label>
            <input matInput type="text" formControlName="name" required />
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="form-group __select">
          <mat-form-field style="width: 100%" class="_wrap_input _small">
            <label i18n>Surface *</label>
            <mat-select formControlName="terrain" class="__chp_select" required>
              <mat-option value>--</mat-option>
              <mat-option *ngFor="let terrain of terrains" [value]="terrain">
                {{ terrain.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="terrainCtrl.hasError('required')" i18n>
              Champ obligatoire
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n>Site web de l'événement</label>
            <input
              matInput
              type="text"
              formControlName="website"
              #eventWebsite
            />
            <mat-error *ngIf="eventWebsiteCtrl.hasError('validateUrl')"
              >Veuillez saisir une url valide
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--<mat-error *ngIf="eventWebsiteCtrl.hasError('required')">Champ obligatoire</mat-error>-->

      <div class="col-12 text-center">
        <button
          class="_sp_btn _white_black _inline"
          mat-button
          matStepperPrevious
        >
          Retour
        </button>
        <button class="_sp_btn _blue _inline" mat-button matStepperNext>
          Continuer
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="eventDateForm" state="event-date-location">
    <form [formGroup]="eventDateForm" (ngSubmit)="completeEvent()">
      <h4 i8n>Date et lieu de votre événement</h4>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n>Année de la prochaine édition *</label>
            <input
              #edition
              formControlName="edition"
              matInput
              min="2022"
              pattern="\d*"
              step="1"
              type="number"
            />
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input _icon">
            <label i18n>Date de début de la course *</label>
            <div class="__icon_input">
              <input
                [matDatepicker]="dateBeginDatepicker"
                formControlName="dateBegin"
                #datbegin
                matInput
                (focus)="dateBeginDatepicker.open()"
                (click)="dateBeginDatepicker.open()"
                readonly
                required
              />
              <mat-datepicker-toggle
                [for]="dateBeginDatepicker"
                matSuffix
              ></mat-datepicker-toggle>
            </div>
            <mat-datepicker #dateBeginDatepicker></mat-datepicker>
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input _icon">
            <label i18n>Date de fin de la course *</label>
            <div class="__icon_input">
              <input
                [min]="dateBeginCtrl.value"
                [matDatepicker]="dateEndDatepicker"
                matInput
                formControlName="dateEnd"
                (focus)="dateEndDatepicker.open()"
                (click)="dateEndDatepicker.open()"
                readonly
                #dateend
                required
              />
              <mat-datepicker-toggle
                [for]="dateEndDatepicker"
                matSuffix
              ></mat-datepicker-toggle>
            </div>
            <mat-error>Champ obligatoire</mat-error>
            <mat-datepicker #dateEndDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="form-group __input">
          <mat-form-field class="_wrap_input">
            <label i18n>Lieu de départ *</label>
            <input
              matInput
              type="text"
              formControlName="name"
              name="autocomplete"
              id="autocomplete"
              #autocompleteLocation
              required
            />
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 text-center">
        <button
          class="_sp_btn _white_black _inline"
          mat-button
          matStepperPrevious
        >
          Retour
        </button>
        <button class="_sp_btn _blue _inline" type="submit" mat-button>
          Créer l'événement
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step state="event-done">
    <h4 class="text-success" i8n>Bravo! Votre évènement a été ajouté.</h4>
    <p>
      On se retrouve dans votre espace organisateur pour compléter l’évènement
      et ajouter des courses !
    </p>
    <div class="col-12 text-center">
      <button
        type="button"
        mat-button
        class="_sp_btn _blue _inline _full"
        i18n
        matTooltip="Allez"
        matTooltipShowDelay="1000"
        aria-label="Bouton Allez"
        (click)="enter()"
        matDialogClose
      >
        <span>Je découvre mon espace !</span>
      </button>
    </div>
  </mat-step>
  <ng-template matStepperIcon="event-orga">
    <mat-icon></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="event-name">
    <mat-icon></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="event-date-location">
    <mat-icon></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="event-done">
    <mat-icon></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <mat-icon></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="done"></ng-template>
</mat-horizontal-stepper>
