import { ChallengeModel } from '../models/challenge.model';
import { TerrainModel } from '../models/terrain.model';
import { DisciplineModel } from '../models/discipline.model';
import { SearchElementCriterias } from './searchElementCriterias';
import { UserModel } from '../models/user.model';

export class SearchRaceCriterias extends SearchElementCriterias {

  raceName: string;
  dateSearchStart: Date;
  dateSearchEnd: Date;
  dateSearchStartTimestamp: number;
  dateSearchEndTimestamp: number;

  distanceRange: [];
  ascendingElevationRange: [];

  proximity: number;
  price: number;

  latitude: number;
  longitude: number;
  formattedAddress: string;
  postalCode: string;
  locality: string;
  country: string;
  administrativeAreaLevel1: string; // département
  administrativeAreaLevel2: string; // région

  challenge: ChallengeModel;
  challengeId: number;
  terrain: TerrainModel;
  terrainId: number;
  discipline: DisciplineModel;
  disciplineId: number;

  maxSeats: number;
  hasTimeLimit: boolean;
  hasUtmbPoint: boolean;
  isNightRace: boolean;
  arePolesProhibited: boolean;

  status: string[]; // plusieurs statuts de recherche parmis 'draft'|'published'|'archived'|'canceled';

  // Recherche par event
  eventId: string;
  eventName: string;
  // eventStatus:'draft'|'published'|'archived'|'canceled';
  eventStatus: string[]; // plusieurs statuts de recherche parmis 'draft'|'published'|'archived'|'canceled';
  newest: boolean; // ask for most recent races created


  constructor(searcher: UserModel = null) {

    super();

    // Pour les trailers, par défaut les recherches se font sur les événements publiés
    if (searcher && searcher.type_user === 'trailer') {
      this.eventStatus = ['published', 'archived'];
      this.status = ['published', 'archived', 'canceled'];
    }
  }

}
