import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {


    @Input() length: number;
    @Input() pageSize;
    @Input() pageSizeOptions = [5, 10, 25, 100];

    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    changePage(pageNav: any) {
        this.pageChanged.emit(pageNav);
    }
}
