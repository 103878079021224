<div class="content-wrap">
  <div matDialogTitle class="dialog-title">{{ title }}</div>
  <mat-dialog-content>
    <p *ngIf="extraMessage" class="dialog-message" [innerHtml]="extraMessage"></p>
    <p class="dialog-message" [innerHtml]="message"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-end" style="width: 100%">
      <button type="submit" mat-button (click)="dialogRef.close('confirm')"  color="accent">{{ yesLabel || "Confirmer"}}</button>
    </div>
  </mat-dialog-actions>
</div>
