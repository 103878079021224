<mat-dialog-content class="poi-dialog-content p-0">
  <div class="content-wrap">
    <div class="no-gutters _contain_poi">
      <div class="col-12 p-0 d-flex" id="pois-datas-wrap">
        <div class="_left_subsection d-flex flex-wrap p-3 col-12 flex-fill">
          <div class="subsection col-12 px-3">
            <span class="_since">Depuis le départ</span>
            <div
              class="d-flex justify-content-between subsection-content align-items-center"
            >
              <div class="text-center">
                <i class="icon-splits_distance splits-icon-md"></i>
                <span>{{ workingPoi?.distance | number : "1.1-1" }} km</span>
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span
                  >+
                  {{
                    workingPoi?.cumulativePositiveElevation | number : "1.0-0"
                  }}
                  mD+</span
                >
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span
                  >-
                  {{
                    workingPoi?.cumulativeNegativeElevation | number : "1.0-0"
                  }}
                  mD-</span
                >
              </div>
            </div>
          </div>
          <div *ngIf="workingPoi?.previousPoiName" class="col-12 subsection">
            <span class="_since">Depuis {{ workingPoi?.previousPoiName }}</span>
            <div
              class="d-flex justify-content-between subsection-content align-items-center"
            >
              <div class="text-center">
                <i class="icon-splits_distance splits-icon-md"></i>
                <span
                  >{{
                    workingPoi?.distanceInBetween | number : "1.1-1"
                  }}
                  km</span
                >
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span
                  >+
                  {{
                    workingPoi?.cumulativePositiveElevationInBetween
                      | number : "1.0-0"
                  }}
                  mD+</span
                >
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span
                  >-
                  {{
                    workingPoi?.cumulativeNegativeElevationInBetween
                      | number : "1.0-0"
                  }}
                  mD-</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 _content_poi" id="poi-form-wrap">
        <form (ngSubmit)="savePoi()" [formGroup]="poiForm">
          <a (click)="close()" class="close" aria-label="Close"
            ><i class="icon-splits_clear splits-icon-md"></i
          ></a>
          <h3 class="h3 _middle">Modifier un point d'intêret</h3>
          <div class="row">
            <div class="col-12">
              <div class="form-group __input">
                <mat-form-field class="_wrap_input">
                  <label i18n>Nom du point *</label>
                  <input
                    maxlength="40"
                    formControlName="name"
                    matInput
                    required
                    type="text"
                  />
                  <mat-hint align="end"
                    >{{ nameCtrl.value.length }} / 40</mat-hint
                  >
                  <mat-error *ngIf="nameCtrl.hasError('required')">
                    Champ obligatoire
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group __input">
                <mat-form-field class="_wrap_input">
                  <label i18n>Kilométrage (km) *</label>
                  <input
                    matInput
                    formControlName="distance"
                    required
                    step="1"
                    min="0"
                    [max]="maxDistance"
                    type="number"
                  />
                  <mat-error *ngIf="distanceCtrl.hasError('required')">
                    Champ obligatoire
                  </mat-error>
                  <mat-error
                    *ngIf="
                      distanceCtrl.dirty && distanceCtrl.hasError('pattern')
                    "
                  >
                    Valeur positive attendue
                  </mat-error>
                  <mat-error
                    *ngIf="distanceCtrl.dirty && distanceCtrl.hasError('max')"
                  >
                    La distance entrée est hors parcours
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group __checkbox">
                <label i18n>Type de point </label>
                <div
                  *ngIf="poiTypesLoaded; else skeletonLoaderPoiTypes"
                  class="poi-types-wrap d-flex flex-wrap align-items-end justify-content-start my-2 __checkbox_icon"
                >
                  <div
                    *ngFor="let poiType of poiTypes; let i = index"
                    class="poi-type-item d-flex justify-content-center"
                  >
                    <ng-container>
                      <div
                        class="_contain_card d-flex align-items-center"
                        [ngClass]="{ __checked: checkboxPoiType.checked }"
                      >
                        <div class="poi-type-icon-wrap _sm">
                          <input
                            type="checkbox"
                            [id]="'poi-type-' + poiType.id"
                            [value]="poiType.id"
                            [checked]="poiTypeIsChecked(poiType.id)"
                            (change)="onPoiTypeSelectedChange($event)"
                            #checkboxPoiType
                            class="d-none"
                          />
                          <label [for]="'poi-type-' + poiType.id">
                            <div
                              role="button"
                              [ngClass]="[
                                'poi-type-icon __icon _bg-size-90',
                                '_icon-poi-' + poiType.code
                              ]"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <!--            <div class="col-md-4" *ngIf="workingPoi?.poiType?.id==1">-->
                <!--              <div class="form-group __input">-->
                <!--                <mat-form-field class="_wrap_input">-->
                <!--                  <label i18n>Heure</label>-->
                <!--                  <input name="time_limit" id="time_limit" [(ngModel)]="workingPoi.timeLimit" matInput type="time">-->
                <!--                </mat-form-field>-->
                <!--              </div>-->
                <!--            </div>-->
                <div class="row" *ngIf="displayTimeLimit">
                  <div class="col-12">
                    <div class="form-group __input">
                      <mat-form-field class="_wrap_input">
                        <label i18n>Heure limite de passage</label>
                        <input
                          formControlName="timeLimit"
                          matInput
                          type="time"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #skeletonLoaderPoiTypes>
                <ngx-skeleton-loader
                  count="5"
                  appearance="circle"
                  [theme]="{ width: '60px', height: '60px', margin: '0.5rem' }"
                ></ngx-skeleton-loader>
              </ng-template>
            </div>
          </div>

          <mat-dialog-actions>
            <div class="d-flex justify-content-end" style="width: 100%">
              <button matDialogClose class="_sp_btn _white" type="button">
                Annuler
              </button>
              <button class="_sp_btn _blue" type="button" (click)="deletePoi()">
                Supprimer
              </button>
              <button
                type="submit"
                class="_sp_btn _blue"
                [disabled]="!poiForm.valid || distanceCtrl.errors !== null"
                color="accent"
              >
                Modifier
              </button>
            </div>
          </mat-dialog-actions>
        </form>

        <div class="">{{ workingPoi.estimatedAveragePace }}</div>
      </div>
    </div>
  </div>
</mat-dialog-content>
