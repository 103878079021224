import { EventModel } from "./event.model";
import { AddressModel } from "./address.model";
import { ChallengeModel } from "./challenge.model";
import { DisciplineModel } from "./discipline.model";
import { RaceTagModel } from "./race-tag.model";
import { RaceBaseModel } from "./race-base-model";

/**
 * Created by macbookaxel on 10/06/2016.
 */
export class RaceModel extends RaceBaseModel {
  id: number;

  deadlineRegistration: Date;
  dateBeginRegistration: Date;
  utmbIndex: number;
  runningStones: number;
  suppliesCount: number;
  price: number;
  proximity: number;
  address: AddressModel;
  event: EventModel;
  eventId: number;
  challenge: ChallengeModel = null;
  challengeId: number;
  discipline: DisciplineModel = null;
  disciplineId: number;
  tags?: Array<RaceTagModel>;

  maxSeats: number;
  hasTimeLimit: boolean;
  isNightRace: boolean;
  arePolesProhibited: boolean;

  isGpxVisible: boolean;

  isFallbackCourse: boolean;
  parentRaceRefId: number;

  public constructor(init?: Partial<RaceModel>) {
    super(init);
    Object.assign(this, init);
  }
}
