<div class="container-fluid">
  <button mat-button navigateBack class="fixed-top-left-container">
    <i class="icon-splits_back splits-icon-md"></i>
    Retour
  </button>

  <h1>Comment ca marche</h1>

  <div class="d-flex flex-column">
    <!--<img src="/assets/img/app/roadbook/old/dbdb_profile_rb_thumb.png" alt="">-->
    <h2>Faire une demande de référencement</h2>
    <p>
      Vous organisez un évènement trail prochainement ? <br> Faites une demande de référencement auprès de Splits et accédez à votre "Espace Organisateur" dédié.
    </p>
    <iframe frameborder="0" src="https://player.vimeo.com/video/260741511"width="640" height="360"></iframe>
  </div>

</div>
