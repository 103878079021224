import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IndexService } from '../../../services/common/index.service';
import { TRAILER } from '../../../models/user.model';
import { INDEX_BACKGROUND_TRAILER_GALLERY, MediaModel } from '../../../models/media.model';
import { SearchParam } from '../../../classes/search-param';
import { MediaService } from '../../../services/media/media.service';
import { environment } from '../../../../environments/environment';
import { NavbarState } from '../../../classes/OverlayState';
import { ToolbarService } from '../../../services/common/toolbar.service';
import { ShowHideState } from '../../../classes/LoaderState';
import { Subscription } from 'rxjs';

@Component({
             selector: 'app-index-trailer',
             templateUrl: './index-trailer.component.html',
             styleUrls: ['../index-base.component.scss']
           })
export class IndexTrailerComponent implements OnInit, AfterViewInit {

  hasSocialSignIn = true;
  typeUserToLogin: string;

  showRegisterTab = false;

  searchParams: Array<SearchParam>;

  constructor(private indexService: IndexService,
              private mediaService: MediaService,
              private router: Router) {
  }

  ngOnInit() {
    this.typeUserToLogin = TRAILER;

    // this.searchParams = [];
    // this.searchParams.push(new SearchParam('galleryId', INDEX_BACKGROUND_TRAILER_GALLERY));
    // this.searchParams.push(new SearchParam('count', 1));
    //
    // this.mediaService.getRandomMedias(this.searchParams).subscribe((randomMedias: Array<MediaModel>) => {
    //
    //     const randomMedia: string = (randomMedias && randomMedias.length > 0) ?
    //     environment.webrootApi + environment.mediaDistPath + randomMedias[0].media
    //         : environment.backgroundPath + 'default_background.jpg';
    //
    //     this.mediaService.backgroundIndexMedia.next(randomMedia);
    //
    // });

  }

  ngAfterViewInit() {
    this.indexService.indexChange.next(TRAILER);
  }

  changedTab(tabIndex: any) {
    if (this.showRegisterTab === false && tabIndex.index === 1) {
      this.showRegisterTab = true;
    }
  }


  home() {
    this.router.navigate(['/']);
  }

}
