import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RaceModel } from '../../../models/race.model';
import { BaseComponent } from '../../base.component';
import { UserService } from '../../../services/user/user.service';

import { animate, query, style, transition, trigger } from '@angular/animations';
import { UserModel } from '../../../models/user.model';

@Component({
             selector: 'app-race-orga-list',
             templateUrl: './race-orga-list.component.html',
             styleUrls: ['./race-orga-list.component.scss']
           })
export class RaceOrgaListComponent extends BaseComponent implements OnInit{

  user: UserModel;

  hasError = false;

  @Input() races: Array<RaceModel> = [];

  // #PAGINATION #
  page = 0;
  @Input() pageSize = 5;
  @Input() canModify: boolean;

  @Output() enterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() leaveEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(protected userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {
    this.user = this.loggedInUser;
  }

  showRace(race) {
    this.router.navigate([this.user.type_user, 'races', 'show', race.id]);
  }

  mouseEnter(itemId: number) {
    setTimeout(() => {
      this.enterEvent.emit(itemId);
    }, 300);
  }

  mouseLeave(itemId: number) {
    setTimeout(() => {
      this.leaveEvent.emit(itemId);
    }, 300);
  }

  handlePageChange(pageChange) {
    this.pageSize = pageChange.pageSize;
    this.page = pageChange.pageIndex;
  }
}
