import { Component, Input, OnInit } from '@angular/core';
import { TrailerModel } from '../../../models/trailer.model';
import { OrganizerModel } from '../../../models/organizer.model';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { TRAILER } from '../../../models/user.model';

@Component({
             selector: 'app-account-nav-menu',
             templateUrl: './account-nav-menu.component.html',
             styleUrls: ['./account-nav-menu.component.scss']
           })
export class AccountNavMenuComponent implements OnInit {

  @Input() user: TrailerModel | OrganizerModel;

  constructor(private router: Router, private userService: UserService) {
  }

  ngOnInit(): void {
  }

  home() {
    this.router.navigate([this.user.type_user, 'home']);
  }

  myProfile() {
    if (this.user.type_user === TRAILER) {
      this.router.navigate(['/trailer/profile/personnal-datas']);
    } else {
      this.router.navigate([this.user.type_user, 'profile']);
    }
  }

  goToMyFavourites() {
    this.router.navigate(['/events'], {queryParams: {scope: 'favourite'}});
  }

  logout() {
    this.userService.logout();
    this.router.navigate([this.user.type_user]);
  }


}
