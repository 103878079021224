<div class="progress-spinner-wrap">

    <mat-progress-spinner class="track"
                          diameter="80"
                          color="primary"
                          mode="determinate" value="100">
    </mat-progress-spinner>
    <span class="estimation-progress-value">{{percent}}%</span>
    <mat-progress-spinner
      color="primary"
      mode="determinate"
      diameter="80"
      [value]="percent">
    </mat-progress-spinner>
</div>

