<div class="race-list-items-wrap py-3">
  <race-list-item *ngFor="let currentRace of races | slice:pageSize*(page):pageSize*(page+1); let i=index"
                  [race]="currentRace"
                  [id]="i" (click)="showRace(currentRace);" (mouseenter)="mouseEnter(currentRace.id)"
                  (mouseleave)="mouseLeave(currentRace.id)"
  ></race-list-item>

  <div class="pagination-wrap ">
    <paginator [length]="races?.length" [pageSize]=pageSize (pageChanged)="handlePageChange($event)"></paginator>
  </div>
</div>

