import {
  ApplicationRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { EventModel } from "../../../models/event.model";
import { OrganizerModel } from "../../../models/organizer.model";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { CommonService } from "../../../services/common/common.service";
import { EventService } from "../../../services/event/event.service";
import { SpinnerService } from "../../../services/common/spinner.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { ErrorService } from "../../../services/common/error.service";
import { DialogService } from "../../../services/common/dialog.service";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { ORGANIZER, TRAILER, UserModel } from "../../../models/user.model";
import { TrailerModel } from "../../../models/trailer.model";
import { throwError } from "rxjs";
import { HttpStatusCode } from "@angular/common/http";
import { SearchParam } from "../../../classes/search-param";
import { AvatarModel } from "../../../models/avatar.model";
import { DataService } from "../../../services/common/data.service";

@Component({
  selector: "app-register-trailer-stepper",
  templateUrl: "./register-trailer-stepper.component.html",
  styleUrls: ["./register-trailer-stepper.component.css"],
})
export class RegisterTrailerStepperComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  defaultSelectedIndex = 0;
  lastnameCtrl: UntypedFormControl;
  firstnameCtrl: UntypedFormControl;
  avatarCtrl: UntypedFormControl;
  registerTrailerForm: UntypedFormGroup;

  private registeringTrailer: TrailerModel;

  avatars: Array<AvatarModel>;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private ref: ApplicationRef,
    private userService: UserService,
    private commonService: CommonService,
    private eventService: EventService,
    private spinner: SpinnerService,
    private snackbarService: SnackbarService,
    private dataService: DataService,
    private errorService: ErrorService,
    private dialogService: DialogService
  ) {
    this.userService.registeringUser.subscribe((trailer: TrailerModel) => {
      if (trailer) {
        this.registeringTrailer = trailer;
      }
    });
  }

  /**
   *
   */
  ngOnInit() {
    this.defaultSelectedIndex = 0;
    this.buildForm();
    this.loadAvatars(TRAILER);
  }

  private buildForm() {
    this.lastnameCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.firstnameCtrl = new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    );
    this.avatarCtrl = new UntypedFormControl(null);

    this.registerTrailerForm = this.formBuilder.group({
      firstname: this.firstnameCtrl,
      lastname: this.lastnameCtrl,
      avatar: this.avatarCtrl,
    });
  }

  save() {
    if (this.registerTrailerForm.valid) {
      const dataTrailer: OrganizerModel = {
        ...this.registeringTrailer,
        ...this.registerTrailerForm.value,
      };

      this.spinner.start("Initialisation de votre espace trailer...");

      this.userService
        .registerTrailer(dataTrailer)
        .pipe(
          map((registeredUser: TrailerModel) => registeredUser),
          catchError((e) => {
            const formErrorMessage: string = this.errorService.handleFormError(
              e.error
            );
            e.message = formErrorMessage
              ? formErrorMessage
              : "Erreur lors de la création de votre espace - Veuillez contacter" +
                " l'equipe Splits";
            return throwError(() => new Error(e));
          })
        )
        .subscribe({
          next: () => {
            this.spinner.stop();
            this.stepper.next();
          },
          error: (error) => {
            this.errorService.handleError(error);
          },
        });
    }
  }

  /**
   *
   */
  login() {
    this.spinner.start("Chargement de votre espace trailer...");

    this.userService
      .login({
        email: this.registeringTrailer.email,
        password: this.registeringTrailer.matchingPasswords.password,
        type_user: TRAILER,
      })
      .subscribe({
        next: () => {
          // close dialog
          this.dialogService.triggerCloseDialog.next(true);
          this.spinner.stop();
          this.router.navigate(["/", TRAILER, "home"]);
        },
        error: (error) => {
          const errorCode = this.errorService.extractErrorCode(error);
          error.message =
            errorCode === HttpStatusCode.Unauthorized
              ? "Erreur lors de la connexion à l'espace trailer"
              : error.message;
          this.errorService.handleError(error);
        },
      });
  }

  protected loadAvatars(typeAvatar: string = TRAILER) {
    // get terrains dropdownlist
    this.dataService
      .getDropDownList("avatars", [new SearchParam("type", typeAvatar)])
      .pipe(
        tap((avatars: AvatarModel[]) => this.avatarCtrl.setValue(avatars[0]))
      )
      .subscribe((avatars: AvatarModel[]) => {
        this.avatars = avatars;
      });
  }
}
