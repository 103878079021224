<div class="container-fluid p-0 _full_height">
  <div class="row">
    <div class="col p-5" [@fullWidthList]="mapDisplayed ? 'narrow' : 'full'">
      <button mat-button appNavigateBack>
        <i class="icon-splits_back splits-icon-md"></i>
        Retour
      </button>

      <div class="d-flex align-items-center">
        <h3 i18n>Prochaines courses</h3>
      </div>
      <search-box-advanced
        scroller
        (eventsSearching)="searching()"
        (eventsFound)="refreshSearchResults($event)"
        #searchboxadvanced
      ></search-box-advanced>

      <div class="d-flex align-items-center my-1">
        <div class="_user_filters">
          <mat-button-toggle-group
            *ngIf="loggedInUser && loggedInUser?.type_user === 'trailer'"
            [disabled]="!listEventsLoaded"
            [(ngModel)]="scope"
            (ngModelChange)="searchEventsByFilters()"
            name="scope"
            aria-label="scope"
            #statusBtnGroup
            class="d-flex flex-wrap"
          >
            <mat-button-toggle [disableRipple]="true" value="incoming"
              >A venir</mat-button-toggle
            >
            <mat-button-toggle
              appearance="legacy"
              [disableRipple]="true"
              value="profile"
              >Selon mon profil
            </mat-button-toggle>
            <mat-button-toggle
              appearance="legacy"
              [disableRipple]="true"
              value="favourite"
              >Mes favoris
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <button
          (click)="toggleAdvancedSearch()"
          class="d-flex align-items-center _sp_btn _white _small mx-0 my-sm-3"
          matTooltip="Rechercher + précisément une course"
          matTooltipShowDelay="1000"
          i18n
          type="button"
        >
          <i class="fa-solid fa-sliders"></i><span>Plus de filtres</span>
        </button>

        <div class="ml-auto toggle-map-toggle">
          <mat-slide-toggle
            [(ngModel)]="mapDisplayed"
            class="example-margin"
            color="primary"
            labelPosition="before"
          >
            Afficher la carte
          </mat-slide-toggle>
        </div>
      </div>

      <div
        id="count-search-wrap"
        class="d-flex justify-content-between align-items-center"
      >
        <div class="count-result" *ngIf="eventSearched">
          <div *ngIf="events?.length === 1">
            <span class="count">{{ events?.length }} </span> évènements
            correspond à votre recherche
          </div>
          <div *ngIf="events?.length > 1">
            <span class="count">{{ events?.length }} </span> évènements
            correspondent à votre recherche
          </div>
        </div>
      </div>
      <div class="next-races-wrap d-flex flex-column flex-grow-1">
        <div *ngIf="listEventsLoaded; else skeletonLoaderEvents">
          <ng-container *ngIf="events?.length > 0; else noEvents">
            <app-event-directory-item
              (mouseenter)="bounceInMarker(incomingEvent.id)"
              (mouseleave)="bounceOutMarker(incomingEvent.id)"
              *ngFor="
                let incomingEvent of events
                  | slice : pageSize * page : pageSize * (page + 1);
                let i = index;
                first as isFirstLine
              "
              [event]="incomingEvent"
            ></app-event-directory-item>
          </ng-container>
          <ng-template #noEvents
            ><span
              >Il n'y a actuellement aucun événement correspondant à votre
              recherche</span
            >
          </ng-template>
        </div>
        <ng-template #skeletonLoaderEvents>
          <ngx-skeleton-loader
            count="4"
            [theme]="{
              'border-radius': '10px',
              height: '100px',
              margin: '0.7rem'
            }"
          ></ngx-skeleton-loader>
        </ng-template>

        <div class="pagination-wrap">
          <paginator
            [length]="events?.length"
            [pageSize]="pageSize"
            (pageChanged)="handlePageChange($event)"
          ></paginator>
        </div>
      </div>
    </div>

    <div class="col">
      <div
        [@showHideMap]="mapDisplayed ? 'show' : 'hide'"
        class="block-map-wrap"
      >
        <map-cmp
          [lat]="lat"
          [lng]="lng"
          [zoom]="zoomLevel"
          #gmap
          (markerClicked)="showEvent($event)"
        ></map-cmp>
      </div>
    </div>
  </div>
</div>
