<!--<a (click)="close()" class="close fixed-top-right" aria-label="Close"><i
            class="icon-splits_clear splits-icon-md"></i></a>-->
<mat-dialog-content>

    <div class="content-wrap clearfix">

        <div class="_babass_header text-center">
            <div class="_avatar_vma"><img src="assets/img/app/trailer/picto_trailer_babass.svg"></div>
            <h3 class="_middle"> Estimez votre perf' !</h3>
            <span>Calculez votre VMA, votre niveau d’endurance et estimez votre temps de course.</span>
        </div>

        <div>

            <!--<h4>Renseignez au moins 2 valeurs :</h4>-->
            <!--<br>-->
            <form #babassForm="ngForm">

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group __input">
                            <mat-form-field class="_wrap_input">
                                <label i18n>Distance *</label>
                                <input matInput
                                       type="number"
                                       step="1"
                                       min=0
                                       [formControl]="distanceCtrl"
                                       required
                                       pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                                >
                                <mat-error
                                        *ngIf="distanceCtrl.dirty && distanceCtrl.errors && distanceCtrl.errors.required">
                                    Champ obligatoire
                                </mat-error>
                                <mat-error
                                        *ngIf="distanceCtrl.dirty && distanceCtrl.errors && distanceCtrl.errors.pattern">
                                    Valeur
                                    positive attendue
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group __input">
                            <mat-form-field class="_wrap_input">
                                <label i18n>Vitesse (km/h)</label>
                                <input matInput
                                       type="number"
                                       [formControl]="estimatedAverageSpeedCtrl" name="estimated_average_speed"
                                       id="estimated_average_speed"
                                       min=0
                                       pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                                >
                                <mat-error
                                        *ngIf="estimatedAverageSpeedCtrl.dirty && estimatedAverageSpeedCtrl.errors && estimatedAverageSpeedCtrl.errors.pattern">
                                    Valeur positive attendue
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group __input">
                            <mat-form-field class="_wrap_input">
                                <label i18n>Allure ('/km) *</label>
                                <input matInput
                                       required
                                       type="time"
                                       [formControl]="estimatedAveragePaceCtrl"
                                       name="estimated_average_pace" id="estimated_average_pace"
                                >
                                <mat-error>Champ obligatoire</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <span class="_span_little">Temps estimé</span>
                        <div class="d-flex align-items-center justify-content-around">
                            <div class="form-group __input mx-1">
                                <mat-form-field class="_wrap_input">
                                    <input matInput
                                           type="number"
                                           [formControl]="estimatedDurationHourCtrl" name="estimated_duration_hour"
                                           id="estimated_duration_hour" min="0" step="1"
                                           pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
                                    >
                                    <label class="_little" i18n>Heures</label>
                                    <mat-error>!</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group __input mx-1">
                                <mat-form-field class="_wrap_input">

                                    <input matInput
                                           type="number"
                                           [formControl]="estimatedDurationMinCtrl" name="estimated_duration_min"
                                           id="estimated_duration_min" min="0" max="59" step="1"
                                           pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
                                    >
                                    <label class="_little" i18n>Minutes</label>
                                    <mat-error>!</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group __input mx-1">
                                <mat-form-field class="_wrap_input">
                                    <input matInput
                                           type="number"
                                           [formControl]="estimatedDurationSecCtrl" name="estimated_duration_sec"
                                           id="estimated_duration_sec" min="0" max="59" step="1"
                                           pattern="^(?:[1-9]\d*|0)?(?:\d+)?$"
                                    >
                                    <label class="_little" i18n>Secondes</label>
                                    <mat-error>!</mat-error>
                                </mat-form-field>
                            </div>
                            <input type="hidden"
                                   [formControl]="estimatedDurationCtrl"
                                   name="estimated_duration" id="estimated_duration">
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center btn-action-wrap text-center">
                        <button mat-dialog-close class="_sp_btn _blue _full" type="button">
                            <span>Fermer</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
