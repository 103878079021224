<mat-card class="event_card" role="button" (click)="showEvent()">
  <!--  <mat-card-subtitle>-->
  <!--    <span class="badge event_badge">Inscriptions ouvertes</span>-->
  <!--  </mat-card-subtitle>-->
  <mat-card-content>
    <h4>
      {{ event?.name }}
    </h4>
    <span class="event_date">{{ event?.dateEventDisplay }}</span>
    <span class="event_depart">{{ event?.locality }}</span>

    <div class="_contain_distances">
      <div class="distances-wrap my-2">
        <div class="d-flex  align-content-around flex-wrap">
          <div *ngFor="let race of event?.races.slice(0,3); let i=index" class="distance-item d-flex">
            <div class="d-flex __distance">
              <div class>
                <span class="__d">{{ race?.distance | number : '1.0-2'}} km</span>
                <span class="__e">{{ race?.ascendingElevation }}D+</span>
              </div>
            </div>
          </div>
          <div class="distance-item d-flex" *ngIf="event?.races.length > 3">
            <div class="d-flex __distance align-items-center">
              <span><i>+{{event?.races.length - 3 }}</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tags-wrap d-flex align-items-end my-2">
      <div *ngFor="let raceTag of raceTagsReduced?.uniqueRaceTags.slice(0,3); let i=index" class="tag-item d-flex">
        <div class="_contain_card d-flex align-items-center">
          <div class="tag-icon-wrap  _sm">
            <div [ngClass]="['__icon', '_icon-'+raceTag.code]"></div>
          </div>
        </div>
      </div>
      <div class="tag-item d-flex" *ngIf="raceTagsReduced?.extraTagLabel"
           [matTooltip]="raceTagsReduced?.extraTagLabel" matTooltipShowDelay="1000">
        <div class="_contain_card d-flex align-items-center">
          <div class="tag-icon-wrap  _sm">
            <div class="__icon _sm d-flex justify-content-center align-items-center">
              <i class=" fa-solid fa-ellipsis"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
