/**
 * Created by macbookaxel on 26/06/2017.
 */
export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    DOWN_ARROW = 40,
    UP_ARROW = 38,
    ESCAPE = 27
}
