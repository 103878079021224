import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UserService } from '../../../services/user/user.service';

@Component({
             selector: 'app-faq',
             templateUrl: './faq.component.html',
             styleUrls: ['./faq.component.scss']
           })
export class FaqComponent extends BaseComponent implements OnInit {

  constructor(protected userService: UserService, public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  afterInit() {
    // nothing to do
  }

  showFaq(typUser: string) {
    return (!this.loggedInUser ||
      (this.loggedInUser.type_user === typUser)) ? true : false;
  }
}
