<div class="loading-overlay d-flex align-items-center justify-content-center" *ngIf="active">
  <mat-card class="loading-content">
    <a *ngIf="cancelTransaction" (click)="close()" class="close" aria-label="Close"><i class="icon-splits_clear splits-icon-md"></i></a>
    <mat-card-content>
      <div class="d-flex flex-column align-items-center">
        <mat-spinner [id]="id" class="loading-spinner mx-auto"></mat-spinner>
        <p>{{message}}</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
