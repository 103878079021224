import { UserModel } from './user.model';
import { NOTIFICATION_TYPE } from '../enums/notification-type.enum';
import { UserNotificationModel } from './user.notification.model';
import { CallToAction } from '../classes/CallToAction';

/**
 * Created by macbookaxel on 16/06/2016.
 */


// type de notification

export class NotificationModel {

    subject: string;
    title: string;
    content: string;
    type: NOTIFICATION_TYPE;
    sender: UserModel;

    // notification liée à une course
    // on enverra à tous les inscrits à la course
    relatedObject: string;
    relatedObjectId: number;

    retrieveRecipientsFromRelatedObject: boolean;

    notificationUsers: Array<UserNotificationModel>;

    emailNotification: boolean; // déclenche l'envoi d'un mail
    internNotification: boolean; // déclenche l'ajout d'une notification

    // Call To Action
    callToActionCode: string;
    callToAction: CallToAction;

    iconNotificationTypeClass: string;

}
