import { Injectable } from '@angular/core';
import { NotificationModel } from '../../models/notification.model';
import { HttpService } from '../http/http.service';
import { UserModel } from '../../models/user.model';
import { SearchParam } from '../../classes/search-param';
import { Observable } from 'rxjs';
import { CallToAction } from '../../classes/CallToAction';
import { NOTIFICATION_TYPE } from '../../enums/notification-type.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    sendNotificationUrl = '/api/notifications';
    getNotificationsUrl = '/api/notifications';

    patchNotificationsUrl = '/api/user_notifications';

    private searchParams: Array<SearchParam> = [];

    constructor(private http: HttpService) {
    }

    sendNotification(notification: NotificationModel) {
        return this.http.post(this.sendNotificationUrl, notification);
    }

    /** retourne les notifications d'un user
     *
     * @param user
     * @returns {*}
     */
    getNotitifications(user: UserModel) {

        this.searchParams = [];

        this.searchParams.push(new SearchParam('userId', user.id));

        return this.http.get(this.getNotificationsUrl, this.searchParams, false);
    }

    /**
     *
     * @param notifications
     * @returns {*}
     */
    clearNewNotifications(notifications: Array<NotificationModel>): Observable<NotificationModel> {

        const userNotifications = [];

        if (typeof notifications !== 'undefined') {
            notifications.forEach((notification: NotificationModel) => {
                if (typeof notification.notificationUsers[0] !== 'undefined') {
                    notification.notificationUsers[0].isNew = false;

                    userNotifications.push(notification.notificationUsers[0]);
                }
            });

            // sauvegarde en base
            return this.http.put(this.patchNotificationsUrl, userNotifications, false);
        }
    }

    /**
     *
     * @param callToActionCode
     * @param baseUrl
     * @param relatedObject
     * @param relatedObjectId
     * @returns {CallToAction}
     */
    handleNotificationCallToAction(callToActionCode: string, baseUrl: string, relatedObject: string, relatedObjectId: number) {

        const cta = new CallToAction(callToActionCode);

        switch (callToActionCode) {
                case 'new_roadbook':
                    cta.url = baseUrl + '/roadbooks/new/' + relatedObjectId + '/chart';
                    cta.label = 'Creer un roadbook';
                    break;
                case 'view_roadbook':
                    cta.url = baseUrl + '/roadbooks/show/' + relatedObjectId;
                    cta.label = 'Consulter le roadbook';
                    break;
                case 'edit_roadbook':
                    cta.url = baseUrl + '/roadbooks/edit/' + relatedObjectId + '/chart';
                    cta.label = 'Modifier le roadbook';
                    break;
                case 'validate_roadbook':
                    cta.url = baseUrl + '/roadbooks/validate/' + relatedObjectId;
                    cta.label = 'Valider le roadbook';
                    break;
                case 'statistics_roadbook':
                    cta.url = baseUrl + '/roadbooks/statistics/' + relatedObjectId;
                    cta.label = 'Voir les statistiques';
                    break;
        }
        return cta;
    }


    /**
     *
     * @param notificationType
     */
    getNotificationIconClass(notificationType: number) {

        let iconColor: string;
        let iconTypeNotification: string;

        switch (notificationType) {
            case NOTIFICATION_TYPE.DEFAULT:
                iconTypeNotification = 'icon-splits_message';
                iconColor = 'text-default';
                break;
            case NOTIFICATION_TYPE.INFO:
                iconTypeNotification = 'icon-splits_info-circle';
                iconColor = 'text-info';
                break;
            case NOTIFICATION_TYPE.DANGER:
                iconTypeNotification = 'icon-splits_circle-clear';
                iconColor = 'text-danger';
                break;
            case NOTIFICATION_TYPE.SUCCESS:
                iconTypeNotification = 'icon-splits_check';
                iconColor = 'text-success';
                break;
            case NOTIFICATION_TYPE.WARNING:
                iconTypeNotification = 'icon-splits_info-circle upside-down';
                iconColor = 'text-warning';
                break;
            default:
                iconTypeNotification = 'icon-splits_message';
                iconColor = 'text-default';
                break;
        }

        return iconTypeNotification + ' ' + iconColor;
    }


    clear() {

    }

    remove() {

    }

}
