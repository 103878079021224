/**
 * Created by macbookaxel on 23/06/2016.
 */
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from './../services/user/user.service';
import { SnackbarService } from './../services/notifications/snackbar.service';
import { SessionService } from '../services/common/session.service';
import { ORGANIZER, TRAILER } from '../models/user.model';
import { DialogService } from '../services/common/dialog.service';
import { Observable } from 'rxjs';

@Injectable({
              providedIn: 'root'
            })
export class OnlyLoggedInUsersGuard implements CanActivate {

  constructor(private userService: UserService,
              private sessionService: SessionService,
              private dialogService: DialogService,
              private router: Router,
              private snackBarService: SnackbarService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      const isLoggedIn: boolean = this.userService.isLoggedIn();
      if (!isLoggedIn) {
        const typeUserToLogin = (state.url?.match(/^\/organizer.*/)) ? ORGANIZER : TRAILER;
        this.dialogService.openSignInPopup(typeUserToLogin, 0, state.url);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

}


