import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { EventModel } from '../../../models/event.model';
import { Router } from '@angular/router';
import { RaceTagModel } from '../../../models/race-tag.model';
import { DataService } from '../../../services/common/data.service';


@Component({
             selector: 'app-event-card',
             templateUrl: './event-card.component.html',
             styleUrls: ['./event-card.component.scss']
           })
export class EventCardComponent implements OnInit {

  @Input() event: EventModel;

  raceTagsReduced: { extraTagLabel: string; uniqueRaceTags: Array<RaceTagModel> };

  constructor(private router: Router, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.raceTagsReduced = this.dataService.reduceRaceTags(this.event);
  }

  showEvent() {
    this.router.navigate(['/events',this.event.id,'show']);
  }
}
