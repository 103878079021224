import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/common/nav.service';
import { NavbarState } from '../../classes/OverlayState';
import { Subscription } from 'rxjs';

@Component({
    selector: 'nav-default',
    templateUrl: './nav-default.component.html',
})
export class NavDefaultComponent implements OnInit {

    private subscription: Subscription;

    navLinks: Array<any>;

    expanded = true;

    constructor(private navService: NavService) { }

    ngOnInit() {

        this.subscription = this.navService.navbarState
            .subscribe((state: NavbarState) => {
                this.expanded = state.expanded;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    trigger() {
        this.navService.clearSearch();
    }
}
