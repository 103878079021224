<!-- <mat-tab label="Résultats" i18n> Content 3</mat-tab>
    <mat-tab label="Intéressés" i18n> Content 3</mat-tab>-->
<div class="container-fluid">
  <div
    *ngIf="eventLoaded; else skeletonLoadBanner"
    class="hero-banner row gradiant-cover"
  >
    <div class="__img">
      <img [src]="heroBannerUrl" alt="home orga" />
    </div>
    <div class="event-header-wrap">
      <h2>{{ event?.name }}</h2>
      <span class="event_date">{{ event?.dateEventDisplay }}</span>
    </div>
  </div>
  <ng-template #skeletonLoadBanner>
    <ngx-skeleton-loader
      [theme]="{ height: '350px', padding: '1rem 0' }"
      count="1"
    ></ngx-skeleton-loader>
  </ng-template>
</div>
<div class="container-fluid">
  <mat-tab-group #eventShowTab>
    <mat-tab label="Détails de l'évènement">
      <div class="container-splits">
        <button appNavigateBack mat-button>
          <i class="icon-splits_back splits-icon-md"></i>
          Retour
        </button>
        <div class="d-flex flex-wrap">
          <div
            class="d-flex left-content flex-column w-75 pt-5 pr-5 order-1 order-md-0"
          >
            <div *ngIf="eventLoaded; else skeletonLoadEvent">
              <div
                class="description-wrap pb-5 text-left mr-md-5 order-0 order-md-1"
              >
                <div *ngIf="event?.description">
                  <h3 class="my-3 _middle">Description</h3>
                  <p>
                    {{ event?.description }}
                  </p>
                </div>
                <div *ngIf="event?.website" class="pt-3">
                  <h3 class="my-3 _middle">Site internet</h3>
                  <a href="{{ event?.website }}">
                    {{ event?.website }}
                  </a>
                </div>
              </div>
              <div class="race-list-wrap pb-5 pt-3 text-left">
                <h3 class="my-3 _middle">Les courses</h3>
                <race-list
                  (showRaceRequest)="
                    triggerTabSelectAndRequestForDetails($event)
                  "
                  class="py-3"
                  [races]="event?.races"
                ></race-list>
              </div>
            </div>

            <ng-template #skeletonLoadEvent>
              <div>
                <ngx-skeleton-loader
                  count="3"
                  [theme]="{
                    'border-radius': '10px',
                    height: '30px',
                    padding: '1rem 0'
                  }"
                ></ngx-skeleton-loader>
              </div>
              <div class="mt-5">
                <ngx-skeleton-loader
                  count="2"
                  [theme]="{
                    'border-radius': '10px',
                    height: '170px',
                    padding: '1rem 0'
                  }"
                ></ngx-skeleton-loader>
              </div>
            </ng-template>
          </div>
          <div class="d-flex right-content flex-column w-25 mt-4">
            <div class="_card _border organizer_card">
              <div
                *ngIf="eventLoaded; else skeletonLoadSumUp"
                class="d-flex flex-column align-items-center"
              >
                <div class="__avatar _profil d-flex align-items-center">
                  <div
                    [ngClass]="[
                      'avatar-icon __icon _bg-size-90 _center',
                      event?.organizer?.avatar
                        ? '_icon-avatar-' + event?.organizer?.avatar.code
                        : '_icon-avatar-orga-male-1'
                    ]"
                  ></div>
                </div>
                <h4 class="pb-sm-2 pb-md-3 w-100" i18n>
                  Organisé par {{ event?.organizer.organizm }}
                </h4>
                <div>
                  <span class="event_date">{{ event?.dateEventDisplay }}</span>
                  <a
                    href="{{ event?.website }}"
                    target="_blank"
                    class="a_surline"
                    >{{ event?.website }}</a
                  >
                  <div
                    class="d-flex w-100 pt-2 justify-content-center align-items-center location-wrap"
                  >
                    <span
                      ><i class="fa-solid fa-location-dot mr-3"></i
                      >{{ event?.locality }}</span
                    >
                    <span *ngIf="event?.postalCode"
                      >({{ event?.postalCode | slice : 0 : 2 }})</span
                    >
                    <span *ngIf="event?.country">, {{ event?.country }}</span>
                  </div>
                </div>
                <div class="pb-2">
                  <ul class="_orga_socials">
                    <li class="_fb" *ngIf="event?.facebookPage">
                      <a [href]="event?.facebookPage" target="_blank">
                        <i class="fa-brands fa-facebook-square"></i>
                      </a>
                    </li>
                    <li class="_insta" *ngIf="event?.instagramPage">
                      <a [href]="event?.instagramPage" target="_blank">
                        <i class="fa-brands fa-instagram-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <ng-template #skeletonLoadSumUp>
                <div class="pt-3">
                  <ngx-skeleton-loader
                    count="3"
                    [theme]="{
                      'border-radius': '10px',
                      height: '30px',
                      padding: '1rem 0'
                    }"
                  ></ngx-skeleton-loader>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Courses" i18n>
      <div
        class="container-fluid races-distance-banner d-flex justify-content-center p-2"
      >
        <div
          class="races-distance-item _hover d-flex flex-column justify-content-center p-3"
          [ngClass]="{ active: requestRaceForDetails === currentRace }"
          *ngFor="let currentRace of event?.races"
          (click)="displayRaceDetail(currentRace)"
        >
          <span>{{ currentRace.distance }} kms</span>
          <span>{{ currentRace.ascendingElevation }} D+</span>
        </div>
      </div>
      <div class="race-details-wrap m-3">
        <app-race-show [race]="requestRaceForDetails"></app-race-show>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
