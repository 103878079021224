import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PoiService } from "../../services/poi/poi.service";
import { DataService } from "../../services/common/data.service";
import { PoiModel } from "../../models/Pois/poi.model";
import { RaceModel } from "../../models/race.model";
import { PoiTypeModel } from "../../models/Pois/poi.type.model";
import { ConvertorService } from "../../services/common/convertor.service";
import { Trkpt } from "../../classes/trkpt";
import { UtilsService } from "../../services/common/utils.service";
import { debounceTime, filter, map } from "rxjs/operators";
import { SnackbarService } from "../../services/notifications/snackbar.service";
import { DatetimeService } from "../../services/common/datetime.service";
import { RaceOffModel } from "../../models/race-off.model";
import { PoiTypesEnum } from "../../enums/poiTypes.enum";
import { SelectItemModel } from "../../models/select.item.model";

@Component({
  selector: "poi-base",
  template: "",
})
export class PoiBaseComponent implements OnInit, AfterViewInit {
  patternPositiveFloat = "^(?:[1-9]d*|0)?(?:.d+)?$";
  patternPositiveInteger = "^(?:[1-9]d*|0)?(?:d+)?$";

  poi: PoiModel = new PoiModel();
  workingPoi: PoiModel = new PoiModel();

  pois: Array<PoiModel> = [];
  trkpts: Array<Trkpt> = [];

  race: RaceModel | RaceOffModel;

  //    Liste déroulantes
  poiTypes: Array<PoiTypeModel> = [];
  checkedPoiTypes: Array<PoiTypeModel> = [];

  submitted = false;
  hasError = false;
  errorMessage: string;

  poiForm: UntypedFormGroup;

  distanceCtrl = new UntypedFormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.pattern("^(?:[1-9]\\d*|0)?(?:\\.\\d+)?$"),
    ])
  );
  nameCtrl = new UntypedFormControl("", Validators.required);
  originCtrl = new UntypedFormControl(
    "",
    Validators.compose([Validators.required])
  );

  estimatedAverageSpeedCtrl = new UntypedFormControl("");
  estimatedAveragePaceCtrl = new UntypedFormControl("");
  estimatedDurationCtrl = new UntypedFormControl("");

  poiTypesCtrl = new UntypedFormControl("");
  timeLimitCtrl = new UntypedFormControl("");
  maxDistance: number;

  public displayTimeLimit = false;
  poiTypesLoaded: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected poiService: PoiService,
    protected dataService: DataService,
    protected snackbarService: SnackbarService,
    protected formBuilder: UntypedFormBuilder,
    protected convertorService: ConvertorService,
    protected utilsService: UtilsService,
    protected datetimeService: DatetimeService,
    public dialogRef: MatDialogRef<PoiBaseComponent>
  ) {}

  ngOnInit() {
    // remplissage des lists déroulantes
    this.populateDropDownLists();

    this.poi = this.data.poi;
    this.pois = this.data.pois;
    this.race = this.data.race;
    this.trkpts = this.data.trkpts;

    const finishLineTrkpt: Trkpt = this.trkpts[this.trkpts.length - 1];
    this.maxDistance = finishLineTrkpt.dst;

    this.distanceCtrl.addValidators(Validators.max(finishLineTrkpt.dst));

    // this.trkpts = this.race.trkpts;
    this.workingPoi = Object.assign({}, this.poi);
  }

  buildForm() {
    this.poiForm = this.formBuilder.group({
      name: this.nameCtrl,
      origin: this.originCtrl,
      distance: this.distanceCtrl,
      timeLimit: this.timeLimitCtrl,
      poiTypes: this.poiTypesCtrl,
    });
  }

  ngAfterViewInit() {
    this.activateChangeDetection();
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.id === i2.id;
  }

  /**
   *
   * @param eventId
   */
  populateDropDownLists() {
    // get terrains dropdownlist

    const poiTypes$ = [];

    this.dataService
      .getDropDownList("poi_types")
      .pipe(
        filter((poiTypes) => poiTypes.length > 0),
        map((poiTypes: SelectItemModel[]) => {
          const poiTypesFiltered = poiTypes.filter(
            (poiType) => poiType.code !== "finish"
          );
          poiTypes$.push(...poiTypesFiltered);
          return poiTypes$;
        })
      )
      .subscribe({
        next: (poiTypes) => (this.poiTypes = poiTypes),
        error: (error) => {
          this.hasError = true;
          this.errorMessage =
            "Erreur " + error.error.code + " - Liste introuvable";
        },
        complete: () => (this.poiTypesLoaded = true),
      });
  }

  onPoiTypeSelectedChange(e) {
    const poiType = this.poiTypes.filter(
      (item) => +item.id === +e.target.value
    )[0];

    if (e.target.checked) {
      this.checkedPoiTypes.push(poiType);
    } else {
      this.checkedPoiTypes.forEach((checkedPoiType, index) => {
        if (checkedPoiType.code === poiType.code) {
          this.checkedPoiTypes.splice(index, 1);
        }
      });
    }
    this.poiTypesCtrl.setValue(this.checkedPoiTypes);

    this.displayTimeLimit = this.timeLimitPoiIsChecked();
  }

  timeLimitPoiIsChecked() {
    return (
      this.checkedPoiTypes.filter(
        (checkedPoiType) => checkedPoiType.code === PoiTypesEnum.TIME_LIMIT
      ).length > 0
    );
  }

  /** Activation de la détéction de changement sur les champs de la popup
   *
   */
  activateChangeDetection() {
    // ajustement des distance et élévation en fonction de la distance choisie
    this.distanceCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((distance) => {
        this.populatePoiInfoWithPreviousPoi(distance);
        const speed = this.estimatedAverageSpeedCtrl.value;
        const distanceInBetween = this.workingPoi.distanceInBetween;
        if (speed && distanceInBetween) {
          this.estimatedDurationCtrl.setValue(
            this.convertorService.getDurationFromSpeed(distanceInBetween, speed)
          );
        }
      });
  }

  public populatePoiInfoWithPreviousPoi(distance) {
    this.workingPoi = this.poiService.populatePoiInfoWithPreviousPoi(
      distance,
      this.workingPoi,
      this.pois,
      this.trkpts
    );
  }

  savePoi() {
    if (typeof this.workingPoi.internId === "undefined") {
      this.workingPoi.internId = this.utilsService.createRandomString(5, "aA#");
    }
  }

  close() {
    this.dialogRef.close();
  }
}
