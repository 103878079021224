import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavbarState } from '../../classes/OverlayState';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class NavService {

    public stateSubject = new Subject<NavbarState>();
    navbarState = this.stateSubject.asObservable();

    constructor(private searchService: SearchService) {
    }

    expand() {
        this.stateSubject.next({expanded: true} as NavbarState);
    }

    collapse() {
        this.stateSubject.next({expanded: false} as NavbarState);
    }

    clearSearch() {
        this.searchService._fastSearchInputClear.next(true);
    }
}
