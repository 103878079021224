<mat-dialog-content>
  <div class="sign-in-container">
    <ng-container *ngIf="signInStep === 'login'">
      <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="Se connecter">
          <login-form [typeUserToLogin]="typeUser" [hasSocialSignIn]="true" [callbackUrl]="callbackUrl"></login-form>
        </mat-tab>
        <mat-tab label="S'inscrire">
          <app-register [typeUserToRegister]="typeUser"></app-register>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-container *ngIf="signInStep === 'organizer'">
      <!--    stepper orga-->
      <app-register-orga-stepper></app-register-orga-stepper>
    </ng-container>
    <ng-container *ngIf="signInStep === 'trailer'">
      <!--    dddjd-->
      <!--    stepper trailer-->
      <app-register-trailer-stepper></app-register-trailer-stepper>
    </ng-container>
  </div>
</mat-dialog-content>


