import { Directive, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Directive({
             selector: '[appNavigateBack]'
           })
export class NavigateBackDirective {

  constructor(private el: ElementRef, private router: Router, private location: Location) {
  }

  @HostListener('click') onClick(e): void {
    this.location.back();
  }


}
