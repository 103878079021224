import { Routes } from '@angular/router';
import { AuthGuardMaintenanceGuard } from './guards/auth-guard-maintenance.guard';
import { IndexBaseComponent } from './components/index-base/index-base.component';
import { IndexTrailerComponent } from './components/index-base/index-trailer/index-trailer.component';
import { LandingTrailerComponent } from './components/landing/landing-trailer/landing-trailer.component';
import { EventsComponent } from './components/events/events.component';
import { EventDirectoryComponent } from './components/events/event-directory/event-directory.component';
import { EventShowComponent } from './components/events/event-show/event-show.component';
import { IndexOrganizerComponent } from './components/index-base/index-organizer/index-organizer.component';
import { LandingOrgaComponent } from './components/landing/landing-orga/landing-orga.component';


export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuardMaintenanceGuard],
    component: IndexBaseComponent,
    children: [
      {
        path: '', component: IndexTrailerComponent,
        children: [
          {
            path: '', component: LandingTrailerComponent, data: {
              title: 'Accès espace Trailer'
            }
          },
          {
            path: 'events', component: EventsComponent,
            children: [
              {
                path: '', component: EventDirectoryComponent, data: {
                  title: 'Les prochains évènements'
                }
              },
              {
                path: ':eventId/show', component: EventShowComponent, data: {
                  title: 'Evénement'
                },
              },
              {
                path: ':eventId/show/:raceId/race', component: EventShowComponent, data: {
                  title: 'Evénement'
                },
              },
            ]
          }
        ]
      },
      {
        path: 'organizer',
        loadChildren: () => import('./modules/organizer.module').then(m => m.OrganizerModule)
      },
      {
        path: 'trailer',
        loadChildren: () => import('./modules/trailer.module').then(m => m.TrailerModule)
      },
      // {
      //   path: 'login', redirectTo: '/',
      //   pathMatch: 'full'
      // },
      // {
      //   path: 'landing-orga', component: LandingOrgaComponent, data: {
      //     title: 'Accès espace Trailer'
      //   }
      // },`
    ]
  },

  {
    path: 'pages',
    loadChildren: () => import('./modules/pages.module').then(m => m.PagesModule)
  },
  {path: '**', redirectTo: '/pages/page-not-found', pathMatch: 'full'}
];
