import { Component, Input, OnInit } from '@angular/core';

@Component({
             selector: 'local-spinner',
             templateUrl: './local-spinner.component.html',
             styleUrls: ['./local-spinner.component.scss']
           })
export class LocalSpinnerComponent implements OnInit {

  // Sets initial value to true to show loading spinner on first load
  active = false;
  message = 'Chargement...';

  @Input() id: string; // id du spinner
  @Input() size: string; // small , medium, big

  public constructor() {
  }

  public start() {
    this.active = true;
  }

  public stop() {
    this.active = false;
  }

  ngOnInit() {
  }

}
