import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EventModel } from '../../models/event.model';
import { HttpService } from '../http/http.service';
import { SearchParam } from '../../classes/search-param';
import { ORGANIZER, UserModel } from '../../models/user.model';
import { DatetimeService } from '../common/datetime.service';
import { SearchService } from '../common/search.service';
import { SearchEventCriterias } from '../../classes/searchEventCriterias';
import { map, tap } from 'rxjs/operators';
import { TerrainModel } from '../../models/terrain.model';
import { RaceModel } from '../../models/race.model';


@Injectable({
              providedIn: 'root'
            })
export class EventService {

  searchEventCriterias: SearchEventCriterias;
  listEvents: Observable<Array<EventModel>>;

  event: Observable<EventModel>;

  eventsUrl = '/api/events';
  addEventUrl = '/api/events';
  editEventUrl = '/api/events/';
  deleteEventUrl = '/api/events/';
  duplicateEventUrl = '/api/events/';
  oneEventUrl = '/api/events/';

  constructor(private http: HttpService,
              private datetimeService: DatetimeService,
              private searchService: SearchService) {
  }

  /**
   *
   */
  canModify(event: EventModel, user: UserModel) {
    return (user.type_user === ORGANIZER &&
      user.id === event.organizerId);
  }

  /** Récupération de la liste complète des événements
   *
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  getEvents(searchParams: Array<SearchParam>) {

    this.listEvents = this.http.get(this.eventsUrl, searchParams);
    return this.listEvents;
  }

  getEventsByCriterias(searchEventCriterias: SearchEventCriterias, limit: number = null): Observable<Array<EventModel>> {
    return this.searchService.searchElementByCriterias('event', searchEventCriterias).pipe(
      map(events => events.map((event) => new EventModel(event))));
  }

  getEventsByScope(defaultSearchScope: string,
                   searchEventCriterias: SearchEventCriterias,
                   loggedInUser: UserModel = null,
                   limit: number = null): Observable<Array<EventModel>> {

    const searchEventCriteriasByScope = this.getSearchEventsCriteriasByScope(defaultSearchScope, loggedInUser, limit);

    const criterias = {...searchEventCriteriasByScope, ...searchEventCriterias};
    return this.searchService.searchElementByCriterias('event', criterias).pipe(
      map(events => events.map((event) => new EventModel(event))));
  }

  /** Retourne les détails d'une course
   *
   * @returns {Observable<RaceModel>}
   */
  getOneEvent(eventId: number) {
    this.event = this.http.get<EventModel>(this.oneEventUrl + eventId, null).pipe(
      map(event => new EventModel(event)),
      map((event: EventModel) => {
        if (event.races && event.races.length > 0) {
          event.races = event.races.map(race => new RaceModel(race));
        }
        return event;
      }));
    return this.event;
  }

  /** POST event
   *
   * @param event
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  postEvents(event: EventModel): Observable<EventModel> {
    return this.http.post(this.addEventUrl, event);
  }

  putEvents(event: EventModel): Observable<EventModel> {
    return this.http.put(this.editEventUrl + event.id, event);
  }

  patchEvent(event: EventModel): Observable<EventModel> {
    return this.http.patch(this.editEventUrl + event.id, event);
  }

  deleteEvent(event: EventModel) {
    return this.http.delete(this.deleteEventUrl + event.id, event);
  }

  duplicateEvent(event: EventModel) {
    return this.http.post(this.duplicateEventUrl + event.id + '/duplicate', event);
  }

  isDraft(event: EventModel): boolean {
    return (event.status === 'draft');
  }

  isPublished(event: EventModel): boolean {
    return (event.status === 'published' && this.datetimeService.getIntervalToToday(event.dateEnd) >= 0);
  }

  isArchived(event: EventModel): boolean {
    return (event.status === 'archived' ||
      event.status === 'published' && this.datetimeService.getIntervalToToday(event.dateEnd) < 0);
  }


  getSearchEventsCriteriasByScope(searchScope: string, loggedInUser: UserModel, limit: number) {
    const searchEventCriterias = new SearchEventCriterias(loggedInUser);

    switch (searchScope) {
      case 'profile':
        searchEventCriterias.ascendingElevationRange = loggedInUser.preferenceAscendingElevation;
        searchEventCriterias.distanceRange = loggedInUser.preferenceDistance;
        searchEventCriterias.terrain = (loggedInUser.preferenceTerrain) ? loggedInUser.preferenceTerrain.id : null;
        searchEventCriterias.discipline = (loggedInUser.preferenceDiscipline)
                                          ? loggedInUser.preferenceDiscipline.id : null;
        searchEventCriterias.hasTimeLimit = loggedInUser.preferenceHasTimeLimit;
        searchEventCriterias.hasUtmbPoint = loggedInUser.preferenceHasUtmbPoint;
        searchEventCriterias.isNightRace = loggedInUser.preferenceIsNightRace;
        searchEventCriterias.locality = loggedInUser.baseCampLocation;
        searchEventCriterias.latitude = loggedInUser.baseCampLatitude;
        searchEventCriterias.longitude = loggedInUser.baseCampLongitude;
        searchEventCriterias.proximity = loggedInUser.preferenceMaxDistanceFromBasecamp;
        searchEventCriterias.limit = (limit) ? limit : null;
        searchEventCriterias.status = 'published';
        break;
      case 'archived':
        break;
      case 'recent':
        searchEventCriterias.newest = true;
        searchEventCriterias.limit = (limit) ? limit : null;
        searchEventCriterias.status = 'published';
        break;
      case 'favourite':
      case 'default' :
        searchEventCriterias.limit = (limit) ? limit : null;
        // tous les statuts mais classé par published et ensuite archivée
        break;
      case 'incoming':
        const today: Date = new Date();
        searchEventCriterias.dateSearchStartTimestamp = this.datetimeService.getTimestampInSecond(today);
        searchEventCriterias.limit = (limit) ? limit : null;
        searchEventCriterias.status = 'published';
        break;
      default:
        break;
    }
    return searchEventCriterias;
  }

  retrieveEventHeroBannerUrlFromTerrain(terrain: TerrainModel) {
    let heroBannerUrl = 'assets/img/app/events/background_montagne.png';
    if (terrain) {
      switch (terrain.code) {
        case 'mountain':
          heroBannerUrl = 'assets/img/app/events/background_montagne.png';
          break;
        case 'sea':
          heroBannerUrl = 'assets/img/app/events/background_mer.png';
          break;
        case 'country':
          heroBannerUrl = 'assets/img/app/events/background_coline.png';
          break;
        case 'city':
          heroBannerUrl = 'assets/img/app/events/background_city.png';
          break;
        default:
          heroBannerUrl = 'assets/img/app/events/background_montagne.png';
          break;
      }
    }
    return heroBannerUrl;
  }

}
