<div class="container-splits">
  <div class="d-flex flex-wrap">
    <div class="d-flex pt-md-0 left-content flex-column order-1 order-md-0">
      <div
        class="race-details-wrap text-left mr-md-5"
        style="position: relative"
      >
        <h3 class="_middle my-4">Caractéristiques</h3>
        <div class="d-flex flex-wrap bd-highlight race-infos">
          <div *ngIf="race?.distance" class="align-items-start _bl">
            <div class="_center">
              <h4 i18n>Distance</h4>
              <span>{{ race?.distance }} km </span>
            </div>
          </div>
          <div class="align-items-start _bl">
            <div class="_center">
              <h4 i18n>Dénivelé +</h4>
              <span>{{ race?.ascendingElevation || "--" }} mD+ </span>
            </div>
          </div>
          <div class="lign-items-start _bl">
            <div class="_center">
              <h4 i18n>Dénivelé -</h4>
              <span>{{ race?.negativeElevation || "--" }} mD- </span>
            </div>
          </div>
          <div class="align-items-start _bl _no_border">
            <div class="_center">
              <h4 i18n>Ravito</h4>
              <span>{{ countRavitos }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-sm-3 mt-md-5 race-caracteristiques">
          <div class="col-md-6 pl-0 race-detail-item">
            <i
              class="icon-splits_discipline splits-icon-l"
              matTooltipShowDelay="1000"
              matTooltip="Discipline"
              matTooltipPosition="above"
            ></i>
            <span>{{ race?.discipline?.name || "--" }}</span>
          </div>
          <div class="col-md-6 pl-0 race-detail-item">
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_cup" color="black" class="splits-icon-l"></mat-icon>-->
            <i
              class="icon-splits_challenge splits-icon-l"
              matTooltipShowDelay="1000"
              matTooltip="Challenge"
              matTooltipPosition="above"
            ></i>
            <span>{{ race?.challenge?.name || "--" }}</span>
          </div>

          <div class="col-md-6 pl-0 race-detail-item">
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_timer" color="black" class="splits-icon-l"></mat-icon>-->
            <i
              class="icon-splits_timer splits-icon-l"
              matTooltipShowDelay="1000"
              matTooltip="Barrière horaire"
              matTooltipPosition="above"
            ></i>
            <span *ngIf="race?.hasTimeLimit">Avec barrière horaire</span>
            <span *ngIf="!race?.hasTimeLimit">Sans barrière horaire</span>
          </div>
          <div class="col-md-6 pl-0 race-detail-item">
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_polls" color="black" class="splits-icon-l"></mat-icon>-->
            <div *ngIf="race?.arePolesProhibited">
              <i
                class="icon-splits_no-polls splits-icon-l"
                matTooltipShowDelay="1000"
                matTooltip="Bâtons"
                matTooltipPosition="above"
              ></i>
              <span>Bâtons interdits</span>
            </div>
            <div *ngIf="!race?.arePolesProhibited">
              <i
                class="icon-splits_polls splits-icon-l"
                matTooltipShowDelay="1000"
                matTooltip="Bâtons"
                matTooltipPosition="above"
              ></i>
              <span>Bâtons autorisés</span>
            </div>
          </div>
          <div class="col-md-6 pl-0 race-detail-item">
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_runners" color="black" class="splits-icon-l"></mat-icon>-->
            <i
              class="icon-splits_runners splits-icon-l"
              matTooltipShowDelay="1000"
              matTooltip="Nombre de coureur max"
              matTooltipPosition="above"
            ></i>
            <span>{{ race?.maxSeats || "--" }} coureurs max</span>
          </div>
          <div class="pl-0 col-md-6 race-detail-item">
            <div id="deadline-wrap" class="d-flex flex-row align-items-center">
              <div>
                <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_elevation" color="black" class="splits-icon-l"></mat-icon>-->
                <i
                  class="icon-splits_register splits-icon-l"
                  matTooltipShowDelay="1000"
                  matTooltip="Date limite d'inscription"
                  matTooltipPosition="above"
                ></i>
              </div>
              <div>
                <span
                  >Inscription jusqu'au <br /><strong>{{
                    race?.deadlineRegistration | date : "EEE dd MMM y"
                  }}</strong></span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 pl-0 race-detail-item" *ngIf="race?.utmbIndex">
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_tag" color="black" class="splits-icon-l"></mat-icon>-->
            <div class="d-flex align-items-center">
              <div
                [ngClass]="[
                  '__icon _no-border',
                  '_icon-utmb-index-' + race?.utmbIndex
                ]"
              ></div>
            </div>
          </div>
          <div
            class="col-md-6 pl-0 race-detail-item"
            *ngIf="race?.runningStones"
          >
            <!--<mat-icon fontSet="Splits-icon" fontIcon="icon-splits_tag" color="black" class="splits-icon-l"></mat-icon>-->
            <i
              class="icon-extra-splits icon-picto_utmb"
              matTooltipShowDelay="1000"
              matTooltip="Running Stones®"
              matTooltipPosition="above"
              style="font-size: 1.7rem; margin-right: 8px"
            ></i>
            <span>{{ race?.runningStones || "0" }} Running Stones</span>
          </div>
        </div>

        <div *ngIf="race?.description" class="description-wrap pt-3">
          <h3 class="_middle my-4">Description</h3>
          <p>
            {{ race?.description }}
          </p>
          <!--    <p class="primary"><a [href]="race?.event.website" target="_blank" [title]="race?.event.website" class="primary">{{ race?.event.website }}</a></p>-->
        </div>

        <div class="race-map-wrap pt-3">
          <h3 class="_middle my-4">Détails du parcours</h3>
          <div class="block-race-map">
            <map-cmp [lat]="lat" [lng]="lng" [zoom]="zoomLevel" #gmap></map-cmp>
          </div>
        </div>

        <div id="block-race-elevation-chart" class="pt-5">
          <h3 class="_middle my-3">Profil d'élévation</h3>
          <div *ngIf="race?.gpx; else noChart">
            <race-chart-cmp
              [chartWidth]="chartWidth"
              [chartHeight]="chartHeight"
              #raceChart
            ></race-chart-cmp>
          </div>
          <ng-template #noChart>
            <p>Le profil de la course sera disponible prochainement</p>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="d-flex pt-md-0 right-content flex-column order-0 order-md-1">
      <div class="_card _border">
        <div class="d-flex flex-column card-infos">
          <div class="like-button-wrap">
            <button
              mat-icon-button
              *ngIf="user && user?.type_user === 'trailer'"
              (click)="toggleLike()"
            >
              <span *ngIf="!raceLiked"
                ><i class="fa-regular fa-heart"></i
              ></span>
              <span *ngIf="raceLiked"><i class="fa-solid fa-heart"></i></span>
            </button>
          </div>
          <div class="status-wrap d-flex align-self-center">
            <span *ngIf="race?.isFallbackCourse" class="badge bg-warning"
              >Parcours de repli</span
            >
            <span *ngIf="isArchived" class="badge bg-default">Terminé</span>
            <span *ngIf="isCanceled" class="badge bg-danger">Annulée</span>
          </div>

          <div class="d-flex flex-column align-items-start py-3 _bl _no_border">
            <h4 i18n>Départ</h4>
            <span>{{ race?.date | date : "EEE dd MMM y" }}</span>
            <span>à {{ race?.departureTime | date : "HH:mm" }} </span>
          </div>
          <div
            *ngIf="race?.locality"
            class="d-flex flex-column align-items-start pb-3 _bl _no_border"
          >
            <h4 i18n>Lieu</h4>
            <span>{{ race?.locality }}</span>
          </div>
          <div
            *ngIf="race?.price"
            class="d-flex flex-column align-items-start pb-3 _bl _no_border"
          >
            <h4 i18n>Tarif inscription</h4>
            <span>{{ race?.price }}€</span>
          </div>
          <button
            class="_sp_btn _blue"
            *ngIf="(!user || user?.type_user === 'trailer') && isPublished"
            (click)="createRoadbookOrRegister()"
          >
            <span>Créer un roadbook</span>
          </button>
        </div>
      </div>

      <div class="_card p-0" *ngIf="race?.tags">
        <h3 class="_middle my-4">Thématique</h3>
        <div class="tags-wrap d-flex justify-content-start flex-wrap">
          <div
            *ngFor="let raceTag of race?.tags; let i = index"
            class="tag-item d-flex"
          >
            <div class="_contain_card d-flex align-items-center flex-wrap">
              <div class="tag-icon-wrap _md">
                <div [ngClass]="['__icon ', '_icon-' + raceTag.code]"></div>
                <div class="suggestion-infos pt-2">
                  <span class="_little">{{ raceTag.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
