import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { RaceModel } from '../../../../models/race.model';
import { UserService } from '../../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../base.component';
import { OrganizerModel } from '../../../../models/organizer.model';
import { DialogService } from '../../../../services/common/dialog.service';
import { RaceService } from '../../../../services/race/race.service';

@Component({
             selector: 'app-race-orga-list-item',
             templateUrl: './race-orga-list-item.component.html',
             styleUrls: ['./race-orga-list-item.component.scss']
           })
export class RaceOrgaListItemComponent extends BaseComponent implements OnInit {

  hasError = false;

  @Input() race: RaceModel;
  @Input() id: number;
  @Input() isLastLine: boolean;
  @Input() canModify: boolean;
  isDraft = false;
  isPublished = false;
  isArchived = false;
  isCanceled = false;
  percentComplete: number;
  @Output() raceSelected: EventEmitter<RaceModel> = new EventEmitter<RaceModel>();
  organizer: OrganizerModel;
  messageMissingRaceInfos: Array<string> = [];


  constructor(protected userService: UserService,
              public router: Router,
              private raceService: RaceService,
              private route: ActivatedRoute,
              public viewContainerRef: ViewContainerRef,
              private dialogService: DialogService) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.isDraft = this.raceService.isDraft(this.race);
    this.isPublished = this.raceService.isPublished(this.race);
    this.isArchived = this.raceService.isArchived(this.race);
    this.isCanceled = this.raceService.isCanceled(this.race);

    this.determinePercentComplete();

  }

  afterInit() {
    this.organizer = this.loggedInUser;
  }

  selectRace() {

    this.raceSelected.emit(this.race);
    this.router.navigate(['/organizer/races', this.race.id, 'show'], { relativeTo: this.route});
  }

  showRace() {
    this.raceSelected.emit(this.race);
    this.router.navigate(['/organizer/races', this.race.id, 'show'], { relativeTo: this.route });
  }

  editRace() {
    this.router.navigate(['/organizer/races', this.race.id, 'edit'], { relativeTo: this.route});
  }

  notifyTrailers(defaultSubject?: string, defaultTitle?: string, defaultContent?: string) {

    this.dialogService.notifyTrailers(this.organizer, this.race, this.viewContainerRef);
  }

  determinePercentComplete() {
    if (this.isDraft) {
      this.percentComplete = 25;

      if (this.race.gpx) {
        this.percentComplete += 25;
      }
      else{
        this.messageMissingRaceInfos.push('Ajouter un tracé GPX');
      }

      if (this.race.hasPoiOrga) {
        this.percentComplete += 25;
      }
      else{
        this.messageMissingRaceInfos.push('Ajouter des Pois');
      }

      if (this.race.description) {
        this.percentComplete += 25;
      }
      else{
        this.messageMissingRaceInfos.push('Ajouter une description');
      }
    }
  }
}
