import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-duration-format';


@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value, args?) {
        return moment.duration(value, 'seconds').format('hh[h] mm[m] ss[s]');
    }
}
