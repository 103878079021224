import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EventListComponent } from './event-list/event-list.component';
import { SpinnerService } from '../../services/common/spinner.service';
import { ToolbarService } from '../../services/common/toolbar.service';

@Component({

             selector: 'events-cmp',
             templateUrl: './events.component.html',
             styleUrls: ['./events.component.scss']
           })

export class EventsComponent implements OnInit, AfterViewInit {

  constructor(private spinner: SpinnerService, private toolbarService: ToolbarService) {
  }

  ngOnInit() {
    this.toolbarService.showQuickSearch();
  }

  ngAfterViewInit() {
    this.spinner.stop();
  }
}
