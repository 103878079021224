import { SearchElementCriterias } from "./searchElementCriterias";
import { UserModel } from "../models/user.model";
import { ChallengeModel } from "../models/challenge.model";
import { TerrainModel } from "../models/terrain.model";
import { DisciplineModel } from "../models/discipline.model";

export class SearchEventCriterias extends SearchElementCriterias {
  name: string;
  dateSearchStart: Date;
  dateSearchEnd: Date;
  dateSearchStartTimestamp: number;
  dateSearchEndTimestamp: number;
  status: string; // un seul statut de recherche
  statuses: string[]; // plusieurs statuts de recherche parmis 'draft'|'published'|'archived'|'canceled';

  latitude: number;
  longitude: number;
  formattedAddress: string;
  postalCode: string;
  locality: string;
  country: string;
  administrativeAreaLevel1: string; // département
  administrativeAreaLevel2: string; // région

  distanceRange: [];
  ascendingElevationRange: [];
  proximity: number;
  maxPrice: number;

  challenge: number;
  terrain: number;
  discipline: number;

  maxSeats: number;
  hasTimeLimit: boolean;
  hasUtmbPoint: boolean;
  isNightRace: boolean;
  arePolesProhibited: boolean;

  // Recherche par event
  eventId: string;
  eventName: string;

  raceTags: string[]; // plusieurs tags de recherche possible;
  eventTags: string[]; // plusieurs tags de recherche possible;

  newest: boolean; // ask for most recent races created

  favourite: boolean; // favourite
  trailerId: number; // favourite

  scope: string;
  hasPublishedRaces: boolean; // only events with races

  constructor(searcher: UserModel = null) {
    super();

    // // Pour les trailers, par défaut les recherches se font sur les événements publiés
    // if (searcher && searcher.type_user === 'trailer') {
    //   this.statuses = ['published', 'archived', 'canceled'];
    // }
  }
}
