import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output } from '@angular/core';
// TODO : a réactiver import { NgUploaderOptions, NgFileSelectDirective } from 'ngx-uploader';
import { FileService } from '../../services/common/file.service';
import { HttpService } from '../../services/http/http.service';
import { SnackbarService } from '../../services/notifications/snackbar.service';
import { RoadbookModel } from '../../models/roadbook.model';
import { UtilsService } from '../../services/common/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { forkJoin } from 'rxjs';
import { UserService } from '../../services/user/user.service';

@Component({
             selector: 'uploader-img',
             templateUrl: './uploader-img.component.html',
             styleUrls: ['./uploader-img.component.scss']
           })
export class UploaderImgComponent implements OnInit {

  authorizedExtension: Array<string> = ['jpg', 'jpeg', 'png', 'gif'];
  authorizedExtensionsLabel: string;

  uploadFile: any;
  uploadProgress: number;
  uploadResponse: Object;

  totalUploadProgress: number;

  previewDatas: Array<any> = [];

  hasBaseDropZoneOver: boolean;

  uploadUrl: string;

  // TODO : a réactiver options: NgUploaderOptions;
  response: any = {};
  resp: any = {};

  bufferValue = 100;
  sizeLimit = 10000000; // 1MB
  errorMessage: string;

  inputUploadEvents: EventEmitter<string> = new EventEmitter<string>();

  // course lié à ce GPX
  @Input() roadbook: RoadbookModel;
  @Input() multiple: boolean;
  @Input() maxUpload: number;
  @Input() currentImageCount: number;

  @Output() imgQueueCompleted = new EventEmitter();
  @Output() nbTotalInQueue = new EventEmitter();
  @Output() imgUploaded = new EventEmitter<any>();
  @Output() itemsAdded = new EventEmitter<any>();
  @Output() itemsRemoved = new EventEmitter<any>();


  uploader: FileUploader;
  roadbookImageUrl: string;

  // TODO a réactiver : @ViewChild(NgFileSelectDirective) private uploaderDirective;

  constructor(@Inject(NgZone) private zone: NgZone,
              private httpService: HttpService,
              private fileService: FileService,
              private snackbarService: SnackbarService,
              public sanitizer: DomSanitizer,
              private userService: UserService) {
    this.authorizedExtensionsLabel = this.authorizedExtension.join(', ');
  }

  ngOnInit() {
    this.uploadUrl = this.httpService.baseUrl + '/api/roadbooks/' + this.roadbook.id + '/pictures';
    this.uploadProgress = 0;
    this.uploadResponse = {};
    this.initUploader();
  }

  fileOverBase(e: boolean) {
    this.hasBaseDropZoneOver = e;
  }

  startUpload() {
    this.inputUploadEvents.emit('startUpload');
  }


  handlePreviewData(data: any) {
    this.previewDatas.push(data);
  }

  /**
   *
   * @private
   */
  private initUploader() {
    this.uploader = new FileUploader({
                                       url: this.uploadUrl,
                                       isHTML5: true,
                                       disableMultipart: false,
                                       itemAlias: 'roadbookImage',
                                       method: 'post',
                                       authToken: `Bearer ${this.userService.getTokenUser().token}`,
                                       allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
                                       maxFileSize: 1048576 // 1MB,
                                     });

    this.uploader.onAfterAddingFile = file => {
      // check images dimensions
      // this.checkImageDimension(file._file, 1920, 1200);

      file.withCredentials = false;
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
      // create local url to display preview
      const url = window.URL ? window.URL.createObjectURL(file._file) : (window as any).webkitURL.createObjectURL(file._file);
      this.roadbookImageUrl = url;

      this.itemsAdded.emit(true);
    };

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      let errorMessage = '';
      switch (filter.name) {
        case 'fileSize':
          errorMessage = `Taille maximum autorisée du fichier dépassée (${item.size} sur ${this.uploader.options.maxFileSize} autorisé)`;
          break;
        case 'mimeType':
          const allowedTypes = this.uploader.options.allowedMimeType.join(',');
          errorMessage = `Seules les images de type ${allowedTypes} sont acceptées`;
          break;
        default:
          errorMessage = `Unknown error (filter is ${filter.name})`;
      }
      this.snackbarService.openDanger(errorMessage);
    };
  }


  public upload() {
    this.uploader.uploadAll();
  }

  public removeBg() {
    this.resetToDefaultBgImage();
  }


  public resetToDefaultBgImage() {
    this.uploader.clearQueue();
    this.itemsRemoved.emit(true);
    this.roadbookImageUrl = '';
  }

}
