import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SnackbarService } from '../notifications/snackbar.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorService } from '../common/error.service';

@Injectable({
              providedIn: 'root'
            })
export class JwtInterceptorService implements HttpInterceptor {
  private token: string | null;

  constructor(private router: Router, public snackbarService: SnackbarService, public errorService: ErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.token && !req.headers.has('No-Auth')) {
      req = req.clone({headers: req.headers.set('Authorization', `Bearer ${this.token}`), withCredentials: true});
    }
    req = req.clone({headers: req.headers.delete('No-Auth')});

    return next.handle(req).pipe(map((event: HttpEvent<any>) => event
    ), catchError((err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status === 503) {
          this.router.navigate(['/pages/maintenance']);
        }
        if (err.status === 401) {
          // this.errorService.handleExpiredOrInvalidSessionError(err);
        }
      }
      return throwError(err);
    }));
  }

  setJwtToken(token: string) {
    this.token = token;
  }

  removeJwtToken() {
    this.token = null;
  }

  /**
   *
   * @param token
   * @returns {boolean}
   */
  isTokenValid(token) {
    if (token && token.expires) {
      const today: Date = new Date();
      const expirationDate: Date = new Date(token.expires);
      return (expirationDate.getTime() > today.getTime());
    } else {
      return false;
    }
  }

}
