import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { EventModel } from "src/app/models/event.model";

import { environment } from "../../../../environments/environment";
import { SearchEventCriterias } from "../../../classes/searchEventCriterias";
import { TrailerModel } from "../../../models/trailer.model";
import { SearchService } from "../../../services/common/search.service";
import { EventService } from "../../../services/event/event.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { UserService } from "../../../services/user/user.service";
import { BaseComponent } from "../../base.component";

@Component({
  selector: "app-event-directory",
  templateUrl: "./event-directory.component.html",
  styleUrls: ["./event-directory.component.scss"],
  animations: [
    trigger("showHideMap", [
      // ...
      state(
        "show",
        style({
          flex: "0 0 30%",
          left: "auto",
          "max-width": "30%",
        })
      ),
      state(
        "hide",
        style({
          flex: "0 0 0",
          left: "100%",
          "max-width": 0,
        })
      ),
      transition("show <=> hide", [animate("0.3s")]),
    ]),
    trigger("fullWidthList", [
      // ...
      state(
        "full",
        style({
          flex: "0 0 100%",
          "max-width": "100%",
        })
      ),
      state(
        "narrow",
        style({
          flex: "0 0 70%",
          "max-width": "70%",
        })
      ),
      transition("full <=> narrow", [animate("0.3s")]),
    ]),
  ],
})
export class EventDirectoryComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  events: EventModel[];

  // ## GMAP ##
  // latitude par défaut de la France
  lat = environment.gmap.default.lat;
  lng = environment.gmap.default.lng;
  zoomLevel = environment.gmap.default.zoom;

  mapDisplayed = true;

  // #PAGINATION #
  page = 0;
  pageSize = 10;

  @ViewChild("gmap", { static: true }) gmap;
  @ViewChild("searchboxadvanced", { static: true }) searchboxadvanced;

  listEventsLoaded: boolean;
  eventSearched = false;

  scope: string;
  trailer: TrailerModel;

  constructor(
    private eventService: EventService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    public viewContainerRef: ViewContainerRef,
    private snackbarService: SnackbarService,
    protected userService: UserService,
    private router: Router
  ) {
    super(userService, viewContainerRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   *
   */
  afterInit() {
    const searchEventCriterias: SearchEventCriterias =
      new SearchEventCriterias();

    searchEventCriterias.hasPublishedRaces = true;

    this.route.queryParams
      .pipe(
        map((params: any) => {
          this.listEventsLoaded = false;
          this.scope = params.scope ? params.scope : "incoming";
          return { ...searchEventCriterias, ...params };
        }),
        switchMap((searchEventsCriterias: SearchEventCriterias) =>
          this.eventService.getEventsByScope(
            this.scope,
            searchEventsCriterias,
            this.loggedInUser
          )
        )
      )
      .subscribe({
        next: (events) => this.refreshResultEvent(events),
        error: () => {
          this.snackbarService.openDanger(
            "Erreur lors de la recherche des courses",
            "OK"
          );
        },
      });
  }

  searchEventsByFilters() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { scope: this.scope },
    });
  }

  handlePageChange(pageChange) {
    this.pageSize = pageChange.pageSize;
    this.page = pageChange.pageIndex;
  }

  showEvent(eventId) {
    this.router.navigate(["/events", eventId, "show"]);
  }

  toggleAdvancedSearch() {
    this.searchboxadvanced.toggleShow();
  }

  searching() {
    this.listEventsLoaded = false;
  }

  refreshSearchResults(events: Array<EventModel>) {
    this.scope = null;
    this.refreshResultEvent(events);
  }

  refreshResultEvent(events: Array<EventModel>) {
    // on ajoute les marqueurs
    this.events = events;
    this.gmap.addEventMarkers(events);
    this.listEventsLoaded = true;
    this.eventSearched = true;
  }

  bounceInMarker(itemId: number) {
    this.gmap.manageBounceMarkerByItemId(itemId, "start");
  }

  bounceOutMarker(itemId: number) {
    this.gmap.manageBounceMarkerByItemId(itemId, "stop");
  }
}
