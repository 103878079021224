import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vma-spinner',
  templateUrl: './vma-spinner.component.html',
  styleUrls: ['./vma-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VmaSpinnerComponent implements OnInit {

  @Input() percent: number;
  @Input() height = 60;

  spinnerColor: string;
  spinnerTextColor: string;
  vmaPictoUrl: string;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.refreshSpinnerColors();
  }

  /** déclenchement de la mise à jour du composent au change du roadbook
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (changes.percent) {
      this.refreshSpinnerColors();
    }
  }

  /**
   *
   */
  refreshSpinnerColors() {

    if (this.percent >= 0 && this.percent <= 40) {
      this.spinnerColor = 'info';
      this.spinnerTextColor = 'text-info';
      this.vmaPictoUrl = '/assets/img/app/icons/picto-vma-1.png';
    }

    if (this.percent > 40 && this.percent <= 75) {
      this.spinnerColor = 'info';
      this.spinnerTextColor = 'text-info';
      this.vmaPictoUrl = '/assets/img/app/icons/picto-vma-2.png';
    }

    if (this.percent >= 75 && this.percent <= 82) {
      this.spinnerColor = 'warn';
      this.spinnerTextColor = 'text-warning';
      this.vmaPictoUrl = '/assets/img/app/icons/picto-vma-3.png';
    }

    if (this.percent > 82) {
      this.spinnerColor = 'accent';
      this.spinnerTextColor = 'text-danger';
      this.vmaPictoUrl = '/assets/img/app/icons/picto-vma-4.png';
    }
  }

}
