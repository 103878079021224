import { RaceModel } from './race.model';
import { UserModel } from './user.model';
import moment from 'moment';
import { TerrainModel } from './terrain.model';
import { RaceTagModel } from './race-tag.model';
import { EventTagModel } from './event-tag.model';

/**
 * Created by macbookaxel on 10/06/2016.
 */
export class EventModel {
  id: number;
  name: string;
  description: string;
  terrain: TerrainModel = null;
  terrainId: number;

  tags?: Array<EventTagModel>;

  races: Array<RaceModel>;
  private _dateBegin: Date = null;
  private _dateEnd: Date = null;

  edition: number;
  website: string;
  facebookPage: string;
  instagramPage: string;
  status: 'draft' | 'published' | 'archived' | 'deleted'| 'canceled';
  organizer: UserModel;
  organizerId: number;
  created: Date;
  updated: Date;

  formattedAddress: string;
  postalCode: string;
  locality: string;
  country: string;
  administrativeAreaLevel1: string; // département
  administrativeAreaLevel2: string; // région

  latitude: number;
  longitude: number;

  // tslint:disable-next-line:variable-name
  private _dateEventDisplay: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  get dateBegin(): Date {
    return this._dateBegin;
  }

  set dateBegin(dateBegin: Date) {
    this._dateBegin = new Date(dateBegin);
  }

  get dateEnd(): Date {
    return this._dateEnd;
  }

  set dateEnd(dateEnd: Date) {
    this._dateEnd = new Date(dateEnd);
  }

  get dateEventDisplay(): string {

    const dateBegin: Date = this.dateBegin;
    const dateEnd: Date = this.dateEnd;

    if (dateBegin.getTime() === dateEnd.getTime()) {
      this._dateEventDisplay = moment(dateBegin).format('DD MMMM YYYY');
    } else if (dateBegin.getDate() !== dateEnd.getDate() && dateBegin.getMonth() === dateEnd.getMonth()) {
      this._dateEventDisplay = dateBegin.getDate() + '-' + moment(dateEnd).format('DD MMMM YY');
    } else if (dateBegin.getMonth() !== dateEnd.getMonth()) {
      this._dateEventDisplay = moment(dateBegin).format('DD MMMM YY') + ' - ' + moment(dateEnd).format('DD MMMM YY');
    }
    return this._dateEventDisplay;
  }
}
