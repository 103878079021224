import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Subscription } from 'rxjs';
import { TrailerModel } from '../models/trailer.model';
import { OrganizerModel } from '../models/organizer.model';

@Component({
             // create seletor base for test override property
             selector: 'app-base-cmp',
             template: ''
           })
export abstract class BaseComponent implements OnInit, OnDestroy {

  loggedInUser: TrailerModel | OrganizerModel;
  loginSubscription: Subscription;

  // droit d'accès
  canModify = false;
  canPublish = false;
  canDelete = false;

  areFormsSaved = false;
  unsavedChanges = false;

  constructor(protected userService: UserService, public viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    // user connecté
    this.loginSubscription = this.userService.userEvents.subscribe
    (user => {
      this.loggedInUser = user;
      this.afterInit();
    });
  }

  abstract afterInit();

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.id === i2.id;
  }

}
