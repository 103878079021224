/**
 * Created by macbookaxel on 10/06/2016.
 */
export interface UtmbIndexModel {
  id: number;
  name: string;
}

export enum UtmbIndexEnum {
  "20k" = "20K",
  "50k" = "50K",
  "100k" = "100K",
  "100m" = "100M",
}
