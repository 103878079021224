import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent  {

  public title: string;
  public message: string;
  public extraAction: string;
  public extraMessage: string;
  public noLabel: string;
  public yesLabel: string;

  constructor(public dialogRef: MatDialogRef<ModalComponent>) {
  }

}
