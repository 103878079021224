import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchParam } from '../../classes/search-param';
import { HttpService } from '../http/http.service';
import { SearchRaceCriterias } from '../../classes/searchRaceCriterias';
import { SearchEventCriterias } from '../../classes/searchEventCriterias';
import { SearchRoadbookCriterias } from '../../classes/searchRoadbookCriterias';


@Injectable({
              providedIn: 'root'
            })
export class SearchService {

  private searchParams: Array<SearchParam> = [];

  private arrayOfCriterias;

  listRacesUrl = '/api/races';
  listEventsUrl = '/api/events';
  listRoadbooksUrl = '/api/roadbooks';
  listPoisUrl = '/api/pois';

  searchUrl: string;

  public lastRaceResults: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(undefined);
  public lastEventResults: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(undefined);

  public _fastSearchInputClear: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public lastResults: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(undefined);

  public searchMobileOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpService) {
  }

  /** Recherche de course multi-critères
   *
   * @param searchRaceCriterias
   * @returns {*}
   */
  searchElementByCriterias(element: string,
                           searchCriterias: SearchRaceCriterias | SearchEventCriterias | SearchRoadbookCriterias = null): Observable<Array<any>> {

    const searchUrl = this.determineSearchUrl(element);

    this.searchParams = [];

    if (searchCriterias) {
      this.arrayOfCriterias = Object.keys(searchCriterias);

      this.arrayOfCriterias.forEach((key) => {

        // si le critère est un boolean, on le convertie en number
        let value = searchCriterias[key];
        if (typeof value === 'boolean') {value = +value; }

        // si c'est un objet, on prend l'id
        this.searchParams.push(new SearchParam(key, value));
      });
    }
    return this.http.get(searchUrl, this.searchParams);
  }


  /** Retourne l'url de recherche en fonction de l'élément
   *
   * @param element
   */
  private determineSearchUrl(element: string) {

    let searchUrl: string;
    switch (element) {
      case 'event':
        searchUrl = this.listEventsUrl;
        break;

      case 'roadbook':
        searchUrl = this.listRoadbooksUrl;
        break;

      case 'race':
        searchUrl = this.listRacesUrl;
        break;

      case 'poi':
        searchUrl = this.listPoisUrl;
        break;
      default:
        break;
    }

    return searchUrl;

  }

}
