import {RaceModel} from '../race.model';
import {PoiTypeModel} from './poi.type.model';
import {UserModel} from '../user.model';
import {EstimationModel} from './estimation.model';
import {RaceOffModel} from '../race-off.model';
import {RoadbookModel} from '../roadbook.model';

/**
 * Created by macbookaxel on 10/06/2016.
 */
export class PoiModel {
  id: number;

  internId: string; // clé temporaire du POI qui permet de discriminer
  // le POI en cours de modification pour la recherche du poi le plus proche

  name: string;
  idx: number;
  elevation = 0; // elévation du poi
  distanceInput = 0; // distance saisie par le user
  distance = 0; // distance réelle en fonctiond des POIS calculée
  latitude = 0;
  longitude = 0;

  cumulativePositiveElevation = 0;
  cumulativeNegativeElevation = 0; // distance depuis le départ du poi
  origin: string;

  race: RaceModel;
  raceId: number;

  raceOff: RaceOffModel;

  poiTypes: PoiTypeModel[];
  poiType: PoiTypeModel;
  poiTypeId: number;

  user: UserModel;
  userId: number;

  timeLimit: string; // heure barriere horaire

  // données sur le segment

  estimatedAverageSpeed: number;
  estimatedAveragePace: string;
  estimatedDuration: number;

  measuredAverageSpeed: number;
  measuredAveragePace: string;
  measuredDuration: number;

  accurateEstimationPercentage = 0;

  // donnée global, depuis le départ

  globalEstimatedAverageSpeed: number;
  globalEstimatedAveragePace: string;
  globalEstimatedDuration: number;

  globalMeasuredAverageSpeed: number;
  globalMeasuredAveragePace: string;
  globalMeasuredDuration: number;

  //
  previousPoiName: string;
  previousPoiEta: any;
  distanceInBetween: number;
  cumulativePositiveElevationInBetween: number;
  cumulativeNegativeElevationInBetween: number;

  estimatedTimeOfArrival: Date; // Date
  estimatedTimeOfArrivalDisplay: Date;
  measuredTimeOfArrival: Date; // Date
  measuredTimeOfArrivalDisplay: Date;

  isFinishLine: boolean;

  trailerEstimation: EstimationModel;
  estimations: Array<EstimationModel>;
}
