<div class="event-item d-flex flex-wrap px-3 py-4">
  <div class="_normal_block d-flex col-12 col-md-6 col-lg-4" (click)="showEvent()" role="button">
    <div>
      <h5 class="event_badge">Inscriptions ouvertes</h5>
      <h4>
        <a [routerLink]="['/events',event?.id, 'show']"> {{ event?.name }}</a>
      </h4>
    </div>
  </div>
  <div class="_normal_block ps-3 d-flex flex-grow-1" (click)="showEvent()" role="button">
    <div class="w-100">
      <span class="event_date">{{ dateEventDisplay }}</span>
      <p>{{ event?.locality }} <span *ngIf="event?.postalCode"> ({{event?.postalCode | slice:0:2 }}) </span></p>
      <div class="d-flex flex-wrap distances-wrap">
        <div *ngFor="let race of event?.races; let i=index" class="distance-item __distance" (click)="showRace(race.id)" role="button">
          <span class="__d">{{ race?.distance | number : '1.0-2'
            }} km</span>
        </div>
      </div>
    </div>
  </div>
  <div class="_normal_block d-flex">
    <div class="tags-wrap d-flex align-items-end my-2">
      <div *ngFor="let raceTag of raceTagsReduced?.uniqueRaceTags.slice(0,3); let i=index" class="tag-item d-flex">
        <div class="_contain_card d-flex align-items-center">
          <div class="tag-icon-wrap _sm">
            <div [ngClass]="['__icon ', '_icon-'+raceTag.code]"  [matTooltip]="raceTag.name" ></div>
          </div>
        </div>
      </div>
      <div class="tag-item d-flex" *ngIf="raceTagsReduced?.extraTagLabel"
           [matTooltip]="raceTagsReduced?.extraTagLabel">
        <div class="_contain_card d-flex align-items-center">
          <div class="tag-icon-wrap  _sm">
            <div class="__icon _sm d-flex justify-content-center align-items-center">
              <i class=" fa-solid fa-ellipsis"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
