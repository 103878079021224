import {Component, EventEmitter, Input, Output} from '@angular/core';

import {UserService} from '../../../services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RaceOffModel} from '../../../models/race-off.model';

@Component({
  selector: 'app-race-off-list-item',
  templateUrl: './race-off-list-item.component.html',
  styleUrls: ['./race-off-list-item.component.scss']
})
export class RaceOffListItemComponent  {
  hasError = false;

  @Input() raceOff: RaceOffModel;
  @Input() id: number;
  @Output() raceSelected: EventEmitter<RaceOffModel> = new EventEmitter<RaceOffModel>();

  constructor(protected userService: UserService) {

  }


}
