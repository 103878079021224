<mat-toolbar class="toolbar-trailer __splits_toolbar fixed-top">
  <div class="d-flex flex-row align-items-center justify-content-start container-splits">

    <div class="logo-wrap">
      <a [routerLink]="'/'"><img
        src="/assets/img/app/logo_black.png" alt="Splits" class="logo"></a>
    </div>
    <div class="search-box-wrap align-self-baseline p-2 d-none d-lg-block" *ngIf="hideSearchShow">
      <div class="">
        <app-search-box-lite [minimized]="true"></app-search-box-lite>
      </div>
    </div>

    <div class="d-flex ml-auto">

      <div class="d-flex" *ngIf="!user">

        <a [routerLink]="['/organizer']" class="_sp_btn _white">
          <span>Vous êtes un organisateur ?</span>
        </a>

        <div class="start-button-wrap">
          <button id="my-account-btn" type="button" class="_sp_btn _blue" i18n [matMenuTriggerFor]="startMenu"
                  matTooltip="Accéder à mon espace trailer" matTooltipShowDelay="1000" aria-label="Mon compte"><span>Mon compte</span>
          </button>
          <!-- this menu starts as hidden by default -->
          <mat-menu #startMenu="matMenu" class="__connexion_menu">
            <button id="btn-signin" mat-menu-item (click)="signIn()">
              <span>Me connecter</span>
            </button>
            <button id="btn-register" mat-menu-item (click)="register()">
              <span>M'inscrire</span>
            </button>
            <mat-divider></mat-divider>
            <button id="btn-faq" mat-menu-item (click)="faq()">
              <i class="fa-regular fa-circle-question text-success"></i>
              <span>Aide</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="jewel-button d-flex align-items-center">
        <!--        <notification-widget></notification-widget>-->
      </div>
      <div class="account-wrap d-flex" *ngIf="user">
        <div class="toolbar-icon d-flex align-items-center">
          <button (click)="showBabassSplits()" mat-icon-button matTooltip="Babass Splits" matTooltipShowDelay="1000">
            <img src="assets/img/app/trailer/picto_trailer_babass.svg">
          </button>
        </div>
        <!-- notification widget-->
        <app-account-nav-menu [user]="user"></app-account-nav-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
