/**
 * Created by macbookaxel on 10/06/2016.
 */

export enum PoiTypes {
  TIME_LIMIT = 1,
  FOOD_BEV = 2,
  BEV = 3,
  LIFE_HOUSE = 4,
  CHECK_POINT = 5,
  FINISH = 6,
  SHUTTLE = 7,
  COMPANION = 8,
}

export class PoiTypeModel {
  constructor(id?: number, code?: string, name?: string) {
    this.id = id;
    this.code = code;
    this.name = name;
  }

  id: number;
  code: string;
  name: string;
}
