import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UserService } from '../../../services/user/user.service';
import { NotificationService } from '../../../services/notifications/notification.service';
import { NotificationModel } from '../../../models/notification.model';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { CommonService } from '../../../services/common/common.service';

@Component({
             selector: 'notification-widget',
             templateUrl: './notification-widget.component.html',
             styleUrls: ['./notification-widget.component.scss']
           })
export class NotificationWidgetComponent extends BaseComponent {

  _baseUrlCta: string;

  _newNotificationCount = 0;

  _show = false;

  _iconTypeNotification: string;

  nbResultToDisplay = 5;

  @Input('notifications') notifications: Array<NotificationModel> = [];
  newNotifications: Array<NotificationModel> = [];

  checkTimer: Observable<any>;

  constructor(protected userService: UserService,
              private notificationService: NotificationService,
              private snackbarService: SnackbarService,
              public viewContainerRef: ViewContainerRef) {
    super(userService, viewContainerRef);
  }

  ngOnInit() {
   super.ngOnInit();

    // on check toutes les 15 secondes
    this.checkNotificationInterval(15000);
  }

  /**
   *
   * @param interval
   */
  checkNotificationInterval(intervalInMs: number) {

    interval(intervalInMs).pipe(map(() =>
                                       this.notificationService.getNotitifications(this.loggedInUser).pipe(
                                         map((notifications: any) => {
                                           // selection du bon icon en fonction du type de notif
                                           notifications.forEach((notification: NotificationModel) => {
                                             notification.iconNotificationTypeClass = this.notificationService.getNotificationIconClass(
                                               notification.type);
                                           });
                                           return notifications;
                                         }),
                                         map((notifications: any) => {
                                           notifications.forEach(
                                             (notification: NotificationModel) => {
                                               if (notification.callToActionCode) {
                                                 notification.callToAction = this.notificationService.handleNotificationCallToAction(
                                                   notification.callToActionCode,
                                                   this._baseUrlCta,
                                                   notification.relatedObject,
                                                   notification.relatedObjectId);
                                               }
                                             });
                                           return notifications;
                                         })).subscribe(
                                         (notifications) => {
                                           this.notifications = notifications;
                                           this.newNotifications = notifications.filter(
                                             notification => (notification.notificationUsers[0].isNew));
                                           this._newNotificationCount = this.newNotifications.length;
                                         })
    ));

  }


  afterInit() {
    this._baseUrlCta = '/' + this.loggedInUser.type_user;
  }

  hide() {
    this._show = false;
    this.nbResultToDisplay = 5;

    // déblocage du scroll du body
    // this.commonService.scroll(true);
  }

  show() {
    this._show = true;

    // blocage du scroll du body
    // this.commonService.scroll(false);

    this.clear();
  }


  clear() {
    // on passe toutes les notitications à isNew = false;
    this._newNotificationCount = 0;
    this.notificationService.clearNewNotifications(this.notifications)
      .subscribe(() => {
                 },
                 error => this.snackbarService.openDanger('Erreur lors du nettoyage des notifications'));
  }

  more() {
    this.nbResultToDisplay += 5;
  }

  /**
   *
   */
  togglePanel() {

    if (this._show) {
      this.hide();
    } else {
      this.show();
    }
  }
}
