import {
  Component,
  EventEmitter,
  Output,
  ViewContainerRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { UserService } from "../../../services/user/user.service";
import { Router } from "@angular/router";
import { ORGANIZER, TRAILER, UserModel } from "../../../models/user.model";
import { NavService } from "../../../services/common/nav.service";
import { BabassComponent } from "../../babass/babass.component";
import { DialogService } from "../../../services/common/dialog.service";
import { BaseComponent } from "../../base.component";
import { ShowHideState } from "../../../classes/LoaderState";
import { Subscription } from "rxjs";
import { ToolbarService } from "../../../services/common/toolbar.service";
import { SignInComponent } from "../../sign-in/sign-in.component";

@Component({
  selector: "toolbar-trailer",
  templateUrl: "./toolbar-trailer.component.html",
  styleUrls: ["./toolbar-trailer.component.scss"],
})
export class ToolbarTrailerComponent extends BaseComponent {
  user: UserModel;
  userEventsSubscription: any;
  searchScope: string[] = ["race"];

  @Output() openSideBar = new EventEmitter();

  dialogBabassRef: MatDialogRef<BabassComponent>;

  toolbarExpanded: boolean;

  isConnected: boolean;

  private subscription: Subscription;
  hideSearchShow: boolean;
  private;

  constructor(
    protected userService: UserService,
    private router: Router,
    private navbarService: NavService,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    private toolbarService: ToolbarService
  ) {
    super(userService, viewContainerRef);
    this.toolbarExpanded = true;
    this.isConnected = false;
  }

  ngOnInit() {
    super.ngOnInit();

    /** Gestion affichage de la sidebar
     *
     * @type {Subscription}
     */
    this.subscription = this.toolbarService.quickSearchState.subscribe(
      (state: ShowHideState) => {
        this.hideSearchShow = state.show;
      }
    );
  }

  afterInit() {
    // nothing to do
    this.user = this.loggedInUser;
  }

  toogleExpandNavbar() {
    if (!this.toolbarExpanded) {
      this.navbarService.expand();
      this.toolbarExpanded = true;
    } else {
      this.navbarService.collapse();
      this.toolbarExpanded = false;
    }
  }

  signIn() {
    this.dialogService.openSignInPopup(TRAILER, 0);
  }

  register() {
    this.dialogService.openSignInPopup(TRAILER, 1);
  }

  faq() {
    this.router.navigate(["pages/faq"]);
  }

  /**
   *
   */
  showBabassSplits() {
    // config popup
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = "500px";
    dialogConfig.maxWidth = "100%";

    this.dialogBabassRef = this.dialog.open(BabassComponent, dialogConfig);

    // La popup renvoi le poi créé après fermeture de la popup
    this.dialogBabassRef.afterClosed().subscribe((ret: any) => {
      if (
        typeof ret !== "undefined" &&
        typeof ret.poi !== "undefined" &&
        ret.action == "add"
      ) {
      }
      this.dialogBabassRef = null;
    });
  }

  goToLandingOrga() {
    this.router.navigate(["/about-orga"]);
  }
}
