<div class="overlay transparent" [ngClass]="{show: _show}" (click)="hide()"></div>
<div class="notification-widget-wrap">
    <!--<div class="notification-button-wrap d-flex align-items-center" [ngClass]="{'hasNew': _newNotificationCount > 0}"-->
         <!--(click)="togglePanel()">-->
        <!--<i class="icon-splits_notification splits-icon-l"></i>-->
        <!--<div class="notification-count"><span>{{ _newNotificationCount }}</span></div>-->
    <!--</div>-->

    <div class="notification-button-wrap" [ngClass]="{'hasNew': _newNotificationCount > 0}">
        <button mat-icon-button (click)="togglePanel()" matTooltip="Mes notifications" matTooltipShowDelay="1000">
            <!--<i class="icon-splits_notification splits-icon-l"></i>-->
            <mat-icon>notifications</mat-icon>
            <div class="notification-count"><span>{{ _newNotificationCount }}</span></div>
        </button>
    </div>
    <div class="notification-card-wrap" [ngClass]="{'show': _show}">
        <mat-card class="z-depth-4">
            <mat-card-title>Notifications</mat-card-title>

            <mat-card-content>

                <div class="notification-list-wrap">
                    <mat-list dense>
                        <div *ngIf="notifications?.length==0" class="clearfix">
                            <h3 matSubheader>Aucune notification</h3>
                        </div>
                        <div *ngIf="notifications?.length>0" class="clearfix">

                            <mat-list-item
                                    *ngFor="let notification of notifications | slice:0:nbResultToDisplay; let i=index">
                                <div matLine class="list-item-wrap">
                                    <div class="d-flex align-items-top list-item-content-wrap">
                                        <div class="notification-icon-wrap">
                                            <i [ngClass]="notification.iconNotificationTypeClass" class="splits-icon-md"></i>
                                        </div>
                                        <div class="notification-content-wrap d-flex flex-column">
                                            <span class="subject text-wrap">{{ notification.subject }}</span>
                                            <span class="title text-wrap">{{ notification.title }}</span>
                                            <div *ngIf="notification.callToAction" class="call-to-action-wrap">
                                                <a class="cta-link mx-auto" href="{{notification.callToAction?.url}}">{{notification.callToAction?.label}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-list-item>
                        </div>
                    </mat-list>
                </div>

                <div *ngIf="notifications?.length>nbResultToDisplay" class="more-wrap" >
                    <a mat-button color="accent" (click)="more()" class="more">Voir plus</a>
                </div>

            </mat-card-content>


        </mat-card>
    </div>
</div>
