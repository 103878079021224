export class CallToAction {

    code: string;
    label: string;
    url: string;

    constructor(code: string, label: string = null, url: string = null) {
        this.code = code;
        this.label = label;
        this.url = url;
    }
}
