import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {EventService} from '../../../services/event/event.service';
import {EventModel} from '../../../models/event.model';
import {take, tap} from 'rxjs/operators';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {ToolbarService} from '../../../services/common/toolbar.service';
import {Router} from '@angular/router';
import {SearchEventCriterias} from '../../../classes/searchEventCriterias';
import {SwiperOptions} from 'swiper';
import {DataService} from '../../../services/common/data.service';
import {RaceTagModel} from '../../../models/race-tag.model';
import {ErrorService} from '../../../services/common/error.service';
import {RoadbookService} from '../../../services/roadbook/roadbook.service';
import {RoadbookModel} from '../../../models/roadbook.model';
import {SearchService} from '../../../services/common/search.service';


@Component({
  selector: 'app-landing-trailer',
  templateUrl: './landing-trailer.component.html',
  styleUrls: ['./landing-trailer.component.scss']
})
export class LandingTrailerComponent implements OnInit, AfterViewInit {

  swipperRacesConfig: SwiperOptions;
  swipperCategoryConfig: SwiperOptions;
  incomingEvents: Array<EventModel> = [];
  incomingEventsLoaded = false;

  @ViewChild('quicksearchwrap') quicksearchwrap: ElementRef<HTMLInputElement>;

  scrollingSubscription: any;
  countTotalEvents = 0;
  raceTags: Array<RaceTagModel>;
  tagsLoaded = false;

  constructor(
    private router: Router,
    public scroll: ScrollDispatcher,
    private eventService: EventService,
    private roadbookService: RoadbookService,
    private dataService: DataService,
    private searchService: SearchService,
    private errorService: ErrorService,
    private toolbarService: ToolbarService) {

    this.swipperRacesConfig = {
      slidesPerView: 4.5,
      spaceBetween: 20,
      resizeObserver: true,
      navigation: false,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.1,
          spaceBetween: 0
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1.3,
          spaceBetween: 0
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2.5,
          spaceBetween: 10
        },
        900: {
          slidesPerView: 3.2,
          spaceBetween: 5
        },
        1300: {
          slidesPerView: 3.2,
          spaceBetween: 5
        },
        1600: {
          slidesPerView: 3.4,
          spaceBetween: 10
        },
        1750: {
          slidesPerView: 4.2,
          spaceBetween: 10
        }
      }
    };

    this.swipperCategoryConfig = {
      slidesPerView: 4.5,
      spaceBetween: 20,
      resizeObserver: true,
      navigation: false,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 3.4,
          spaceBetween: 0
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 4.6,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 6.2,
          spaceBetween: 20
        },
        992: {
          slidesPerView: 7.5,
          spaceBetween: 10
        },
        1400: {
          slidesPerView: 8.5,
          spaceBetween: 10
        },
        1700: {
          slidesPerView: 9.5,
          spaceBetween: 10
        }
      }
    };
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    this.toolbarService.hideQuickSearch();

    this.getIncomingEvents();

    this.getTags();
  }

  private getIncomingEvents() {

    const searchEventCriterias: SearchEventCriterias = new SearchEventCriterias();
    searchEventCriterias.status = 'published';
    searchEventCriterias.hasPublishedRaces = true;

    this.eventService.getEventsByScope('incoming', searchEventCriterias)
      .pipe(tap((events) => this.countTotalEvents = events.length), take(4))
      .subscribe(events => {
        this.incomingEvents = events;
        this.incomingEventsLoaded = true;
      });
  }

  /**
   *
   * @param eventId
   */
  private getTags() {
    this.dataService.getDropDownList('raceTags').subscribe((raceTags: Array<RaceTagModel>) => {
      this.tagsLoaded = true;
      this.raceTags = raceTags;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  searchByTag(raceTag) {
    const searchParams = {raceTag};
    this.router.navigate(['events'], {queryParams: searchParams});
  }

  @HostListener('window:scroll', ['$event'])
  onQuickSearchScroll(element) {
    const position = this.quicksearchwrap.nativeElement.getBoundingClientRect();
    if (position.y <= -50) {
      this.toolbarService.showQuickSearch();
    } else {
      this.toolbarService.hideQuickSearch();
    }
  }

  goToLandingOrga() {
    this.router.navigate(['/about-orga']);
  }

  openSearch() {
    this.searchService.searchMobileOpened.next(true);
  }
}
