<div class="container-splits">

    <div class="row">
        <button mat-button navigateBack>
            <i class="icon-splits_back splits-icon-md"></i>
            Retour
        </button>
        <div class="col-12">
            <h3>Questions fréquentes</h3>
        </div>
    </div>



    <!-- FAQ ORGA -->
    <div class="row" *ngIf="showFaq('organizer')">
        <div class="col-md-12">
            <h2 class="mb-5">Organisateurs</h2>
            <mat-accordion>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="faq-items-wrap">

                                    <h4>Connexion à Splits</h4>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Demande de Référencement</span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>
                                            Si vous souhaitez référencer vos évènements Trail, remplissez le Formulaire
                                            « Demande de référencement ». <br>
                                            L’équipe SPLITS sera notifiée et pourra activer votre compte. Vous recevrez
                                            alors un Mail de confirmation. Vos identifiants de connexion seront ceux
                                            renseignés dans le Formulaire de Demande de Référencement.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Mot de passe oublié ?</span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Cliquez sur « Vous avez oublié votre mot de passe » depuis la page de
                                            connexion, puis consultez votre boîte mail afin de générer un nouveau mot de
                                            passe.</p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Problème de connexion
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Vérifiez que vous êtes bien sur la page de connexion « Espace Organisateur ».
                                            Si le problème de connexion persiste, contacter l’équipe support de SPLITS :
                                            <a href="mailto:contact@splits.fr" matTooltip="Contacter Splits"
                                               matTooltipShowDelay="1000">contact@splits.fr</a>
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Lexique SPLITS
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ul>
                                            <li>Organisateur : personne physique ou morale organisant une manifestation
                                                sportive.
                                            </li>
                                            <li>Evénement : ensemble d’une ou plusieurs course(s)
                                            </li>
                                            <li>Course : parcours d’un évènement caractérisé avec un profil spécifique
                                            </li>
                                            <li>Profil : Données d’une course sous forme de tableau/graphique (distance,
                                                dénivelé, horaires, POI..)
                                            </li>
                                            <li>Point d’intérêt (POI) : point de données fournies par l’organisateur
                                                d’un évènement (ravitaillement, point de contrôle, barrière horaire..)
                                            </li>
                                        </ul>


                                    </mat-expansion-panel>


                                    <h4>Mon Profil</h4>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Modifier mon mot de passe</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis « Mon Profil », modifiez le mot de passe et enregistrez les
                                            modifications.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Désactiver l’envoi de notifications par email</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Décochez la case « Recevoir les notifications par email ? » dans « Mon
                                            Profil ». Enregistrez la modification.</p>

                                    </mat-expansion-panel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="faq-items-wrap ">

                                    <h4>Evénements et Courses</h4>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Créer un Evènement</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis l’entrée de menu « Evènements et courses », cliquez sur le bouton
                                            « Ajouter un évènement ».
                                            Remplissez toutes les données obligatoires pour pouvoir le sauvegarder.
                                            L’évènement créé sera stocké dans l’onglet « En cours d’édition » de la page
                                            « Evènements et courses ».
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Publier un Evènement</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis l’entrée de menu « Evènements et courses », cliquez sur le pictogramme
                                            « Publier l’évènement ».
                                            Si l’évènement à publier a au moins une course rattachée, une Pop-Up s’ouvre
                                            vous proposant de publier les courses ou de ne pas publier les courses.
                                            Elles resteront alors à l’état de « Brouillon ».
                                            L’évènement publié sera déplacé dans l’onglet « Publié» de la page
                                            « Evènements et courses ».
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Modifier un Evènement</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis l’entrée de menu « Evènements et courses », cliquez sur le pictogramme
                                            « Modifier l’évènement ».
                                            Sauvegardez les données modifiées.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Supprimer un Evènement</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis l’entrée de menu « Evènements et courses », cliquez sur le pictogramme
                                            « Supprimer l’évènement ».
                                            Une Pop-Up vous propose d’annuler ou de continuer l’action de suppression
                                            qui est irréversible.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Créer une Course</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Depuis l’entrée de menu « Evènements et courses », déroulez les chevrons de
                                            l’évènement et cliquez sur le bouton « Ajouter une course ».
                                            Renseignez les données obligatoires pour pouvoir enregistrer la course.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Modifier une Course</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Vous pouvez modifier les données d’une course soit depuis l’entrée de menu
                                            « Evènements et courses », en cliquant sur le pictogramme « Modifier », soit
                                            depuis la Fiche de la course (pictogramme « Consulter ») en cliquant sur le
                                            bouton « Modifier ».
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Télécharger le .GPX de la Course</span>
                                            </mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Cf. « Modifier une course » pour accéder au mode édition de la course.
                                            Le Fichier.GPX peut être glissé-déposé ou téléchargé.
                                            SPLITS va analyser la qualité du Fichier. S’il n’est pas adapté à la qualité
                                            d’affichage souhaitée, il sera rejeté.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Ajouter des POI (points d’intérêt)</span>
                                            </mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Après avoir téléchargé le fichier GPX, vous pouvez ajouter des Points
                                            d’Intérêt :
                                        </p>
                                        <ul>
                                            <li>soit en cliquant sur le bouton « Ajouter un POI »</li>
                                            <li>soit en cliquant sur le Tracé de la course</li>
                                        </ul>
                                        <p>Les données à renseigner sont « Nom du point » / « Type de point » /
                                            « Kilométrage ».
                                            Les types de POI sont :
                                        </p>
                                        <ul>
                                            <li>Barriere horaire</li>
                                            <li>Base de vie</li>
                                            <li>Point de controle</li>
                                            <li>Ravitaillement</li>
                                            <li>Ravitaillement liquide</li>
                                        </ul>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Supprimer un POI</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Cliquez sur le POI et dans la pop-up, cliquez sur le bouton « Supprimer ».
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Annuler une Course</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Une course peut être annulée après avoir été publiée. Depuis l’entrée de menu
                                            « Evènements et courses », déroulez les chevrons de l’évènement et cliquez
                                            sur le pictogramme « Annuler la course ». Une Pop-Up de confirmation de
                                            l’annulation de la course s’ouvre. Vous pouvez Annuler l’action. La
                                            confirmatoin est définitivé. Une notification sera envoyée aux coureurs
                                            ayant créé un roadbook sur le Profil de la course.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Supprimer une Course</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Si la course est à l’état de « Brouillon », vous pouvez la supprimer en
                                            cliquant sur le picto « Supprimer » depuis l’entrée de menu « Evènements et
                                            courses ».
                                            Si la course est à l’état « Publiée, il faut l’annuler dans un premier temps
                                            (cf. Annuler une course).
                                            Vous pouvez alors la supprimer en cliquant sur le picto « Supprimer » depuis
                                            l’entrée de menu « Evènements et courses ».
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Créer un Parcours de Repli</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>La fonctionnalité « Créer un parcours de repli » n’est activée que pour une
                                            course « Publiée ».
                                            Depuis l’entrée de menu « Evènements et courses », déroulez les chevrons de
                                            l’évènement et cliquez sur le bouton « Créer un parcours de repli » pour la
                                            course concernée. Une Pop-Up s’ouvre vous indiquant les conséquences liées à
                                            la création d’un parcours de repli, comme ci-dessous. Vous pouvez alors
                                            annuler l’action ou la confirmer.
                                        </p>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Notifier les coureurs</span></mat-panel-title>

                                        </mat-expansion-panel-header>
                                        <p>Lorsque des coureurs vont créer un roadbook sur le tracé d’une de vos
                                            courses, une pastille apparaîtra au niveau du bouton « Notifier les
                                            coureurs » sur la fiche de la course. Cette pastille indique le nombre de
                                            roadbooks créés. En cliquant sur le bouton « Notifier les coureurs », vous
                                            pourrez envoyer des informations relatives à la course aux coureurs
                                            concernés.
                                        </p>
                                    </mat-expansion-panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-accordion>
        </div>
    </div>


    <!-- FAQ TRAILERS -->

    <div class="row __wrap_faq_trailer" *ngIf="showFaq('trailer')">
        <div class="col-md-12">
            <h2 class="mb-5">Traileurs</h2>
            <mat-accordion>
                <div class="row">
                    <div class="col-12 col-md-6 p-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="faq-items-wrap ">

                                    <h4>Connexion à Splits</h4>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Mot de passe oublié ?</span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Cliquez sur « Vous avez oublié votre mot de passe » depuis la page de
                                            connexion, puis consultez votre boîte mail afin de générer un nouveau mot de
                                            passe.</p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel >
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Lexique SPLITS
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ul>
                                            <li>Evénement : ensemble d’une ou plusieurs course(s)
                                            </li>
                                            <li>Course : parcours d’un évènement caractérisé avec un profil spécifique
                                            </li>
                                            <li>Profil : Données d’une course sous forme de tableau/graphique (distance,
                                                dénivelé, horaires, POI..)
                                            </li>
                                            <li>Point d’intérêt (POI) : point de données fournies par l’organisateur
                                                d’un évènement (ravitaillement, point de contrôle, barrière horaire..)
                                            </li>
                                        </ul>


                                    </mat-expansion-panel>


                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Problème de connexion
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Contacter l’équipe support de SPLITS : <a href="mailto:contact@splits.fr"
                                                                                     matTooltip="Contacter Splits"
                                                                                     matTooltipShowDelay="1000">contact@splits.fr</a>
                                        </p>

                                    </mat-expansion-panel>


                                    <h4>Mon Profil</h4>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Modifier mon mot de passe</span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Depuis « Mon Profil », modifiez le mot de passe et enregistrez les
                                            modifications.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Désactiver l’envoi de notifications par email
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <p>Décochez la case « Recevoir les notifications par email ? » dans « Mon
                                            Profil ». Enregistrez la modification.
                                        </p>
                                    </mat-expansion-panel>


                                    <h4>Mes Roadbooks</h4>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Créer un roadbook</span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Dans la fiche d’une course (cf. rechercher une course), cliquez sur « Créer
                                            un roadbook ». Pensez à le sauvegarder.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Ajouter des POIs (points d’intérêt)
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>A la 1ère Etape de création d’un roadbook, vous pouvez ajouter des POIs sur
                                            le Profil de course </p>
                                        <ul>
                                            <li>soit en cliquant sur le bouton « Ajouter un POI »
                                            </li>
                                            <li>soit en cliquant sur le Tracé de la course
                                            </li>
                                        </ul>
                                        <p>
                                            Les données à renseigner sont « Nom du point » / « Kilométrage (km)» /
                                            « Temps estimé du départ à : POI » soit en renseignant le temps, la vitesse
                                            moyenne ou l’allure moyenne.
                                        </p>
                                    </mat-expansion-panel>


                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Supprimer un POI
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Cliquez sur le POI et dans la pop-up, cliquez sur le bouton « Supprimer ».
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Ajouter une image
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>A l’étape 2 de création d’un roadbook, vous pouvez télécharger une photo
                                            depuis votre ordinateur, soit par Glisser/déposer, soit via le bouton
                                            « Télécharger une image ».
                                        </p>

                                    </mat-expansion-panel>


                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Générer des images DBDB
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Splits® génère de manière automatique et aléatoire des images issues de la
                                            bibliothèque Des Bosses et Des Bulles®. Vous n’avez donc rien à faire, à
                                            part passer à l’Etape 3.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Modifier un roadbook
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Dans l’entrée de Menu « Mes Roadbooks », cliquez sur l’icône pour éditer
                                            votre Roadbook.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Valider un roadbook
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Lorsque le départ de la course est passé, votre Roadbook « complété » ne sera
                                            plus modifiable mais il passera au statut à « Valider ». Cliquez sur l’icône
                                            et renseignez les « heures de passage » à chaque POI créé à l’étape 1. Vous
                                            pouvez alors valider le Roadbook.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Visualiser les statistiques
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Les Statistiques de votre course sont générées après validation du Roadbook
                                            associé. Dans « Mes roadbooks », les Roadbooks archivés présentent le picto
                                            qui permet d’accéder aux Statistiques de la course.
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Imprimer un roadbook
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Les Roadbooks ont été conçus pour être imprimés en recto-verso au format A4.
                                            Une fois pliés, ils seront donc au format A5 pour pouvoir le glisser dans
                                            son sac
                                        </p>

                                    </mat-expansion-panel>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 p-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="faq-items-wrap ">
                                    <h4>Recherche course</h4>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Recherche de course selon mon Profil
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>En renseignant « Mon Profil », à l’étape 3 « Types de course », vous pouvez
                                            indiquer les critères de courses qui vous intéressent. Après enregistrement,
                                            Splits vous proposera les courses adaptées à ces critères dans la rubrique
                                            « les prochaines courses », onglet « Selon votre profil » de votre « Carnet
                                            de courses ».
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Recherche rapide
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Tapez les premières lettres de de la course que vous recherchez dans le
                                            moteur de recherche en haut de l’écran « Les courses ».
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Recherche avancée
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>En cliquant sur le bouton « Recherche avancée », vous pouvez affiner tous les
                                            filtres de recherche.
                                            Le filtre « Rayon de recherche (km) » s’active lorsque vous renseigner le
                                            « lieu de départ ». Très pratique quand vous êtes en vacances
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Effacer les filtres par défaut
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Cliquer sur « Recherche avancée » puis sur « Effacer les filtres ».
                                        </p>

                                    </mat-expansion-panel>


                                    <h4>Statistiques course</h4>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Quelles données sont fournies ?
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Les statistiques d’une course permettent d’obtenir le % d’estimation de votre
                                            course, au global ou à chaque étape de la course. 
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Générer les statistiques d’une course
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Pour générer les statistiques d’une course, il faut valider le roadbook
                                            (cf. Valider un roadbook)
                                        </p>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title><span>Accéder aux statistiques
                                            </span></mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p>Les Statistiques de votre course sont générées après validation du Roadbook
                                            associé. Dans « Mes roadbooks », les Roadbooks archivés présentent le picto
                                            qui permet d’accéder aux Statistiques de la course.
                                        </p>

                                    </mat-expansion-panel>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-accordion>
        </div>
    </div>
</div>
