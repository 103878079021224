import { Injectable } from '@angular/core';
import { SnackbarService } from '../notifications/snackbar.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
              providedIn: 'root'
            })

export class ErrorService {

  constructor(private router: Router, protected snackbarService: SnackbarService, public sessionService: SessionService) {
  }

  extractErrorCode(err: any) {
    let errorCode: any;

    if (err.error instanceof ErrorEvent) {
      // client-side error
      errorCode = err.error.code;
    } else {
      // server-side error
      errorCode = err.status;
    }
    return errorCode ?? 500;
  }

  extractErrorMessage(err: any) {

    let errorMessage: any;

    if (err.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = err.error.message;
    } else {
      // server-side error
      errorMessage = err.message;
    }

    return errorMessage ?? 'Undefined Error';
  }

  handleError(error: any) {

    const errMsg: string = this.extractErrorMessage(error);
    this.snackbarService.openDanger(errMsg);

    return throwError(error);
  }


  handleConnexionError(err: any) {

    const errorMessage = 'Erreur lors de la connexion au serveur. Veuillez vérifier votre connexion internet'; // par défaut

  }

  handleExpiredOrInvalidSessionError(err: any) {

    this.snackbarService.openDanger('Votre session a expiré / invalide. Merci de vous reconnecter.', 'Se connecter')
      .afterOpened().pipe(map(() => {
      this.sessionService.clearSession();
    })).subscribe(() => {
      this.router.navigate(['/']);
    });

  }

  /** Gestion des erreurs de formulaire
   *
   * @param err
   */
  handleFormError(err: any) {

    const errorCode: any = this.extractErrorCode(err);
    let errorMessage: any = (typeof err.error !== 'undefined' && typeof err.error.message !== 'undefined')
                            ? err.error.message : null;


    // form errors
    if (typeof err.error !== 'undefined'
      && typeof err.error.errors !== 'undefined'
      && errorCode === HttpStatusCode.BadRequest) {
      const errorsMessages: string[] = [];

      err.error.errors.forEach((error, index) => {
        errorsMessages.push(error);
      });

      if (errorsMessages.length > 0) {
        errorMessage += ' : ' + errorsMessages.join(' - ');
      }

    }

    return errorMessage;
  }

}
